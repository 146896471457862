// ** React Imports
import React from "react"

// ** React Router Imports
import { useNavigate } from "react-router-dom"

// styles
import Styles from "./jobApplyCard.module.css"

// ** Component Imports
import Button from "../../Button/Button"

function JobApplyCard({ id, title, description, datePosted }) {
  // ** Hooks
  const navigate = useNavigate()

  // ** Handlers
  const handleApply = () => {
    navigate(`/careers/${id}`)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.leftContainer}>
        <h3>{title}</h3>
        <p>{description}</p>
        <span>{datePosted}</span>
      </div>

      <div className={Styles.rightContainer}>
        <Button text="Apply" onClick={handleApply} width={130} />
      </div>
    </div>
  )
}

export default JobApplyCard
