import React from "react"

// styles
import Styles from "./successModal.module.css"

// icons
import { ReactComponent as SuccessIcon } from "../../../assets/icons/imported/success-rounded.svg"

// redux
import { useSelector } from "react-redux"

// components
import WenbitModal from "../../../components/Modal/Modal"
import Button from "../../../components/Button/Button"

function SuccessModal({ open = false, handleClose }) {
  const modalData = useSelector((state) => state.successModalState)

  return (
    <>
      <WenbitModal
        open={open}
        showCloseButton={true}
        onClose={handleClose}
        showLineHeader={true}
        lineHeaderColor="var(--success-color)"
        render={() => (
          <div className={Styles.bodyContainer}>
            <SuccessIcon height={55} width={55} color="var(--positive-color)" />
            <span className={Styles.title}>{modalData.data.title}</span>
            <span className={Styles.description}>
              {modalData.data.subtitle}
            </span>

            <div className={Styles.buttonsContainer}>
              <Button
                text="Cancel"
                variant="dark"
                style={{
                  width: "fit-content",
                  color: "var(--positive-color)"
                }}
                onClick={() => {
                  handleClose()
                }}
              />
              {modalData?.showActionButton && (
                <Button
                  linkTo={modalData.href ?? "#"}
                  type={modalData.href && "link"}
                  text={modalData?.actionButtonText ?? "Confirm"}
                  style={{
                    width: "fit-content",
                    backgroundColor: "var(--positive-color)"
                  }}
                  onClick={() => {
                    modalData.callback
                      ? () => {
                          modalData.callback()
                        }
                      : null
                    handleClose()
                  }}
                />
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default SuccessModal
