// ** React Imports
import React from "react"

// ** Redux Imports
import { store } from "../../../redux/store"

// ** API Imports
import { _getUserCryptoDetails } from "../redux/actions"

// ** Translations Imports
import { FormattedMessage } from "react-intl"

// ** Utils Imports
import { showErrorSnackbar } from "../../snackbar/helpers"
import { showSelectPaymentMethodModal } from "../../modals/select-payment-method/helpers"
import { showCoinifyConfirmPaymentModal } from "../../modals/coinify/confirm-payment/helpers"

export const handleBuyCrypto = ({
  fiatCoin = null,
  cryptoCoin = null,
  fromValue = 0,
  toValue = 0
}) => {
  // ** Constants
  const limitValues = { min: 50, max: 1000 }

  // ** States
  const isLoggedIn = store.getState().userData.value.email
  const userData = store.getState().userData.value

  if (!fiatCoin || !cryptoCoin || !fromValue) {
    showErrorSnackbar({
      alertMessage: "Amount is required"
    })
    return
  }

  if (fromValue < limitValues.min) {
    showErrorSnackbar({
      alertMessage: `Minimim amount permitted is ${
        limitValues.min
      } ${fiatCoin.id.toUpperCase()}`
    })
    return
  }

  if (fromValue > limitValues.max) {
    showErrorSnackbar({
      alertMessage: `Maximum amount permitted is ${
        limitValues.max
      } ${fiatCoin.id.toUpperCase()}`
    })
    return
  }

  // ** Step 1 - Check if user is eligible to buy crypto
  if (!isLoggedIn) {
    showErrorSnackbar({
      alertMessage: <FormattedMessage id="You must login first" />
    })
    return
  }

  // ** Step 2 - Check if user can is permitted to buy crypto
  if (!userData.buy_crypto) {
    showErrorSnackbar({
      alertMessage: (
        <FormattedMessage id="Your account is not permitted to buy crypto" />
      )
    })
    return
  }

  // ** Step 3 - Show the select payment method
  showSelectPaymentMethodModal({
    callback: () => {},
    fiatCoin,
    cryptoCoin,
    fromValue,
    toValue,
    onConfirm: (exchangeRate) =>
      showCoinifyConfirmPaymentModal({
        fiatCoin,
        cryptoCoin,
        fromValue,
        toValue,
        exchangeRate
      })
  })
}
