import React from "react"

// icons
import { ReactComponent as LeftArrowIcon } from "../../assets/icons/imported/left-arrow.svg"
import { ReactComponent as RightArrowIcon } from "../../assets/icons/imported/right-arrow.svg"

// MUI
import Pagination from "@mui/material/Pagination"
import { PaginationItem } from "@mui/material"

function MUIPagination({
  page = 1,
  onChange = () => {},
  pageSize,
  totalCount,
  disabled = false
}) {
  return (
    <Pagination
      disabled={disabled}
      size="small"
      count={Math.ceil(totalCount / pageSize)}
      page={page}
      shape="rounded"
      variant="outlined"
      color="primary"
      onChange={onChange}
      boundaryCount={1}
      siblingCount={1}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: () => (
              <LeftArrowIcon
                style={{ color: "var(--primary-color)" }}
                width={5}
              />
            ),
            next: () => (
              <RightArrowIcon
                style={{ color: "var(--primary-color)" }}
                width={5}
              />
            )
          }}
          {...item}
        />
      )}
    />
  )
}

export default MUIPagination
