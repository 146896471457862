import React, { useEffect, useState } from 'react'

// styles
import Styles from './walletHistoryTab.module.css'

// icons
import deposited from '../../../../assets/icons/deposited.svg'
import withdrawn from '../../../../assets/icons/withdrawn.svg'
import { ReactComponent as Coinify } from '../../../../assets/icons/imported/coinify.svg'

// components
import Search from '../../../../components/Search/Search'
import CoinTag from '../../../../components/CoinTag/CoinTag'

import { _getCurrencies } from '../../../exchange/redux/actions'
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import { _getBuyCryptoHistory, _getDepositHistory, _getWalletHistory, _getWithdrawalHistory } from '../../redux/actions'
import { formatDateTime } from '../../../../utility/formatDateTime'
import { formatDate } from '../../../../utility/formatDate'
import MobileRowDatagrid from '../../../../components/MUIDatagrid/MobileRowDatagrid'
import { Box, capitalize } from '@mui/material'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'
import getWalletHistoryStateColor from '../../../../utility/wallet/getWalletHistoryStateColor'
import getBuyHistoryStateColor from '../../../../utility/wallet/getBuyHistoryStateColor'
import Button from '../../../../components/Button/Button'
import { useSearchParams } from 'react-router-dom'
import Filters from '../../../../components/Filters'

function HistoryTab() {
  document.title = 'Wenbit: Wallet History'

  // ** URL Params
  const [filteringParams, setFilteringParams] = useSearchParams({
    type: 'crypto',
    page: 1
  })

  // table states
  const pageLimit = 10
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const [walletHistoryArray, setWalletHistoryArray] = useState([])
  const [buyCryptoHistoryArray, setBuyCryptoHistoryArray] = useState([])

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 740)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 740)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // ** Handlers
  const fetchHistory = () => {
    setTableLoading(true)
    _getWalletHistory(
      {
        limit: pageLimit,
        page: filteringParams.get('page'),
        currency: filteringParams.get('coin'),
        time_from: filteringParams.get('time_from'),
        time_to: filteringParams.get('time_to'),
        action: filteringParams.get('type') === 'crypto' ? filteringParams.get('action') : null
      },
      res => {
        const dataArray = res.data
        dataArray.map((el, index) => ({
          id: index,
          ...el
        }))
        setTableLoading(false)
        setTotalRows(res.total)
        setWalletHistoryArray(dataArray)
        setTableLoading(false)
      },
      err => setTableLoading(false)
    )
  }

  const fetchBuyCryptoHistory = () => {
    setTableLoading(true)
    _getBuyCryptoHistory(
      {
        pageLimit,
        page: filteringParams.get('page'),
        currency: filteringParams.get('coin'),
        time_from: filteringParams.get('time_from'),
        time_to: filteringParams.get('time_to')
      },
      res => {
        const dataArray = res.data
        dataArray.map((el, index) => ({
          id: index,
          ...el
        }))
        setTableLoading(false)
        setTotalRows(res.total)
        setBuyCryptoHistoryArray(dataArray)
      },
      err => setTableLoading(false)
    )
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set('page', value)
      return newParams
    })
  }

  const handleFiatTabClick = () => {
    setFilteringParams({ type: 'fiat', page: 1 })
    setPage(1)
  }
  const handleCryptoTabClick = () => {
    setFilteringParams({ type: 'crypto', page: 1 })
    setPage(1)
  }

  useEffect(() => {
    // If no page is provided by URL
    if (!filteringParams.get('page')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('page', 1)
        setPage(1)
        return newParams
      })
    } else {
      // Else if page is provided by URL, set the page state
      setPage(Number(filteringParams.get('page')))
    }

    if (!filteringParams.get('type')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('type', 'crypto')
        return newParams
      })
    }
  }, [])

  const walletHistoryColumns = isMobileView
    ? [
        {
          flex: 1,
          field: 'currency',
          headerName: 'Coin',
          minWidth: 200,
          renderCell: data => (
            <MobileRowDatagrid
              rows={[
                {
                  title: 'Action',
                  value: (
                    <span className={Styles.actionColumn}>
                      {data.row.type === 'Deposit' ? 'Deposited' : 'Withdrawn'}
                    </span>
                  )
                },
                { title: 'Amount', value: data.row.amount },
                {
                  title: 'Date/Time',
                  value: <span>{formatDateTime(data.row?.created_at)}</span>
                },
                {
                  title: 'Status',
                  value: (
                    <span
                      style={{
                        color: ['Completed', 'accepted'].includes(data.row.state)
                          ? 'var(--positive-color)'
                          : 'var(--negative-color)',
                        textAlign: 'center'
                      }}
                    >
                      {capitalize(data.row.state)}
                    </span>
                  )
                  // value: <CoinTag
                  //     tag={data.row.state}
                  //     size={12}
                  // style={{
                  //     color: ['succeed', 'accepted'].includes(data.row.state) ? (
                  //         'var(--positive-color)'
                  //     ) : ('var(--negative-color)'),
                  //     textAlign: 'center'
                  // }}
                  // />
                }
              ]}
              renderRowHeader={() => {
                return (
                  <CoinDisplay
                    coinImgURL={data.row.icon_url}
                    coinName={data.row.coin_name}
                    coinTag={data.row.currency?.toUpperCase()}
                  />
                )
              }}
            />
          )
        }
      ]
    : filteringParams.get('type') === 'crypto'
    ? [
        {
          flex: 1,
          field: 'currency',
          headerName: 'Coin',
          minWidth: 200,
          renderCell: data => {
            return (
              <span className={Styles.coinColumn}>
                <img src={data.row.icon_url} />
                <span>{data.row.coin_name}</span>
                <CoinTag tag={data.row.currency?.toUpperCase()} size={12} />
              </span>
            )
          }
        },
        {
          flex: 1,
          field: 'fee',
          headerName: 'Action',
          minWidth: 140,
          renderCell: data => {
            // const currentCoin = testCurrencies.find(el => el?.id === data.row?.currency)
            return (
              <span className={Styles.actionColumn}>
                <img src={data.row.type === 'Deposit' ? deposited : withdrawn} />
                {data.row.type}
              </span>
            )
          }
        },
        {
          flex: 1,
          field: 'amount',
          headerName: 'Amount',
          minWidth: 100,
          maxWidth: 140
        },
        {
          flex: 1,
          field: 'completed_at',
          headerName: 'Date/Time',
          minWidth: 140,
          renderCell: data => <span style={{ color: 'var(--gray-color)' }}>{formatDateTime(data.row?.created_at)}</span>
        },
        {
          flex: 1,
          field: 'state',
          headerName: 'State',
          minWidth: 150,
          // maxWidth: 90,
          renderCell: data => (
            <span
              style={{
                color: getWalletHistoryStateColor(data.row.state)
              }}
            >
              {capitalize(data.row.state)}
            </span>
          )
        }
      ]
    : [
        {
          flex: 1,
          field: 'out_currency',
          headerName: 'Coin',
          minWidth: 160,
          renderCell: ({ row }) => <CoinDisplay useReduxStore coinId={row.out_currency.toLowerCase()} />
        },
        {
          flex: 1,
          field: 'in_currency',
          headerName: 'Paid With',
          minWidth: 160,
          // maxWidth: 180,
          renderCell: ({ row }) => <CoinDisplay useReduxStore coinId={row.in_currency.toLowerCase()} />
        },
        {
          flex: 1,
          field: 'transfer_in_receive_amount',
          headerName: 'Amount Paid',
          minWidth: 160,
          // maxWidth: 180,
          renderCell: ({ row }) => (
            <>
              {row.transfer_in_receive_amount} {row.transfer_in_currency}
            </>
          )
        },
        {
          flex: 1,
          field: 'transfer_out_send_amount',
          headerName: 'Amount Received',
          minWidth: 160,
          renderCell: ({ row }) => (
            <>
              {row.transfer_out_send_amount} {row.out_currency}
            </>
          )
        },
        {
          flex: 1,
          // field: "transfer_out_send_amount",
          headerName: 'Payment Method',
          minWidth: 180,
          renderCell: ({ row }) => (
            <CoinTag
              tag={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px'
                  }}
                >
                  <Coinify width={16} height={16} />
                  Coinify
                </Box>
              }
            ></CoinTag>
          )
        },
        {
          flex: 1,
          field: 'created_at',
          headerName: 'Date Created',
          minWidth: 180,
          renderCell: ({ row }) => <span style={{ color: 'var(--gray-color)' }}>{formatDateTime(row.created_at)}</span>
        },
        {
          flex: 1,
          field: 'state',
          headerName: 'State',
          minWidth: 150,
          // maxWidth: 90,
          renderCell: data => (
            <span
              style={{
                color: getBuyHistoryStateColor(data.row.state)
              }}
            >
              {capitalize(data.row.state)}
            </span>
          )
        }
      ]

  return (
    <div className={Styles.tabContainer}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>Wallet History</h5>
          <span>It comes and goes in bits. Here’s all that’s come in and gone out.</span>
        </div>
      </div>

      {/* tabs container */}
      <div className={Styles.tabsContainer}>
        <Button
          text='Crypto Transactions'
          type='tab'
          onClick={handleCryptoTabClick}
          isTabSelected={filteringParams.get('type') === 'crypto'}
        />
        <Button
          text='Payment Transactions'
          type='tab'
          onClick={handleFiatTabClick}
          isTabSelected={filteringParams.get('type') === 'fiat'}
          style={{ marginRight: '10px' }}
        />
        <Filters
          mobileBreakpoint='1136px'
          filteringParams={filteringParams}
          setFilteringParams={setFilteringParams}
          onChange={filteringParams.get('type') === 'crypto' ? fetchHistory : fetchBuyCryptoHistory}
          filterCoin
          filterDateRange
          filterWalletAction={filteringParams.get('type') === 'crypto'}
        />
      </div>

      <MUIDatagrid
        disableSorting
        page={page}
        loading={tableLoading}
        pageLimit={pageLimit}
        rows={filteringParams.get('type') === 'crypto' ? walletHistoryArray : buyCryptoHistoryArray}
        totalRowsCount={totalRows}
        onPageChange={handlePageChange}
        getRowId={row => row.created_at}
        columns={walletHistoryColumns}
        rowHeight={isMobileView && 180}
        hideHeader={isMobileView}
      />
    </div>
  )
}

export default HistoryTab
