import { Blogs_API } from "../../../../utility/API"

//************************************//
export const _addForm = (data, callback, callbackErr) => {
  Blogs_API.post(`public/add_form`, data)
    .then((response) => {
      if (response.data) {
        callback(response.data)
      }
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getRequestTypes = (callback, callbackErr) => {
  Blogs_API.get(`public/get_request_types`)
    .then((response) => {
      if (response.data) {
        callback(response.data)
      }
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
