// ** React Imports
import React, { useEffect, useState } from "react"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"

// ** Component Imports
import TradingView from "../../../charting_library/charting_library.standalone.js"

// ** API Imports
import {
  dataFeedObject,
  getTradingChartTimezone,
  stdTimezoneOffset
} from "./api"

// ** Utils Imports
import { customWidgetOptions } from "./TradingChartConfig"
import { handleChartOnMessage } from "../../../utility/tradingview/helpers.js"
import { chartTypeToNumber, periodStringToMinutes } from "../utility/helpers"

const TradingViewChart = ({
  hideDefaultStyleContainerStyle = false, // if true the style of wenbit pallete will apply
  containerStyle = {},
  selectedMarketId = null,

  // Chart Variables
  timeframe = 240, // 4 hours
  chartType = "candle",
  urlTheme,

  hideLeftToolbarByDefault = false
}) => {
  const tickersData = useSelector((state) => state.marketTickers.value)
  const selectedMarket = selectedMarketId
    ? { id: selectedMarketId }
    : useSelector((state) => state.selectedMarket.value)
  const markets = useSelector((state) => state.marketList.value)
  const theme = useSelector((state) => state.theme.value)
  const klineData = useSelector((state) => state.kline)

  const datafeed = dataFeedObject(TradingViewChart, markets)
  const currentTimeOffset = new Date().getTimezoneOffset()
  const clockPeriod =
    currentTimeOffset === stdTimezoneOffset(new Date()) ? "STD" : "DST"

  // Default chart container styling
  const defaultContainerStyle = {
    borderRadius: 10,
    overflow: "hidden",
    height: 600,
    padding: 10,
    backgroundColor: "var(--secondary-color)",
    ...containerStyle
  }

  // Default chart widget configs
  const widgetOptions = {
    allow_symbol_change: false,
    autosize: true,
    calendar: true,
    hide_legend: false,
    save_image: true,
    client_id: "tradingview.com",
    custom_css_url: "/chartStyles.css",
    debug: false,
    details: true,
    enabled_features: [
      "charts_auto_save",
      "control_bar",
      "header_in_fullscreen_mode",
      "header_chart_type",
      "header_resolutions",
      "side_toolbar_in_fullscreen_mode",
      "dont_show_boolean_study_arguments",
      "hide_last_na_study_output",
      "save_chart_properties_to_local_storage",
      "remove_library_container_border",
      hideLeftToolbarByDefault && "hide_left_toolbar_by_default"
    ],
    disabled_features: [
      "header_widget",
      "use_localstorage_for_settings",
      "header_symbol_search",
      "symbol_search_hot_key",
      "header_compare",
      "edit_buttons_in_legend",
      "show_hide_button_in_legend",
      "legend_context_menu",
      "context_menu",
      "header_screenshot",
      "header_undo_redo",
      "go_to_date",
      "study_templates",
      "save_shortcut",
      "header_saveload",
      "timeframes_toolbar",
      "widget_logo",
      "always_show_legend_values_on_mobile",
      "format_button_in_legend",
      "delete_button_in_legend"
    ],

    library_path: "/charting_library/",
    show_popup_button: true,
    timeframe: "1D",
    user_id: "public_user_id",
    withdateranges: false,
    charts_storage_url: "https://saveload.tradingview.com",
    charts_storage_api_version: "1.1",
    symbol: selectedMarket.id ?? "btcusdt",
    datafeed,

    // Timeframe and Chart type
    interval: timeframe,
    type: chartType,

    container_id: "tv_chart_container",
    container: "tv_chart_container",
    timezone: getTradingChartTimezone(currentTimeOffset, clockPeriod),
    ...customWidgetOptions(urlTheme ? urlTheme : theme)
  }

  // Initialize the TradingView widget
  useEffect(() => {
    const initWidget = () => {
      if (window.tvWidget) {
        window.tvWidget.remove()
        window.tvWidget = null
      }
      window.tvWidget = new TradingView.widget(widgetOptions)

      // When the chart is ready, adjust the zoom level
      window.tvWidget.onChartReady(() => {
        // Chart Instance
        const chart = window.tvWidget.activeChart()

        if (chart) {
          const now = Date.now()
          const thirtyFiveDaysAgo = now - 35 * 24 * 60 * 60 * 1000 // Coverting 25 days

          chart.setVisibleRange({
            from: thirtyFiveDaysAgo / 1000,
            to: now / 1000
          })
        }
      })
    }

    initWidget()
  }, [theme])

  // Chart K-Line Data
  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        if (klineData.length) {
          const newData = klineData.map((kline) => ({
            time: kline.timestamp * 1000, // Convert to milliseconds
            open: kline.open,
            high: kline.high,
            low: kline.low,
            close: kline.close,
            volume: kline.volume
          }))

          // Use applyNewData to update the chart data
          window.tvWidget.activeChart().SubscribeBarsCallback(newData)
        }
      })
    }
  }, [klineData])

  // On theme change
  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        // Apply theme color overrides
        window.tvWidget.activeChart().applyOverrides({
          ...customWidgetOptions(urlTheme ? urlTheme : theme).overrides
        })

        // window.tvWidget.activeChart().changeTheme(theme)

        // Apply toolbar styling
        // console.log(window.tvWidget.)
        // window.tvWidget.setCSSCustomProperty(
        //   "--toolbar-bg-color",
        //   customWidgetOptions(theme).toolbar_bg
        // )
      })
    }
  }, [theme])

  // On selected market change
  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        window.tvWidget.activeChart().setSymbol(selectedMarket.id, () => { })
      })
    }
  }, [selectedMarket])

  // On timeframe change
  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        const chart = window.tvWidget.activeChart()
        if (chart) {
          chart.setResolution(timeframe)
        }
      })
    }
  }, [timeframe])

  // On chart type change
  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        const chart = window.tvWidget.activeChart()
        if (chart) {
          chart.setChartType(chartTypeToNumber(chartType))
        }
      })
    }
  }, [chartType])

  // Message listener for when a message a send from iframe to the chart widget
  useEffect(() => {
    if (window.tvWidget) {
      window.addEventListener("message", handleChartOnMessage)

      return () => {
        window.removeEventListener("message", handleChartOnMessage)
      }
    }
  }, [])

  return (
    <div
      id="tv_chart_container"
      style={
        hideDefaultStyleContainerStyle
          ? { ...containerStyle }
          : defaultContainerStyle
      }
    ></div>
  )
}

export default TradingViewChart
