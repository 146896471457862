import { setBeneficiary } from "../../../../redux/slices/beneficiarySlice"
import { store } from "../../../../redux/store"
import {
  setActivateBeneficiaryModalData,
  setActivateBeneficiaryModalOpen
} from "../redux/slices/activateBeneficiaryModalSlice"

export const showActivateBeneficiaryModal = (beneficiaryId) => {
  store.dispatch(setActivateBeneficiaryModalOpen(true))
  store.dispatch(setBeneficiary({ id: beneficiaryId }))
}

export const hideActivateBeneficiaryModal = () => {
  store.dispatch(setActivateBeneficiaryModalOpen(false))
  store.dispatch(setActivateBeneficiaryModalData({}))
}
