// ** Utils Imports
import React, { useState } from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Icons Imports
import {
  LuAreaChart,
  LuBarChart4,
  LuCandlestickChart,
  LuLineChart
} from "react-icons/lu"
import { BiCandles } from "react-icons/bi"
import { LiaWaveSquareSolid } from "react-icons/lia"
import { TbChartArea, TbChartCandleFilled, TbTicTac } from "react-icons/tb"
import { ReactComponent as Renko } from "../../../../../../assets/icons/imported/renko.svg"

// ** Component Imports
import HoverPopper from "../../../../../../components/HoverPopper"

// ** Utils Imports
import { capitalize, useMediaQuery } from "@mui/material"
import { chartTypeToNumber } from "../../../../utility/helpers"

function ChartTypeDropdown({ chartType, setChartType }) {
  // ** Media Queries
  const isMobileView = useMediaQuery("(max-width:600px)")

  // ** Statesc
  const [drawerOpen, setDrawerOpen] = useState(false)

  // ** Constants
  const chartTypes = [
    {
      label: "candle",
      value: chartTypeToNumber("candle"),
      icon: <LuCandlestickChart size={20} />
    },
    {
      label: "bar",
      value: chartTypeToNumber("bar"),
      icon: <LuBarChart4 size={20} />
    },
    {
      label: "line",
      value: chartTypeToNumber("line"),
      icon: <LuLineChart size={20} />
    },
    {
      label: "area",
      value: chartTypeToNumber("area"),
      icon: <LuAreaChart size={20} />
    },
    {
      label: "hollowCandle",
      value: chartTypeToNumber("hollowCandle"),
      icon: <BiCandles size={20} />
    },
    {
      label: "heikinAshi",
      value: chartTypeToNumber("heikinAshi"),
      icon: <TbChartCandleFilled size={20} />
    },
    {
      label: "baseline",
      value: chartTypeToNumber("baseline"),
      icon: <TbChartArea size={20} />
    },
    {
      label: "renko",
      value: chartTypeToNumber("renko"),
      icon: <Renko width={20} />
    },
    {
      label: "kagi",
      value: chartTypeToNumber("kagi"),
      icon: <LiaWaveSquareSolid size={20} />
    },
    {
      label: "Point & Figure",
      value: chartTypeToNumber("pointAndFigure"),
      icon: <TbTicTac size={20} />
    }
  ]

  // ** Handlers
  const toggleDrawer = () => {
    setDrawerOpen(false)
  }

  if (!isMobileView)
    return (
      <HoverPopper
        popperContent={
          <div className={styles.container}>
            {chartTypes.map((type) => (
              <button
                onClick={() => setChartType(type)}
                className={`${styles.button} ${
                  chartType.label === type.label && styles.selectedType
                }`}
              >
                {type.icon} {capitalize(type.label)}
              </button>
            ))}
          </div>
        }
      >
        <button className={styles.chartButton}>{chartType.icon}</button>
      </HoverPopper>
    )

  if (isMobileView)
    return (
      <button onClick={toggleDrawer} className={styles.chartButton}>
        {chartType.icon}
      </button>
    )
}

export default ChartTypeDropdown
