import React, { useEffect, useState } from "react"

// styles
import Styles from "./privacy-policy.module.css"

// ** Utils
import { showErrorSnackbar } from "../snackbar/helpers"

// ** Actions
import { _getPolicies } from "./redux/actions"

// ** Components
import PageHeader from "../../components/PageHeader/PageHeader"

export default function PrivacyPolicy() {
  // ** States
  const [policy, setPolicy] = useState("")

  useEffect(() => {
    _getPolicies(
      (data) => setPolicy(data.data.PrivacyPolicy),
      (err) =>
        showErrorSnackbar({ alertMessage: "Error while loading policies" })
    )
  }, [])

  return (
    <div className={Styles.pageContainwhater}>
      <PageHeader
        title={policy.title}
        // sectionTitle="Privacy & Policies"
        // title="lorem ipsum jhfrm hev"
        // description="Lorem ipsum dolor sit amet. Est unde galisum et impedit praesentium aut enim consequatur non galisum ipsam At officia unde! Aut dicta consequatur et fuga
        // Lorem ipsum dolor sit amet. Est unde galisum et impedit praesentium aut enim consequatur non galisum ipsam At officia unde! Aut dicta consequatur et fuga"
      />

      <div
        style={{
          maxWidth: "1100px",
          margin: "0 auto",
          padding: 40
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: policy.body }} />
      </div>
    </div>
  )
}
