import { createSlice } from '@reduxjs/toolkit'

const showPriceSlice = createSlice({
  name: 'showPriceState',
  initialState: {
    showPrice: true
  },
  reducers: {
    setShowPrice: (state, action) => {
      state.showPrice = action.payload
    },
    toggleshowPrice: (state, action) => {
        state.showPrice = action.payload
    }
  }
})

export const { setShowPrice } = showPriceSlice.actions

export default showPriceSlice.reducer