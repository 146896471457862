import React from "react"

// icons
import { ReactComponent as CloseIcon } from "../../../assets/icons/imported/close.svg"

// redux
import { useSelector } from "react-redux"

function CloseCircle({ size = 15, color = "primary", bgColor = "white" }) {
  const iconSize = `calc(${size}px - 35%)`
  return (
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: bgColor,
        width: size,
        height: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CloseIcon
        style={{
          width: iconSize,
          height: iconSize,
          color: `var(--${color}-color)`
        }}
      />
    </div>
  )
}

export default CloseCircle
