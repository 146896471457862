import React from "react"

// styles
import Styles from "./SelectNetworkInput.module.css"

// icons
import { ReactComponent as DropdownIcon } from "../../../assets/icons/imported/down-arrow.svg"
import {
  hideSelectNetworkModal,
  showSelectNetworkModal
} from "../../../modules/modals/deposit-withdraw/select-network/helpers"

function SelectNetworkInput({
  coinId,
  networkName,
  onChange = () => {},
  error
}) {
  const handleSelectNetwork = () => {
    showSelectNetworkModal(coinId, (network) => {
      onChange(network)
      hideSelectNetworkModal()
    })
  }

  return (
    <div className={Styles.selectNetwordContainer}>
      <span>Select network</span>
      <div
        onClick={handleSelectNetwork}
        className={`${error?.message && Styles.errorInput}`}
      >
        {networkName ? (
          <span className={Styles.selectedNetwork}>{networkName}</span>
        ) : (
          <span style={{ color: "var(--gray-color)" }}>Select network</span>
        )}

        <DropdownIcon color="var(--gray-color)" />
      </div>
      {error?.message && (
        <span className={Styles.errorSpan}>
          {error?.message ? error.message : " "}
        </span>
      )}
    </div>
  )
}

export default SelectNetworkInput
