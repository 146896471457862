// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Component Imports
import Input from '../../../../components/Input/Input'
import Search from '../../../../components/Search/Search'
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'
import { debounce } from '@mui/material'

function StakingTableFilters({ filteringParams, setFilteringParams }) {
  // ** Handlers
  const handleSearch = e => {
    setFilteringParams(prevParams => ({ ...prevParams, searchQuery: e.target.value }))
  }

  return (
    <div className={styles.container}>
      <Search placeholder='Search coin name' onChange={debounce(handleSearch, 500)} />

      <Input
        type='select'
        selectDefaultValue='All'
        selectOptions={[
          { label: 'All Durations', value: 'All' },
          { label: 'flexible', value: 'flexible' },
          { label: 15, value: 15 },
          { label: 30, value: 30 },
          { label: 60, value: 60 },
          { label: 90, value: 90 },
          { label: 120, value: 120 }
        ]}
        containerStyle={{ marginBottom: '-10px', height: 89 }}
        variant='secondary'
      />

      <CheckboxInput label='Match my assets' />
    </div>
  )
}

export default StakingTableFilters
