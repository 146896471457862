import { API } from "../../../../../../utility/API"

//************************************//
export const _sendOtp = (data, callback, callbackErr) => {
  // persistStore().purge()
  API.post(`authenticate/resource/phones/request_otp`, data)
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _verifyOtp = (data, callback, callbackErr) => {
    // persistStore().purge()
    API.post(`authenticate/resource/phones/disable_sms_auth`, data)
      .then(function ({ data }) {
        callback({ data })
      })
      .catch(function (error) {
        callbackErr(error.response.data)
      })
  }
