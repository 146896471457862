// ** Next Import
import React, { lazy, Suspense } from 'react'

// ! To avoid 'Window is not defined' error
const ReactApexcharts = lazy(() => import('react-apexcharts'))

function ReactChart(props) {
  return (
    <Suspense
      fallback={
        <div>
          <strong>_._</strong>
        </div>
      }
    >
      <ReactApexcharts {...props} />
    </Suspense>
  )
}

export default ReactChart
