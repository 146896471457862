import { createSlice, current } from "@reduxjs/toolkit"
import { groupByTicketSize, applyUpdatesToRawData, sortAndFilterOrders } from "../helpers" // Assuming the helper functions are in this path

const MAX_ORDER_BOOK_ENTRIES = 10 // Maximum number of entries in the order book
const ORDERBOOK_LEVELS = 10 // rows count
const MAX_RAW_ORDERS = 500
const deffaultTicketSize = 0.001
export const sortAsks = (asks) => {
  return asks.sort((a, b) => Number(a[0]) - Number(b[0]))
}
export const sortBids = (bids) => {
  return bids.sort((a, b) => Number(b[0]) - Number(a[0]))
}

const sliceArray = (data, index) => {
  if (!data || data.length === 0) {
    return []
  }

  return data.slice(0, index)
}

export const handleIncrementalUpdate = (depthOld, newLevel, type) => {
  if (newLevel.length !== 2) {
    return depthOld
  }
  const index = depthOld.findIndex(([price]) => +price === +newLevel[0])
  if (index === -1 && +newLevel[1]) {
    const data = [...depthOld, newLevel]
    if (type === "asks") {
      return sortAsks(data)
    }
    return sortBids(data)
  }
  const result = [...depthOld]
  if (Number(newLevel[1]) !== 0) {
    result[index] = newLevel
  } else {
    result.splice(index, 1)
  }
  return result
}

export const handleIncrementalUpdateArray = (depthOld, newLevels, type) => {
  const prices = {}
  for (let i = 0; i < newLevels.length; i += 1) {
    prices[newLevels[i][0]] = newLevels[i][1]
  }
  const rest = depthOld.filter(([price]) => !prices[price])
  const newData = newLevels.filter(([_, amount]) => Number(amount) !== 0)
  const result = [...rest, ...newData]
  if (type === "asks") {
    return sortAsks(result)
  }
  return sortBids(result)
}

const addTotalSums = (orders) => {
  const totalSums = []

  return orders.map((order, idx) => {
    const size = parseFloat(order[1])
    if (typeof order[2] !== "undefined") {
      return order
    } else {
      const updatedLevel = [...order]
      const totalSum = idx === 0 ? size : size + totalSums[idx - 1]
      updatedLevel[2] = totalSum
      totalSums.push(totalSum)
      return updatedLevel
    }
  })
}

const addDepths = (orders, maxTotal) => {
  return orders.map((order) => {
    const calculatedTotal = parseFloat(order[1])
    const depth = (calculatedTotal / maxTotal) * 100
    const updatedOrder = [...order]
    updatedOrder[3] = depth
    return updatedOrder
  })
}

const getMaxTotalSum = (orders) => {
  const totalSums = orders.map((order) => order[1])
  return Math.max.apply(Math, totalSums)
}

// const removePriceLevel = (price, levels) => levels.filter(level => level[0] !== price)
const removePriceLevel = (deltaLevelPrice, updatedLevels) => {
  return updatedLevels.filter((level) => level[0] !== deltaLevelPrice)
}
const updatePriceLevel = (updatedLevel, levels) => {
  return levels.map((level) => {
    if (level[0] === updatedLevel[0]) {
      level = updatedLevel
    }
    return level
  })
}

const levelExists = (deltaLevelPrice, currentLevels) =>
  currentLevels.some((level) => level[0] === deltaLevelPrice)

const addPriceLevel = (deltaLevel, levels) => {
  return [...levels, deltaLevel]
}

const applyDeltas = (currentLevels, orders) => {
  let updatedLevels = currentLevels

  orders.forEach((deltaLevel) => {
    const deltaLevelPrice = deltaLevel[0]
    const deltaLevelSize = parseFloat(deltaLevel[1])

    // If new size is zero - delete the price level
    // if (deltaLevelSize === 0) {
    //     console.log(`Removing level: ${deltaLevelPrice}`)

    //   updatedLevels = removePriceLevel(deltaLevelPrice, updatedLevels)
    // } else {
    // If the price level exists and the size is not zero, update it
    if (levelExists(deltaLevelPrice, currentLevels)) {
      updatedLevels = updatePriceLevel(deltaLevel, updatedLevels)
    } else {
      // If the price level doesn't exist in the orderbook and there are less than 25 levels, add it
      if (updatedLevels.length < ORDERBOOK_LEVELS) {
        updatedLevels = addPriceLevel(deltaLevel, updatedLevels)
      } else {
        // console.log("<<<<<<<<<<<<<<<<<<WARNING!!!>>>>>>>>>>>>>>>>>>")
      }
      // }
    }
  })
  // console.log(updatedLevels)
  return updatedLevels
}

export const sortOrders = (orders, lastTrade) => {
  // Group orders by price
  const groupedOrders = orders.reduce((acc, order) => {
    const price = parseFloat(order[0])
    const size = parseFloat(order[1])
    if (!acc[price]) {
      acc[price] = 0
    }
    acc[price] += size
    return acc
  }, {})

  // Convert the grouped orders back to an array
  let sortedGroupedOrders = Object.entries(groupedOrders).map(
    ([price, size]) => [parseFloat(price), size]
  )

  // Filter out too high or too low prices if lastTrade is defined
  if (lastTrade !== undefined) {
    const threshold = 0.1 // Define a threshold for "too far", e.g., 10%
    sortedGroupedOrders = sortedGroupedOrders.filter(
      ([price]) =>
        price <= lastTrade * (1 + threshold) &&
        price >= lastTrade * (1 - threshold)
    )
  }

  // Sort the orders: asks in ascending, bids in descending order
  // sortedGroupedOrders.sort((a, b) => type === 'asks' ? a[0] - b[0] : b[0] - a[0])

  // Limit to 1000 orders
  return sortedGroupedOrders.slice(0, 10)
}

export const orderBookSlice = createSlice({
  name: "orderBook",
  initialState: {
    value: {
      asks: [],
      bids: [],
      sequence: null
    },
    asks: [],
    rawAsks: [],
    bids: [],
    lastTrade: '',
    rawBids: [],
    sequence: null,
    load: true,
    matching_engine: true,
    groupingSize: 0.01, // Added grouping size state
    maxTotalBids: 0,
    maxTotalAsks: 0
  },
  reducers: {
    changeOrderBook: (state, payload) => {
      state.value = payload.payload
    },
    clearOrderBook: (state, payload) => {
      state.rawBids = []
      state.rawAsks = []
      state.maxTotalBids = ""
      state.maxTotalAsks = ""
      state.bids = []
      state.asks = []
    },
    addOrderBook: (state, action) => {
      // Grouping logic added here
      const groupedOrders = groupByTicketSize(
        [action.payload, ...state.value],
        state.groupingSize
      )
      state.value = groupedOrders.slice(0, MAX_ORDER_BOOK_ENTRIES)
    },
    addOrderBookBatch: (state, action) => {
      // Grouping logic added here
      const groupedOrders = groupByTicketSize(
        [...action.payload, ...state.value],
        state.groupingSize
      )
      state.value = groupedOrders.slice(0, MAX_ORDER_BOOK_ENTRIES)
    },
    setOrderBookLoad: (state, payload) => {
      state.load = payload.payload
    },

    updateOrderBookSnaapshot: (state, action) => {
      const { asks, bids, sequence = 1 } = action.payload
      const groupedAsks = groupByTicketSize(asks, state.groupingSize)
      const groupedBids = groupByTicketSize(bids, state.groupingSize)

      return {
        ...state,
        asks: sliceArray(groupedAsks, MAX_ORDER_BOOK_ENTRIES),
        bids: sliceArray(groupedBids, MAX_ORDER_BOOK_ENTRIES),
        sequence,
        loading: false
      }
    },
    updateOrderBookSnapshot: (state, { payload }) => {
      const rawBids = payload.bids
      const rawAsks = payload.asks
      const bids = groupByTicketSize(rawBids, current(state).groupingSize)
      const asks = groupByTicketSize(rawAsks, current(state).groupingSize)

      // state.market = payload['product_id']
      // state.rawBids = rawBids
      // state.rawAsks = rawAsks
      state.maxTotalBids = getMaxTotalSum(bids)
      state.maxTotalAsks = getMaxTotalSum(asks)
      state.bids = addDepths(bids, current(state).maxTotalBids)
      state.asks = addDepths(asks, current(state).maxTotalAsks)
    },
    addBids: (state, { payload }) => {
      const { bids: newBids, lastTrade } = payload
      // Apply updates to raw data
      const updatedRawBids = applyUpdatesToRawData(state.rawBids, newBids)
      state.rawBids = [...updatedRawBids]
      if (state.rawBids.length > 300) {
        state.rawBids = state.rawBids.slice(-300)
      }
      // state.rawBids = updatedRawBids.slice(0, 10)

      // Regroup the updated raw data based on the current ticket size
      const groupedBids = groupByTicketSize(updatedRawBids, state.groupingSize)

      // Filter and sort the grouped data
      const sortedAndFilteredBids = sortAndFilterOrders(groupedBids, lastTrade, "bids").slice(0, MAX_ORDER_BOOK_ENTRIES)
      
      state.maxTotalBids = getMaxTotalSum(sortedAndFilteredBids)

      state.bids = addDepths(sortedAndFilteredBids, state.maxTotalBids)
    },
    
    addAsks: (state, { payload }) => {
      const { asks: newAsks, lastTrade } = payload
      // Apply updates to raw data
      const updatedRawAsks = applyUpdatesToRawData(state.rawAsks, newAsks)
      state.rawAsks = [...updatedRawAsks]
      if (state.rawAsks.length > 300) {
        state.rawAsks = state.rawAsks.slice(-300)
      }
      // console.log(state.asks.length)
      // console.log(state.bids.length)
      // console.log(state.rawAsks.length)
      // console.log(state.rawBids.length)
      // Regroup the updated raw data based on the current ticket size
      const groupedAsks = groupByTicketSize(updatedRawAsks, state.groupingSize)

      // Filter and sort the grouped data
      const sortedAndFilteredAsks = sortAndFilterOrders(groupedAsks, lastTrade, "asks").slice(0, MAX_ORDER_BOOK_ENTRIES)
      
      state.maxTotalAsks = getMaxTotalSum(sortedAndFilteredAsks)
      state.asks = addDepths(sortedAndFilteredAsks, state.maxTotalAsks)
    },
    updateOrderBookIncrement: (state, action) => {
      const payload = {
        ...state,
        sequence: action.payload.sequence
      }

      if (!state.matching_engine) {
        const { asks, bids, sequence } = action.payload
        const groupedAsks = groupByTicketSize(asks, state.groupingSize)
        const groupedBids = groupByTicketSize(bids, state.groupingSize)

        return {
          ...state,
          asks: sliceArray(groupedAsks, MAX_ORDER_BOOK_ENTRIES),
          bids: sliceArray(groupedBids, MAX_ORDER_BOOK_ENTRIES),
          sequence
        }
      }

      if (action.payload.asks) {
        const groupedAsks = groupByTicketSize(
          action.payload.asks,
          state.groupingSize
        )
        payload.asks = Array.isArray(groupedAsks[0])
          ? handleIncrementalUpdateArray(state.asks, groupedAsks, "asks").slice(
            0,
            MAX_ORDER_BOOK_ENTRIES
          )
          : handleIncrementalUpdate(state.asks, groupedAsks, "asks").slice(
            0,
            MAX_ORDER_BOOK_ENTRIES
          )
      }

      if (action.payload.bids) {
        const groupedBids = groupByTicketSize(
          action.payload.bids,
          state.groupingSize
        )
        payload.bids = Array.isArray(groupedBids[0])
          ? handleIncrementalUpdateArray(state.bids, groupedBids, "bids").slice(
            0,
            MAX_ORDER_BOOK_ENTRIES
          )
          : handleIncrementalUpdate(state.bids, groupedBids, "bids").slice(
            0,
            MAX_ORDER_BOOK_ENTRIES
          )
      }

      return payload
    },

    setGroupingSize: (state, action) => {
      const groupingSize = action.payload
      state.groupingSize = groupingSize // New reducer for setting grouping size
      // const bids = groupByTicketSize(rawBids, groupingSize)
      // const asks = groupByTicketSize(rawAsks, groupingSize)

      const groupedAsks = groupByTicketSize(state.asks, groupingSize)
      const groupedBids = groupByTicketSize(state.bids, groupingSize)

      const sortedAndFilteredAsks = sortAndFilterOrders(groupedAsks, state.lastTrade, "asks")
      const sortedAndFilteredBids = sortAndFilterOrders(groupedBids, state.lastTrade, "bids")

      state.maxTotalAsks = getMaxTotalSum(sortedAndFilteredAsks)
      state.maxTotalBids = getMaxTotalSum(sortedAndFilteredBids)

      const newAsksWithDepth = addDepths(sortedAndFilteredAsks.slice(0, 10), state.maxTotalAsks)
      const newBidsWithDepth = addDepths(sortedAndFilteredBids.slice(0, 10), state.maxTotalBids)


      state.asks = newAsksWithDepth
      state.bids = newBidsWithDepth
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  changeOrderBook,
  clearOrderBook,
  addOrderBook,
  setOrderBookLoad,
  addOrderBookBatch,
  updateOrderBookIncrement,
  updateOrderBookSnapshot,
  setGroupingSize,
  addAsks,
  addBids
} = orderBookSlice.actions

export default orderBookSlice.reducer
