import { store } from '../../../../redux/store'
import {
  setStatkingSubscribeSuccessModalOpen,
  setStatkingSubscribeSuccessModalData
} from './redux/statkingSubscribeSuccessModalSlice'

export const showStakingSubscribeSuccessModal = () => {
  store.dispatch(
    setStatkingSubscribeSuccessModalData({
      title: 'Subscribe'
    })
  )
  store.dispatch(setStatkingSubscribeSuccessModalOpen(true))
}

export const hideStakingSubscribeSuccessModal = () => {
  store.dispatch(setStatkingSubscribeSuccessModalOpen(false))
}
