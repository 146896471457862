import React, { useState, useEffect } from 'react'

// react router
import { Link } from 'react-router-dom'

// packages
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { FormattedMessage, injectIntl } from 'react-intl'

// components
import Button from '../../../../components/Button/Button'
import RadioButton from '../../../../components/RadioButton/RadioButton'
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput'
import ToolTip from '../../../../components/Tooltip/Tooltip'

// styles
import Styles from './marketForm.module.css'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'

// icons
import { ReactComponent as FlagIcon } from '../../../../assets/icons/imported/finish.svg'
import { ReactComponent as MarketIcon } from '../../../../assets/icons/imported/market.svg'

// redux
import { _addOrder } from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { _getBalances } from '../../../../redux/actions'
import { setBalances } from '../../../../redux/slices/balancesSlice'
import { addOrders, addOrder } from '../../redux/slices/openOrdersSlice'
import { setSnackbarData, setSnackbarOpen } from '../../../snackbar/redux/snackbarSlice'

// other
import { formatNumber } from '../../../helpers/formatNumber'
import { estimateUnitValue } from '../../../helpers/estimateValueBase'

const sliderMarks = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%'
}

function MarketForm() {
  const dispatch = useDispatch()
  //user data
  const userData = useSelector(state => state.userData?.value)
  const balances = useSelector(state => state.balances.value)
  const loggedIn = useSelector(state => state.loggedIn.value)

  const marketsList = useSelector(state => state.marketList.value)
  const selectedMarket = useSelector(state => state.selectedMarket.value)
  const reduxLimitPrice = useSelector(state => state.limitPrice.value)
  const [buyQuantity, setBuyQuantity] = useState('')
  const [sellQuantity, setSellQuantity] = useState('')
  const [sellPrice, setSellPrice] = useState('')
  const [buyPrice, setBuyPrice] = useState('')
  const [toCurrency, setToCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
  const [fromCurrency, setFromCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
  const [selectedRadioButton, setSelectedRadioButton] = useState('limit')
  const marketTickers = useSelector(state => state.marketTickers.value)
  const currencies = useSelector(state => state.currencies.value)
  const [buyLimitPrice, setBuyLimitPrice] = useState('')
  const [sellLimitPrice, setSellLimitPrice] = useState('')
  const [refreshBalance, setRefreshBalance] = useState(false)
  const [buySliderValue, setBuySliderValue] = useState(0)
  const [sellSliderValue, setSellSliderValue] = useState(0)
  const [buyTotalPrice, setBuyTotalPrice] = useState('')
  const [sellTotalPrice, setSellTotalPrice] = useState('')
  const [sellConvertedPrice, setSellConvertedPrice] = useState('')
  const [buyConvertedPrice, setBuyConvertedPrice] = useState('')

  const handleBuySliderChange = value => {
    setBuySliderValue(value)
    let currency
    if (selectedRadioButton === 'limit') {
      currency = balances.find(el => el.currency === fromCurrency)
      if (buyLimitPrice) {
        setBuyQuantity((currency.balance * value) / 100)
      }
    } else {
      currency = balances.find(el => el.currency === toCurrency)
      setBuyPrice((currency.balance * value) / 100)
    }
  }

  const handleSellSliderChange = value => {
    setSellSliderValue(value)
    let currency
    if (selectedRadioButton === 'limit') {
      currency = balances.find(el => el.currency === fromCurrency)
      if (sellLimitPrice) {
        setSellQuantity((currency.balance * value) / 100)
      }
    } else {
      currency = balances.find(el => el.currency === toCurrency)
      setSellPrice((currency.balance * value) / 100)
    }
  }
  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [modalStates, setModalStates] = useState({
    quickBuySellOpen: false,
    convertOpen: false
  })

  useEffect(() => {
    setSellLimitPrice(parseFloat(reduxLimitPrice.replace(/,/g, '')))
    setBuyLimitPrice(parseFloat(reduxLimitPrice.replace(/,/g, '')))
  }, [reduxLimitPrice])

  const [isTableCollapsed, setIsTableCollapsed] = useState(window.innerWidth < 940)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700)

  const handleResize = () => {
    setIsTableCollapsed(window.innerWidth < 900)
    setIsMobileView(window.innerWidth < 700)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  useEffect(() => {
    setFromCurrency(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
    setToCurrency(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
    setBuyConvertedPrice(
      selectedRadioButton === 'limit'
        ? buyTotalPrice / buyLimitPrice
        : estimateUnitValue(
            buyQuantity ? toCurrency : fromCurrency,
            buyQuantity ? fromCurrency : toCurrency,
            parseFloat(buyQuantity || buyPrice),
            currencies,
            marketsList,
            marketTickers
          )
    )
  }, [
    buyQuantity,
    selectedRadioButton,
    selectedMarket,
    buyPrice,
    currencies,
    marketTickers,
    buyTotalPrice,
    buyConvertedPrice
  ])

  const resetInputValues = () => {
    setSellPrice('')
    setBuyPrice('')
    setBuyQuantity('')
    setSellQuantity('')
    setBuyLimitPrice('')
    setSellLimitPrice('')
    setBuySliderValue(0)
    setSellSliderValue(0)
  }

  useEffect(() => {
    setBuySliderValue(0)
    setSellSliderValue(0)
    console.log(userData)
    if (loggedIn) {
      _getBalances(
        res => {
          dispatch(setBalances(res))
        },
        () => {}
      )
    }
  }, [refreshBalance, selectedMarket])

  useEffect(() => {
    resetInputValues()
  }, [selectedMarket, selectedRadioButton])

  useEffect(() => {
    setFromCurrency(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
    setToCurrency(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
    setSellConvertedPrice(
      selectedRadioButton === 'limit'
        ? sellTotalPrice / sellLimitPrice
        : estimateUnitValue(
            sellQuantity ? toCurrency : fromCurrency,
            sellQuantity ? fromCurrency : toCurrency,
            parseFloat(sellQuantity || sellPrice),
            currencies,
            marketsList,
            marketTickers
          )
    )
  }, [sellQuantity, marketTickers, selectedMarket, sellPrice, sellTotalPrice, sellConvertedPrice])

  const handleSubmit = orderType => {
    console.log(sellQuantity)
    _addOrder(
      {
        market: selectedMarket.id,
        side: orderType,
        volume: orderType === 'buy' ? buyQuantity || buyConvertedPrice : sellQuantity || sellConvertedPrice,
        ...(selectedRadioButton === 'limit' && {
          price: orderType === 'buy' ? buyLimitPrice : sellLimitPrice
        }),
        ord_type: selectedRadioButton
      },
      data => {
        dispatch(
          setSnackbarData({
            alertMessage: 'Order set',
            buttonMessage: 'See order',
            callback: () => {},
            actionType: 'link', // link - close
            linkTo: '/account/orders/open',
            severity: 'success',
            position: 'topRight'
          })
        )
        dispatch(setSnackbarOpen(true))
        // dispatch(setSnackbarData({
        //   alertMessage: 'Information Added Successfully',
        //   severity: 'success',
        //   callback: () => { },
        //   position: 'topRight'
        // }))
        //dispatch(addOrder(data))
        resetInputValues()
        setRefreshBalance(!refreshBalance)
      },
      error => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
      }
    )
  }
  const handleBuyQuantityChange = event => {
    setBuyQuantity(event.target.value)
    setBuyPrice('')
    setBuyTotalPrice('')
  }

  const handleBuyPriceChange = event => {
    setBuyPrice(event.target.value)
    setBuyQuantity('')
  }

  const handleBuyTotalPriceChange = event => {
    console.log(event.target.value)
    setBuyTotalPrice(event.target.value)
    setBuyQuantity('')
  }

  const handleSellTotalPriceChange = event => {
    setSellTotalPrice(event.target.value)
    setSellQuantity('')
  }

  const handleBuyLimitPriceChange = event => {
    console.log(event.target.value)
    setBuyLimitPrice(event.target.value)
  }

  const handleSellLimitPriceChange = event => {
    setSellLimitPrice(event.target.value)
  }
  const handleSellQuantityChange = event => {
    setSellQuantity(event.target.value)
    setSellPrice('')
    setSellTotalPrice('')
  }

  const handleSellPriceChange = event => {
    setSellPrice(event.target.value)
    setSellQuantity('')
  }
  const coinNamePrice = useSelector(state => state.selectedMarket.value?.name?.split('/')[1])
  return (
    <div className={Styles.card}>
      {/* radio buttons container */}
      <div className={Styles.radioButtonsContainer}>
        <RadioButton
          text={<FormattedMessage id='page.markets.trade.limit' />}
          icon={<FlagIcon />}
          isSelected={selectedRadioButton === 'limit'}
          onClick={() => {
            if (selectedRadioButton === 'limit') return
            setSelectedRadioButton('limit')
          }}
        />
        <RadioButton
          text={<FormattedMessage id='page.markets.trade.market' />}
          icon={<MarketIcon width={12} />}
          isSelected={selectedRadioButton === 'market'}
          onClick={() => {
            if (selectedRadioButton === 'market') return
            setSelectedRadioButton('market')
          }}
        />

        <ToolTip
          toolTipStyle={{
            margin: '0 0 auto auto'
          }}
          text={
            <>
              <div>
                Limit: Want to buy or sell at a specific price? A limit order lets you do that, but remember that it
                might not always go through.A limit order lets you do that, but remember that it might not always go
                through.
              </div>
              <div
                style={{
                  marginTop: 20
                }}
              >
                Market: Buying or selling now means you'll get a quick trade, taking crypto prices from the current
                market.
              </div>
            </>
          }
        />
      </div>

      {/* MAIN Buy/Sell container */}
      <div className={Styles.mainBuySellContainer}>
        {/* Buy Container */}
        <div className={Styles.buyContainer}>
          <span className={Styles.balanceAvailable}>
            {userData.email &&
              (balances ? (
                balances.find(el => el.currency === toCurrency)?.balance ? (
                  `Available balance: ${
                    formatNumber(balances.find(el => el.currency === toCurrency)?.balance) +
                      ' ' +
                      balances.find(el => el.currency === toCurrency)?.currency.toUpperCase() ?? '0.00'
                  }`
                ) : (
                  `Available balance: 0.00 ${toCurrency?.toUpperCase()}`
                )
              ) : (
                <ShimmerLine height='10px' width='70px' />
              ))}
          </span>
          <CurrencyInput
            title={selectedRadioButton === 'limit' ? `Limit Price` : 'Price'}
            onChange={selectedRadioButton === 'limit' ? handleBuyLimitPriceChange : handleBuyPriceChange}
            type='number'
            onDropdownClick={() => {}}
            disabled={selectedRadioButton === 'market'}
            placeholder={selectedRadioButton === 'limit' ? '0.00' : buyConvertedPrice ? buyConvertedPrice : '0.00'}
            value={selectedRadioButton === 'limit' ? buyLimitPrice : buyPrice}
            containerStyle={{
              marginTop: 15
            }}
            // currencyImg={useSelector(state => state.selectedMarket.value?.icon_url) ?? ''}
            currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.quote_url}
            coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[1])}
          />

          <CurrencyInput
            title={`Amount`}
            onChange={handleBuyQuantityChange}
            type='number'
            onDropdownClick={() => {}}
            placeholder='0.00'
            value={buyQuantity || buyConvertedPrice ? buyQuantity || buyConvertedPrice : ''}
            containerStyle={{
              marginTop: 15
            }}
            coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])}
            currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
          />
          {/* slider here */}
          {userData.email && (
            <Slider
              min={0}
              max={100}
              marks={sliderMarks}
              value={buySliderValue}
              onChange={handleBuySliderChange}
              trackStyle={[{ backgroundColor: 'var(--primary-color)' }]}
              railStyle={{ backgroundColor: 'var(--gray-color)' }}
              handleStyle={{
                height: 12,
                width: 12,
                marginTop: -3.5,
                backgroundColor: 'white',
                border: `2px solid var(--primary-color)`,
                borderRadius: '50%',
                boxShadow: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              dotStyle={{
                backgroundColor: 'var(--gray-color)',
                borderColor: 'var(--gray-color)'
              }}
              activeDotStyle={{
                backgroundColor: 'var(--primary-color)',
                borderColor: 'var(--primary-color)'
              }}
              style={{ margin: '30px auto', width: '90%' }}
            />
          )}
          {selectedRadioButton === 'limit' && (
            <CurrencyInput
              title={`Total`}
              // currencyIcon=""
              onChange={handleBuyTotalPriceChange}
              type='number'
              // disabled={true}
              onDropdownClick={() => {}}
              placeholder='0.00'
              value={
                buyTotalPrice
                  ? buyTotalPrice
                  : buyPrice || buyLimitPrice * buyQuantity
                  ? buyPrice || buyLimitPrice * buyQuantity
                  : ''
              }
              containerStyle={{
                marginTop: 15
              }}
              coinName={coinNamePrice}
            />
          )}

          {/* buy button */}
          {userData.email ? (
            <Button
              text={`Buy ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`}
              style={{
                marginTop: 30,
                backgroundColor: '#10B981',
                color: 'white'
              }}
              onClick={e => {
                handleSubmit('buy')
              }}
              className={Styles.buyButton}
            />
          ) : (
            <Button type='loggedOut' />
          )}
        </div>

        {/* Sell Container */}
        <div className={Styles.sellContainer}>
          <span className={Styles.balanceAvailable}>
            {userData.email &&
              (balances ? (
                balances.find(el => el.currency === fromCurrency)?.balance ? (
                  `Available balance: ${
                    formatNumber(balances.find(el => el.currency === fromCurrency)?.balance) +
                      ' ' +
                      balances.find(el => el.currency === fromCurrency)?.currency.toUpperCase() ?? '0.00'
                  }`
                ) : (
                  `Available balance: 0.00 ${fromCurrency?.toUpperCase()}`
                )
              ) : (
                <ShimmerLine height='10px' width='70px' />
              ))}
          </span>
          <CurrencyInput
            title={selectedRadioButton === 'limit' ? `Limit Price` : 'Price'}
            onChange={selectedRadioButton === 'limit' ? handleSellLimitPriceChange : handleSellPriceChange}
            disabled={selectedRadioButton === 'market'}
            type='number'
            onDropdownClick={() => {}}
            placeholder={selectedRadioButton === 'limit' ? '0.00' : sellConvertedPrice ? sellConvertedPrice : '0.00'}
            value={selectedRadioButton === 'limit' ? sellLimitPrice : sellPrice}
            containerStyle={{
              marginTop: 15
            }}
            currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.quote_url}
            coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[1])}
          />
          <CurrencyInput
            title={`Amount`}
            onChange={handleSellQuantityChange}
            type='number'
            onDropdownClick={() => {}}
            placeholder='0.00'
            value={sellQuantity || sellConvertedPrice ? sellQuantity || sellConvertedPrice : ''}
            containerStyle={{
              marginTop: 15
            }}
            coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])}
            currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
          />

          {/* slider here */}
          {userData.email && (
            <Slider
              min={0}
              max={100}
              marks={sliderMarks}
              value={sellSliderValue}
              onChange={handleSellSliderChange}
              trackStyle={[{ backgroundColor: 'var(--primary-color)' }]}
              railStyle={{ backgroundColor: 'var(--gray-color)' }}
              handleStyle={{
                height: 12,
                width: 12,
                marginTop: -3.5,
                backgroundColor: 'white',
                border: `2px solid var(--primary-color)`,
                borderRadius: '50%',
                boxShadow: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              dotStyle={{
                backgroundColor: 'var(--gray-color)',
                borderColor: 'var(--gray-color)'
              }}
              activeDotStyle={{
                backgroundColor: 'var(--primary-color)',
                borderColor: 'var(--primary-color)'
              }}
              style={{ margin: '30px auto', width: '90%' }}
            />
          )}
          {selectedRadioButton === 'limit' && (
            <CurrencyInput
              title={`Total`}
              currencyIcon=''
              onChange={handleSellTotalPriceChange}
              type='number'
              // disabled={true}
              onDropdownClick={() => {}}
              placeholder='0.00'
              value={
                sellTotalPrice
                  ? sellTotalPrice
                  : sellPrice || sellLimitPrice * sellQuantity
                  ? sellPrice || sellLimitPrice * sellQuantity
                  : ''
              }
              containerStyle={{
                marginTop: 15
              }}
              coinName={coinNamePrice}
            />
          )}

          {/* sell button */}
          {userData.email ? (
            <Button
              text={`Sell ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`}
              style={{
                marginTop: 30,
                backgroundColor: 'rgba(240,68,68,255)',
                color: 'white'
              }}
              onClick={e => {
                handleSubmit('sell')
              }}
            />
          ) : (
            <Button type='loggedOut' />
          )}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(MarketForm)
