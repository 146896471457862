import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'

// styles
import Styles from './orders.module.css'

// icons
import { ReactComponent as LinkIcon } from '../../../../assets/icons/imported/link.svg'
import { ReactComponent as TrashIcon } from '../../../../assets/icons/imported/trash.svg'
import { ReactComponent as SellArrowSquare } from '../../../../assets/icons/imported/sell-arrow-square.svg'
import { ReactComponent as BuyArrowSquare } from '../../../../assets/icons/imported/buy-arrow-square.svg'
import { FaTrash } from 'react-icons/fa'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { _getOpenOrders, _cancelOrder, _getOrderHistory } from '../../redux/actions'
import { addOrders, addOrder, clearOrders } from '../../redux/slices/openOrdersSlice'
import { setSnackbarOpen, setSnackbarData } from '../../../snackbar/redux/snackbarSlice'

// components
import Button from '../../../../components/Button/Button'
import CoinTag from '../../../../components/CoinTag/CoinTag'
import TableButton from '../../../../components/TableButton/TableButton'
import MobileRowDatagrid from '../../../../components/MUIDatagrid/MobileRowDatagrid'

// helpers
import { isoStringToDate } from '../../../helpers/localeDate'
import { getTimezone } from '../../../helpers/timezone'

// MUI DATAGRID
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import { IconButton, capitalize } from '@mui/material'

function Orders() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openOrders = useSelector(state => state.openOrders.value)
  const [ordersToShow, setOrdersToShow] = useState(openOrders)
  const [tableLoading, setTableLoading] = useState(false)

  const userData = useSelector(state => state.userData?.value)
  const loggedIn = useSelector(state => state.loggedIn.value)
  const markets = useSelector(state => state.marketList.value)

  const [selectedTab, setSelectedTab] = useState('open') // open - history

  // ----- RESPONSIVENESS STATE -----   ----
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 840)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 840)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  useEffect(() => {
    if (loggedIn) {
      setTableLoading(true)
      if (selectedTab === 'open') {
        _getOpenOrders(
          data => {
            dispatch(clearOrders())
            dispatch(addOrders(data))
            setOrdersToShow(data)
            setTableLoading(false)
          },
          error => {
            setTableLoading(false)
          }
        )
      }
    }
    if (selectedTab === 'history') {
      _getOrderHistory(
        data => {
          setOrdersToShow(data)
          setTableLoading(false)
        },
        error => {
          setTableLoading(false)
        }
      )
    }
  }, [selectedTab])

  const handleCancelOrder = orderId => {
    _cancelOrder(
      orderId,
      () => {
        dispatch(
          setSnackbarData({
            alertMessage: 'This order will be canceled shortly',
            callback: () => {},
            severity: 'success',
            position: 'topRight'
          })
        )
        dispatch(setSnackbarOpen(true))
      },
      error => {
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
      }
    )
  }

  const columns = isMobileView
    ? [
        {
          field: 'side',
          headerName: 'Side',
          minWidth: 20,
          editable: false,
          renderCell: data => {
            const market = markets.find(el => el.id === data.row.market)
            return (
              <MobileRowDatagrid
                renderRowHeader={() => {
                  const market = markets.find(el => el.id === data.row.market)
                  return (
                    <span className={Styles.marketNameRowMobile}>
                      <img src={market?.icon_url} />
                      <span>{market?.name}</span>
                    </span>
                  )
                }}
                rows={[
                  {
                    title: 'Date',
                    value: (
                      <span>
                        {isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}
                      </span>
                    )
                  },
                  {
                    title: 'Side',
                    value: <span>{data.row.side.charAt(0).toUpperCase() + data.row.side.slice(1)}</span>
                  },
                  {
                    title: 'Amount',
                    value: <span>{data.row?.origin_volume}</span>
                  },
                  {
                    title: 'Price',
                    value: <span>{data.row?.price}</span>
                  },
                  {
                    title: selectedTab === 'open' ? 'Action' : selectedTab === 'history' && 'status',
                    value: (
                      <span>
                        {selectedTab === 'open' ? (
                          <Button
                            text='Cancel'
                            variant='link'
                            // icon={<TrashIcon width={14} style={{marginLeft: 10}}/>}
                            style={{
                              padding: 0,
                              marginTop: -10
                            }}
                            onClick={() => handleCancelOrder(data.row.id)}
                          />
                        ) : (
                          selectedTab === 'history' && (
                            <span
                              style={{
                                color:
                                  data.row?.state === 'done'
                                    ? 'var(--success-color)'
                                    : data.row?.state === 'cancel' && 'var(--danger-color)'
                              }}
                            >
                              {capitalize(data.row?.state)}
                            </span>
                          )
                        )}
                      </span>
                    )
                  }
                ]}
              />
            )
          },
          flex: 1
        }
      ]
    : [
        {
          field: 'side',
          headerName: 'Side',
          minWidth: 120,
          maxWidth: 300,
          editable: false,
          renderCell: data => (
            <span className={Styles.sideColumn}>
              {data.row.side === 'sell' ? <SellArrowSquare height={24} /> : <BuyArrowSquare height={24} />}

              <div>
                <span>{data.row.side.charAt(0).toUpperCase() + data.row.side.slice(1)}</span>
                <span>
                  {isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}
                </span>
              </div>
            </span>
          ),
          flex: 1
        },
        {
          field: 'market',
          headerName: 'Pair',
          renderCell: data => (
            <span>
              <CoinTag
                tag={`${markets.find(el => el.id === data.row.market)?.base_unit.toUpperCase()}/${markets
                  .find(el => el.id === data.row.market)
                  ?.quote_unit.toUpperCase()}`}
                style={{ width: 'fit-content' }}
                size={11}
              />
            </span>
          ),
          minWidth: 95,
          maxWidth: 300,
          flex: 1
        },
        {
          field: 'origin_volume',
          headerName: 'Amount',
          minWidth: 100,
          maxWidth: 300,
          flex: 1
        },
        {
          field: 'price',
          headerName: 'Price',
          minWidth: 100,
          maxWidth: 300,
          flex: 1
        },
        {
          field: selectedTab === 'open' ? 'action' : 'state',
          headerName: selectedTab === 'open' ? 'Action' : 'Status',
          sortable: selectedTab === 'history',
          flex: 1,
          minWidth: 100,
          maxWidth: 300,
          renderCell:
            !tableLoading &&
            (selectedTab === 'open'
              ? data => (
                  <span>
                    <IconButton aria-label='delete' onClick={() => handleCancelOrder(data.row.id)}>
                      <TrashIcon width={15} height={15} color='var(--text-color)' />
                    </IconButton>
                  </span>
                )
              : data => {
                  // console.log(data)
                  return <span className={Styles.stateColumn}>{capitalize(data.row.state)}</span>
                })
        }
      ]

  return (
    <div className={`${Styles.card} orders_container`}>
      {/* links container */}
      <div className={Styles.linksContainer}>
        <Button
          type='tab'
          text='Open Orders'
          isTabSelected={selectedTab === 'open'}
          disabled={tableLoading}
          onClick={() => {
            if (selectedTab === 'open') return
            setOrdersToShow([])
            setSelectedTab('open')
          }}
        />
        <Button
          type='tab'
          text='Order History'
          isTabSelected={selectedTab === 'history'}
          disabled={tableLoading}
          onClick={() => {
            if (selectedTab === 'history') return
            setOrdersToShow([])
            setSelectedTab('history')
          }}
        />
        {/* <Link to='/account/orders/open'> */}
        {userData.email && (
          <TableButton
            buttonText='Order History'
            onClick={() => navigate('/account/orders/open')}
            buttonIcon={<LinkIcon width={11} />}
            style={{
              marginLeft: 'auto',
              marginBottom: 'auto'
            }}
          />
        )}
        {/* </Link> */}
      </div>

      {/* MUI DATAGRID */}
      <MUIDatagrid
        hideFooter={true}
        rows={selectedTab === 'open' ? openOrders : ordersToShow}
        loading={tableLoading}
        getRowId={row => row.id}
        renderNoRowsOverlay={
          !userData.email
            ? () => (
                <div className={Styles.loggedOutContainer}>
                  <Link to='/login'>Sign In</Link> or <Link to='/signup'>Register Now</Link> to trade
                </div>
              )
            : () => <></>
        }
        columns={columns}
      />
    </div>
  )
}

export default injectIntl(Orders)
