import React from "react"

// styles
import Styles from "./SelectLanguageCurrencyInput.module.css"

// icons
import { ReactComponent as DropdownIcon } from "../../../assets/icons/imported/down-arrow.svg"

// redux
import { useSelector } from "react-redux"

// languages
import { languageOptions } from "../../../constants/languageConstants"

// helpers
import { showLanguageCurrencySelectModal } from "../../../modules/modals/language-currency-select/helpers"

function SelectLanguageCurrencyInput({
  inputType = "language", // language - currency
  error,
  containerStyle = {}
}) {
  const currency = useSelector((state) => state.currency.value)
  const language = languageOptions.find(
    (el) => el.value === useSelector((state) => state.language)?.code
  )
  const handleSelect = () => {
    showLanguageCurrencySelectModal(inputType)
  }

  return (
    <div className={Styles.selectNetwordContainer} style={containerStyle}>
      <span>{`Select ${inputType}`}</span>
      <div
        onClick={handleSelect}
        className={`${error?.message && Styles.errorInput}`}
      >
        <span className={Styles.selectedNetwork}>
          {inputType === "language"
            ? language.label
            : inputType === "currency"
            ? currency?.toUpperCase()
            : null}
        </span>

        <DropdownIcon color="var(--gray-color)" />
      </div>
      {error?.message && (
        <span className={Styles.errorSpan}>
          {error?.message ? error.message : " "}
        </span>
      )}
    </div>
  )
}

export default SelectLanguageCurrencyInput
