import { store } from "../../../../redux/store"
import {
  setCreateBeneficiaryModalData,
  setCreateBeneficiaryModalOpen
} from "../redux/slices/createBeneficiaryModalSlice"

export const showCreateBeneficiaryModal = ({ selectedCoin = null }) => {
  store.dispatch(setCreateBeneficiaryModalData({ selectedCoin }))
  store.dispatch(setCreateBeneficiaryModalOpen(true))
}

export const hideCreateBeneficiaryModal = () => {
  store.dispatch(
    setCreateBeneficiaryModalData({
      callback: () => {}
    })
  )
  store.dispatch(setCreateBeneficiaryModalOpen(false))
}
