export const faq = [
  {
    summary: "Which cryptocurrencies can I purchase on Wenbit?",
    details:
      "You can purchase all major cryptocurrencies via Wenbit, including ADA, AAVE, BCH, BNB, BNBBSC, BTC, DOGE, DOT, ETH, LINK, LTC, MANA, SHIB, SOL, UNI, USDC and USDT."
  },
  {
    summary: "Can I use my credit card to buy cryptocurrency?",
    details:
      "Yes, Wenbit has simplified the process of buying crypto. Simply purchase crypto directly using our Coinify payment facility, by inputting the FIAT amount you wish to invest into crypto. The system will automatically show you the equivalent amount of crypto assets you will be purchasing, and hit ‘Buy’. The platform will take you through an easy journey to buy your crypto assets within a few seconds."
  },
  {
    summary: "Who holds my credit card details?",
    details:
      "Wenbit does not keep your credit card details. All payments are done through our secure third-party payment platform, Coinify. They will securely hold your credit card details for payment."
  },
  {
    summary: "What is Coinify?",
    details:
      "Coinify is a regulated global payment platform that allows you to accept, buy or sell cryptocurrency. It is a simple solution to exchange cash into crypto, supported in over 180 countries by 45,000 businesses and payment service providers."
  },
  {
    summary: "How safe is it to buy cryptocurrency on Wenbit?",
    details:
      "Wenbit follows all necessary security regulations and precautions to offer a secure platform to protect and safeguard client transactions and information. Our third-party payment platform, Coinify, works alongside national and international regulatory bodies that help shape new regulations as a founding member of the Blockchain and Virtual Currencies Working Group under the European Commission, to ensure that we as an entity adhere to the highest industry standards and comply to international laws, like e.g. the EU 5th Anti-Money Laundering Directive."
  },
  {
    summary: "Is there a waiting period when I buy crypto?",
    details:
      "No. Once you have made a purchase, the equivalent crypto amount will appear in your crypto wallet within seconds. There is no waiting period that could affect the value of your crypto. The crypto value amount at the time of purchase is what is reflected in your crypto wallet."
  }
]
