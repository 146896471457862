import React, { useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from "./actionsBeneficiaryModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  _activateBeneficiary,
  _resendBeneficiaryActivationPin
} from "../redux/actions"

// components
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"

// helpers
import { hideActionsBeneficiaryModal } from "./helpers"
import { showViewBeneficiaryModal } from "../view/helpers"
import { showActivateBeneficiaryModal } from "../activate/helpers"
import { showDeleteBeneficiaryModal } from "../delete/helpers"

function ActionsBeneficiaryModal() {
  // ** States
  const modalOpen = useSelector(
    (state) => state.actionsBeneficiaryModalState.open
  )

  const modalData = useSelector(
    (state) => state.actionsBeneficiaryModalState.data
  )

  // ** Handlers
  const handleClose = () => {
    hideActionsBeneficiaryModal()
  }

  const handleView = () => {
    hideActionsBeneficiaryModal()
    showViewBeneficiaryModal(modalData)
  }

  const handleActivate = () => {
    hideActionsBeneficiaryModal()
    showActivateBeneficiaryModal(modalData.id)
  }

  const handleDelete = () => {
    hideActionsBeneficiaryModal()
    showDeleteBeneficiaryModal(modalData.id)
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title="Manage Beneficiary"
        render={() => (
          <div className={Styles.buttonsContainer}>
            <Button text="View" onClick={handleView} />
            <Button
              text="Activate"
              style={{
                backgroundColor: "var(--background-color-inverted)",
                color: "var(--success-color)"
              }}
              onClick={handleActivate}
            />
            <Button
              text="Delete"
              style={{ backgroundColor: "var(--danger-color)" }}
              onClick={handleDelete}
            />
          </div>
        )}
      />
    </>
  )
}

export default ActionsBeneficiaryModal
