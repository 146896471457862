// ** React Imports
import React, { useState, useEffect } from "react"

// ** Styles Imports
import Styles from "./browseCareers.module.css"

// ** Component Imports
import Input from "../../../components/Input/Input"
import Button from "../../../components/Button/Button"
import PageHeader from "../../../components/PageHeader/PageHeader"
import JobApplySection from "../../../components/careers-components/JobApplySection/JobApplySection"

// ** Actions Imports
import { _listJobs, _getLocations, _getDepartments } from "../redux/actions"

// ** Utils Imports
import { showErrorSnackbar, showSuccessSnackbar } from "../../snackbar/helpers"

function BrowseCareers() {
  // ** States
  const [jobs, setJobs] = useState([])
  const [filteredJobs, setFilteredJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([])
  const [departments, setDepartments] = useState([])
  const [filterModel, setFilterModel] = useState({
    location: null,
    department: null
  })

  // ** Handlers
  const handleClearFilter = () => {
    setFilterModel({
      location: null,
      department: null
    })
  }

  useEffect(() => {
    if (filterModel.department === null && filterModel.location === null) {
      setLoading(true)
      setJobs([])
      setFilteredJobs([])
      _getLocations(
        (data) => setLocations(data.data),
        () => showErrorSnackbar({ alertMessage: "Something went wrong" })
      )
      _getDepartments(
        (data) => setDepartments(data.data),
        () => showErrorSnackbar({ alertMessage: "Something went wrong" })
      )
      _listJobs(
        {
          group_by: "departments_id"
        },
        (res) => {
          setJobs(res.data)
          setLoading(false)
        },
        (err) => {
          setLoading(false)
          showErrorSnackbar({ alertMessage: "Something went wrong" })
        }
      )
    }
  }, [filterModel])

  // ** Filtering
  useEffect(() => {
    if (filterModel.department === null && filterModel.location === null) {
      return
    }
    setLoading(true)
    setJobs([])
    setFilteredJobs([])
    _listJobs(
      {
        department_id: filterModel.department,
        location_id: filterModel.location
      },
      (res) => {
        setFilteredJobs(res.data)
        setLoading(false)
      },
      (err) => {
        setLoading(false)
        showErrorSnackbar({ alertMessage: "Something went wrong" })
      }
    )
  }, [filterModel])

  return (
    <div className={Styles.pageContainer}>
      <PageHeader sectionTitle="Company" title="Current Job Openings" />

      <div className={Styles.filterContainer}>
        <Input
          type="select"
          placeholder="All departments"
          selectOptions={[
            {
              label: "All departments",
              value: null
            },
            ...departments.map((el) => ({
              label: `${el.name}`,
              value: el.id
            }))
          ]}
          selectValue={filterModel.department}
          onChange={(e, value) =>
            setFilterModel((prevModel) => ({
              location: prevModel.location,
              department: value
            }))
          }
        />
        <Input
          type="select"
          placeholder="All locations"
          selectOptions={[
            {
              label: "All locations",
              value: null
            },
            ...locations.map((el) => ({
              label: `${el.city} - ${el.country}`,
              value: el.id
            }))
          ]}
          selectValue={filterModel.location}
          onChange={(e, value) =>
            setFilterModel((prevModel) => ({
              location: value,
              department: prevModel.department
            }))
          }
        />
        <Button text="Clear" variant="link" onClick={handleClearFilter} />
      </div>

      {/* Non filtered data */}
      {filterModel.location || filterModel.department ? (
        <JobApplySection jobs={filteredJobs} loading={loading} />
      ) : (
        jobs.map((group) => (
          <JobApplySection
            jobs={group.jobs}
            title={group.department.name}
            loading={loading}
          />
        ))
      )}

      {/* Filtered data */}
      {/* {(filterModel.location || filterModel.department) && (
        <JobApplySection
          jobs={filteredJobs}
          // title={group.department.name}
          loading={loading}
        />
      )} */}
    </div>
  )
}

export default BrowseCareers
