import React, { useState } from "react"

// styles
import Styles from "./registerConfirm.module.css"

// components
import { Form } from "reactstrap"
import Input from "../../../../components/Input/Input"
import Radio from "../../../../components/Radio/Radio"
import Button from "../../../../components/Button/Button"
import { _authenticate } from "../../redux/actions"
import { FormattedMessage, injectIntl } from "react-intl"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { useDispatch, useSelector } from "react-redux"

function RegisterConfirm() {
  const dispatch = useDispatch()
  const [confirmBy, setConfirmBy] = useState("phone") // phone - email

  const handleChange = (event) => {
    setConfirmBy(event.target.value)
  }

  const handleAuthenticate = () => {
    _authenticate(
      confirmBy,
      (data) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: "Code sent successfully",
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  return (
    <div className={Styles.tabContainer}>
      {/* form */}
      <Form className={Styles.formDiv}>
        {/* title */}
        <span className={Styles.title}>Just checking you out</span>

        {/* subtitle */}
        <span className={Styles.subtitle}>
          We just sent a secret code to your phone. <br />
          Enter it to prove you're you and join the cool kids club.
        </span>

        <div style={{ marginTop: 20 }}>
          <Radio
            name={confirmBy}
            value="phone"
            setValue={setConfirmBy}
            label="Get the code by text massage"
          />
          <Input type="phoneNumber" />

          <Radio
            name={confirmBy}
            value="email"
            setValue={setConfirmBy}
            label="Get the code by email at"
            containerStyle={{
              marginTop: 20
            }}
          />
          <Input type="text" inputLabel="Email" />

          <Button
            text="Continue"
            onClick={handleAuthenticate}
            style={{ marginTop: 20 }}
          />
        </div>
      </Form>
    </div>
  )
}

export default RegisterConfirm
