import { API } from "../../../../utility/API"

//************************************//
export const _getActivity = (page = 1, callback, callbackErr) => {
    // persistStore().purge()
    API.get(`authenticate/resource/users/activity/all?limit=7&page=${page}`)
        .then(function ({ data, headers }) {
            callback({data, page: headers['page'], total: headers['total']})
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _generateCode = (callback, callbackErr) => {
    API.post(`authenticate/resource/otp/generate_qrcode`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _changePassword = (data, callback, callbackErr) => {
    API.put(`authenticate/resource/users/password`, data)
        .then(response => {
            if (response.data) {
                callback(response.data)
            }
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getBeneficiaries = (data, callback, callbackErr) => {
    // persistStore().purge()
    API.get(`trading/account/beneficiaries`, {
        params: data
    })
        .then(function ({ data, headers }) {
            callback({data, page: headers['page'], total: headers['total']})
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getActiveBeneficiaries = (id, callback, callbackErr) => {
    // persistStore().purge()
    API.get(`trading/account/beneficiaries?currency=${id}&state=active`)
        .then(function ({ data, headers }) {
            callback({data})
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _createBeneficiary = (data, callback, callbackErr) => {
    API.post(`trading/account/beneficiaries`, data)
        .then(response => {
            if (response.data) {
                callback(response.data)
            }
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}