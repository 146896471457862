import { createSlice } from '@reduxjs/toolkit'

export const beneficiarySlice = createSlice({
  name: 'beneficiary',
  initialState: {
    value: ''
  },
  reducers: {
    setBeneficiary: (state, payload) => {
      state.value = payload.payload
    },
    clearBeneficiary: (state, payload) => {
      state.value = []
    }
  }
})

// Action creators are generated for each case reducer function
export const { setBeneficiary, clearBeneficiary, addBeneficiary } = beneficiarySlice.actions

export default beneficiarySlice.reducer