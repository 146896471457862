import React from "react"

// redux
import { useSelector } from "react-redux"

// styles
import Styles from "./buyCrypto.module.css"

// icons
import { ReactComponent as VisaIcon } from "../../../assets/icons/imported/visa.svg"
import { ReactComponent as PaypalIcon } from "../../../assets/icons/imported/paypal.svg"
import { ReactComponent as MaestroIcon } from "../../../assets/icons/imported/maestro.svg"
import { ReactComponent as VisaIconLight } from "../../../assets/icons/imported/visa-light.svg"

// components
import Button from "../../../components/Button/Button"

function BuyCrypto() {
  const theme = useSelector((state) => state.theme.value)

  return (
    <div className={Styles.cardContainer}>
      <h3>
        Crypto Trading <br /> just in 3 steps
      </h3>
      <span>
        Buy Bitcoin & 99+ cryptocurrency <br /> with 50+ fiat currencies
      </span>
      <Button
        text="Buy Crypto"
        style={{
          fontSize: 18,
          width: "100%"
        }}
        type="link"
        linkTo="/buy-crypto"
        maxWidth
      />
      <div className={Styles.iconsContainer}>
        {/* {theme === 'dark' ? <VisaIcon style={{transform: 'scale(1.35)'}}/> : <VisaIconLight  style={{transform: 'scale(1.35)'}} />} */}
        {/* <MaestroIcon /> */}
        {/* <PaypalIcon /> */}
      </div>
    </div>
  )
}

export default BuyCrypto
