import React from "react"

const buyCryptoFAQs = [
  {
    summary: <span>How do I get started trading on your platform?</span>,
    details: (
      <span>
        Getting started is easy - create an account and follow our user-friendly
        onboarding process.
      </span>
    )
  },
  {
    summary: (
      <span>What cryptocurrencies can I buy and sell on your exchange?</span>
    ),
    details: (
      <span>
        Our exchange supports many cryptocurrencies, including Bitcoin,
        Ethereum, Litecoin, and many more.
      </span>
    )
  },
  {
    summary: (
      <span>
        What payment methods do you accept for deposits and withdrawals?
      </span>
    ),
    details: (
      <span>
        We accept multiple payment methods, including credit/debit cards, bank
        transfers, and cryptocurrency deposits.
      </span>
    )
  },
  {
    summary: (
      <span>
        How long does it take for deposits and withdrawals to be processed?
      </span>
    ),
    details: (
      <span>
        Deposits and withdrawals are typically processed within minutes,
        depending on the payment method and network congestion.
      </span>
    )
  },
  {
    summary: (
      <span>
        What security measures do you have in place to protect my funds and
        personal information?
      </span>
    ),
    details: (
      <span>
        We take security seriously and have implemented top-notch measures to
        protect your funds and personal information, including two-factor
        authentication, SSL encryption, and cold storage for user funds.
      </span>
    )
  }
]

export default buyCryptoFAQs
