import React, { useEffect, useRef, useState } from "react"

// styles
import Styles from "./radio.module.css"

// redux
import { useSelector } from "react-redux"

// icons
import selectedRadio from "./icons/radio-selected.svg"
import notSelectedRadio from "./icons/radio-not-selected.svg"

function Radio({
  name,
  value,
  selectedValue,
  setValue = () => {},
  label,
  containerStyle,
  register
}) {
  const theme = useSelector((state) => state.theme.value)

  const [isSelected, setIsSelected] = useState(false)

  const handleRadioChange = (event) => {
    register?.onChange(event)
    setValue(event.target.value)
  }

  useEffect(() => {
    setIsSelected(selectedValue === value)
  }, [selectedValue])

  return (
    <div
      className={`${Styles.radioButtonContainer} ${
        name === value && Styles.selectedButton
      } ${theme === "light" && Styles.radioButtonContainerLight}`}
      style={{ ...containerStyle }}
    >
      <input
        name={name}
        type="radio"
        value={value}
        id={`${name}-${value}`}
        {...register}
        onChange={handleRadioChange}
      />
      <label htmlFor={`${name}-${value}`} className={Styles.radioLabel}>
        {isSelected ? (
          <img className={Styles.radioImage} src={selectedRadio} />
        ) : (
          <img className={Styles.radioImage} src={notSelectedRadio} />
        )}
        <span className={Styles.radioText}>{label}</span>
      </label>
    </div>
  )
}

export default Radio
