// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Utils Imports
import { formatNumber } from '../../../helpers/formatNumber'

// ** API Imports
import { _getBalances } from './redux/actions/index'

// ** Component Imports
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import DurationButton from '../components/DurationButton'
import WenbitModal from '../../../../components/Modal/Modal'
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'

// ** Helpers Imports
import { hideStakingSubscribeModal } from './helpers'
import { Link } from 'react-router-dom'

function StakingSubscribeModal() {
  // ** !!TEMP STATIC VARIABLES!! - Replace this when linking
  const durations = [
    { duration: 'Flexible', apr: 18 },
    { duration: 15, apr: 8 },
    { duration: 30, apr: 10 },
    { duration: 60, apr: 13 },
    { duration: 90, apr: 14 },
    { duration: 120, apr: 17 }
  ]

  // ** States
  const modalOpen = useSelector(state => state.stakingSubscribeModalState.open)
  const modalData = useSelector(state => state.stakingSubscribeModalState.data)

  const [amount, setAmount] = useState(null)
  const [coinBalance, setCoinBalance] = useState(null)

  const [loading, setLoading] = useState(false)

  // ** Handlers
  const handleClose = () => {
    hideStakingSubscribeModal()
  }

  const fetchBalances = () => {
    setLoading(true)
    _getBalances(
      res => {
        console.log(res)
        setLoading(false)
        setCoinBalance(res.find(wallet => wallet.currency === 'btc') ?? null)
      },
      () => setLoading(false)
    )
  }

  const handleAmountChange = e => setAmount(e.target.value)
  const handleMaxAmount = () => setAmount(coinBalance?.balance ?? 0)

  useEffect(() => {
    // Get the balances on modal Open
    // fetchBalances()
  }, [modalOpen])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title={modalData?.title}
        render={() => (
          <>
            {/* Durations Container */}
            <div className={styles.durationsContainer}>
              {durations.map(el => (
                <DurationButton key={el.duration} apr={el.apr} duration={el.duration} value={el.duration} />
              ))}
            </div>

            {/* Amount Input */}
            <Input
              type='number'
              inputLabel='Amount'
              variant='primary'
              showBorder
              containerStyle={{ margin: '20px 0' }}
              indicatorLabel={`Available: ${formatNumber(coinBalance?.balance ?? 0, 6) ?? 0.0}`}
              style={{ fontSize: 13, background: 'transparent' }}
              value={amount || ''}
              placeholder='Min 0.5 BTC'
              // error={errors.amount}
              onChange={handleAmountChange}
              icon={
                <div className={styles.inputEndContainer}>
                  <div className={styles.inputCoinName}>BTC</div>
                  <div className={styles.divider} />
                  <Button
                    text='MAX'
                    variant='link'
                    onClick={handleMaxAmount}
                    style={{
                      padding: 0,
                      width: 'fit-content'
                    }}
                  />
                </div>
              }
            />

            <h5>Summary</h5>
            <div className={styles.summaryNumbersContainer}>
              <span>Daily Real-Time Reward APR 0.16%</span>
              <span>0.0053 BTC</span>
            </div>

            <p className={styles.summaryText}>
              Real-Time APR is subject to change every minute and rewards are accrued and directly accumulated in your
              Earn Wallet every minute. APR does not mean the actual or predicted returns in fiat currency.
            </p>

            <CheckboxInput
              label={
                <>
                  I have read and agreed to <Link>WenBit Staking Service Terms & Conditions</Link>
                </>
              }
              containerStyle={{ margin: '40px 0' }}
            />

            <Button text='Confirm' />
          </>
        )}
      />
    </>
  )
}

export default StakingSubscribeModal
