import React from "react"

// styles
import Styles from "./companySecurity.module.css"

// assets
import SecurityPicture from "./assets/security.png"

function CompanySecurity() {
  document.title = "Wenbit: Security"

  return (
    <div className={Styles.pageContainer}>
      <section className={Styles.headerContainer}>
        <span>Security</span>
        <h1>Security is no joke at WenBit</h1>
        <p>
          We've gone to great lengths to protect your crypto bits. Our
          multi-layered security approach is designed to keep your crypto safe
          and sound. We use the latest encryption technology to secure your
          data, and our firewalls and intrusion detection systems are always on
          the lookout for any suspicious activity. <br />
          <br />
          But that's not all - we don't just rely on software to keep you safe.
          We also conduct security audits and penetration testing to ensure our
          defences are rock-solid. And if we ever detect anything fishy, we have
          a crack team of experts on standby to investigate and take action.
          <br />
          <br />
          If you're looking for an exchange that takes security seriously, look
          no further than WenBit. We've got your back, so you can focus on what
          matters - making epic crypto moves.
        </p>
      </section>
      <img src={SecurityPicture} />
    </div>
  )
}

export default CompanySecurity
