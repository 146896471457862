import React from "react"

// styles
import Styles from "./sessionExpired.module.css"

// icons
import { ReactComponent as WarningIcon } from "./assets/warning-icon.svg"
import Button from "../../components/Button/Button"

function SessionExpired() {
  return (
    <div className={Styles.container}>
      <WarningIcon width={54} />
      <h1>Session Expired</h1>
      <p>
        Your session has expired. Please log in again to continue using our
        services.
      </p>
      <div>
        <Button
          variant="link"
          text="Back to home"
          style={{
            color: "var(--warning-color)"
          }}
          type="link"
          linkTo="/trade"
        />
        <Button
          text="Sign in"
          type="link"
          linkTo="/login"
          style={{
            backgroundColor: "var(--warning-color)"
          }}
        />
      </div>
    </div>
  )
}

export default SessionExpired
