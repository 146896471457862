import { createSlice } from '@reduxjs/toolkit'

const viewBeneficiaryModalSlice = createSlice({
  name: 'viewBeneficiaryModalState',
  initialState: {
    open: false,
    data: {
      callback: () => {}
    }
  },
  reducers: {
    setViewBeneficiaryModalOpen: (state, action) => {
      state.open = action.payload
    },
    setViewBeneficiaryModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setViewBeneficiaryModalData, setViewBeneficiaryModalOpen } = viewBeneficiaryModalSlice.actions

export default viewBeneficiaryModalSlice.reducer