import React, { useEffect, useState } from "react"

// styles
import Styles from "./Enable2FAModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { _enableGoogleAuth } from "./redux/actions"
import { _getUserInfo } from "../../../../redux/actions"
import { setUser } from "../../../user/redux/slices/userDataSlice"

// components
import Input from "../../../../components/Input/Input"
import { FormattedMessage, injectIntl } from "react-intl"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// utils
import { closeEnable2FAModal } from "./helpers"

// schema
const enable2FAAuthSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^\d{6}$/, "Code must be 6 digits")
    .test("is-number", "Code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    })
})

function Enable2FAModal() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(enable2FAAuthSchema),
    mode: "onSubmit"
  })

  const dispatch = useDispatch()
  const modalOpen = useSelector((state) => state.Enable2FAModalState.open)
  const modalData = useSelector((state) => state.Enable2FAModalState.data)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!modalOpen) {
      reset()
    }
  }, [modalOpen])

  const onSubmit = (formData) => {
    setLoading(true)
    _enableGoogleAuth(
      {
        code: formData?.code
      },
      (data) => {
        _getUserInfo(
          (data) => {
            setLoading(true)
            dispatch(setUser(data))
            closeEnable2FAModal()
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "2 Factor-Authentication enabled",
                severity: "success",
                //buttonMessage: 'See order',
                callback: () => {},
                //actionType: 'link', // link - close
                //linkTo: '/account/orders/open',
                position: "topRight"
              })
            )
          },
          (error) => {
            setLoading(false)
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "2 Factor-Authentication enabled",
                severity: "success",
                //buttonMessage: 'See order',
                callback: () => {},
                //actionType: 'link', // link - close
                //linkTo: '/account/orders/open',
                position: "topRight"
              })
            )
          }
        )
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  const renderTwoFactorAuthQR = (barcode) => {
    const src = `data:image/png;base64,${barcode}`
    return (
      modalData.barcode.length > 0 && (
        <img className="parent-profile-two-factor-auth__qr" src={src} />
      )
    )
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={closeEnable2FAModal}
        title={modalData?.title}
        render={() => (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={Styles.modalBody}>
              <span>Scan QR code to activate</span>
              <div
                style={{
                  height: "auto",
                  margin: "20px auto",
                  maxWidth: 140,
                  width: "100%",
                  padding: 16,
                  borderRadius: 5,
                  backgroundColor: "white"
                }}
              >
                {renderTwoFactorAuthQR(modalData.barcode)}
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <Input
                id="sec_code"
                placeholder="Security Code"
                type="text"
                showBorder
                inputLabel="Enter 6 digit from authenticator app"
                {...register("code")}
                error={errors.code}
                maxLength={6}
              />
            </div>

            <Button isLoading={loading} text="Verify" type="submit" />
          </form>
        )}
      />
    </>
  )
}

export default injectIntl(Enable2FAModal)
