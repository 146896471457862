import { useDispatch } from "react-redux"
import { store } from "../../../../redux/store"
import {
  setEnableSMSAuthModalOpen,
  setEnableSMSAuthModalData
} from "./redux/EnableSMSAuthModalSlice"

export const showEnableSMSAuthModal = () => {
  store.dispatch(
    setEnableSMSAuthModalData({
      title: "Enable SMS Authentication"
    })
  )
  store.dispatch(setEnableSMSAuthModalOpen(true))
}

export const closeEnableSMSAuthModal = () => {
  store.dispatch(setEnableSMSAuthModalOpen(false))
}
