// ** React Imports
import React, { forwardRef } from "react"

// ** Styles Imports
import Styles from "./autocomplete.module.css"

// ** Icons Imports
import {
  BiChevronDown,
  BiChevronUp,
  BiSolidRightArrowCircle
} from "react-icons/bi"
import { ReactComponent as SearchIcon } from "../../assets/icons/imported/search.svg"

// ** Component Imports
import { ReactComponent as CloseIcon } from "../../assets/icons/imported/close.svg"

// ** MUI Imports
import {
  Autocomplete as AutocompleteMUI,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  styled
} from "@mui/material"

const Autocomplete = forwardRef(
  (
    {
      options = [],
      getOptionLabel,
      renderOption = (params, option) => (
        <div {...params} id={option.key}>
          {getOptionLabel(option)}
        </div>
      ),
      renderInput,
      placeholder,
      label,
      onChange,
      searchContainerIcon,
      containerStyle,
      loading = false,
      value,
      inputValue,
      filterOptions,
      onInputChange,
      showUpDownArrow = true,
      ...rest
    },
    ref
  ) => {
    // ** Styled Components
    const StyledAutocomplete = styled(AutocompleteMUI)({
      ".MuiTextField-root": {
        bgColor: "var(--inverted-background-color)"
      },
      ".MuiAutocomplete-popper": {
        bgColor: "var(--inverted-background-color)"
      },
      ".MuiAutocomplete-input": {
        fontSize: "14px !important"
      }
    })

    // const StyledTextField = styled(TextField)(() => ({
    //   backgroundColor: "var(--background-color)",
    //   height: "42px",
    //   borderRadius: "5px",
    //   fontSize: "160px"
    // }))

    const StyledTextField = styled(TextField)()

    return (
      <div style={{ ...containerStyle }}>
        {label && <span className={Styles.label}>{label}</span>}
        <div
          className={Styles.inputContainer}
          style={{
            paddingRight: searchContainerIcon && 10
          }}
        >
          <StyledAutocomplete
            options={options}
            onChange={onChange}
            renderOption={renderOption}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            popupIcon={<BiChevronDown />}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                inputRef={ref}
                onChange={onInputChange}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      {value && (
                        <IconButton
                          aria-label="clear selection"
                          onClick={() => onChange(null)}
                          className="clearButtonAutocomplete"
                          sx={{
                            mr: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "var(--gray-color)"
                          }}
                          size="small"
                        >
                          <CloseIcon width={9} height={9} />
                        </IconButton>
                      )}
                    </>
                  )
                }}
              />
            )}
            style={{
              width: "100%"
            }}
            inputValue={inputValue}
            value={value}
            clearIcon={BiSolidRightArrowCircle}
            noOptionsText={
              loading ? (
                <div className={Styles.loadingContainer}>
                  <CircularProgress
                    style={{
                      margin: "10px auto",
                      color: "var(--primary-color)"
                    }}
                  />
                </div>
              ) : (
                <span>No options</span>
              )
            }
            {...rest}
          />
          {searchContainerIcon && searchContainerIcon}
        </div>
      </div>
    )
  }
)
Autocomplete.displayName = "Autocomplete"

export default Autocomplete
