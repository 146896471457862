import React from "react"
import { useNavigate } from "react-router-dom"

// styles
import Styles from "./blogCard.module.css"

// icons
import { CgProfile } from "react-icons/cg"
import { AiOutlineClockCircle } from "react-icons/ai"

// components
import Button from "../../../components/Button/Button"
import { formatDate } from "../../../utility/format"

function BlogCard({ id, author = "", created_at, title }) {
  // ** Hooks
  const navigate = useNavigate()

  const handleViewBlog = () => {
    navigate(`/blogs/${id}`)
  }

  return (
    <div className={Styles.cardContainer}>
      {/* blog thumbnail */}
      <div className={Styles.thumbnailContainer}></div>

      {/* blog writer + date */}
      <div className={Styles.blogInfoContainer}>
        {/* writer */}
        <div>
          <CgProfile color="var(--primary-color)" size={20} />
          {author}
        </div>

        {/* date */}
        <div>
          <AiOutlineClockCircle color="var(--primary-color)" size={20} />
          {formatDate(created_at)}
        </div>
      </div>

      {/* blog title */}
      <h2>{title}</h2>

      {/* blog description */}
      {/* <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis
        ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu sagittis
        metus
      </p> */}

      <Button
        text="Read More"
        style={{ width: "fit-content" }}
        onClick={handleViewBlog}
      />
    </div>
  )
}

export default BlogCard
