import { createSlice } from '@reduxjs/toolkit'

const selectCurrencyModalSlice = createSlice({
  name: 'selectCurrencyModalState',
  initialState: {
    open: false,
    data: {
      title: 'Select Currency',
      showTabs: true,
      type: 'coin', // all - coin - fiat
      showMarketPairs: false, // if a pair needs to be selected instead of coin
      currenciesToHide: [], // ex: ['usdt', 'sol']
      disableBackdropClick: false,
      callback: () => {} // ex: (currency) => {setFromCurrency(currency.id)}
    }
  },
  reducers: {
    setSelectCurrencyModalOpen: (state, action) => {
      state.open = action.payload
    },
    setSelectCurrencyModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setSelectCurrencyModalOpen, setSelectCurrencyModalData } = selectCurrencyModalSlice.actions

export default selectCurrencyModalSlice.reducer