import { useDispatch } from "react-redux"
import { store } from "../../../../redux/store"
import {
  setDisableSMSAuthModalData,
  setDisableSMSAuthModalOpen
} from "./redux/disableSMSAuthModalSlice"

export const showDisableSMSAuthModal = () => {
  store.dispatch(
    setDisableSMSAuthModalData({
      title: "Disable SMS Authenticator"
    })
  )
  store.dispatch(setDisableSMSAuthModalOpen(true))
}

export const closeDisableSMSAuthModal = () => {
  store.dispatch(setDisableSMSAuthModalOpen(false))
}
