export const klineArrayToObject = (el) => {
  const [time, open, high, low, close, volume] = el.map((e) => {
    switch (typeof e) {
      case "number":
        return e
      case "string":
        return Number.parseFloat(e)
      default:
        throw new Error(`unexpected type ${typeof e} in kline: ${el}`)
    }
  })
  return {
    time: time * 1e3,
    open,
    high,
    low,
    close,
    volume
  }
}
export const lastKlineArrayToObject = (el) => {
  const [time, open, high, low, close, volume] = el.map((e) => {
    switch (typeof e) {
      case "number":
        return e
      case "string":
        return Number.parseFloat(e)
      default:
        throw new Error(`unexpected type ${typeof e} in kline: ${el}`)
    }
  })
  return {
    time,
    open,
    high,
    isLastBar: true,
    isBarClosed: false,
    low,
    close,
    volume
  }
}
export const periodsMapNumber = {
  1: 1,
  "5m": 5,
  "15m": 15,
  "30m": 30,
  "1h": 60,
  "2h": 120,
  "4h": 240,
  "6h": 360,
  "12h": 720,
  "1d": 1440,
  "3d": 4320,
  "1w": 10080,
  "4w": 40320
}
export const periodsMapString = {
  1: "1m",
  5: "5m",
  15: "15m",
  30: "30m",
  60: "1h",
  120: "2h",
  240: "4h",
  360: "6h",
  720: "12h",
  1440: "1d",
  4320: "3d",
  10080: "1w",
  40320: "4w"
}
export const periodStringToMinutes = (period) => periodsMapNumber[period]
export const periodMinutesToString = (period) => periodsMapString[period]
export const marketKlineStreams = (marketId, periodString) => ({
  channels: [`${marketId}.kline-${periodString}`]
})
export const chartTypeToNumber = (type) => {
  switch (type) {
    case "candle":
      return 1 // Candlestick
    case "bar":
      return 0 // Bar
    case "line":
      return 2 // Line
    case "area":
      return 3 // Area
    case "hollowCandle":
      return 9 // Hollow Candle
    case "heikinAshi":
      return 8 // Heikin Ashi
    case "baseline":
      return 10 // Baseline
    case "pointAndFigure":
      return 11 // Point and Figure
    case "kagi":
      return 12 // Kagi
    case "renko":
      return 13 // Renko
    default:
      return 1 // Default to Candlestick if no match
  }
}

// import React, { useEffect, useState } from "react"
// // import TradingView from "../../../charting_library/charting_library.standalone.js"
// import { useDispatch, useSelector } from "react-redux"
// import { dataFeedObject, getTradingChartTimezone, stdTimezoneOffset } from "./api"
// import { periodStringToMinutes } from "../utility/helpers"
// import { customWidgetOptions } from "./TradingChartConfig"
// import { widget } from '../../../charting_library'
// const TradingViewChart = () => {
//   const tickersData = useSelector((state) => state.marketTickers.value)
//   const selectedMarket = useSelector((state) => state.selectedMarket.value)
//   const markets = useSelector((state) => state.marketList.value)
//   const theme = useSelector((state) => state.theme.value)
//   const klineData = useSelector((state) => state.kline)
//   let tvWidget = null
//   const log = console.log
//   const datafeed = dataFeedObject(TradingViewChart, markets)
//   const currentTimeOffset = new Date().getTimezoneOffset()
//   const clockPeriod = currentTimeOffset === stdTimezoneOffset(new Date()) ? "STD" : "DST"

//   useEffect(() => {
//     log("tickersData", tickersData)
//     const colorTheme = theme

//     const widgetOptions = {
//       allow_symbol_change: false,
//       autosize: true,
//       calendar: true,
//       hide_legend: false,
//       save_image: true,
//       client_id: "tradingview.com",
//       custom_css_url: "/chartStyles.css",
//       debug: false,
//       details: true,
//       enabled_features: [
//         "charts_auto_save",
//         "use_localstorage_for_settings",
//         "control_bar",
//         "legend_context_menu",
//         "header_in_fullscreen_mode",
//         "header_chart_type",
//         "header_resolutions",
//         "side_toolbar_in_fullscreen_mode",
//         "dont_show_boolean_study_arguments",
//         "hide_last_na_study_output",
//         "save_chart_properties_to_local_storage",
//         "remove_library_container_border",
//       ],
//       disabled_features: [
//         "hide_left_toolbar_by_default",
//         "widget_logo",
//       ],
//       hide_side_toolbar: false,
//       hotlist: false,
//       library_path: "/charting_library/",
//       show_popup_button: true,
//       timeframe: "1D",
//       user_id: "public_user_id",
//       withdateranges: false,
//       charts_storage_url: "https://saveload.tradingview.com",
//       charts_storage_api_version: "1.1",
//       symbol: selectedMarket.id ?? "btcusdt",
//       datafeed,
//       interval: String(periodStringToMinutes("1m")),
//       container_id: "tv_chart_container",
//       // interval: "1D", // default interval
//       container: "tv_chart_container",
//       timezone: getTradingChartTimezone(currentTimeOffset, clockPeriod),
//       ...customWidgetOptions(colorTheme)
//     }
//     tvWidget = new widget(widgetOptions)
//   }, [theme])

//   // useEffect(() => {
//   //   if (klineData && klineData.last.time !== klineData.pre_last.time) {
//   //     datafeed.onRealtimeCallback(klineData)
//   //   }
//   // }, [klineData])

//   useEffect(() => {
//     if (tvWidget) {
//       tvWidget.onChartReady(() => {
//         tvWidget.activeChart().setSymbol(selectedMarket.id, () => {
//           console.log("Symbol set", selectedMarket.id)
//         })
//       })
//     }
//   }, [selectedMarket])

//   return (
//     <div
//       id="tv_chart_container"
//       style={{
//         borderRadius: 10,
//         overflow: "hidden",
//         height: 600,
//         padding: 10,
//         backgroundColor: "var(--secondary-color)"
//       }}
//     ></div>
//   )
// }

// export default TradingViewChart
