// ** React Imports
import React, { useState } from "react"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** Component Imports
import Input from "../../../../../components/Input/Input"
import Button from "../../../../../components/Button/Button"
import PasswordRequirements from "../../../../user/components/signup/PasswordRequirements"

// ** API Imports
import { _changePassword } from "../../../redux/actions"

// ** Utils Imports
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../../snackbar/helpers"
import { FormattedMessage } from "react-intl"

// ** Form Validation Imports
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/,
      "Password doesn't meet requirements"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Password confirmation is required")
})

function ResetPassword() {
  // ** States
  const [loading, setLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  // ** Form Validation Hook
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit"
  })

  // ** Handlers
  const onSubmit = () => {
    _changePassword(
      {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      },
      (data) => {
        setOldPassword("")
        setConfirmPassword("")
        setNewPassword("")
        showSuccessSnackbar({ alertMessage: "Password successfully reset" })
      },
      (error) => {
        showErrorSnackbar({
          alertMessage: error.errors.length ? (
            <FormattedMessage id={error.errors[0]} />
          ) : (
            "Unknown Error"
          )
        })
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={Styles.passwordSection}>
        {/* section header */}
        <div>
          <h6>Password</h6>
          <span className={Styles.smallLabel}>
            Think of something unique and strong. Let's lock your account down
            tight!
          </span>
        </div>

        <Input
          type="password"
          variant="secondary"
          {...register("oldPassword")}
          inputLabel="Old Password"
          onChange={(e) => {
            register("oldPassword").onChange(e)
            setOldPassword(e.target.value)
          }}
          error={errors.oldPassword}
        />

        {/* new password container */}
        <div className={Styles.newPasswordContainer}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column"
            }}
          >
            <Input
              type="password"
              variant="secondary"
              {...register("newPassword")}
              inputLabel="New Password"
              onChange={(e) => {
                register("newPassword").onChange(e)
                setNewPassword(e.target.value)
              }}
              containerStyle={{ width: "100%" }}
              error={errors.newPassword}
            />

            <PasswordRequirements
              password={newPassword}
              containerStyle={{ marginBottom: 30 }}
            />
          </div>

          <Input
            type="password"
            variant="secondary"
            {...register("confirmPassword")}
            inputLabel="Re-enter Password"
            onChange={(e) => {
              register("confirmPassword").onChange(e)
              setConfirmPassword(e.target.value)
            }}
            error={errors.confirmPassword}
          />
        </div>

        {/* change password footer */}
        <div className={Styles.tabFooterContainer}>
          <span className={Styles.smallLabel} style={{ flex: 1 }}>
            To ensure your account is well protected, please use 8 or more
            characters with a mix of letters, numbers & symbols.
          </span>
          <Button
            type="submit"
            disabled={confirmPassword !== newPassword || loading}
            isLoading={loading}
            text="Save Password"
            style={{
              width: "fit-content"
            }}
          />
        </div>
      </div>
    </form>
  )
}

export default ResetPassword
