// ** React Imports
import React, { useEffect, useState } from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Component Imports
import CloseCircle from "../../../../../components/icon-components/CloseCircle/CloseCircle"
import CheckmarkCircle from "../../../../../components/icon-components/CheckmarkCircle/CheckmarkCircle"
import { ReactComponent as CheckedIcon } from "../../../../../components/Radio/icons/radio-selected.svg"
import { ReactComponent as NotCheckedIcon } from "../../../../../components/Radio/icons/radio-not-selected.svg"

function PasswordRequirements({ password = "", containerStyle = {} }) {
  // Track password conditions
  const [conditions, setConditions] = useState({
    length: false,
    containsNumber: false,
    containsCapitalLetter: false,
    containsSpecialCharacter: false
  })

  // On password change
  useEffect(() => {
    setConditions({
      length: password.length > 7,
      containsNumber: /\d/.test(password),
      containsCapitalLetter: /[A-Z]/.test(password),
      containsSpecialCharacter: /[^A-Za-z\d]/.test(password)
    })
  }, [password])

  return (
    <div className={styles.container} style={{ ...containerStyle }}>
      <div
        className={styles.row}
        style={{ color: conditions.length && "var(--primary-color)" }}
      >
        {conditions.length ? (
          <CheckedIcon width={14} />
        ) : (
          <NotCheckedIcon width={14} />
        )}{" "}
        8+ characters
      </div>
      <div
        className={styles.row}
        style={{
          color: conditions.containsCapitalLetter && "var(--primary-color)"
        }}
      >
        {conditions.containsCapitalLetter ? (
          <CheckedIcon width={14} />
        ) : (
          <NotCheckedIcon width={14} />
        )}{" "}
        At least one capital letter
      </div>
      <div
        className={styles.row}
        style={{ color: conditions.containsNumber && "var(--primary-color)" }}
      >
        {conditions.containsNumber ? (
          <CheckedIcon width={14} />
        ) : (
          <NotCheckedIcon width={14} />
        )}{" "}
        Contains a number
      </div>
      <div
        className={styles.row}
        style={{
          color: conditions.containsSpecialCharacter && "var(--primary-color)"
        }}
      >
        {conditions.containsSpecialCharacter ? (
          <CheckedIcon width={14} />
        ) : (
          <NotCheckedIcon width={14} />
        )}{" "}
        Contains a special character (@, %, _, -)
      </div>
    </div>
  )
}

export default PasswordRequirements
