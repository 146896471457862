import React, { useEffect, useState } from 'react'

// icons
import { ReactComponent as InfoIcon } from '../../../assets/icons/imported/info.svg'
import { ReactComponent as FlagIcon } from '../../../assets/icons/imported/finish.svg'
import { ReactComponent as WalletIcon } from '../../../assets/icons/imported/wallet.svg'
import { ReactComponent as MarketIcon } from '../../../assets/icons/imported/market.svg'
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/imported/down-arrow.svg'
import { ReactComponent as MarketCapIcon } from '../../../assets/icons/imported/market-cap.svg'
import { ReactComponent as MarketCapIconFilled } from '../../../assets/icons/imported/market-filled.svg'
// translations
import { FormattedMessage } from 'react-intl'

import { toast } from 'react-toastify'

// redux
import {
  setSelectCurrencyModalOpen,
  setSelectCurrencyModalData
} from '../../modals/select-currency/redux/selectCurrencyModalSlice'
import { _getBalances } from '../../../redux/actions'
import { setBalances } from '../../../redux/slices/balancesSlice'

// helpers
import { estimateUnitValue } from '../../helpers/estimateValueBase'
import { setSnackbarData, setSnackbarOpen } from '../../snackbar/redux/snackbarSlice'
import { formatNumber } from '../../helpers/formatNumber'
// styles
import Styles from './quickBuySell.module.css'

// components
import Button from '../../../components/Button/Button'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import RadioButton from '../../../components/RadioButton/RadioButton'
import { _addOrder, _getCurrencies } from '../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import CoinTag from '../../../components/CoinTag/CoinTag'
import { Tooltip } from '@mui/material'

function QuickBuySell({
  selectedSide = 'buy', // buy - sell
  hideTitle,
  containerStyle = {}
}) {
  const [quantity, setQuantity] = useState('')
  const [apiCurrencies, setApiCurrencies] = useState([])
  const [selectedTab, setSelectedTab] = useState(selectedSide)
  const [selectedRadioButton, setSelectedRadioButton] = useState('market')
  const [price, setPrice] = useState('')
  const [priceLimit, setPriceLimit] = useState('')
  const selectedCoin = useSelector(state => state.selectedCoin.value)
  const marketsList = useSelector(state => state.marketList.value)
  const marketTickers = useSelector(state => state.marketTickers.value)
  const currencies = useSelector(state => state.currencies.value)
  const isLoggedIn = useSelector(state => state.loggedIn.value)
  const dispatch = useDispatch()
  const balances = useSelector(state => state.balances.value)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [refreshBalance, setRefreshBalance] = useState(false)
  const [totalPrice, setTotalPrice] = useState('')
  const [convertedPrice, setConvertedPrice] = useState('')

  //quick buy/sell coin
  // const [quantityCurrency, setQuantityCurrency] = useState(marketsList.find(el => el.id === selectedCoin.id)?.quote_unit)
  // const [buyForCurrency, setBuyForCurrency] = useState(useSelector(state => state.currency.value))

  // convert coin
  const [toCurrency, setToCurrency] = useState(marketsList?.find(el => el.id === selectedCoin.id)?.quote_unit)
  const [fromCurrency, setFromCurrency] = useState(marketsList?.find(el => el.id === selectedCoin.id)?.base_unit)
  const [currencySide, setCurrencySide] = useState('fromCurrency')

  // redux
  const userData = useSelector(state => state.userData?.value)

  useEffect(() => {
    marketTickers['btcusdt']?.avg_price
  }, [marketTickers])

  useEffect(() => {
    setFromCurrency(marketsList?.find(el => el.id === selectedCoin.id)?.base_unit)
    setToCurrency(marketsList?.find(el => el.id === selectedCoin.id)?.quote_unit)
  }, [selectedCoin])

  const handleQuantityChange = event => {
    setTotalPrice('')
    setQuantity(event.target.value)
    setPrice('')
  }

  // const handlePriceChange = (event) => {
  //   setPrice(event.target.value)
  //   setQuantity("")
  // }

  const handlePriceChange = event => {
    setQuantity('')
    if (selectedRadioButton === 'limit') {
      setTotalPrice(event.target.value)
    } else {
      setPrice(event.target.value)
    }
  }

  const handlePriceLimitChange = event => {
    // setTotalPrice("")
    setPriceLimit(event.target.value)
  }

  useEffect(() => {
    _getCurrencies(
      data => {
        setApiCurrencies(data)
      },
      error => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
      }
    )
  }, [])

  let singleConvertedPrice = estimateUnitValue(toCurrency, fromCurrency, 1, currencies, marketsList, marketTickers)
  useEffect(() => {
    setConvertedPrice(
      selectedRadioButton === 'limit'
        ? totalPrice / priceLimit
        : estimateUnitValue(
            quantity ? toCurrency : fromCurrency,
            quantity ? fromCurrency : toCurrency,
            parseFloat(quantity || price),
            currencies,
            marketsList,
            marketTickers
          )
    )
    console.log(priceLimit)
    console.log(quantity)
    console.log(convertedPrice)
    singleConvertedPrice = estimateUnitValue(toCurrency, fromCurrency, 1, currencies, marketsList, marketTickers)
  }, [marketTickers, quantity, price, totalPrice, convertedPrice, priceLimit])

  useEffect(() => {
    if (isLoggedIn) {
      _getBalances(
        res => {
          dispatch(setBalances(res))
        },
        () => {}
      )
    }
  }, [refreshBalance, selectedCoin])

  const handleSubmit = () => {
    setButtonLoading(true)
    _addOrder(
      {
        market: `${fromCurrency}${toCurrency}`,
        side: selectedTab,
        volume: price ? convertedPrice : quantity,
        // Only include price property if selectedRadioButton is "limit"
        ...(selectedRadioButton === 'limit' && {
          price: priceLimit ? priceLimit : convertedPrice
        }),
        ord_type: selectedRadioButton
      },
      data => {
        setButtonLoading(false)
        dispatch(
          setSnackbarData({
            alertMessage: 'Order set',
            buttonMessage: 'See order',
            callback: () => {},
            actionType: 'link', // link - close
            linkTo: '/account/orders/open',
            severity: 'success',
            position: 'topRight'
          })
        )
        dispatch(setSnackbarOpen(true))
        setRefreshBalance(!refreshBalance)
      },
      error => {
        setButtonLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
        setRefreshBalance(!refreshBalance)
      }
    )
  }

  return (
    <div className={Styles.card} style={{ ...containerStyle }}>
      {!hideTitle && <h5 style={{ marginBottom: 20 }}>QUICK ORDER</h5>}

      {/* tabs container */}
      <div className={Styles.buySellTabsContainer}>
        <button
          className={selectedTab === 'buy' ? Styles.selectedTab : undefined}
          onClick={() => {
            if (selectedTab === 'buy') return
            setSelectedTab('buy')
          }}
        >
          <FormattedMessage id='page.markets.trade.buy' /> {fromCurrency?.toUpperCase()}
        </button>
        <button
          className={selectedTab === 'sell' ? Styles.selectedTab : undefined}
          onClick={() => {
            if (selectedTab === 'sell') return
            setSelectedTab('sell')
          }}
        >
          <FormattedMessage id='page.markets.trade.sell' /> {fromCurrency?.toUpperCase()}
        </button>
      </div>

      {/* tab content */}
      <div className={Styles.tabContentContainer}>
        {/* radio buttons container */}
        <div className={Styles.radioButtonContainer}>
          <RadioButton
            text={<FormattedMessage id='page.markets.trade.market' />}
            icon={<MarketCapIconFilled width={12} />}
            isSelected={selectedRadioButton === 'market'}
            onClick={() => {
              if (selectedRadioButton === 'market') return
              setSelectedRadioButton('market')
            }}
          />
          <RadioButton
            text={<FormattedMessage id='page.markets.trade.limit' />}
            icon={<FlagIcon />}
            isSelected={selectedRadioButton === 'limit'}
            onClick={() => {
              if (selectedRadioButton === 'limit') return
              setSelectedRadioButton('limit')
            }}
          />
        </div>

        {/* COIN PRICE / SELECT PAIR ROW */}
        <div className={Styles.coinPriceSelectPairContainer}>
          <div className={Styles.approxPrice}>
            <div>
              <img
                src={apiCurrencies ? apiCurrencies?.find(el => el.id === fromCurrency?.toLowerCase())?.icon_url : '/'}
              />
              <span>{apiCurrencies ? apiCurrencies?.find(el => el.id === fromCurrency?.toLowerCase())?.name : ''}</span>
              <CoinTag
                size={10}
                tag={
                  apiCurrencies
                    ? apiCurrencies?.find(el => el.id === fromCurrency?.toLowerCase())?.id.toUpperCase()
                    : ''
                }
              />
            </div>

            <div>
              {' '}
              &#8776; {formatNumber(singleConvertedPrice)} {toCurrency?.toUpperCase()}
            </div>
          </div>

          {/* SELECT PAIRS */}
          <div>
            <Button
              text='Select Pair'
              variant='link'
              style={{
                color: 'var(--gray-color)',
                paddingRight: 10
              }}
              icon={<DownArrowIcon />}
              iconPosition='right'
              onClick={() => {
                dispatch(
                  setSelectCurrencyModalData({
                    title: 'Select Pair For Quick Order',
                    showTabs: false,
                    type: 'all',
                    showMarketPairs: true,
                    searchPlaceholder: 'Search pair',
                    currenciesToHide: [toCurrency],
                    disableBackdropClick: false,
                    callback: market => {
                      setFromCurrency(market.base_unit)
                      setToCurrency(market.quote_unit)
                    }
                  })
                )
                dispatch(setSelectCurrencyModalOpen(true))
              }}
            />
          </div>
        </div>
        {selectedRadioButton === 'limit' ? (
          <CurrencyInput
            type='number'
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <span>Price Limit ({toCurrency?.toUpperCase()})</span>
                <Tooltip
                  title=' Want to buy or sell at a specific price? A limit order lets you do that, but remember that it might not always go through.'
                  arrow
                  placement='top'
                >
                  <InfoIcon color='lightgray' width={12} />
                </Tooltip>
              </div>
            }
            placeholder='0.00'
            id='limit'
            defaultValue={''}
            onChange={handlePriceLimitChange}
            containerStyle={{
              marginBottom: 20
            }}
            coinName={toCurrency?.toUpperCase()}
            // currencyImg={apiCurrencies ? apiCurrencies.find(el => el.id === fromCurrency?.toLowerCase())?.icon_url : ''}
          />
        ) : null}

        <CurrencyInput
          type='number'
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span>Quantity ({fromCurrency?.toUpperCase()})</span>
              <Tooltip
                title={`How much crypto do you want to ${selectedTab === 'buy' ? 'buy' : 'sell'}?`}
                arrow
                placement='top'
              >
                <InfoIcon color='lightgray' width={12} />
              </Tooltip>
            </div>
          }
          placeholder='0.00'
          value={quantity || convertedPrice ? quantity || convertedPrice : ''}
          onChange={handleQuantityChange}
          containerStyle={{
            marginBottom: selectedRadioButton === 'market' ? 40 : 20,
            marginTop: selectedRadioButton === 'market' && 40
          }}
          coinName={fromCurrency?.toUpperCase()}
          secondaryTitle={
            userData.email &&
            `Balance: ${formatNumber(balances?.find(el => el.currency === fromCurrency)?.balance) ?? '0.00'}`
          }
          currencyImg={apiCurrencies ? apiCurrencies?.find(el => el.id === fromCurrency?.toLowerCase())?.icon_url : ''}
        />

        <CurrencyInput
          // disabled={selectedRadioButton === 'market'}
          type='number'
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span>
                {selectedTab === 'buy'
                  ? `Buy For (${toCurrency?.toUpperCase()})`
                  : `Sell For (${toCurrency?.toUpperCase()})`}
              </span>
              <Tooltip
                title={`Your calculated total ${selectedTab === 'buy' ? 'spend' : 'gain'}, based off your request`}
                arrow
                placement='top'
              >
                <InfoIcon color='lightgray' width={12} />
              </Tooltip>
            </div>
          }
          placeholder='0.00'
          // disabled={selectedRadioButton === "limit"}
          value={
            selectedRadioButton === 'limit'
              ? totalPrice
                ? totalPrice
                : convertedPrice || priceLimit * quantity
                ? convertedPrice || priceLimit * quantity
                : ''
              : price || convertedPrice
              ? price || convertedPrice
              : ''
          }
          onChange={handlePriceChange}
          containerStyle={{
            marginBottom: selectedRadioButton === 'market' ? 40 : 10,
            marginTop: selectedRadioButton === 'market' && 20
            // paddingRight: 20
          }}
          onDropdownClick={() => {}}
          coinName={toCurrency?.toUpperCase()}
          currencyImg={apiCurrencies ? apiCurrencies?.find(el => el.id === toCurrency?.toLowerCase())?.icon_url : ''}
          showDropdownArrow={false}
          secondaryTitle={
            userData.email &&
            `Balance: ${formatNumber(balances?.find(el => el.currency === toCurrency)?.balance) ?? '0.00'}`
          }
          // onDropdownClick={() => {
          //   dispatch(setSelectCurrencyModalData({
          //     title: 'Select Market',
          //     showTabs: false,
          //     type: 'all',
          //     showMarketPairs: true,
          //     currenciesToHide: [toCurrency],
          //     disableBackdropClick: false,
          //     callback: (market) => {
          //       setFromCurrency(market.base_unit)
          //       setToCurrency(market.quote_unit)
          //     }
          //   }))
          //   dispatch(setSelectCurrencyModalOpen(true))
          // }}
        />
      </div>

      {/* fee info */}
      <span className={Styles.feeInfo}>
        {<FormattedMessage id='page.markets.trade.total' />}: (+
        {<FormattedMessage id='page.markets.trade.fee' />} 0.2){' '}
        {convertedPrice > 0 ? parseFloat(convertedPrice) + 0.2 : '0.00'}
      </span>

      {/* buy/sell button */}
      <Button
        text='Quick Order'
        // text={selectedTab === 'buy' ? (`Quick Buy ${marketsList.find(el => el.id === selectedCoin.id)?.base_unit.toUpperCase()}`) : (`Quick Sell ${marketsList.find(el => el.id === selectedCoin.id)?.base_unit.toUpperCase()}`)}
        style={{
          marginTop: selectedRadioButton === 'market' ? 30 : 10,
          backgroundColor: userData.email && selectedTab === 'sell' ? 'rgba(240,68,68,255)' : null,
          color: selectedTab === 'sell' ? 'white' : null
        }}
        onClick={handleSubmit}
        type={!userData.email && 'loggedOut'}
        isLoading={buttonLoading}
      />
    </div>
  )
}

export default QuickBuySell
