import React from "react"

// icons
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/imported/checkmark.svg"

// redux
import { useSelector } from "react-redux"

function CheckmarkCircle({
  size = 15,
  themeBackgroundColor = false,
  hideBg = false
}) {
  const iconSize = `calc(${size}px - 35%)`
  const theme = useSelector((state) => state.theme.value)
  return (
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: hideBg
          ? "transparent"
          : themeBackgroundColor
          ? theme === "dark"
            ? "black"
            : "white"
          : "white",
        width: size,
        height: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CheckmarkIcon
        style={{
          width: iconSize,
          height: iconSize,
          color: "var(--primary-color)"
        }}
      />
    </div>
  )
}

export default CheckmarkCircle
