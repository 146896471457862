import { API } from "../../../../utility/API"
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"

//************************************//
export const _getCurrencies = (callback, data) => {
  API.get(`/trading/public/currencies`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {})
}
//************************************//
export const _getCoinKline = (data, callback, callbackErr) => {
  const currentTime = Math.floor(Date.now() / 1000)
  // let url = `trading/public/markets/k-line?period=30&time_from=${currentTime - (24 * 60 * 60)}&time_to=${currentTime}` // 1440
  let url = `trading/public/markets/k-line?period=1440`
  if (data && data.pairs && data.pairs.length > 0) {
    url += data.pairs.map((pair) => `&markets[]=${pair}`).join("")
  }
  API.get(url, { params: data })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
