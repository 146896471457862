import { Blogs_API } from "../../../../utility/API"

//************************************//
export const _getBlogs = (params, callback, callbackErr) => {
  Blogs_API.get(`public/show`, { params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getBlog = (id, callback, callbackErr) => {
  Blogs_API.get(`public/blog/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
