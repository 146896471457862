import { store } from "../../../../redux/store"
import {
  setActionsBeneficiaryModalData,
  setActionsBeneficiaryModalOpen
} from "../redux/slices/actionsBeneficiaryModalSlice"

export const showActionsBeneficiaryModal = (beneficiary) => {
  store.dispatch(setActionsBeneficiaryModalData(beneficiary))
  store.dispatch(setActionsBeneficiaryModalOpen(true))
}

export const hideActionsBeneficiaryModal = () => {
  store.dispatch(setActionsBeneficiaryModalOpen(false))
  store.dispatch(setActionsBeneficiaryModalData({}))
}
