import React from "react"

// styles
import Styles from "./languageCurrencyButton.module.css"

// icon
import CheckmarkCircle from "../icon-components/CheckmarkCircle/CheckmarkCircle"

function LanguageCurrencyButton({
  label = "",
  isSelected = false,
  onClick = () => {}
}) {
  return (
    <button
      onClick={onClick}
      className={`${Styles.button} ${isSelected ? Styles.selected : ""}`}
    >
      <span>{label}</span>
      {isSelected && <CheckmarkCircle themeBackgroundColor />}
    </button>
  )
}

export default LanguageCurrencyButton
