import { store } from "../../../redux/store"
import {
  setSelectPaymentMethodModalData,
  setSelectPaymentMethodModalOpen
} from "./redux/selectPaymentMethodModalSlice"

export const showSelectPaymentMethodModal = ({
  callback = () => {},
  fiatCoin = null,
  cryptoCoin = null,
  supportedCoins = [],
  onChange = () => {},
  onConfirm = () => {},
  fromValue = 0,
  toValue = 0
}) => {
  store.dispatch(
    setSelectPaymentMethodModalData({
      callback,
      fiatCoin,
      cryptoCoin,
      supportedCoins,
      onChange,
      onConfirm,
      fromValue,
      toValue
    })
  )
  store.dispatch(setSelectPaymentMethodModalOpen(true))
}

export const hideSelectPaymentMethodModal = () => {
  store.dispatch(
    setSelectPaymentMethodModalData({
      callback: () => {},
      fiatCoin: null,
      cryptoCoin: null,
      supportedCoins: [],
      onChange: () => {},
      onConfirm: () => {}
    })
  )
  store.dispatch(setSelectPaymentMethodModalOpen(false))
}
