import { store } from "../../../redux/store"
import {
  setSuccessModalData,
  setSuccessModalOpen
} from "./redux/successModalSlice"

export const showSuccessModal = ({
  title = "",
  subtitle = "",
  showActionButton = false,
  actionButtonText = "",
  href = null,
  callback = () => {}
}) => {
  store.dispatch(
    setSuccessModalData({
      title,
      subtitle,
      showActionButton,
      actionButtonText,
      href,
      callback
    })
  )
  store.dispatch(setSuccessModalOpen(true))
}

export const hideSuccessModal = () => {
  store.dispatch(
    setSuccessModalData({
      title: "",
      subtitle: "",
      showActionButton: false,
      actionButtonText: "",
      href: null,
      callback: () => {}
    })
  )
  store.dispatch(setSuccessModalOpen(false))
}
