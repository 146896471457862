import { createSlice } from "@reduxjs/toolkit"

const coinifyConfirmPaymentSlice = createSlice({
  name: "coinifyConfirmPaymentState",
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setCoinifyConfirmPaymentModalOpen: (state, action) => {
      state.open = action.payload
    },
    setCoinifyConfirmPaymentModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setCoinifyConfirmPaymentModalOpen,
  setCoinifyConfirmPaymentModalData
} = coinifyConfirmPaymentSlice.actions

export default coinifyConfirmPaymentSlice.reducer
