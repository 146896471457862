import React from 'react'
import Layout from '../components/Layout'
import { Navigate, Route, Routes } from 'react-router-dom'
import Exchange from '../modules/exchange/views/Exchange'
import Markets from '../modules/markets/views/markets'
// import Profile from '../modules/user/views/profile'
import Settings from './settings'
import Reset from './reset'
import OtpVerify from './otp-verify'
import OtpNumber from './otp-number'
import Lock from './lock'
import TermsAndConditions from '../modules/terms-and-conditions/terms-and-conditions'
import NewsDetails from './news-details'
import Notfound from '../modules/not-found/notfound'
import Convert from '../modules/convert/views/Convert'
import LandingPage from '../modules/landing-page/views/LandingPage'
import Wallet from '../modules/history/views/Wallet/Wallet'
import Profile from '../modules/profile/views/Profile'
import KYCVerification from '../modules/kyc/views/KYCVerification'
import ComingSoon from '../modules/coming-soon/ComingSoon'
import Blogs from '../modules/blogs/views/Blogs'

// redux
import { useSelector } from 'react-redux'
import UserTabsNavigation from '../modules/user/views/UserTabsNavigation'
import SessionExpired from '../modules/session-expired/SessionExpired'

// routes
import {
  userRoutes,
  authenticatedRoutes,
  comingSoonRoutes,
  blogRoutes,
  profileRoutes
} from '../constants/routesConstants'

// launchpad pages
import Launchpad from '../modules/launchpad/views/Launchpad/Launchpad'
import CoinDetails from '../modules/launchpad/views/CoinDetails/CoinDetails'
import ApplyLaunchpad from '../modules/launchpad/views/ApplyLaunchpad/ApplyLaunchpad'

// footer pages
import FAQs from '../modules/FAQs/FAQs'
import AboutUs from '../modules/about-us/AboutUs'
import Downloads from '../modules/downloads/Downloads'
import Careers from '../modules/careers/Careers/Careers'
import CareerApply from '../modules/careers/CareerApply/CareerApply'
import ContactSupport from '../modules/contact-support/ContactSupport'
import YourBitMatters from '../modules/your-bit-matters/YourBitMatters'
import CompanySecurity from '../modules/company-security/CompanySecurity'
import BrowseCareers from '../modules/careers/BrowseCareers/BrowseCareers'
import MobileApplication from '../modules/mobile-application/MobileApplication'
import OnOffRamp from '../modules/on-off-ramp/views/OnOffRamp/OnOffRamp'
import PrivacyPolicy from '../modules/privacy-policy/privacy-policy'
import ViewBlog from '../modules/blogs/views/view-blog'

// mobile
import MobileChart from '../components/MobileChart'
import CoinifyRedirect from '../modules/coinify-redirect'
import Staking from '../modules/staking'

export default function index() {
  const userData = useSelector(state => state.userData.value)
  const isLoggedIn = useSelector(state => state.userData.value.email)
  const hasEmailLabel = userData?.labels?.some(label => label.key === 'email')

  return (
    <>
      <Layout>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/trade/:marketName?' element={<Exchange />} />
          <Route path='/markets' element={<Markets />} />

          {/* ------------  BLOG PAGES ------------ */}
          {/* ------------------------------------- */}
          {blogRoutes.map(route => (
            <Route path={route} key={route} element={<Blogs />} />
          ))}
          <Route path='/blogs/:id' element={<ViewBlog />} />

          {/* ------------  LAUNCHPAD PAGES ------------ */}
          {/* ------------------------------------------ */}
          {/* <Route path="/launchpad/apply">
            <ApplyLaunchpad />
          </Route>
          <Route path="/launchpad">
            <Launchpad />
          </Route>
          <Route path="/launchpad/:coinName?">
            <CoinDetails />
          </Route> */}
          {/* ------------------------------------------ */}
          {/* ------------------------------------------ */}
          <Route
            path='/account/orders/*'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />
          <Route
            path='/account/trade/*'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />

          <Route
            path='/account/convert/*'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />

          <Route
            path='/account/wallet/*'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />

          <Route
            path='/account/launchpad'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />
          <Route
            path='/convert/history'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />

          {profileRoutes.map(route => (
            <Route
              path={route}
              element={
                isLoggedIn && hasEmailLabel ? (
                  <Profile />
                ) : isLoggedIn && !hasEmailLabel ? (
                  <Navigate to='/email-verification-required' replace />
                ) : (
                  <Navigate to='/trade' replace />
                )
              }
            />
          ))}

          {/* <Route path="/convert">
            <Convert />
          </Route > */}
          <Route path='/convert/:marketId?' element={<Convert />} />
          <Route
            path='/history'
            element={
              isLoggedIn && hasEmailLabel ? (
                <Wallet />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />

          {/* On Off Ramp */}
          <Route path='/buy-crypto' element={<OnOffRamp />} />

          {/* <Route path="/settings">
            {isLoggedIn && hasEmailLabel ? (
              <Settings />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Navigate to="/email-verification-required" replace/>
            ) : (
              <Navigate to="/trade" replace/>
            )}
          </Route > */}

          {/* -------------- FOOTER PAGES -------------- */}
          {/* ------------------------------------------ */}
          <Route path='/contact-support' element={<ContactSupport />} />
          <Route path='/downloads' element={<Downloads />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/company-security' element={<CompanySecurity />} />
          <Route path='/faq' element={<FAQs />} />
          <Route path='/your-bit-matters' element={<YourBitMatters />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/careers/browse' element={<BrowseCareers />} />
          <Route path='/careers/:jobId' element={<CareerApply />} />
          <Route path='/mobile-application' element={<MobileApplication />} />
          {/* ------------------------------------------ */}
          {/* ------------------------------------------ */}

          <Route
            path='/kyc'
            element={
              isLoggedIn && hasEmailLabel ? (
                <KYCVerification />
              ) : isLoggedIn && !hasEmailLabel ? (
                <Navigate to='/email-verification-required' replace />
              ) : (
                <Navigate to='/trade' replace />
              )
            }
          />

          {userRoutes.map(route => (
            <Route path={route} element={<UserTabsNavigation />} />
          ))}
          {/* <Route path="/login">
            <Login />
          </Route >
          <Route path="/signup">
            <Signup />
          </Route >
          <Route path="/recover-password">
            <PasswordRecovery />
          </Route >
          <Route path="/2fa">
            <TwoFA />
          </Route >
          <Route path="/confirmation-code">
            <CodeConfirmation />
          </Route > */}
          {/* <Route path="/otp-number">
            <OtpNumber />
          </Route > */}
          {/* <Route path="/reset">
            <Reset />
          </Route > */}
          {/* <Route path="/lock">
            <Lock />
          </Route > */}
          {/* <Route path="/otp-verify">
            <OtpVerify />
          </Route > */}
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/privacy-and-policy' element={<PrivacyPolicy />} />
          <Route path='/news-details' element={<NewsDetails />} />
          {/* <Route path="/accounts/confirmation">
            <EmailVerified />
          </Route > */}
          {/* <Route path="/accounts/password_reset">
            <ResetPassword />
          </Route > */}

          {comingSoonRoutes.map(route => (
            <Route path={route} element={<ComingSoon />} />
          ))}

          {/* MOBILE VIEW CHART */}
          <Route path='/mobile/chart' element={<MobileChart />} />

          {/* COINIFY */}
          <Route path='/coinify-redirect' element={<CoinifyRedirect />} />
          <Route path='/session-expired' element={<SessionExpired />} />

          {/* STAKING */}
          <Route path='/staking' element={<Staking />} />

          <Route element={<Notfound />} />
        </Routes>
      </Layout>
    </>
  )
}
