// function to add three dots for long description in the table
export const formatBeneficiaryCellText = (text) => {
  try {
    if (typeof text !== "string") {
      throw new Error("Invalid argument: input must be a string")
    }

    if (text.length > 16) {
      return text.substring(0, 16) + "..."
    }

    return text
  } catch (error) {
    console.error(error)
  }
}
