import { createSlice } from "@reduxjs/toolkit"

const snackbarSlice = createSlice({
  name: "snackbarState",
  initialState: {
    open: false,
    data: {
      alertMessage: "",
      buttonMessage: "",
      callback: () => {},
      actionType: "", // link - close
      linkTo: "", // if action type is link
      severity: "", // error - warning - info - success
      position: "topRight" // topRight - bottomLeft
    }
  },
  reducers: {
    setSnackbarOpen: (state, action) => {
      state.open = action.payload
    },
    setSnackbarData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setSnackbarOpen, setSnackbarData } = snackbarSlice.actions

export default snackbarSlice.reducer
