// ** React Imports
import React, { useEffect, useState } from "react"

// ** Component Imports
import CoinDisplay from "../CoinDisplay/CoinDisplay"
import Autocomplete from "../Autocomplete/Autocomplete"

// ** Redux Imports
import { _getCoins } from "./actions"
import { useSelector } from "react-redux"

function SelectMarketAutocomplete({
  placeholder = "Pair",
  value,
  onChange = () => {}
}) {
  // ** States
  const markets = useSelector((state) => state.marketList.value)

  // Custom filter logic to allow searching with or without the slash
  const filterOptions = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.replace("/", "").toLowerCase()
    return options.filter((option) => {
      const optionText = `${option.id}${option.name}`.toLowerCase()
      return optionText.includes(normalizedInputValue)
    })
  }

  return (
    <Autocomplete
      value={value}
      options={markets}
      onChange={onChange}
      placeholder={placeholder}
      filterOptions={filterOptions}
      getOptionLabel={(option) => `${option.name}`}
      renderOption={(params, option) => <div {...params}>{option.name}</div>}
    />
  )
}

SelectMarketAutocomplete.displayName = "SelectMarketAutocomplete"

export default SelectMarketAutocomplete
