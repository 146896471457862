import { API } from "../../../../../utility/API"

//************************************//
export const _getCoinifyPaymentMethods = (callback, callbackErr = () => {}) => {
  API.get(`trading/account/trade_gateway/payment_methods`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
