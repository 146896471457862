import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Styles from './OrderbookRow.module.css'
import { formatNumber } from '../../../helpers/formatNumber'
import { accumulateVolume } from './helpers/accumulateVolume'

export const mapValues = (maxVolume, data) => {
  const resultData =
    data && maxVolume && data.length
      ? data.map(currentVolume => {
          // tslint:disable-next-line:no-magic-numbers
          return {
            value: (currentVolume / maxVolume) * 100
          }
        })
      : []
  return resultData.sort((a, b) => a.value - b.value)
}

const OrderbookRow = ({ maxVolume, isAsk, resultData, order, index }) => {
  if (!order) {
    return null
  }

  const initialPrice = formatNumber(order[0], 2)
  const price = parseFloat(initialPrice.replace(/,/g, ''))
  const quantity = order[1] || 0

  const totalPrice = formatNumber((Number(price) * Number(quantity)).toString().substring(0, 7)) || '0'
  const rowBackgroundColor = isAsk ? 'rgb(3, 166, 109, 0.2)' : 'rgb(220, 38, 38, 0.2)'
  const rowColor = isAsk ? 'var(--positive-color)' : 'var(--negative-color)'
  console.log(resultData)

  const getRowWidth = () => {
    if (resultData && resultData.length) {
      return {
        width: `${resultData[index].value}% !important`
      }
    }

    return {
      display: 'none'
    }
  }

  return (
    <div className={Styles.rowContainer}>
      <div style={{ color: rowColor }}>{price}</div>
      <div>{quantity}</div>
      <span className={Styles.rowBackground} style={{ ...getRowWidth(), backgroundColor: rowBackgroundColor }}></span>
      <div>{totalPrice}</div>
    </div>
  )
}

OrderbookRow.propTypes = {
  isAsk: PropTypes.bool,
  total: PropTypes.number,
  order: PropTypes.array,
  width: PropTypes.string
}

export default OrderbookRow
