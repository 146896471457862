import { createSlice } from '@reduxjs/toolkit'

export const marketTickersSlice = createSlice({
  name: 'marketTickers',
  initialState: {
    value: []
  },
  reducers: {
    subscribeToMarket: (state) => {
      state.subscribed = true
    },
    unsubscribeFromMarket: (state) => {
      state.subscribed = false
    },
    setMarketTickers: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMarketTickers, subscribeToMarket, unsubscribeFromMarket } = marketTickersSlice.actions

export default marketTickersSlice.reducer