import { createSlice } from '@reduxjs/toolkit'

const selectCurrencyModalSlice = createSlice({
  name: 'confirmConvertModalState',
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setConfirmConvertModalOpen: (state, action) => {
      state.open = action.payload
    },
    setConfirmConvertModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setConfirmConvertModalOpen, setConfirmConvertModalData } = selectCurrencyModalSlice.actions

export default selectCurrencyModalSlice.reducer