import { store } from "../../../redux/store"
import {
  setLanguageCurrencySelectModalOpen,
  setLanguageCurrencySelectModalData
} from "./redux/LanguageCurrencySelectModalSlice"

export const showLanguageCurrencySelectModal = (selectedTab = "language") => {
  store.dispatch(setLanguageCurrencySelectModalData({ selectedTab }))
  store.dispatch(setLanguageCurrencySelectModalOpen(true))
}

export const hideLanguageCurrencySelectModal = () => {
  store.dispatch(
    setLanguageCurrencySelectModalData({ selectedTab: "language" })
  )

  store.dispatch(setLanguageCurrencySelectModalOpen(false))
}

export const setLanguageCurrencySelectModalSelectedTab = (selectedTab) => {
  store.dispatch(setLanguageCurrencySelectModalData({ selectedTab }))
}
