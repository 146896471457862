import { createSlice } from "@reduxjs/toolkit"

const ModifySMSAuthModalSlice = createSlice({
  name: "modifySMSAuthModalState",
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setModifySAuthModalOpen: (state, action) => {
      state.open = action.payload
    },
    setModifySAuthModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setModifySAuthModalOpen, setModifySAuthModalData } =
  ModifySMSAuthModalSlice.actions

export default ModifySMSAuthModalSlice.reducer
