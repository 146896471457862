import { _getMarketKline } from "../redux/actions/index.js"
import { periodMinutesToString, klineArrayToObject } from "../utility/helpers"
import { changeResolution } from '../redux/slices/klineSlice'
import { store } from "../../../redux/store"
import KlineStream from "./KlineStream.js"
export const print = (...x) => console.log.apply(null, [">>>> TC", ...x])
const CHANGE_KLINE_RESOLUTION = 'CHANGE_KLINE_RESOLUTION'
const by_binance = false
const resolutionToSeconds = (r) => {
    const minutes = parseInt(r, 10)
    if (r === "1D") {
        return 1440
    } else if (r === "1w") {
        return 10080
    } else if (r === "4w") {
        return 40320
    } else if (r === "D") {
        return 4320
    } else if (!isNaN(minutes)) {
        return minutes
    } else {
        return 1
    }
}

const config = {
    supports_timescale_marks: true,
    supports_time: false,
    supported_resolutions: [
        "1",
        "5",
        "15",
        "30",
        "60",
        "120",
        "240",
        "360",
        "720",
        "1440",
        "4320",
        "10080",
        "40320",
    ],
}
export const dataFeedObject = (tradingChart, markets) => {
    const dataFeed = {
        onReady: (cb) => {
            setTimeout(() => cb(config), 0)
        },
        searchSymbols: (
            userInput,
            exchange,
            symbolType,
            onResultReadyCallback
        ) => {
            const symbols = markets.map((m) => ({
                symbol: m.id,
                full_name: m.name,
                description: m.name,
                exchange: window.env.name || "Exchange",
                ticker: m.id,
                type: "bitcoin",
                currency_code: m.quote_unit.toUpperCase(),
            }))
            setTimeout(() => onResultReadyCallback(symbols), 0)
        },
        resolveSymbol: (
            symbolName,
            onSymbolResolvedCallback,
            onResolveErrorCallback
        ) => {
            // expects a symbolInfo object in response
            const symbol = markets.find(
                (m) => m.id === symbolName || m.name === symbolName
            )

            if (!symbol) {
                return setTimeout(
                    () => onResolveErrorCallback("Symbol not found"),
                    0
                )
            }

            const symbolStub = {
                name: symbol.name,
                currency_code: symbol.quote_unit.toUpperCase(),
                description: "",
                type: "bitcoin",
                session: "24x7",
                timezone: "Etc/UTC",
                ticker: symbol.id,
                minmov: 1,
                pricescale: Math.pow(10, symbol.price_precision),
                has_intraday: true,
                intraday_multipliers: [
                    "1",
                    "5",
                    "15",
                    "30",
                    "60",
                    "120",
                    "240",
                    "360",
                    "720",
                    "1440",
                    "4320",
                    "10080",
                    "40320",
                ],
                supported_resolutions: [
                    "1",
                    "5",
                    "15",
                    "30",
                    "60",
                    "120",
                    "240",
                    "360",
                    "720",
                    "1440",
                    "4320",
                    "10080",
                    "40320",
                ],
                volume_precision: symbol.amount_precision,
                data_status: "streaming",
            }
            return setTimeout(() => onSymbolResolvedCallback(symbolStub), 0)
        },
        getTimescaleMarks: async (
            symbolInfo,
            from,
            to,
            onDataCallback,
            resolution
        ) => {
            if (tradingChart.tvWidget && tradingChart.tvWidget.activeChart) {
                const range = tradingChart.tvWidget.activeChart().getVisibleRange()
                const period = tradingChart.tvWidget.activeChart().resolution()
                store.dispatch(changeResolution(period))
                store.dispatch({ type: CHANGE_KLINE_RESOLUTION })
            }
        },
        getBars(
            symbolInfo,
            resolution,
            periodParams,
            onHistoryCallback,
            onErrorCallback
        ) {
            return _getMarketKline(
                by_binance,
                symbolInfo.ticker || symbolInfo.name.toUpperCase(),
                resolution,
                periodParams.from,
                periodParams.to,
                (data) => {
                    const transformedData = data.map((item) => ([
                        Math.floor(item[0] / 1000), // Convert milliseconds to seconds
                        parseFloat(item[1]),
                        parseFloat(item[2]),
                        parseFloat(item[3]),
                        parseFloat(item[4]),
                        parseFloat(item[5])
                    ]))
                    let bars
                    if (by_binance) {
                        bars = transformedData.map(klineArrayToObject)
                        return onHistoryCallback(bars, {
                            noData: false,
                        })
                    } else {
                        bars = data.map(klineArrayToObject)
                        return onHistoryCallback(bars, {
                            noData: false,
                        })
                    }
                },
                // const cdata = data.map(d => {
                //     {time:d[0] / 1000, open:parseFloat(d[1]), high:parseFloat(d[2]), low:parseFloat(d[3]), close:parseFloat(d[4])}
                // })
                // return onHistoryCallback(cdata, {
                //     noData: false,
                // })

                (e) => {
                    return onHistoryCallback([], {
                        noData: true,
                    })
                }
            )
        },
        subscribeBars: (
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscribeUID,
            onResetCacheNeededCallback
        ) => {
            const periodString = periodMinutesToString(
                resolutionToSeconds(resolution)
            )
            KlineStream.subscribeBars(symbolInfo, periodString, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback)
        },
        unsubscribeBars: (subscribeUID) => {
            KlineStream.unsubscribeBars(subscribeUID)
        }
    }
    return dataFeed
}
export const getTradingChartTimezone = (offset, period) => {
    const zone = period === "DST" ? zonesDST[offset] : zonesSTD[offset]
    return zone ? zone : "Etc/UTC"
}
export const stdTimezoneOffset = (date) => {
    const jan = new Date(date.getFullYear(), 0, 1)
    const jul = new Date(date.getFullYear(), 6, 1)
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}
const zonesDST = {
    "-780": "Pacific/Fakaofo",
    "-765": "Pacific/Chatham",
    "-720": "Pacific/Auckland",
    "-600": "Australia/ACT",
    "-570": "Australia/Adelaide",
    "-540": "Asia/Tokyo",
    "-480": "Asia/Shanghai",
    "-420": "Asia/Bangkok",
    "-360": "Asia/Almaty",
    "-345": "Asia/Kathmandu",
    "-330": "Asia/Kolkata",
    "-240": "Asia/Dubai",
    "-270": "Asia/Tehran",
    "-180": "Europe/Moscow",
    "-120": "Europe/Paris",
    60: "Europe/London",
    180: "America/Sao_Paulo",
    240: "America/New_York",
    360: "America/El_Salvador",
    420: "America/Los_Angeles",
    600: "Pacific/Honolulu",
}
/*
 ** TimeZones for Standart Time (Winter time)
 */

const zonesSTD = {
    "-825": "Pacific/Chatham",
    "-780": "Pacific/Auckland",
    "-660": "Australia/Brisbane",
    "-630": "Australia/Adelaide",
    "-540": "Asia/Tokyo",
    "-480": "Asia/Shanghai",
    "-420": "Asia/Bangkok",
    "-360": "Asia/Almaty",
    "-345": "Asia/Kathmandu",
    "-330": "Asia/Kolkata",
    "-240": "Asia/Dubai",
    "-210": "Asia/Tehran",
    "-180": "Europe/Moscow",
    "-120": "Africa/Cairo",
    "-60": "Europe/Paris",
    0: "Europe/London",
    120: "America/Argentina/Buenos_Aires",
    240: "America/Caracas",
    300: "America/Bogota",
    360: "America/El_Salvador",
    480: "America/Los_Angeles",
    600: "Pacific/Honolulu",
}
