import { createSlice } from '@reduxjs/toolkit'

export const lastTradeSlice = createSlice({
  name: 'lastTrade',
  initialState: {
    value: ''
  },
  reducers: {
    setLastTrade: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setLastTrade } = lastTradeSlice.actions

export default lastTradeSlice.reducer