import { createSlice } from "@reduxjs/toolkit"

const LanguageCurrencySelectModalSlice = createSlice({
  name: "languageCurrencySelectModalState",
  initialState: {
    open: false,
    data: {
      title: "",
      selectedTab: "language", // language - currency
      callback: () => {}
    }
  },
  reducers: {
    setLanguageCurrencySelectModalOpen: (state, action) => {
      state.open = action.payload
    },
    setLanguageCurrencySelectModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setLanguageCurrencySelectModalData,
  setLanguageCurrencySelectModalOpen
} = LanguageCurrencySelectModalSlice.actions

export default LanguageCurrencySelectModalSlice.reducer
