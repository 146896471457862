import React, { useState, useContext } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// react router
import { Link, useNavigate } from "react-router-dom"

// utilities
import _, { isEmpty } from "lodash"
import {
  showSuccessSnackbar,
  showErrorSnackbar
} from "../../../snackbar/helpers"

// translations
import { FormattedMessage, injectIntl } from "react-intl"

// redux
import { setUser } from "../../redux/slices/userDataSlice"
import { _generateCode } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"

// styles
import Styles from "./passwordRecoveryTab.module.css"

// ** Icons
import { ReactComponent as RecoverIcon } from "../../../../assets/icons/imported/password_recovery.svg"

// components
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Form } from "reactstrap"
import Button from "../../../../components/Button/Button"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"

// components
import Input from "../../../../components/Input/Input"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// login schema
const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required")
})

const PasswordRecovery = () => {
  // ** Hooks
  const navigate = useNavigate()
  const theme = useSelector((state) => state.theme.value)

  // ** States
  const [loading, setLoading] = useState(false)
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  // ** Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit"
  })

  document.title = "Wenbit: Recover Password"

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      setLoading(true)
      _generateCode(
        {
          email: data.email,
          captcha_response: recaptchaResponse
        },
        (data) => {
          navigate("/login")
          showSuccessSnackbar({
            alertMessage: "Email with reset code sent succesfully"
          })
          setLoading(false)
        },
        (error) => {
          setLoading(false)
          if (error === 404) {
            showErrorSnackbar({
              alertMessage: "No user with this email found."
            })
          } else {
            showErrorSnackbar({
              alertMessage: error.errors[0].length ? (
                <FormattedMessage id={error.errors[0]} />
              ) : (
                "Unknown Error"
              )
            })
          }
        }
      )
    }
  }

  return (
    <div className={Styles.tabContainer}>
      <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>
        <RecoverIcon
          width={80}
          height={80}
          color="var(--primary-color)"
          style={{ margin: "20px auto" }}
        />

        {/* title */}
        <span className={Styles.title}>
          No worries! <br className={Styles.breakLine} />
          Recover your password.
        </span>

        {/* Details */}
        <span className={Styles.detailsSpan}>
          Enter your email to continue.
        </span>
        {/* mail input */}
        <Input
          id="email"
          inputLabel="Email"
          error={errors.email}
          {...register("email")}
        />
        <ReCAPTCHA
          key={`reCaptcha-${theme}`}
          theme={theme}
          style={{ margin: "20px auto 0 auto" }}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={recaptchaOnChange}
        />
        {/* sign in button */}
        <Button
          disabled={!recaptchaConfirmed || loading}
          text="Reset Password"
          type="submit"
          style={{ marginTop: 30, height: "45px" }}
        />

        <span className={Styles.linksContainer}>
          <Link to="/login">Log In</Link> or <Link to="/signup">Register</Link>
        </span>
      </Form>
    </div>
  )
}
export default injectIntl(PasswordRecovery)
