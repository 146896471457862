import { createSlice } from "@reduxjs/toolkit"

const DrawerNavigationSlice = createSlice({
  name: "drawerNavigationState",
  initialState: {
    open: false
  },
  reducers: {
    setDrawerNavigationOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const { setDrawerNavigationOpen } = DrawerNavigationSlice.actions

export default DrawerNavigationSlice.reducer
