import React from 'react'

// styles
import Styles from './servicesCard.module.css'

// redux
import { useSelector } from 'react-redux'

function ServicesCard({
    icon,
    title,
    text
}) {

  const theme = useSelector(state => state.theme.value)

  return (
    <div
      className={Styles.card}
      style={{
        backgroundColor: theme === 'light' && 'transparent',
        border: theme === 'light' && '1.5px #F0F1F5 solid'
      }}
    >
        <img src={icon}/>
        <div>
          <div className={Styles.title}>{title}</div>
          <p className={Styles.text}>{text}</p>
        </div>
    </div>
  )
}

export default ServicesCard