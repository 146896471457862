// ** React Imports
import React from "react"

// ** Style Imports
import Styles from "./mobileRowDatagrid.module.css"

// ** Component Imports
import RowOptions from "../../components/RowOptions"

import MenuItem from "@mui/material/MenuItem"
import { useSelector } from "react-redux"

function MobileRowDatagrid({
  rows = [],
  renderRowHeader,
  renderRowFooter,
  showOptions = false,
  options = []
}) {
  const theme = useSelector((state) => state.theme.value)

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.headerContainer}>
        {renderRowHeader && (
          <div style={{ margin: "7px 0" }}>{renderRowHeader()}</div>
        )}

        {showOptions && <RowOptions options={options} />}
      </div>

      <div className={Styles.rowsContainer}>
        {rows.map((row) => (
          <div className={Styles.row}>
            <span className={Styles.titleSpan}>{row?.title}</span>
            <span className={Styles.valueSpan}>{row?.value}</span>
          </div>
        ))}
      </div>

      {renderRowFooter && (
        <div style={{ margin: "7px 0" }}>{renderRowFooter()}</div>
      )}
    </div>
  )
}

export default MobileRowDatagrid
