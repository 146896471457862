// ** React Imports
import React, { useRef, useState } from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** MUI Imports
import { Popper, Paper } from "@mui/material"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Constants Imports
import { exchangeRoutes } from "../../constants/routesConstants"

function HoverPopper({
  children,
  popperContent,
  mainContainerStyle,
  buttonContainerStyle,
  popperStyle,
  placement = "bottom-start",
  rest
}) {
  // ** States
  const [open, setOpen] = useState(false)
  const [closeTimeout, setCloseTimeout] = useState(null)

  const theme = useSelector((state) => state.theme.value)

  // ** Refs
  const anchorRef = useRef(null)

  const handlePopoverOpen = () => {
    if (closeTimeout) clearTimeout(closeTimeout)
    setOpen(true)
  }

  const handlePopoverClose = () => {
    const timeout = setTimeout(() => {
      setOpen(false)
    }, 10)
    setCloseTimeout(timeout)
  }

  const handlePopperEnter = () => {
    if (closeTimeout) clearTimeout(closeTimeout)
  }

  return (
    <div
      style={{ height: "100%", ...mainContainerStyle }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <div ref={anchorRef} style={{ height: "100%", ...buttonContainerStyle }}>
        {children}
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        sx={{ zIndex: 9999999 }}
        {...rest}
      >
        <div
          onMouseEnter={handlePopperEnter}
          onMouseLeave={handlePopoverClose}
          className={styles.popperContainer}
          style={{
            boxShadow:
              theme === "dark"
                ? "0px 2px 20px rgba(0,0,0,.4)"
                : "0px 2px 20px rgba(0,0,0,.1)",
            backgroundColor: exchangeRoutes.includes(
              location.pathname.split("/")[1]
            )
              ? "var(--secondary-color)"
              : theme === "light"
              ? "white"
              : "black",
            ...popperStyle
          }}
        >
          {popperContent}
        </div>
      </Popper>
    </div>
  )
}

export default HoverPopper
