import React from "react"

// styles
import Styles from "./datagridFooter.module.css"
import "./muiPagination.css"
import MUIPagination from "./MUIPagination"

// // icons
// import { ReactComponent as LeftArrowIcon } from '../../assets/icons/imported/left-arrow.svg'
// import { ReactComponent as RightArrowIcon } from '../../assets/icons/imported/right-arrow.svg'

// // MUI
// import Pagination from '@mui/material/Pagination'
// import { PaginationItem } from '@mui/material'

function DataGridFooter({
  page = 1,
  onChange = () => {},
  pageSize,
  totalCount,
  disabled = false
}) {
  return (
    <div className={Styles.footerContainer}>
      <div className={Styles.footerText}>
        {`${page * pageSize - (pageSize - 1)}-${
          page * pageSize > totalCount ? totalCount : page * pageSize
        }${totalCount ? ` of ${totalCount}` : ""}`}
      </div>

      {/* <Pagination
        disabled={disabled}
        size='small'
        count={Math.ceil(totalCount / pageSize)}
        page={page}
        shape="rounded"
        variant='outlined'
        color='primary'
        onChange={onChange}
        boundaryCount={1}
        siblingCount={1}
        renderItem={(item) => (
            <PaginationItem
                slots={{
                    previous:() =>  <LeftArrowIcon style={{color: 'var(--primary-color)'}} width={5}/>,
                    next:() =>  <RightArrowIcon style={{color: 'var(--primary-color)'}} width={5}/>
                }}
                {...item}
            />
        )}
    /> */}
      <MUIPagination
        totalCount={totalCount}
        pageSize={pageSize}
        onChange={onChange}
        page={page}
      />
    </div>
  )
}

export default DataGridFooter
