import { createSlice } from '@reduxjs/toolkit'

const openOrdersSlice = createSlice({
  name: 'openOrders',
  initialState: {
    value: []
  },
  reducers: {
    addOrders: (state, action) => {
      state.value = action.payload
    },
    clearOrders: (state) => {
      state.value = []
    },
    addOrder: (state, payload) => {
      state.value.unshift(payload.payload)
    }
  }
})

export const { addOrders, clearOrders, addOrder } = openOrdersSlice.actions

export default openOrdersSlice.reducer