// profile routes
export const profileRoutes = [
  "/account/kyc",
  "/account/profile",
  "/account/security",
  "/account/activities",
  "/account/beneficiaries"
]

// wallet routes
export const walletRoutes = [
  // spot wallet
  "/account/wallet/spot",
  "/account/wallet/spot/all",
  "/account/wallet/spot/available",

  // wallet history
  "/account/wallet/history",

  // order history
  "/account/orders",
  "/account/orders/open",
  "/account/orders/history",

  // trade history
  "/account/trade/history",

  // convert history
  "/account/convert/history"
]

export const userRoutes = [
  "/login",
  "/signup",
  "/recover-password",
  "/2fa",
  "/confirmation-code",
  "/confirm-register",
  "/accounts/password_reset",
  "/email-verification",
  "/accounts/confirmation",
  "/email-verification-required"
]

export const authenticatedRoutes = [
  "/account/orders",
  "/account/trade",
  "/account/convert",
  "/account/wallet",
  "/account/launchpad",
  "/convert/history",
  "/account",
  "/history",
  "/settings",
  "/kyc"
]

export const comingSoonRoutes = [
  "trade",
  "convert",
  "request_form",
  "contact_support",
  "FAQ",
  "security",
  "downloads",
  "buy_crypto",
  "referral",
  "listing_tranding",
  "trending",
  "product_news",
  "events",
  "university",
  "research",
  "market_update",
  "about_us",
  "listing_trading",
  "careers",
  // "news",
  // 'security',
  "community",
  "announcements",
  "crew",
  "fees",
  "launchpad",
  "coming-soon"
]

export const exchangeRoutes = [
  "trade",
  "markets",
  "launchpad",
  "convert",
  "history",
  "wallet",
  "account",
  "orders"
]

export const routesToHideHeader = [
  "/login",
  "/signup",
  "/recover-password",
  "/email-verification",
  "/confirmation-code",
  "/confirm-register",
  "/email-verification-required",
  "/accounts/confirmation",
  "/accounts/password_reset",
  "/mobile/chart",
  "/coinify-redirect"
]
export const routesToHideFooter = [
  "/login",
  "/signup",
  "/recover-password",
  "/email-verification",
  "/confirmation-code",
  "/trade",
  "/confirm-register",
  "/email-verification-required",
  "/accounts/confirmation",
  "/accounts/password_reset",
  "/mobile/chart",
  "/coinify-redirect"
]

export const blogRoutes = [
  "/blogs",
  "/blogs/trending",
  "/blogs/wen-new-bits",
  "/blogs/events",
  "/blogs/inside-scoop",
  "/blogs/latest-buzz"
]
