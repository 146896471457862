import { createSlice } from "@reduxjs/toolkit"

const walletLoadingModalSlice = createSlice({
  name: "walletLoadingModalState",
  initialState: {
    open: false,
    data: {
      callback: () => {}
    }
  },
  reducers: {
    setWalletLoadingModalOpen: (state, action) => {
      state.open = action.payload
    },
    setWalletLoadingModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setWalletLoadingModalOpen, setWalletLoadingModalData } =
  walletLoadingModalSlice.actions

export default walletLoadingModalSlice.reducer
