import { createSlice } from "@reduxjs/toolkit"

const walletDepositModalSlice = createSlice({
  name: "walletDepositModalState",
  initialState: {
    open: false,
    data: {
      title: "",
      qr: null,
      destination: ""
    }
  },
  reducers: {
    setWalletDepositModalOpen: (state, action) => {
      state.open = action.payload
    },
    setWalletDepositModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setWalletDepositModalOpen, setWalletDepositModalData } =
  walletDepositModalSlice.actions

export default walletDepositModalSlice.reducer
