import React from "react"

// styles
import Styles from "./walletLoadingModal.module.css"

// assets
import walletLoadingImage from "./assets/wallet-loading.png"

// redux
import { useDispatch, useSelector } from "react-redux"
import { setWalletLoadingModalOpen } from "./redux/walletLoadingModalSlice"

// components
import WenbitModal from "../../../components/Modal/Modal"
import { CircularProgress } from "@mui/material"

function WalletLoadingModal() {
  const dispatch = useDispatch()

  const modalOpen = useSelector((state) => state.walletLoadingModalState.open)
  const modalData = useSelector((state) => state.walletLoadingModalState.data)
  const handleClose = () => {
    dispatch(setWalletLoadingModalOpen(false))
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        modalWidth={300}
        render={() => (
          <div className={Styles.container}>
            <img className={Styles.image} src={walletLoadingImage} />
            <div className={Styles.title}>{modalData?.title}</div>
            <p className={Styles.description}>{modalData?.description}</p>
            <CircularProgress
              style={{ color: "var(--primary-color)", alignSelf: "center" }}
            />
          </div>
        )}
      />
    </>
  )
}

export default WalletLoadingModal
