import { createSlice } from "@reduxjs/toolkit"

const KYCReminderModalSlice = createSlice({
  name: "kycReminderModalState",
  initialState: {
    open: false
  },
  reducers: {
    setKYCReminderModalOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const { setKYCReminderModalOpen } = KYCReminderModalSlice.actions

export default KYCReminderModalSlice.reducer
