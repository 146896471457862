import { createSlice } from '@reduxjs/toolkit'

export const beneficiariesSlice = createSlice({
  name: 'beneficiaries',
  initialState: {
    value: []
  },
  reducers: {
    setBeneficiaries: (state, payload) => {
      state.value = payload.payload
    },
    clearBeneficiaries: (state, payload) => {
      state.value = []
    },
    addBeneficiary: (state, payload) => {
      state.value.unshift(payload.payload)
    },
    removeBeneficiary: (state, action) => {
      const id = action.payload
      state.value = state.value.filter(beneficiary => beneficiary.id !== id)
    }
  }
})

// Action creators are generated for each case reducer function
export const { setBeneficiaries, clearBeneficiaries, addBeneficiary, removeBeneficiary } = beneficiariesSlice.actions

export default beneficiariesSlice.reducer