import React from "react"

// styles
import Styles from "./profileAvatar.module.css"

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/imported/edit.svg"

// redux
import { useSelector } from "react-redux"

// utils
import { showSelectAvatarModal } from "../../modules/modals/avatar/helpers"

function ProfileAvatar({ size = 100 }) {
  const avatarSrc = useSelector((state) => state.userData.value.user_avatar.url)
  const defaultStyle = {
    width: size,
    height: size
  }
  return (
    <button className={Styles.button} onClick={showSelectAvatarModal}>
      <img src={avatarSrc} style={defaultStyle} className={Styles.img} />
      <div className={Styles.editContainer}>
        <EditIcon color="white" />
      </div>
    </button>
  )
}

export default ProfileAvatar
