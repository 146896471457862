import { createSlice } from '@reduxjs/toolkit'

export const selectedCoinSlice = createSlice({
  name: 'selectedCoin',
  initialState: {
    value: {
        id: 'btcusdt',
        name: 'BTC/USDT'
    }
  },
  reducers: {
    setSelectedCoin: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedCoin } = selectedCoinSlice.actions

export default selectedCoinSlice.reducer