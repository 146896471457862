import React from "react"

// react router
import { Link } from "react-router-dom"

// packages
import { BounceLoader } from "react-spinners"

// styles
import Styles from "./button.module.css"

// redux
import { useSelector } from "react-redux"

function Button({
  disabled = false,
  text,
  size = "regular", // large
  onClick = () => {},
  type = "button",
  width = "100%",
  fitContent,
  maxWidth = false,
  variant = "light",
  style,
  textStyle,
  className,
  icon,
  iconPosition = "left",
  isLoading = false,
  isTabSelected,
  linkTo = "#"
}) {
  const theme = useSelector((state) => state.theme.value)

  if (type === "tab") {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${isTabSelected && Styles.selected} ${Styles.tab} ${
          disabled && Styles.disabled
        }} ${className}`}
        style={{ ...style }}
      >
        {text}
        <div className={Styles.bottomLine}></div>
      </button>
    )
  } else if (type === "link") {
    return (
      <Link
        to={linkTo}
        style={{ textDecoration: "none", width: maxWidth && "100%" }}
      >
        <button
          onClick={() => {
            if (isLoading) return
            onClick()
          }}
          disabled={disabled}
          className={`${theme === "light" && Styles.light} ${Styles.button} ${
            variant === "dark" && Styles.dark
          } ${variant === "outlined" && Styles.outlined} ${
            variant === "link" && Styles.link
          } ${isLoading && Styles.loading} ${
            disabled && Styles.disabled
          } ${className}`}
          style={{ width: fitContent ? "fit-content" : width, ...style }}
          type={isLoading ? "button" : type}
        >
          {iconPosition === "left" && (
            <span className={Styles.icon}>{icon}</span>
          )}

          {text}

          {iconPosition === "right" && (
            <span
              style={{ transform: "translateX(6px) translateY(-1px)" }}
              className={Styles.icon}
            >
              {icon}
            </span>
          )}
          {isLoading && (
            <BounceLoader
              className={Styles.loader}
              size={18}
              color={
                variant === "light"
                  ? "var(--secondary-color)"
                  : "var(--primary-color)"
              }
            />
          )}

          {(!disabled ||
            !isLoading ||
            variant !== "link" ||
            variant !== "outlined") && <div className={Styles.bubble}></div>}
        </button>
      </Link>
    )
  } else if (type === "loggedOut") {
    return (
      <div
        className={`${theme === "light" && Styles.light} ${
          Styles.loggedOutContainer
        }`}
        style={{ ...style }}
      >
        <div>
          <Link to="/login">Sign In</Link> or{" "}
          <Link to="/signup">Register Now</Link>
        </div>
      </div>
    )
  }
  return (
    <button
      onClick={() => {
        if (isLoading) return
        onClick()
      }}
      disabled={disabled}
      className={`${theme === "light" && Styles.light} ${Styles.button} ${
        variant === "dark" && Styles.dark
      } ${variant === "outlined" && Styles.outlined} ${
        variant === "link" && Styles.link
      } ${isLoading && Styles.loading} ${
        disabled && Styles.disabled
      } ${className}`}
      style={{
        width: fitContent ? "fit-content" : width,
        height: size === "large" && "45px",
        fontWeight: size === "large" && "300",
        fontSize: size === "large" && "18px",
        ...style
      }}
      type={isLoading ? "button" : type}
    >
      {iconPosition === "left" && <span className={Styles.icon}>{icon}</span>}

      {text}

      {iconPosition === "right" && (
        <span
          style={{ transform: "translateX(6px) translateY(-1px)" }}
          className={Styles.icon}
        >
          {icon}
        </span>
      )}
      {isLoading && (
        <BounceLoader
          className={Styles.loader}
          size={18}
          color={
            variant === "light"
              ? "var(--secondary-color)"
              : "var(--primary-color)"
          }
        />
      )}

      {(!disabled ||
        !isLoading ||
        variant !== "link" ||
        variant !== "outlined") && <div className={Styles.bubble}></div>}
    </button>
  )
}

export default Button
