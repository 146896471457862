import React from 'react'

// styles
import Styles from './SideNavButton.module.css'
import { useSelector } from 'react-redux'

function SideNavButton({
    text,
    icon,
    onClick = () => {},
    isSelected = false,
    variant, // primary - secondary
    style,
    ...rest
}) {

  const theme = useSelector(state => state.theme.value)

  return (
    <button
      onClick={onClick} className={`${(variant === 'secondary') && Styles.secondary} ${theme === 'light' && Styles.lightButton} ${Styles.sideNavButton} ${isSelected && (theme === 'light' ? Styles.selectedLight : Styles.selected)}`} {...rest}
      style={{
        backgroundColor: (variant === 'secondary' && isSelected) && 'var(--secondary-color)',
        color: (variant === 'secondary' && isSelected) && 'var(--primary-color)',
        ...style
      }}
    >
        {icon}
        <span>{text}</span>
    </button>
  )
}

export default SideNavButton