// ** React Imports
import React, { useEffect, useState } from "react"

// ** Styles Imports
import styles from "./orderbookHeader.module.css"

// ** Formatted Messages
import { FormattedMessage, injectIntl } from "react-intl"

// ** Icons Imports
import { BiChevronDown } from "react-icons/bi"

// ** Component Imports
import Input from "../../../../components/Input/Input"
import HoverPopper from "../../../../components/HoverPopper"
import Tooltip from "../../../../components/Tooltip/Tooltip"
import getPrecisionSelectOptions from "../../../../utility/orderbook/getPrecisionSelectOptions"
import { useDispatch, useSelector } from "react-redux"
import { setGroupingSize } from "../../redux/slices/orderBookSlice"

function OrderbookHeader() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const groupingSize = useSelector((state) => state.orderBook.groupingSize)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)

  // State to store the selected value
  const [precisionOptions, setPrecisionOptions] = useState(
    getPrecisionSelectOptions(selectedMarket.price_precision)
  )
  const [selectedPrecision, setSelectedPrecision] = useState(
    getPrecisionSelectOptions(selectedMarket.price_precision)[0]
  )

  // Function to handle selection change
  const handlePrecisionChange = (event) => {
    const parsedNumber = parseFloat(event?.target.value)
    const value = Number.isInteger(parsedNumber)
      ? parseInt(parsedNumber)
      : parsedNumber
    if (value) {
      dispatch(setGroupingSize(value))
      setSelectedPrecision(value)
    }
  }

  useEffect(() => {
    const values = getPrecisionSelectOptions(selectedMarket.price_precision)

    // On market change, get the new precision values
    setPrecisionOptions(values)

    if (values[0]) setSelectedPrecision(values[0])
  }, [selectedMarket])

  return (
    <div className={styles.container}>
      <div className={styles.flexContainer}>
        <span className={styles.cardTitle}>
          <FormattedMessage id={"page.body.trade.orderbook"} />
        </span>

        <Tooltip text="Orderbook Hint Here" iconWidth={10} />
      </div>
      <div className={styles.groupByContainer}>
        <span>Group By</span>

        <HoverPopper
          popperContent={
            <div className={styles.dropdownContainer}>
              {precisionOptions.map((precisionOption) => (
                <button
                  key={precisionOption}
                  value={precisionOption}
                  onClick={handlePrecisionChange}
                  className={`${styles.precisionButton} ${
                    selectedPrecision === precisionOption &&
                    styles.selectedOption
                  }`}
                >
                  {precisionOption}
                </button>
              ))}
            </div>
          }
          placement="bottom-end"
        >
          <span style={{ fontSize: 12 }}>{selectedPrecision}</span>
          <BiChevronDown />
        </HoverPopper>
        {/* <Input
          variant="secondary"
          type="select"
          onChange={handlePrecisionChange}
          selectOptions={precisionOptions.map((el) => {
            return {
              label: el,
              value: el
            }
          })}
          value={selectedPrecision}
          selectValue={selectedPrecision}
          // selectDefaultValue={groupingSize}
          inputIconContainerStyle={{ margin: 0, height: "30px" }}
        /> */}
      </div>
    </div>
  )
}

export default injectIntl(OrderbookHeader)
