// ** React Imports
import React, { useEffect, useState } from "react"

// styles
import Styles from "./selectPaymentMethod.module.css"

// icons
import { ReactComponent as Coinify } from "../../../assets/icons/imported/coinify.svg"

// redux
import { useDispatch, useSelector } from "react-redux"

// utils
import { hideSelectPaymentMethodModal } from "./helpers"
import { clearSelectPaymentMethodModalData } from "./redux/selectPaymentMethodModalSlice"

// components
import Input from "../../../components/Input/Input"
import MethodSelect from "./components/MethodSelect"
import Button from "../../../components/Button/Button"
import PaymentMethods from "./components/PaymentMethods"
import WenbitModal from "../../../components/Modal/Modal"
import { _getCoinifyExchangeRate } from "../../on-off-ramp/redux/actions"
import { _getCoinifyPaymentMethods } from "./redux/actions"
import { showErrorSnackbar } from "../../snackbar/helpers"

function SelectPaymentMethodModal() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const modalOpen = useSelector(
    (state) => state.selectPaymentMethodModalState.open
  )
  const modalData = useSelector(
    (state) => state.selectPaymentMethodModalState.data
  )
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [loading, setLoading] = useState(false)

  // ** Handlers
  const handleClose = () => {
    hideSelectPaymentMethodModal()
  }

  const handleConfirm = () => {
    if (selectedMethod.name === "Coinify") {
      setLoading(true)
      _getCoinifyPaymentMethods(
        (data) => {
          const minValue = data.minimum_in_amounts[modalData.fiatCoin.id]
          const maxValue = data.limit_in_amounts[modalData.fiatCoin.id]

          // If pay value is lower than min value
          if (modalData.fromValue < minValue) {
            showErrorSnackbar({
              alertMessage: `Minimum permitted value for Coinify is ${minValue}${modalData.fiatCoin.id.toUpperCase()} `
            })
            hideSelectPaymentMethodModal()
            return
          }

          // If pay value is higher than min value
          if (modalData.fromValue > maxValue) {
            showErrorSnackbar({
              alertMessage: `Maximum permitted value for Coinify is ${maxValue}${modalData.fiatCoin.id.toUpperCase()} `
            })
            hideSelectPaymentMethodModal()
            return
          }

          hideSelectPaymentMethodModal()
          setLoading(false)
          modalData.onConfirm()
        },
        (err) => setLoading(false)
      )
    }
  }

  const handleMethodChange = (method) => {
    setSelectedMethod(method)
  }

  useEffect(() => {
    if (!modalOpen) {
      dispatch(clearSelectPaymentMethodModalData())
      setLoading(false)
    }
  }, [modalOpen])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        title="Select Payment Method"
        onClose={handleClose}
        render={() => (
          <div>
            <PaymentMethods onChange={handleMethodChange} />

            <div className={Styles.footerContainer}>
              <div>
                <div>You will pay</div>
                <div className={Styles.priceFooter}>
                  {modalData?.fromValue}{" "}
                  {modalData?.fiatCoin?.id?.toUpperCase()}
                </div>
              </div>

              <Button
                text={`Continue`}
                // size="large"
                fitContent
                onClick={handleConfirm}
                disabled={!selectedMethod || loading}
                isLoading={loading}
              />
            </div>
          </div>
        )}
      />
    </>
  )
}

export default SelectPaymentMethodModal
