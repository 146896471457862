import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

// redux
import { _logout } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { setBackdropOpen } from "../../redux/slices/backdropSlice"
import { logoutUser } from "../../modules/user/redux/slices/userDataSlice"

// styles
import Styles from "./drawerNavigation.module.css"

// constants
import { walletRoutes } from "../../constants/routesConstants"

// icons
import { ReactComponent as CloseIcon } from "../../assets/icons/imported/close.svg"
import { ReactComponent as EarthIcon } from "../../assets/icons/imported/global.svg"
import { ReactComponent as WalletIcon } from "../../assets/icons/imported/wallet.svg"
import { ReactComponent as LogoutIcon } from "../../assets/icons/imported/logout.svg"
import { ReactComponent as MarketIcon } from "../../assets/icons/imported/market.svg"
import { ReactComponent as ProfileIcon } from "../../assets/icons/imported/profile.svg"
import { ReactComponent as ConvertIcon } from "../../assets/icons/imported/convert.svg"
import { ReactComponent as OrderHistoryIcon } from "../../assets/icons/imported/menu.svg"
import { ReactComponent as ExchangeIcon } from "../../assets/icons/imported/market-cap.svg"

// components
import Button from "../Button/Button"
import { Drawer, IconButton } from "@mui/material"
import SideNavButton from "../SideNavButton/SideNavButton"
import KYCStatusBadge from "../KYCStatusBadge/KYCStatusBadge"
import DrawerNavigationMenuButton from "./DrawerNavigationMenuButton"

// MUI
import { FormattedMessage } from "react-intl"
import {
  setSnackbarData,
  setSnackbarOpen
} from "../../modules/snackbar/redux/snackbarSlice"

// utils
import handleLogout from "../../utility/user/handleLogout"
import { hideEmail } from "../../modules/helpers/HideEmail"
import { formatNumber } from "../../modules/helpers/formatNumber"
import { showLanguageCurrencySelectModal } from "../../modules/modals/language-currency-select/helpers"
import { closeDrawerNavigation, setOpenDrawerNavigation } from "./helpers"

function DrawerNavigation() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const language = useSelector((state) => state.language)
  const theme = useSelector((state) => state.theme.value)
  const open = useSelector((state) => state.drawerNavigationState.open)
  const TotalFunds = useSelector((state) => state.fundsData.data.total_funds)
  const hideBalance = useSelector((state) => state.userData.value.hide_balance)
  const TotalBtcBalance = useSelector(
    (state) => state.fundsData.data.total_balance_btc
  )

  const languageOptions = [
    { value: "en", label: "English" }
    // { value: "es", label: "Española" },
    // { value: "ar", label: "عربي" },
    // { value: "ru", label: "Русский" },
    // { value: "ur", label: "اردو" },
    // { value: "zh", label: "中国人" }
  ]

  const userData = useSelector((state) => state.userData.value)
  const hasDocumentLabel = userData?.labels?.some(
    (label) => label.key === "document"
  )

  const [isWalletDropdownOpen, setIsWalletDropdownOpen] = useState(false)

  const handleWalletDropdownToggle = (e) => {
    setIsWalletDropdownOpen((prev) => !prev)
  }

  function getEmailUserName(email) {
    const emailParts = email.split("@")
    const username = emailParts[0]
    return username
  }

  const toggleDrawer = (open) => (event) => {
    // console.log(open, event)
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setOpenDrawerNavigation(open)
  }

  const handleMenuItemClick = (path) => {
    setOpenDrawerNavigation(false)
    navigate(path)
  }

  const handleMobileLogout = () => {
    handleLogout().then(() => {
      setOpenDrawerNavigation(false)
    })
    // dispatch(setBackdropOpen(true))
    // _logout(
    //   () => {
    //     dispatch(logoutUser(""))
    //     dispatch(setBackdropOpen(false))

    //     // history.push("/markets")
    //   },
    //   (error) => {
    //     setOpenDrawerNavigation(false)
    //     dispatch(setSnackbarOpen(true))
    //     dispatch(
    //       setSnackbarData({
    //         alertMessage: error.errors[0].length ? (
    //           <FormattedMessage id={error.errors[0]} />
    //         ) : (
    //           "Unknown Error"
    //         ),
    //         severity: "error",
    //         position: "topRight"
    //       })
    //     )
    //   }
    // )
  }

  //keep wallet dropdown if current route is a wallet route
  useLayoutEffect(() => {
    if (open) {
      // on open
      if (walletRoutes.includes(location.pathname)) {
        setIsWalletDropdownOpen(true)
      } else {
        setIsWalletDropdownOpen(false)
      }
    }
  }, [open])

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div
        style={{
          overflow: "overlay"
        }}
      >
        <div className={Styles.closeButtonContainer}>
          <IconButton aria-label="menu" onClick={toggleDrawer(false)}>
            <CloseIcon width={15} height={15} color="var(--text-color)" />
          </IconButton>
        </div>

        <div className={Styles.navHeaderContainer}>
          {userData?.email ? (
            <div className={Styles.userDataContainer}>
              <div className={Styles.userDataHeader}>
                <img src={userData?.user_avatar?.url} />
                <div>
                  <div>
                    {userData.username ?? getEmailUserName(userData.email)}
                    <KYCStatusBadge
                      status={`${
                        hasDocumentLabel
                          ? userData?.labels?.find(
                              (el) => el.key === "document"
                            )?.value === "verified"
                            ? "verified"
                            : "pending"
                          : "not-verified"
                      }`}
                    />
                  </div>
                  <div>{hideEmail(userData.email)}</div>
                </div>
              </div>

              <div className={Styles.walletBalanceTitleContainer}>
                <WalletIcon width={16} />
                <span>Wallet Balance</span>
              </div>

              <div>
                <span style={{ fontSize: 23 }}>
                  {hideBalance ? "********" : `${formatNumber(TotalFunds, 2)}`}
                </span>{" "}
                <span style={{ color: "unset" }}>USDT</span>
                {/* <span style={{ fontSize: 16, color: "var(--gray-color)" }}>
                  &#8776;{" "}
                  {hideBalance
                    ? "********"
                    : `${formatNumber(TotalBtcBalance, 6)}`}{" "}
                  BTC
                </span> */}
              </div>
            </div>
          ) : (
            <div className={Styles.signInButtonsContainer}>
              <Button
                text={<FormattedMessage id="page.header.navbar.signIn" />}
                variant="dark"
                onClick={() => navigate("/login")}
                style={{
                  width: "45%"
                }}
              />
              <Button
                text={<FormattedMessage id="page.header.navbar.signUp" />}
                onClick={() => {
                  navigate("/signup")
                }}
                style={{
                  width: "45%"
                }}
              />
            </div>
          )}
        </div>

        <div className={Styles.divider} style={{ margin: "0 auto" }}></div>

        {userData.email && (
          <DrawerNavigationMenuButton
            text="My Account"
            icon={<ProfileIcon width={17} />}
            isSelected={location.pathname.startsWith("/account/profile")}
            onClick={() => handleMenuItemClick("/account")}
          />
        )}

        {userData.email && (
          <>
            <DrawerNavigationMenuButton
              text="Wallet"
              icon={<WalletIcon height={17} />}
              showArrow
              isSelected={location.pathname.startsWith("/account/wallet")}
              // onClick={() => handleMenuItemClick("/account/wallet/spot/all")}
              onClick={handleWalletDropdownToggle}
              className={
                isWalletDropdownOpen ? Styles.walletDrawerButtonOpen : ""
              }
            />

            <div
              className={`
                ${Styles.dropdownContainer} 
                ${isWalletDropdownOpen ? Styles.walletDropdownOpen : ""} 
              `}
            >
              <SideNavButton
                text="Spot Wallet"
                icon={<WalletIcon height={17} />}
                isSelected={location.pathname.includes("/account/wallet/spot")}
                variant="secondary"
                onClick={() => handleMenuItemClick("/account/wallet/spot")}
              />
              <SideNavButton
                text="Wallet History"
                variant="secondary"
                icon={<WalletIcon height={17} />}
                isSelected={location.pathname.includes(
                  "/account/wallet/history"
                )}
                onClick={() => handleMenuItemClick("/account/wallet/history")}
              />
              <SideNavButton
                text="Order History"
                icon={<OrderHistoryIcon height={17} />}
                variant="secondary"
                isSelected={location.pathname.includes("/account/orders")}
                onClick={() => handleMenuItemClick("/account/orders/open")}
              />
              <SideNavButton
                text="Trade History"
                icon={<MarketIcon height={17} />}
                variant="secondary"
                isSelected={location.pathname.includes(
                  "/account/trade/history"
                )}
                onClick={() => handleMenuItemClick("/account/trade/history")}
              />
              <SideNavButton
                text="Conversion History"
                icon={<ConvertIcon height={17} />}
                variant="secondary"
                isSelected={location.pathname.includes(
                  "/account/convert/history"
                )}
                onClick={() => handleMenuItemClick("/account/convert/history")}
              />
            </div>
          </>
        )}

        <DrawerNavigationMenuButton
          text="Markets"
          icon={<MarketIcon height={20} />}
          isSelected={location.pathname.startsWith("/markets")}
          onClick={() => handleMenuItemClick("/markets")}
        />

        <DrawerNavigationMenuButton
          text="Exchange"
          icon={<ExchangeIcon height={15} />}
          isSelected={location.pathname.startsWith("/trade")}
          onClick={() => handleMenuItemClick("/trade")}
        />

        <DrawerNavigationMenuButton
          text="Convert"
          icon={<ConvertIcon height={17} />}
          isSelected={location.pathname.startsWith("/convert")}
          onClick={() => handleMenuItemClick("/convert")}
        />

        <DrawerNavigationMenuButton
          text="Buy Crypto"
          icon={<MarketIcon height={20} />}
          isSelected={location.pathname.startsWith("/buy-crypto")}
          onClick={() => handleMenuItemClick("/buy-crypto")}
        />

        {userData.email && (
          <>
            <div className={Styles.divider}></div>

            <DrawerNavigationMenuButton
              text={`${
                languageOptions.find((el) => el.value === language.code).label
              } | USD`}
              icon={<EarthIcon height={17} />}
              onClick={() => {
                closeDrawerNavigation()
                showLanguageCurrencySelectModal()
              }}
            />
            <div className={Styles.divider}></div>
            <DrawerNavigationMenuButton
              text="Log Out"
              icon={<LogoutIcon height={17} />}
              onClick={handleMobileLogout}
            />

            <div className={Styles.divider}></div>
          </>
        )}
      </div>
    </Drawer>
  )
}

export default DrawerNavigation
