import React, { useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { hideEmail } from "../../../helpers/HideEmail"
// styles
import Styles from "./activateBeneficiaryModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"

// icons
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/imported/down-arrow.svg"
import {
  setActivateBeneficiaryModalData,
  setActivateBeneficiaryModalOpen
} from "../redux/slices/activateBeneficiaryModalSlice"
import {
  _activateBeneficiary,
  _resendBeneficiaryActivationPin
} from "../redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"

// components
import WenbitModal from "../../../../components/Modal/Modal"
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"

function ActivateBeneficiaryModal() {
  const dispatch = useDispatch()

  const theme = useSelector((state) => state.theme.value)

  const modalOpen = useSelector(
    (state) => state.activateBeneficiaryModalState.open
  )
  const modalData = useSelector(
    (state) => state.activateBeneficiaryModalState.data
  )
  const beneficiaryID = useSelector((state) => state.beneficiary.value.id)
  const userData = useSelector((state) => state.userData.value)

  const [pin, setPin] = useState("")

  const handleClose = () => {
    dispatch(
      setActivateBeneficiaryModalData({
        callback: () => {}
      })
    )
    dispatch(setActivateBeneficiaryModalOpen(false))
  }

  const handleSendPin = () => {
    _resendBeneficiaryActivationPin(
      beneficiaryID,
      () => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: "Pin Sent Successfully",
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }

  const handleActivate = () => {
    _activateBeneficiary(
      beneficiaryID,
      {
        pin
      },
      () => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: "Beneficiary Activated",
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
        handleClose()
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }
  const handlePinChange = (event) => {
    setPin(event.target.value)
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title="Manage Beneficiaries"
        render={() => (
          <div>
            <Input
              inputLabel="Beneficiary Activation Code"
              variant="secondary"
              onChange={handlePinChange}
              icon={
                <Button
                  text="Resend code"
                  onClick={handleSendPin}
                  variant="link"
                />
              }
            />

            <div className={Styles.activateIndicator}>
              Enter the 6-digit code sent to {hideEmail(userData?.email)}
            </div>

            <Button
              disabled={pin.length < 6}
              text="Activate"
              onClick={handleActivate}
            />
          </div>
        )}
      />
    </>
  )
}

export default ActivateBeneficiaryModal
