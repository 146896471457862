import { createSlice } from "@reduxjs/toolkit"

const CoinifyRedirectAlertModalSlice = createSlice({
  name: "coinifyRedirectAlertModalState",
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setCoinifyRedirectAlertModalOpen: (state, action) => {
      state.open = action.payload
    },
    setCoinifyRedirectAlertModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setCoinifyRedirectAlertModalData,
  setCoinifyRedirectAlertModalOpen
} = CoinifyRedirectAlertModalSlice.actions

export default CoinifyRedirectAlertModalSlice.reducer
