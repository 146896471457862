import { createSlice } from '@reduxjs/toolkit'

const informationSlice = createSlice({
  name: 'informationModalState',
  initialState: {
    open: false,
    data: {
        title: '',
        subtitle: '',
        callback: () => {}
    }
  },
  reducers: {
    setInformationModalOpen: (state, action) => {
      state.open = action.payload
    },
    setInformationModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setInformationModalOpen, setInformationModalData } = informationSlice.actions

export default informationSlice.reducer