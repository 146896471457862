import React from "react"

// styles
import Styles from "./contactSupport.module.css"

// icons
import { ReactComponent as MailIcon } from "./icons/mail.svg"
import { ReactComponent as PhoneIcon } from "./icons/phone.svg"
import { ReactComponent as MessageIcon } from "./icons/message.svg"

function ContactSupport() {
  document.title = "Wenbit: Support"

  return (
    <div className={Styles.pageContainer}>
      <section className={Styles.headerContainer}>
        <span>Support</span>
        <h1>Contact support</h1>
        <p>
          Having technical issues with your WenBit? Don't sweat it, we've got
          your back. Get in touch with our expert team and we'll get you up and
          running in no time. <br />
          At WenBit, we're committed to providing you with the best possible
          experience. So don't hesitate to reach out - we're here to help.
        </p>
      </section>

      <section className={Styles.cardsContainer}>
        {/* MAIL */}
        <div className={Styles.card}>
          <div>
            <MailIcon width={40} />
          </div>
          <span>Email</span>
          <p>
            Email us for a swift,
            <br />
            laser-focus support.
          </p>
        </div>

        {/* PHONE */}
        <div className={Styles.card}>
          <div>
            <PhoneIcon width={40} />
          </div>
          <span>Phone</span>
          <p>
            Reach out for
            <br />
            turbocharged assistance.
          </p>
        </div>

        {/* CHAT */}
        <div className={Styles.card}>
          <div>
            <MessageIcon width={40} />
          </div>
          <span>Chat</span>
          <p>
            Our live chat
            <br />
            ROCKS!
          </p>
        </div>
      </section>
    </div>
  )
}

export default ContactSupport
