import React, { useState, useEffect } from "react"

// styles
import Styles from "./walletDepositAddressModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"

// components
import WenbitModal from "../../../../components/Modal/Modal"
import Button from "../../../../components/Button/Button"
import {
  setWalletDepositModalData,
  setWalletDepositModalOpen
} from "../redux/walletDepositModalSlice"

//temp imports
import QR from "../../../../assets/images/QR.svg"
// import QRCode from 'react-qr-code'
import QRCode from "qrcode.react"

function WalletDepositAddressModal() {
  const dispatch = useDispatch()
  const modalOpen = useSelector((state) => state.walletDepositModalState.open)
  const currencies = useSelector((state) => state.currencies.value)
  const depositData = useSelector((state) => state.depositData.data)
  const [addressIsCopied, setAddressIsCopied] = useState(false)
  const [showDiv, setShowDiv] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowDiv(true)
    }, 3000) // 3000 milliseconds = 3 seconds

    // Clean up the timeout on component unmount
    return () => {
      clearTimeout(timeoutId)
    }
  }, []) // Empty dependency array ensures the effect runs only once, on component mount

  const handleClose = () => {
    dispatch(
      setWalletDepositModalData({
        title: "",
        qr: null,
        destination: null,
        callback: () => {}
      })
    )
    dispatch(setWalletDepositModalOpen(false))
  }

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(depositData.depositAddress)
    setAddressIsCopied(true)
    setTimeout(() => {
      setAddressIsCopied(false)
    }, 5000)
  }

  const renderTwoFactorAuthQR = (barcode) => {
    const src = `data:image/png;base64,${barcode}`
    return (
      depositData.depositAddress > 0 && (
        <img className="parent-profile-two-factor-auth__qr" src={src} />
      )
    )
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title={`${
          currencies?.find((el) => el.id === depositData?.selectedCoinId)?.name
        } deposit address`}
        render={() => (
          <>
            <div className={Styles.modalBody}>
              <span>Scan QR code to deposit</span>
              <div
                style={{
                  height: "auto",
                  margin: "20px auto",
                  maxWidth: 140,
                  width: "100%",
                  padding: 16,
                  borderRadius: 5,
                  backgroundColor: "white"
                }}
              >
                {showDiv && (
                  <QRCode
                    value={depositData?.depositAddress}
                    size={110}
                    renderAs={"svg"}
                  />
                )}
              </div>
            </div>

            <div className={Styles.destinationContainer}>
              <span>Destination</span>
              <div>
                <span>
                  {depositData.depositAddress === "" ||
                  depositData.depositAddress === null ||
                  depositData.depositAddress === undefined
                    ? "LOADING"
                    : depositData.depositAddress}
                </span>
                <Button
                  disabled={addressIsCopied}
                  text={addressIsCopied ? "Copied" : "Copy"}
                  style={{
                    transform: "scale(.8)",
                    borderRadius: 5,
                    width: "fit-content",
                    marginRight: -3,
                    fontSize: 14,
                    backgroundColor: addressIsCopied && "#9aa2c1",
                    marginLeft: "auto"
                  }}
                  onClick={handleCopyAddress}
                />
              </div>
            </div>

            {/* disclaimer container */}
            <div className={Styles.disclaimerContainer}>
              <span>Disclaimer</span>
              <div></div>
              <span>
                Please note that only supported networks on our platform are
                shown and if you deposit through another network your assets may
                be lost forever. Send only [insert crypto name ex SHIB] to this
                deposit address. This address does not support the deposit of
                non-fungible tokens. Your funds will be automatically credited
                to this wallet once we have received them.
              </span>
            </div>
          </>
        )}
      />
    </>
  )
}

export default WalletDepositAddressModal
