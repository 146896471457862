// ** React Imports
import React, { useEffect, useState } from "react"

// ** Component Imports
import CoinDisplay from "../CoinDisplay/CoinDisplay"
import Autocomplete from "../Autocomplete/Autocomplete"

// ** Redux Imports
import { _getCoins } from "./actions"
import { useSelector } from "react-redux"

// ** Icon Imports
import { ReactComponent as SearchIcon } from "../../assets/icons/imported/search.svg"

function SelectCoinAutocomplete({
  placeholder = "Coin",
  value,
  onChange = () => {}
}) {
  // ** States
  const coins = useSelector((state) => state.currencies.value)

  return (
    <Autocomplete
      value={value}
      options={coins}
      onChange={onChange}
      placeholder={placeholder}
      getOptionLabel={(option) => option.id.toUpperCase()}
      renderOption={(params, option) => (
        <div {...params}>
          <CoinDisplay
            coinImgURL={option.icon_url}
            coinName={option.id.toUpperCase()}
          />
        </div>
      )}
    />
  )
}

SelectCoinAutocomplete.displayName = "SelectCoinAutocomplete"

export default SelectCoinAutocomplete
