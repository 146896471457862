import { setWalletDepositModalOpen } from "../../modules/modals/deposit-withdraw/redux/walletDepositModalSlice"
import { _getDepositAddress } from "../../redux/actions"
import {
  setDepositAddress,
  setSelectedCoinId
} from "../../redux/slices/depositSlice"
import { store } from "../../redux/store"
import { hideBackdrop, showBackdrop } from "../backdrop/backdropHelpers"
import { handleWalletNotFound } from "./handleWalletNotFound"
import {} from "../../modules/modals/wallet-loading/helpers/walletLoadingModal"

export const handleDeposit = (coinId) => {
  store.dispatch(setSelectedCoinId(coinId))
  showBackdrop()
  _getDepositAddress(
    {
      coin: coinId
    },
    (data) => {
      if (data.address === null) {
        setTimeout(() => {
          _getDepositAddress(
            {
              coin: coinId
            },
            (data) => {
              hideBackdrop()
              if (data.address) {
                store.dispatch(setDepositAddress(data.address))
                store.dispatch(setWalletDepositModalOpen(true))
              } else {
                handleWalletNotFound()
              }
            },
            (err) => {
              hideBackdrop()
              handleWalletNotFound()
            }
          )
        }, 15000) // Delay of 15000 milliseconds (15 seconds)
      } else {
        hideBackdrop()
        store.dispatch(setDepositAddress(data.address))
        store.dispatch(setWalletDepositModalOpen(true))
      }
    },
    (err) => {
      hideBackdrop()
    }
  )
}
