export function formatDateTime(dateTimeString) {
    try {
      const date = new Date(dateTimeString)
      const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
      const formattedDate = date.toLocaleDateString('en-US', options)
      const hours = date.getHours() % 12 || 12
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
      const formattedTime = `${hours}:${minutes} ${ampm}`
      return `${formattedDate} ${formattedTime}`
    } catch (error) {
      console.error('Error formatting date time:', error)
      return null
    }
  }