import React, { useEffect, useState } from "react"

// react router
import { useLocation, useNavigate } from "react-router-dom"

// styles
import Styles from "./emailVerification.module.css"

// icons
import { ReactComponent as ValidIcon } from "../../../../assets/icons/imported/valid.svg"
import { ReactComponent as ExpiredIcon } from "../../../../assets/icons/imported/verification-expired.svg"

// redux
import { useDispatch } from "react-redux"
import { setUser, logoutUser } from "../../redux/slices/userDataSlice"
import { _verifyToken, _getUserInfo } from "../../../../redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"

// utils
import { FormattedMessage } from "react-intl"
import Button from "../../../../components/Button/Button"

const EmailVerification = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [step, setStep] = useState(0)
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("confirmation_token")
  const lang = searchParams.get("lang")

  const displayError = (error) => {
    setStep(2)
    dispatch(setSnackbarOpen(true))
    dispatch(
      setSnackbarData({
        alertMessage: error.errors[0].length ? (
          <FormattedMessage id={error.errors[0]} />
        ) : (
          "Unknown Error"
        ),
        severity: "error",
        callback: () => {},
        position: "topRight"
      })
    )
    // navigate('email-verification-expired', {replace: true})
  }

  const verifyToken = () => {
    _verifyToken(
      { token },
      () => {
        setStep(1)
        _getUserInfo(
          (data) => {
            dispatch(setUser(data))
            if (data.errors) {
              dispatch(logoutUser())
            }
          },
          (error) => {
            if (error.errors) {
              dispatch(logoutUser())
            }
          }
        )
      },
      (error) => {
        displayError(error)
      }
    )
  }

  useEffect(() => {
    verifyToken()
  }, [])

  if (!token) return null

  return step === 1 ? (
    <div className={Styles.container}>
      <ValidIcon color="var(--primary-color)" height={120} width={130} />

      <h1>
        It all checks in! <br />
        We’re one bit closer to the top.
      </h1>

      <Button
        text="Complete"
        onClick={() => navigate("/login")}
        style={{
          width: "60%"
        }}
      />
    </div>
  ) : step === 2 ? (
    <div className={Styles.container}>
      <ExpiredIcon width={80} />
      <p>
        Oops! It seems like the link you provided during registration has
        expired or is invalid.
      </p>

      <Button text="Go To Home Page" onClick={() => navigate("/trade")} />
    </div>
  ) : (
    <div className={Styles.container}>
      <ExpiredIcon width={80} />
      <p>Validating...</p>

      <Button text="Go To Home Page" onClick={() => navigate("/trade")} />
    </div>
  )
}
export default EmailVerification
