// ** Redux Imports
import { store } from "../../../../redux/store"
import {
  setWithdrawFormModalOpen,
  setwithdrawFormModalData
} from "../redux/withdrawFormModalSlice"

export const showWithdrawFormModal = ({ coinId = null }) => {
  store.dispatch(
    setwithdrawFormModalData({
      selectedCoinID: coinId
    })
  )
  store.dispatch(setWithdrawFormModalOpen(true))
}

export const closeWithdrawFormModal = () => {
  store.dispatch(setWithdrawFormModalOpen(false))
}
