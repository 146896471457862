import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import cloneDeep from "lodash/cloneDeep"

// styles
import Styles from "./securityVerificationModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setSecurityVerificationModalData,
  setSecurityVerificationModalOpen
} from "./redux/securityVerificationModalSlice"
import { FormattedMessage, injectIntl } from "react-intl"

// components
import WenbitModal from "../../../components/Modal/Modal"
import Button from "../../../components/Button/Button"
import Input from "../../../components/Input/Input"
import { hideEmail } from "../../helpers/HideEmail"
import { _getUserInfo } from "../../../redux/actions"
import { setUser, logoutUser } from "../../user/redux/slices/userDataSlice"
import { _withdraw } from "../security/Enable2FAModal/redux/actions"
import {
  setSnackbarData,
  setSnackbarOpen
} from "../../snackbar/redux/snackbarSlice"

function SecurityVerificationModal() {
  const phoneOtpRef = useRef()
  const emailOtpRef = useRef()
  const otpRef = useRef()
  const dispatch = useDispatch()
  const modalOpen = useSelector(
    (state) => state.securityVerificationModalState.open
  )
  const modalData = useSelector(
    (state) => state.securityVerificationModalState.data
  )
  const userData = useSelector((state) => state.userData.value)

  const handleClose = () => {
    dispatch(
      setSecurityVerificationModalData({
        callback: () => {}
      })
    )
    dispatch(setSecurityVerificationModalOpen(false))
  }
  useEffect(() => {
    if (modalOpen === true) {
      _getUserInfo(
        (data) => {
          dispatch(setUser(data))
          if (data.errors) {
            dispatch(logoutUser())
          }
        },
        (error) => {
          if (error.errors) {
            dispatch(logoutUser())
          }
        }
      )
    }
  }, [modalOpen])

  const handleSubmit = () => {
    if (modalData.type === "withdraw") {
      const clonedData = cloneDeep(modalData) // Clone the modalData object
      delete clonedData.callback
      // console.log(emailOtpRef.current.value)
      _withdraw(
        {
          amount: clonedData.amount,
          beneficiary_id: clonedData.beneficiary_id,
          currency: clonedData.currency,
          email_otp: emailOtpRef.current.value,
          ...(userData.otp && { otp: otpRef.current.value }),
          ...(userData.sms_auth && { phone_otp: phoneOtpRef.current.value })
        },
        (data) => {
          handleClose()
          dispatch(setSnackbarOpen(true))
          dispatch(
            setSnackbarData({
              alertMessage: "Withdraw Successfull",
              severity: "success",
              callback: () => {},
              position: "topRight"
            })
          )
        },
        (error) => {
          dispatch(setSnackbarOpen(true))
          dispatch(
            setSnackbarData({
              alertMessage: error.errors[0].length ? (
                <FormattedMessage id={error.errors[0]} />
              ) : (
                "Unknown Error"
              ),
              severity: "error",
              //buttonMessage: 'See order',
              callback: () => {},
              //actionType: 'link', // link - close
              //linkTo: '/account/orders/open',
              position: "topRight"
            })
          )
        }
      )
    }
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title="Security verification"
        render={() => (
          <div
            style={{
              borderTop: "1px var(--border-color) solid",
              paddingTop: 20,
              marginTop: 10
            }}
          >
            {userData?.otp === true && (
              <>
                <Input
                  showBorder
                  ref={otpRef}
                  inputLabel="Authenticator code"
                />
                <div className={Styles.sideText}>
                  Enter the 6-digit code from Wenbit/Google Authenticator
                </div>
              </>
            )}

            <Input
              showBorder
              ref={emailOtpRef}
              inputLabel="Email verification code"
              icon={<Button text="Get code" variant="link" />}
            />
            <div className={Styles.sideText}>
              Enter the 6-digit code sent to{" "}
              {userData?.email ? hideEmail(userData.email) : ""}
            </div>
            {userData?.sms_auth === true && (
              <>
                <Input
                  showBorder
                  ref={phoneOtpRef}
                  inputLabel="Phone number verification code"
                  icon={<Button text="Get code" variant="link" />}
                />
                <div className={Styles.sideText}>
                  {`Enter the 6-digit code sent to  ** ${
                    userData?.phones
                      ? userData?.phones[0]?.number?.slice(-2)
                      : ""
                  }`}
                </div>
              </>
            )}

            {/* <Link style={{ fontSize: 10 }}>
              Security verification unavailable?
            </Link> */}

            <Button
              text="Submit"
              onClick={handleSubmit}
              style={{
                marginTop: 30
              }}
            />
          </div>
        )}
      />
    </>
  )
}

export default SecurityVerificationModal
