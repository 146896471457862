// ** React Imports
import React from 'react'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import { store } from '../../redux/store'
import { _logout } from '../../redux/actions'
import { resetFundsData } from '../../redux/slices/fundsSlice'
import { logoutUser, setUser } from '../../modules/user/redux/slices/userDataSlice'
import { setLoggedIn } from '../../redux/slices/loggedIn'

// ** Utils Imports
import { FormattedMessage } from 'react-intl'
import { showErrorSnackbar } from '../../modules/snackbar/helpers'
import { hideBackdrop, showBackdrop } from '../backdrop/backdropHelpers'

const handleLogout = () => {
  return new Promise((resolve, reject) => {
    showBackdrop()
    _logout(
      () => {
        store.dispatch(resetFundsData())
        store.dispatch(logoutUser())
        store.dispatch(setLoggedIn(false))
        store.dispatch(setUser(''))
        hideBackdrop()
      },
      error => {
        hideBackdrop()
        showErrorSnackbar({
          alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error'
        })
      }
    )
  })
}

export default handleLogout
