import { createSlice } from '@reduxjs/toolkit'

const loggedInSlice = createSlice({
  name: 'loggedIn',
  initialState: {
    value: false
  },
  reducers: {
    setLoggedIn: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setLoggedIn } = loggedInSlice.actions

export default loggedInSlice.reducer