import React, { useState, useEffect, useRef } from "react"
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from "./createBeneficiaryModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import { _createBeneficiary } from "../../../profile/redux/actions"
import { _getCurrencies } from "../../deposit-withdraw/redux/actions"
import { _getCoinData, _validateBeneficiary } from "../redux/actions"
import { addBeneficiary } from "../../../../redux/slices/beneficiariesSlice"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"
import SelectCoinInput from "../../../../components/Input/SelectCoinInput/SelectCoinInput"
import SelectNetworkInput from "../../../../components/Input/SelectNetworkInput/SelectNetworkInput"

// utils
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../snackbar/helpers"
import { hideCreateBeneficiaryModal } from "./helpers"

// form validation
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// schema
const createBeneficiarySchema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
  description: Yup.string().required("Description is required"),
  name: Yup.string().required("Name is required"),
  network: Yup.string().required("Network is required")
})

function CreateBeneficiaryModal() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(createBeneficiarySchema),
    mode: "onSubmit"
  })

  const dispatch = useDispatch()
  const modalOpen = useSelector(
    (state) => state.createBeneficiaryModalState.open
  )
  const modalData = useSelector(
    (state) => state.createBeneficiaryModalState.data
  )

  const [selectedBeneficiaryCoinID, setSelectedBeneficiaryCoinID] = useState(
    modalData.selectedCoin ?? null
  )
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  const onSubmit = (formData) => {
    setLoading(true)
    setButtonLoading(true)
    _createBeneficiary(
      {
        currency: selectedBeneficiaryCoinID,
        algorithm: "HS256",
        network: formData.network, // network id
        data: { address: formData.address },
        description: formData.description,
        name: formData.name
      },
      (data) => {
        setLoading(false)
        setButtonLoading(false)
        dispatch(addBeneficiary(data))
        showSuccessSnackbar({
          alertMessage: "Beneficiary Successfully Created"
        })
        hideCreateBeneficiaryModal()
      },
      (error) => {
        setLoading(false)
        setButtonLoading(false)
        showErrorSnackbar({
          alertMessage: error.errors[0].length ? (
            <FormattedMessage id={error.errors[0]} />
          ) : (
            "Unknown Error"
          )
        })
      }
    )
  }

  useEffect(() => {
    setSelectedNetwork([])
    setValue("network", null)
    if (modalOpen && modalData.selectedCoin) {
      setSelectedBeneficiaryCoinID(modalData.selectedCoin)
    }
    if (!modalOpen && !modalData.selectedCoin) {
      reset() // reset error messages
      _getCurrencies((data) => {
        setSelectedBeneficiaryCoinID(data[0]?.id)
      })
    }
  }, [selectedBeneficiaryCoinID, modalOpen])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={hideCreateBeneficiaryModal}
        title="Manage Beneficiaries"
        render={() => (
          <form onSubmit={handleSubmit(onSubmit)}>
            <SelectCoinInput
              onChange={(coin) => {
                setSelectedBeneficiaryCoinID(coin?.id)
              }}
              coinId={selectedBeneficiaryCoinID}
            />

            <SelectNetworkInput
              coinId={selectedBeneficiaryCoinID}
              networkName={selectedNetwork?.name}
              onChange={(network) => {
                setSelectedNetwork(network)
                setValue("network", network.key)
              }}
              error={errors.network}
            />

            <Input
              inputLabel="Beneficiary reference name"
              placeholder="Beneficiary reference name"
              showBorder
              error={errors.name}
              {...register("name")}
            />

            <Input
              inputLabel="Beneficiary description"
              placeholder="Beneficiary description"
              showBorder
              error={errors.description}
              {...register("description")}
            />

            <Input
              inputLabel="Beneficiary address"
              placeholder="Beneficiary address"
              showBorder
              error={errors.address}
              {...register("address")}
            />

            <Button
              text="Confirm"
              disabled={loading && !buttonLoading}
              isLoading={buttonLoading}
              type="submit"
            />
          </form>
        )}
      />
    </>
  )
}

export default CreateBeneficiaryModal
