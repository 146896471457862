// ** React Imports
import React from "react"

// ** React Router Imports
import { Link } from "react-router-dom"

// ** Icons Imports
import { ReactComponent as BackArrow } from "../../assets/icons/imported/back-arrow.svg"

// ** Styles Imports
import styles from "./styles.module.css"

function MegaMenuItem({ name, Icon, onClick = () => {}, description, to }) {
  const Tag = to ? Link : React.Fragment
  return (
    <Tag to={to} style={{ textDecoration: "none" }}>
      <div className={styles.container}>
        {Icon && (
          <Icon color="var(--primary-color)" size={28} width={28} height={28} />
        )}
        <div className={styles.nameDescriptionContainer}>
          <span className={styles.name}>{name}</span>
          <span className={styles.description}>{description}</span>
        </div>
        <BackArrow color="var(--gray-color)" />
      </div>
    </Tag>
  )
}

export default MegaMenuItem
