// ** React Imports
import React from "react"
import { Link } from "react-router-dom"

// ** Styles Imports
import Styles from "./jobCard.module.css"

// temp
import { ReactComponent as JobIcon } from "./assets/temp-job-icon.svg"

function JobCard({ job, icon, name, description }) {
  return (
    <Link
      to={`/careers/${job.id}`}
      style={{ textDecoration: "none", color: "var(--text-color)" }}
    >
      <div className={Styles.card}>
        <div className={Styles.iconContainer}>
          <JobIcon width="100%" />
        </div>

        <div className={Styles.textContainer}>
          <span>{job.title}</span>
          {job.summary && <p>{job?.summary}</p>}
        </div>
      </div>
    </Link>
  )
}

export default JobCard
