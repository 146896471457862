import React from 'react'

// styles
import Styles from './radioButton.module.css'

// redux
import { useSelector } from 'react-redux'

// icons
import selectedRadio from './icons/radio-selected.svg'
import notSelectedRadio from './icons/radio-not-selected.svg'


function RadioButton({
    icon,
    text,
    isSelected = false,
    onClick = null
}) {

    const theme = useSelector(state => state.theme.value)

  return (
    <button
        style={{
            border: !isSelected && `1px ${theme === 'light' ? '#ccced9' : '#ccced926'} solid`
        }}
        className={`${Styles.radioButtonContainer} ${isSelected && (theme === 'light' ? Styles.selectedButtonContainerLight : Styles.selectedButtonContainer)} ${theme === 'light' && Styles.radioButtonContainerLight}`}
        onClick={onClick}
    >
        
        {/* <div className={Styles.radioCircle}>
            { isSelected && <div className={Styles.radioCheckedCircle}></div> }
        </div> */}
        {
            isSelected ? (
                <img
                    className={Styles.radioImage}
                    src={selectedRadio}
                />
            ) : (
                <img
                    className={Styles.radioImage}
                    src={notSelectedRadio}
                />
            )
        }

        { icon && <div className={Styles.iconContainer}>{icon}</div> }

        <span className={Styles.radioText}>{text}</span>

    </button>
  )
}

export default RadioButton