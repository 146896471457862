import React from 'react'

// MUI
import { Tooltip as MUITooltip } from '@mui/material'

// icons
import { ReactComponent as InfoIcon } from '../../assets/icons/imported/info.svg'

// styles
import Styles from './tooltip.module.css'

function Tooltip({
    text = '',
    toolTipStyle,
    color = 'var(--gray-color)',
    iconWidth = 12,
    arrowPlacement = 'top'
}) {
  return (
    <MUITooltip
        title={text}
        arrow placement={arrowPlacement}
        style={{...toolTipStyle}}
        className={Styles.container}
    >
        <InfoIcon color={color} width={iconWidth} />
    </MUITooltip>
)
}

export default Tooltip