// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Styles Imports
import styles from './styles.module.css'

// ** Component Imports
import TableButton from '../../../../components/TableButton/TableButton'
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'

// ** Utils Imports
import { handleStakingSubscribe } from '../../helpers/handleStakingSubscribe'

function StakingTable({ rows = [], setRows, loading = false }) {
  // TESTING DATA
  const currencies = useSelector(state => state.currencies.value)

  // ** Table COlumns
  const columns = [
    {
      field: 'id',
      headerName: 'Coin',
      minWidth: 175,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <CoinDisplay useReduxStore coinId={row.asset.toLowerCase()} coinTag={row.product_summary_list[0].tags[0]} />
      )
    },
    {
      field: 'apr',
      headerName: 'APR',
      minWidth: 105,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        const apr = row.product_summary_list.find(product => product.duration === row.selectedDuration).apr
        return <>{apr}%</>
      }
    },
    {
      field: 'durations',
      headerName: 'Durations (Days)',
      minWidth: 325,
      editable: false,
      flex: 1,
      renderCell: ({ row, api }) => (
        <div className={styles.durationsContainer}>
          {console.log(row)}
          {row.product_summary_list.map((product, index) => (
            <button
              key={index}
              value={product.duration}
              className={`${styles.durationButton} ${
                row.selectedDuration === product.duration && styles.selectedDurationButton
              }`}
              onClick={() => {
                const updatedRows = rows.map(r => {
                  if (r.asset === row.asset) {
                    return { ...r, selectedDuration: product.duration }
                  }
                  return r
                })
                setRows(updatedRows)
              }}
            >
              {product.duration !== 0 ? product.duration : 'Flexible'}
            </button>
          ))}
        </div>
      )
    },
    {
      headerName: 'Action',
      minWidth: 125,
      flex: 1,
      renderCell: ({ row }) => (
        <TableButton
          buttonText='Subscribe'
          style={{ background: 'var(--primary-color)', color: 'var(--text-color-inverted)' }}
          onClick={() => handleSubscribeClick(row.asset, row.selectedDuration)}
        />
      )
    }
  ]

  const handleSubscribeClick = (coinId, selectedDuration) => {
    handleStakingSubscribe({ coinId, selectedDuration })
  }

  return (
    <>
      <MUIDatagrid
        hideFooter
        disableSorting
        columns={columns}
        loading={loading}
        rows={rows}
        getRowId={row => row.asset}
      />
    </>
  )
}

export default StakingTable
