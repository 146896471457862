import React, { useState, useEffect, useContext } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// react router
import { Link, useNavigate } from "react-router-dom"

// form validation
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import Input from "../../../../components/Input/Input"
import { _register } from "../../redux/actions"

// utilities
import _, { isEmpty } from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"
import { showErrorSnackbar } from "../../../snackbar/helpers"

// redux
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../redux/slices/userDataSlice"
import { setBackdropOpen } from "../../../../redux/slices/backdropSlice"

// components
import "react-toastify/dist/ReactToastify.css"
import { Form } from "reactstrap"
import Button from "../../../../components/Button/Button"
import PasswordRequirements from "./PasswordRequirements"
import CheckboxInput from "../../../../components/CheckboxInput/CheckboxInput"

// styles
import Styles from "./signupTab.module.css"
import tabStyles from "../tab.module.css"

// register schema
const registerSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "The username must be 4-12 characters long")
    .max(12, "The username must be 4-12 characters long")
    .matches(/^\S*$/, "The username cannot contain any spaces")
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "The username cannot contain spacial characters"
    ),
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "First name must be alteast 2 characters")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "First name can only contain letters."
    ),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be alteast 2 characters")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Last name can only contain letters."
    ),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/,
      "Password doesn't meet requirements"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
  termsAndConditions: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms and conditions")
})

const SignupTab = () => {
  // ** States
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState("")
  const [recaptchaResponse, setRecaptchaResponse] = useState("")
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)

  const theme = useSelector((state) => state.theme.value)
  const isLoggedIn = useSelector((state) => state.userData.value.email)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(registerSchema),
    mode: "onSubmit"
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/trade")
    }
  }, [isLoggedIn, history])

  document.title = "Wenbit: Register"

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      setLoading(true)
      dispatch(setBackdropOpen(true))

      _register(
        {
          username: data.username,
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          password: data.password,
          captcha_response: recaptchaResponse
        },
        (data) => {
          // before
          dispatch(setBackdropOpen(false))

          dispatch(setUser(data))
          navigate("/trade")

          // after
          // history.push('/confirm-register')

          setLoading(false)
        },
        (error) => {
          setLoading(false)
          dispatch(setBackdropOpen(false))

          showErrorSnackbar({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            )
          })
        }
      )
    }
  }

  return (
    <div className={Styles.tabContainer}>
      {/* form */}
      <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>
        {/* title */}
        <span className={tabStyles.title}>
          {/* <FormattedMessage id={"page.header.signUp"} /> */}
          Welcome to the club!
        </span>

        <span className={Styles.subtitleHeader}>
          You’re just a few bits away from unlocking greatness.
        </span>

        {/* username input */}
        <Input
          id="username"
          inputLabel="Username"
          error={errors.username}
          {...register("username")}
        />

        {/* first name input */}
        <Input
          id="firstName"
          inputLabel="First Name"
          error={errors.firstName}
          {...register("firstName")}
        />

        {/* last name input */}
        <Input
          id="lastName"
          inputLabel="Last Name"
          error={errors.lastName}
          {...register("lastName")}
        />

        {/* mail input */}
        <Input
          id="email"
          inputLabel="Email"
          error={errors.email}
          {...register("email")}
        />

        {/* password input */}
        <Input
          type="password"
          {...register("password")}
          onChange={(e) => {
            register("password").onChange(e)
            setPassword(e.target.value)
          }}
          id="password"
          inputLabel="Password"
          error={errors.password}
        />

        {/* Password Requirements */}
        <PasswordRequirements
          password={password}
          containerStyle={{ marginBottom: 27, marginTop: -11 }}
        />

        {/* confirm password input */}
        <Input
          type="password"
          {...register("confirmPassword")}
          id="confirmPassword"
          inputLabel="Confirm Password"
          error={errors.confirmPassword}
        />

        {/* Accept condition checkbox */}
        <CheckboxInput
          label={
            <>
              <FormattedMessage id="page.header.signUp.terms" />
              <Link
                to="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="page.signUp.termsAndService.text" />
              </Link>
              {", "}
              <Link
                to="/privacy-and-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="page.signUp.PrivacyPolicy.text" />
              </Link>
            </>
          }
          checkboxId="terms"
          checkboxName="terms"
          labelFor="terms"
          error={errors.termsAndConditions}
          register={{ ...register("termsAndConditions") }}
        />
        <div
          className="base-sign-up-form__checkbox"
          style={{ margin: "10px auto 0 auto" }}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={recaptchaOnChange}
            theme={theme}
          />
        </div>
        {/* register button */}
        <Button
          text={<FormattedMessage id="page.signIn.signup" />}
          type="submit"
          disabled={!recaptchaConfirmed || loading}
          style={{ marginTop: 30, height: "45px" }}
        />

        {/* login link */}
        <span className={Styles.loginSpan}>
          <FormattedMessage id="page.signUp.signinExists" />
          <Link to="/login">
            <FormattedMessage id="page.signUp.signin" />
          </Link>
        </span>
      </Form>
    </div>
  )
}
export default injectIntl(SignupTab)
