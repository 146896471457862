import { createSlice } from "@reduxjs/toolkit"

const enableSMSAuthModalSlice = createSlice({
  name: "enableSMSAuthModalState",
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setEnableSMSAuthModalOpen: (state, action) => {
      state.open = action.payload
    },
    setEnableSMSAuthModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setEnableSMSAuthModalData, setEnableSMSAuthModalOpen } =
  enableSMSAuthModalSlice.actions

export default enableSMSAuthModalSlice.reducer
