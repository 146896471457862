import React from "react"

//form handling
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

//styles
import Styles from "./applyLaunchpad.module.css"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import FormRow from "../../../../components/FormRow/FormRow"
import yupPhoneValidate from "../../../../utility/yup-validation/yupPhoneValidate"

//apply launchpad schema
const applySchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  address: Yup.string().required("Required"),
  number: yupPhoneValidate(),
  comments: Yup.string()
})

function ApplyLaunchpad() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(applySchema),
    mode: "onSubmit"
  })

  const onSumbit = (data) => {
    // console.log(data)
  }

  return (
    <div className={Styles.pageContainer}>
      <form onSubmit={handleSubmit(onSumbit)} className={Styles.formContainer}>
        <h1>LAUNCHPAD FORM</h1>

        <span>Contact information</span>

        <FormRow style={{ marginTop: 50 }}>
          <Input
            variant="secondary"
            error={errors.name}
            {...register("name")}
            inputLabel="Alex Jones"
          />
          <Input
            id="email"
            {...register("email")}
            variant="secondary"
            error={errors.email}
            inputLabel="example@mail.com"
          />
        </FormRow>

        <FormRow>
          <Input
            id="address"
            {...register("address")}
            variant="secondary"
            error={errors.address}
            inputLabel="Address"
          />
          <Input
            id="number"
            type="phoneNumber"
            variant="secondary"
            register={{ ...register("number") }}
            error={errors.number}
            inputLabel="Phone number"
          />
        </FormRow>

        <Input
          id="comments"
          type="textarea"
          variant="secondary"
          {...register("comments")}
          error={errors.comments}
          inputLabel="Comments"
        />

        <Button
          text="Submit"
          type="submit"
          style={{ margin: "30px auto", width: 200 }}
        />
      </form>
    </div>
  )
}

export default ApplyLaunchpad
