import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

// styles
import Styles from "./kycVerification.module.css"

// redux
import { useDispatch, useSelector } from "react-redux"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../snackbar/redux/snackbarSlice"

// translations
import { FormattedMessage } from "react-intl"

// apis
import {
  _sendCode,
  _verify,
  _reSendCode,
  _initializeKyc,
  _addInfo
} from "../redux/actions"
import Document from "./Documents"
import VerificationForm from "./VerificationForm"
import VerifyNumber from "./VerifyNumber/VerifyNumber"
import SMSCode from "./SMSCode/SMSCode"

function KYCVerification() {
  const dispatch = useDispatch()
  const location = useLocation()
  const userData = useSelector((state) => state.userData.value)
  const hasPhoneLabel = userData?.labels?.some((label) => label.key === "phone")
  const hasDocumentsLabel = userData?.labels?.some(
    (label) => label.key === "document"
  )
  const [phoneNumber, setPhoneNumber] = useState("")
  const [step, setStep] = useState(0)
  const [token, setToken] = useState("")

  useEffect(() => {
    _initializeKyc(
      (data) => {
        setToken(data.token)
        // console.log(data)
      },
      (err) => {
        // console.log(err)
      }
    )
  }, [])

  useEffect(() => {
    if (hasDocumentsLabel) {
      setStep(4)
    }
    if (hasPhoneLabel) {
      setStep(2)
    }
    if (userData.registration_detail) {
      setStep(3)
    }
    if (!hasPhoneLabel) {
      setStep(0)
    }
  }, []) // hasPhoneLabel, hasDocumentsLabel, userData

  // useEffect(() => {
  //     _initializeKyc(
  //         (data) => {
  //             console.log(data)
  //         },
  //         (err) => {
  //             console.log(err)
  //         }
  //     )
  // }, [])

  return (
    <>
      {step === 0 && (
        <VerifyNumber
          setStep={setStep}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      {step === 1 && <SMSCode phoneNumber={phoneNumber} setStep={setStep} />}
      {step === 2 && <VerificationForm setStep={setStep} />}
      {step === 3 && <Document token={token} email={userData.email} />}
      {step === 4 && <h1>VERIFIED</h1>}
    </>
  )
}

export default KYCVerification
