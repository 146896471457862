import React, { useState } from "react"

// styles
import Styles from "./LanguageCurrencySelectModal.module.css"

// redux
import { useSelector } from "react-redux"
import { _getUserInfo } from "../../../redux/actions"

// components
import Button from "../../../components/Button/Button"
import WenbitModal from "../../../components/Modal/Modal"
import LanguageCurrencyButton from "../../../components/LanguageCurrencyButton/LanguageCurrencyButton"

// constants
import { languageOptions } from "../../../constants/languageConstants"

// helpers
import {
  hideLanguageCurrencySelectModal,
  setLanguageCurrencySelectModalSelectedTab
} from "./helpers"

function LanguageCurrencySelectModal() {
  const language = useSelector((state) => state.language)

  const modalOpen = useSelector(
    (state) => state.languageCurrencySelectModalState.open
  )
  const selectedTab = useSelector(
    (state) => state.languageCurrencySelectModalState.data
  )?.selectedTab

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={hideLanguageCurrencySelectModal}
        render={() => (
          <div style={{ marginTop: -30 }}>
            <div className={Styles.tabsContainer}>
              <Button
                onClick={() =>
                  setLanguageCurrencySelectModalSelectedTab("language")
                }
                isTabSelected={selectedTab === "language"}
                text="Language"
                type="tab"
              />
              <Button
                onClick={() =>
                  setLanguageCurrencySelectModalSelectedTab("currency")
                }
                isTabSelected={selectedTab === "currency"}
                text="Currency"
                type="tab"
              />
            </div>

            <div className={Styles.buttonsContainer}>
              {selectedTab === "language" &&
                languageOptions.map((el) => (
                  <LanguageCurrencyButton
                    isSelected={language?.code === el.value}
                    label={el.label}
                  />
                ))}
              {selectedTab === "currency" && (
                <LanguageCurrencyButton isSelected label="USD" />
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default LanguageCurrencySelectModal
