import { createSlice } from "@reduxjs/toolkit"

const backdropSlice = createSlice({
  name: "backdropState",
  initialState: {
    open: false
  },
  reducers: {
    setBackdropOpen: (state, action) => {
      state.open = action.payload
    },
    toggleBackdrop: (state, action) => {
      state.open = !state.open
    }
  }
})

export const { setBackdropOpen, toggleBackdrop } = backdropSlice.actions

export default backdropSlice.reducer
