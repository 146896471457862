export const hideEmail = (email) => {
  if (email) {
    const [username, domain] = email.split("@")
    let hiddenUsername = ""
  
    if (username.length > 1) {
      hiddenUsername = `${username.substring(0, 2)}${"*".repeat(username.length - 2)}`
    } else {
      hiddenUsername = "*"
    }
  
    return `${hiddenUsername}@${domain}`
  }
}
