import React from 'react'

// react router imports
import { Link, useLocation } from 'react-router-dom'

// styles imports
import Styles from './navLink.module.css'

function NavLink({
    text,
    style = {},
    to = '#',
    isCurrentRoute = false
}) {

    const location = useLocation()

  return (
    <Link
        to={to}
        className={`${isCurrentRoute && Styles.selected } ${Styles.navLink} headerLink`}
        style={{...style}}
    >
        {text}
        <div className={Styles.bottomLine}></div>
    </Link>
  )
}

export default NavLink