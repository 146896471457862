import { store } from "../../../redux/store"
import { setKYCReminderModalOpen } from "./redux/KYCReminderModalSlice"

export const showKYCReminderModal = () => {
  store.dispatch(setKYCReminderModalOpen(true))
}

export const closeKYCReminderModal = () => {
  store.dispatch(setKYCReminderModalOpen(false))
}
