import React, { forwardRef, useEffect, useRef, useState } from 'react'

// redux
import { useSelector } from 'react-redux'

//icons
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { ReactComponent as CloseIcon } from '../../assets/icons/imported/close.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/imported/calendar.svg'

// styles
import Styles from './input.module.css'
import './unstyledSelectMUI.css'

// Date picker
import addDays from 'date-fns/addDays'
import DatePicker from 'react-datepicker'

// MUI
import { Popper } from '@mui/base'
import { styled } from '@mui/system'
import { Select, selectClasses } from '@mui/base/Select'
import { optionClasses, Option } from '@mui/base/Option'

// components
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import './phoneNumber.css'
import DatePickerWrapper from '../DateRangePicker/DatePickerWrapper'

// utils
import countries from '../../utility/countries'
import Radio from '../Radio/Radio'
import { IconButton } from '@mui/material'

const Input = forwardRef(
  (
    {
      id,
      name,
      placeholder,
      type = 'text',
      onChange = () => {},
      icon,
      error,
      style,
      inputLabel,
      indicatorLabel,
      containerStyle,
      inputIconContainerStyle,
      variant = 'primary', // primary - secondary,
      showBorder = false,
      disabled = false,
      required = false,

      /**
       * Used for when a custom input field needs to be rendered
       * instead of the regular input field.
       */
      renderInputField,

      // select
      selectUnstylesContainerStyle,
      selectOptions = [], // { label: "Radio text", value: 1 }
      selectValue,
      selectDefaultValue,
      hideSelectIcon = false,
      renderSelectValue = () => {},

      // radio group
      radios = [],

      // date picker
      // -- range --
      startDateRange = null,
      endDateRange = null,
      setStartDateRange,
      setEndDateRange,
      selectedDate,
      setDate,
      monthsShown = 1,
      selectsRange = false, // boolean to define whether it's date range picker or just date picker

      // form validation
      register,

      ...rest
    },
    ref
  ) => {
    const theme = useSelector(state => state.theme.value)

    // ------------- DATE RANGE PICKER -------------
    // ---------------------------------------------
    const handleDateRangeOnChange = dates => {
      if (selectsRange) {
        const [start, end] = dates
        setStartDateRange(start)
        setEndDateRange(end)
      } else {
        setDate(dates) // Single Date on SelectsRange false
      }
      onChange(dates)
    }

    const handleClearDate = () => {
      if (selectsRange) {
        setStartDateRange(null)
        setEndDateRange(null)
        onChange([null, null])
      } else {
        setDate(null)
        onChange(null)
      }
    }
    // ---------------------------------------------
    // ---------------------------------------------

    // --------------- PHONE NUMBER ----------------
    // ---------------------------------------------
    const phoneNumberInputContainerRef = type === 'phoneNumber' ? useRef(null) : null

    // State to store the width
    const [containerWidth, setContainerWidth] = useState(0)

    // Effect to get the width after mount
    useEffect(() => {
      if (type === 'phoneNumber') {
        if (phoneNumberInputContainerRef?.current) {
          setContainerWidth(phoneNumberInputContainerRef?.current.offsetWidth)
          // console.log("HERE", phoneNumberInputContainerRef?.current.offsetWidth)
        }
      }
    }, [phoneNumberInputContainerRef])

    // ---------------- RADIO GROUP -----------
    // ----------------------------------------
    let radioValue
    let setRadioValue

    if (type === 'radioGroup') [radioValue, setRadioValue] = useState(null)
    // ----------------------------------------
    // ----------------------------------------

    // ---------------- SELECT ----------------
    // ----------------------------------------
    // tracking list width
    const selectContainerRef = useRef(null)
    const [listWidth, setWidthList] = useState(0)
    useEffect(() => {
      let handleResize
      if (type === 'select') {
        handleResize = () => {
          setWidthList(selectContainerRef?.current?.offsetWidth)
        }
        console.log(selectContainerRef?.current?.offsetWidth)
        setWidthList(selectContainerRef?.current?.offsetWidth)
        window.addEventListener('resize', handleResize)
      }
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    renderSelectValue = option => {
      if (option === null && placeholder) {
        return <span className={Styles.selectInputPlaceholder}>{placeholder}</span>
      }

      return <span className={Styles.optionSpan}>{option?.label}</span>
    }

    const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
      const slots = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots
      }

      return <Select {...props} ref={ref} slots={slots} />
    })
    const StyledButton = styled('button')(
      () => `
          display: flex;
          justify-content: space-between;
          & > :first-child {
            flex: 1;
          }
          gap: 25px;

          font-size: 0.875rem;
          box-sizing: border-box;
          min-height: calc(1.5em + 22px);
          min-width: ${listWidth}; /* Original 220px */
          width: 100%;
          padding: 12px;
          border-radius: 5px;
          text-align: left;
          line-height: 1.5;
          background:
                ${
                  theme === 'light'
                    ? variant === 'primary'
                      ? 'var(--secondary-color)'
                      : 'white'
                    : variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'black'
                };
          border: none;
          color: var(--text-color);
        
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 120ms;
        
          &.${selectClasses.expanded} {
            &::after {
              transform: rotate(180deg);
            }
          }
        
          &::after {
            content: '▾';
            float: right;
            color: var(--gray-color);
          }
          `
    )

    const StyledListbox = styled('ul')(
      () => `
          font-size: 0.875rem;
          box-sizing: border-box;
          padding: 6px;
          margin: 0 0 12px 0;
          min-width: ${listWidth}px;
          max-height: 400px;
          border-radius: 5px;
          overflow: auto;
          outline: 0px;
          background: ${theme === 'dark' ? 'black' : 'white'};
          color: var(--text-color);
          box-shadow: 0px 2px 10px ${theme === 'dark' ? '#rgba(20, 20, 20, 0.16)' : 'rgba(56, 56, 56, 0.1)'};
          border: 1px solid var(--border-color);
          `
    )

    const StyledOption = styled(Option)(
      () => `
          list-style: none;
          padding: 8px;
          border-radius: 5px;
          cursor: default;
          margin: 3.5px 0;
          &:last-of-type {
            border-bottom: none;
          }
        
          &.${optionClasses.selected} {
            border: 1px var(--primary-color) solid;
            background-color: var(--primary-color-opacity);
            color: var(--primary-color);
          }
        
          &.${optionClasses.highlighted} {
            background-color: var(--primary-color-opacity);
            color: var(--primary-color);
          }
        
          &.${optionClasses.highlighted}.${optionClasses.selected} {
              background-color: var(--primary-color-opacity);
              color: var(--primary-color);
          }
      
          &:hover:not(.${optionClasses.disabled}) {
              background-color: var(--primary-color-opacity);
              color: var(--primary-color);
          }
          `
    )

    const StyledPopper = styled(Popper)`
      z-index: 9999999;
    `
    // ----------------------------------------
    // ----------------------------------------

    const [passwordShown, setPasswordShown] = useState(false)

    if (renderInputField) {
      return (
        <div
          className={`${theme === 'light' && Styles.light} ${Styles.inputContainer}`}
          style={{
            ...containerStyle
          }}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div className={`${Styles.customInputIconContainer} ${error?.message && Styles.errorInput}`}>
            {renderInputField()}
          </div>
          {indicatorLabel && ( // && !error?.message
            <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
          )}
          <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    } else if (type === 'password') {
      return (
        <div className={`${Styles.inputContainer}`} style={{ ...containerStyle }}>
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${Styles.passwordInputContainer} ${error?.message && Styles.errorInput}`}
            style={{
              backgroundColor:
                theme === 'light'
                  ? variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'white'
                  : variant === 'primary'
                  ? 'var(--secondary-color)'
                  : 'black',
              border: showBorder && '1.45px #CCCED926 solid',
              ...containerStyle
            }}
          >
            <input
              type={passwordShown ? 'text' : 'password'}
              placeholder={placeholder}
              id={id}
              onChange={onChange}
              name={name}
              ref={ref}
              {...rest}
              style={{
                backgroundColor:
                  theme === 'light'
                    ? variant === 'primary'
                      ? 'var(--secondary-color)'
                      : 'white'
                    : variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'black',
                ...style
              }}
              className={Styles.passwordInput}
            />
            <div onClick={() => setPasswordShown(!passwordShown)}>
              {passwordShown ? <FiEye color='#9295A6' /> : <FiEyeOff color='#9295A6' />}
            </div>
          </div>
          {indicatorLabel && ( // && !error?.message
            <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
          )}
          <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    } else if (type === 'phoneNumber') {
      return (
        <div
          className={`${Styles.inputContainer}`}
          style={containerStyle}
          // style={{
          //     backgroundColor: theme === 'light' ? (variant === 'primary' ? 'var(--secondary-color)' : 'white') : (variant === 'primary' ? 'var(--secondary-color)' : 'black'),
          //     ...containerStyle
          // }}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${Styles.inputIconContainer} ${error?.message && Styles.errorInput}`}
            style={{
              overflow: 'unset',
              backgroundColor:
                theme === 'light'
                  ? variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'white'
                  : variant === 'primary'
                  ? 'var(--secondary-color)'
                  : 'black',
              border: showBorder && '1.45px #CCCED926 solid',
              ...inputIconContainerStyle
            }}
            ref={phoneNumberInputContainerRef}
          >
            <PhoneInput
              inputProps={{
                id,
                ref: register ? register?.ref : ref ?? '',
                name: register && register?.name
              }}
              onChange={(value, country, event) => {
                onChange(value)
                if (register) {
                  register?.onChange(event)
                }
              }}
              // country={"us"}
              containerStyle={{
                background: 'none !important',
                margin: '3px 5px'
              }}
              containerClass={Styles.phoneInputContainer}
              inputStyle={{
                border: 'none',
                width: '100%',
                background: 'none',
                borderColor: 'transparent !important',
                color: 'var(--text-color)',
                boxShadow: 'none !important',
                '&:focus': {
                  boxShadow: 'none !important'
                }
              }}
              inputClass={Styles.phoneInput}
              buttonClass={Styles.flagButton}
              dropdownClass={Styles.dropdownPhoneSelect}
              dropdownStyle={{
                boxShadow: theme === 'dark' ? '#rgba(20, 20, 20, 0.16)' : 'rgba(56, 56, 56, 0.1)'
              }}
            />
            <span className={Styles.inputIcon}>{icon}</span>
          </div>
          {indicatorLabel && ( // && !error?.message
            <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
          )}
          <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    } else if (type === 'select') {
      return (
        <div
          className={`${Styles.inputContainer} ${Styles.selectContainer}`}
          style={containerStyle}
          ref={selectContainerRef}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${Styles.inputIconContainer} ${error?.message && Styles.errorInput}`}
            style={{
              backgroundColor:
                theme === 'light'
                  ? variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'white'
                  : variant === 'primary'
                  ? 'var(--secondary-color)'
                  : 'black',
              border: showBorder && '1.45px #CCCED926 solid',
              ...inputIconContainerStyle
            }}
          >
            <CustomSelect
              onChange={onChange}
              defaultValue={selectDefaultValue}
              renderValue={renderSelectValue}
              value={selectValue ? selectValue : undefined}
            >
              {selectOptions.map(option => (
                <StyledOption key={option?.value} value={option?.value} label={option?.label}>
                  {option?.label}
                </StyledOption>
              ))}
            </CustomSelect>
          </div>
          <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    } else if (type === 'country') {
      return (
        <div
          className={`${Styles.inputContainer}`}
          style={containerStyle}
          // style={{
          //     backgroundColor: theme === 'light' ? (variant === 'primary' ? 'var(--secondary-color)' : 'white') : (variant === 'primary' ? 'var(--secondary-color)' : 'black'),
          //     ...containerStyle
          // }}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${Styles.inputIconContainer} ${error?.message && Styles.errorInput}`}
            style={{
              backgroundColor:
                theme === 'light'
                  ? variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'white'
                  : variant === 'primary'
                  ? 'var(--secondary-color)'
                  : 'black',
              ...inputIconContainerStyle
            }}
          >
            <div
              className={`country_input_container ${Styles.optionsContainer}`}
              style={{ ...selectUnstylesContainerStyle }}
            >
              <Select className={`${Styles.selectContainer}`} onChange={onChange} defaultValue={selectDefaultValue}>
                {countries.map(country => (
                  <Option value={country.code}>
                    <img
                      loading='lazy'
                      width='20'
                      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                      alt={`Flag of ${country.label}`}
                      style={{
                        marginRight: 10
                      }}
                    />
                    {country.label}
                  </Option>
                ))}
              </Select>
            </div>
            {icon && <span className={Styles.inputIcon}>{icon}</span>}
          </div>
          <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    } else if (type === 'textarea') {
      return (
        <div
          className={`${theme === 'light' && Styles.light} ${Styles.inputContainer}`}
          style={{
            ...containerStyle
          }}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${Styles.textAreanputIconContainer} ${disabled ? Styles.disabled : ''} ${
              error?.message && Styles.errorInput
            }`}
            style={{
              backgroundColor:
                theme === 'light'
                  ? variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'white'
                  : variant === 'primary'
                  ? 'var(--secondary-color)'
                  : 'black',
              border: showBorder && '1.45px #CCCED926 solid',
              ...inputIconContainerStyle
            }}
          >
            <textarea
              type={type}
              className={`${Styles.textareaInput}`}
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChange}
              ref={ref}
              disabled={disabled}
              style={{
                backgroundColor:
                  theme === 'light'
                    ? variant === 'primary'
                      ? 'var(--secondary-color)'
                      : 'white'
                    : variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'black',
                ...style
              }}
              {...register}
              {...rest}
            />
            {icon && <span className={Styles.inputIcon}>{icon}</span>}
          </div>
          {indicatorLabel && ( // && !error?.message
            <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
          )}
          <span className={`${Styles.errorSpan} ${Styles.textareaErrorSpan}`}>
            {error?.message ? error.message : ' '}
          </span>
        </div>
      )
    } else if (type === 'radioGroup') {
      return (
        <div
          className={`${theme === 'light' && Styles.light} ${Styles.inputContainer}`}
          style={{
            ...containerStyle
          }}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${disabled ? Styles.disabled : ''} ${Styles.radiosContainer}`}
            style={{
              ...inputIconContainerStyle
            }}
          >
            {radios?.map(radio => (
              <Radio
                value={radio?.value}
                setValue={setRadioValue}
                selectedValue={radioValue}
                name='visa-requirement'
                label={radio?.label}
                register={register}
                onChange={onChange}
              />
            ))}
          </div>
          {indicatorLabel && ( // && !error?.message
            <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
          )}
          <span className={Styles.radioErrorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    } else if (type === 'date') {
      return (
        <DatePickerWrapper>
          <div
            className={`${theme === 'light' && Styles.light} ${Styles.inputContainer}`}
            style={{
              ...containerStyle
            }}
          >
            {inputLabel && (
              <span className={Styles.labelSpan}>
                {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
              </span>
            )}
            <div
              className={`${Styles.inputIconContainer} ${disabled ? Styles.disabled : ''} ${
                Styles.datePickerContainer
              } ${error?.message && Styles.errorInput}`}
              style={{
                backgroundColor:
                  theme === 'light'
                    ? variant === 'primary'
                      ? 'var(--secondary-color)'
                      : 'white'
                    : variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'black',
                border: showBorder && '1.45px #CCCED926 solid',
                ...inputIconContainerStyle,
                paddingRight: 10
                // width: "240px"
              }}
            >
              <div style={{ marginRight: '5px' }}>
                <CalendarIcon color='var(--gray-color)' style={{ width: '16px' }} />
              </div>
              <DatePicker
                selectsRange={selectsRange}
                monthsShown={monthsShown}
                startDate={selectsRange ? startDateRange : undefined}
                endDate={selectsRange ? endDateRange : undefined}
                selected={!selectsRange ? selectedDate : undefined}
                placeholderText={placeholder}
                onChange={handleDateRangeOnChange}
                // popperPlacement={popperPlacement}
                // customInput={<CustomInput label='Multiple Months' end={endDateRange} start={startDateRange} />}
              />
              {selectsRange
                ? startDateRange &&
                  endDateRange && (
                    <IconButton
                      aria-label='close'
                      onClick={handleClearDate}
                      sx={{
                        p: 0,
                        mr: '5px',
                        height: 20,
                        width: 20
                      }}
                    >
                      <CloseIcon width={11} height={11} color='var(--gray-color)' />
                    </IconButton>
                  )
                : selectedDate && (
                    <IconButton
                      aria-label='close'
                      onClick={handleClearDate}
                      sx={{
                        p: 0,
                        mr: '5px',
                        height: 20,
                        width: 20
                      }}
                    >
                      <CloseIcon width={11} height={11} color='var(--gray-color)' />
                    </IconButton>
                  )}
              {/* <input
              type={type}
              className={`${Styles.input}`}
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChange}
              ref={ref}
              {...rest}
              disabled={disabled}
              style={{
                backgroundColor:
                  theme === "light"
                    ? variant === "primary"
                      ? "var(--secondary-color)"
                      : "white"
                    : variant === "primary"
                    ? "var(--secondary-color)"
                    : "black",
                ...style
              }}
            /> */}
              {icon && <span className={Styles.inputIcon}>{icon}</span>}
            </div>
            {indicatorLabel && ( // && !error?.message
              <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
            )}
            <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
          </div>
        </DatePickerWrapper>
      )
    } else {
      return (
        <div
          className={`${theme === 'light' && Styles.light} ${Styles.inputContainer}`}
          style={{
            ...containerStyle
          }}
        >
          {inputLabel && (
            <span className={Styles.labelSpan}>
              {inputLabel} {required && <span className={Styles.requiredSpan}>*</span>}
            </span>
          )}
          <div
            className={`${Styles.inputIconContainer}  ${disabled ? Styles.disabled : ''} ${
              error?.message && Styles.errorInput
            }`}
            style={{
              backgroundColor:
                theme === 'light'
                  ? variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'white'
                  : variant === 'primary'
                  ? 'var(--secondary-color)'
                  : 'black',
              border: showBorder && '1.45px #CCCED926 solid',
              ...inputIconContainerStyle
            }}
          >
            <input
              type={type}
              className={`${Styles.input}`}
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChange}
              ref={ref}
              {...rest}
              disabled={disabled}
              style={{
                backgroundColor:
                  theme === 'light'
                    ? variant === 'primary'
                      ? 'var(--secondary-color)'
                      : 'white'
                    : variant === 'primary'
                    ? 'var(--secondary-color)'
                    : 'black',
                ...style
              }}
            />
            {icon && <span className={Styles.inputIcon}>{icon}</span>}
          </div>
          {indicatorLabel && ( // && !error?.message
            <span className={Styles.indicatorLabel}>{indicatorLabel}</span>
          )}
          <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
        </div>
      )
    }
  }
)

Input.displayName = 'Input'
export default Input
