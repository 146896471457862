// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

function DurationButton({ apr, duration, value, onClick, isSelected, ...rest }) {
  return (
    <button
      onClick={onClick}
      {...rest}
      className={`${styles.button} ${isSelected ? styles.selected : ''} ${rest.className ?? ''}`}
    >
      <div className={styles.apr}>{apr}%</div>
      <div className={styles.duration}>
        {duration}
        {duration !== 'Flexible' && 'D'}
      </div>
    </button>
  )
}

export default DurationButton
