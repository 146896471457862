import { createSlice } from "@reduxjs/toolkit"

const disable2FAModalSlice = createSlice({
  name: "disable2FAModalState",
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setDisable2FAModalOpen: (state, action) => {
      state.open = action.payload
    },
    setDisable2FAModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setDisable2FAModalOpen, setDisable2FAModalData } =
  disable2FAModalSlice.actions

export default disable2FAModalSlice.reducer
