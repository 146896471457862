import React, { useEffect, useRef, useState } from "react"

// styles
import Styles from "./selectBeneficiaryModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"

// icon

// components
import WenbitModal from "../../../../components/Modal/Modal"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"

import { randomizeShimmerWidth } from "../../../../components/shimmer-components/helpers"
import { hideSelectBeneficiaryModal } from "./helpers"
import { _getBeneficiaries } from "../redux/actions"
import { CircularProgress } from "@mui/material"

function SelectBeneficiaryModal() {
  let selectedBeneficiaryId

  const modalOpen = useSelector(
    (state) => state.selectBeneficiaryModalState.open
  )
  const modalData = useSelector(
    (state) => state.selectBeneficiaryModalState.data
  )

  // load more states
  const limit = 10
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const scrollContainerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState([])

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollContainerRef.current

    if (scrollHeight - scrollTop === clientHeight) {
      // Scroll reached the bottom
      if (loading || beneficiaries.length === Number(total)) return // if loading or total rows fetched
      setPage((prev) => prev + 1)
    }
  }

  const fetchData = () => {
    setLoading(true)
    _getBeneficiaries(
      {
        limit,
        page,
        currency: modalData?.coinId,
        state: "active"
      },
      (data) => {
        setTotal(data.total)
        setLoading(false)
        setBeneficiaries((prevData) => [...prevData, ...data.data])
      },
      (err) => setLoading(false)
    )
  }

  const handleButtonClick = (beneficiary) => {
    modalData?.callback(beneficiary)
    hideSelectBeneficiaryModal()
  }

  useEffect(() => {
    if (modalOpen) {
      fetchData()
    } else {
      setBeneficiaries([])
      setPage(1)
    }
  }, [page, modalOpen])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={hideSelectBeneficiaryModal}
        title="Select beneficiary"
        disableBackdropClick={false}
        render={() => (
          <div className={Styles.select}>
            {/* <p className={Styles.description}>
              Choose your lane! To finalise your deposit securely,
              <br />
              choose which network you’d like to use to complete the
              transaction.
            </p> */}

            <span className={Styles.boxLabel}>Select beneficiary</span>
            <div
              className={Styles.networksContainer}
              onScroll={handleScroll}
              ref={scrollContainerRef}
            >
              <>
                {beneficiaries.length
                  ? beneficiaries?.map((beneficiary) => (
                      <button
                        onClick={() => handleButtonClick(beneficiary)}
                        key={beneficiary?.id}
                      >
                        {beneficiary?.name}
                      </button>
                    ))
                  : !loading && (
                      <span style={{ color: "var(--gray-color)" }}>
                        No active beneficiaries found
                      </span>
                    )}
                {loading && page !== 1 && (
                  <CircularProgress
                    sx={{
                      margin: "0 auto"
                    }}
                  />
                )}
              </>

              {loading && page === 1 && (
                <>
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                </>
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default SelectBeneficiaryModal
