export const formatNumber = (num, decimalPlaces = 2, hideBalance = false) => {
  if (num === 0 || num === null || num === undefined) {
    return "0.00"
  }

  // Convert string input to number
  if (typeof num === "string") {
    num = Number(num)
  }

  // Check again after conversion, in case the conversion didn't result in a number
  if (num === 0 || !num) {
    return "0.00"
  }

  // Format numbers 1000 and greater with comma as thousand separator and fixed decimal places
  if (num >= 1000) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    })
    return formatter.format(num)
  } else {
    // For numbers less than 1000, use toFixed to ensure the decimal places are always shown
    return num.toFixed(decimalPlaces)
  }
}
