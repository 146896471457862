import React from "react"

// styles
import Styles from "./kycStatusBadge.module.css"

// icons
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/imported/checkmark.svg"

function KYCStatusBadge({
  status // not-verified - pending - verified
}) {
  if (status === "verified")
    return (
      <div className={`${Styles.container} ${Styles.verified}`}>
        <CheckmarkIcon
          className={Styles.checkmarkIcon}
          color="var(--primary-color)"
        />
        <span>Verified</span>
      </div>
    )

  if (status === "pending")
    return (
      <div className={`${Styles.container} ${Styles.pending}`}>
        <span>!</span>
        <span>Pending</span>
      </div>
    )

  if (status === "not-verified")
    return (
      <div className={`${Styles.container} ${Styles.notVerified}`}>
        Not verified
      </div>
    )

  if (!status) return null
}

export default KYCStatusBadge
