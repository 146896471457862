import React from "react"

// styles
import Styles from "./comingSoon.module.css"

// icons
import { ReactComponent as ComingSoonIcon } from "../../assets/icons/imported/coming-soon.svg"

// components
import Button from "../../components/Button/Button"

function ComingSoon() {
  return (
    <div className={Styles.pageContainer}>
      <ComingSoonIcon width={120} height={120} />
      <h1>Coming Soon!</h1>
      <Button
        text="Go Back"
        onClick={() => history.back()}
        style={{
          maxWidth: 160
        }}
      />
    </div>
  )
}

export default ComingSoon
