// ** React Imports
import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// ** Styles Imports
import Styles from "./blogs.module.css"

// ** Assets Imports
import GridBackground from "../assets/background.png"
import GridBackgroundLight from "../assets/background-light.png"

// ** Icons Imports
import { SearchX } from "lucide-react"
import { ReactComponent as AlertIcon } from "../../../assets/icons/imported/alert.svg"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Component Imports
import BlogCard from "../components/BlogCard"
import BlogShimmer from "../components/BlogShimmer"
import Button from "../../../components/Button/Button"
import MUIPagination from "../../../components/MUIDatagrid/MUIPagination"

// ** API Imports
import { _getBlogs } from "../redux/actions"

function Blogs() {
  //  ** Hooks
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useSelector((state) => state.theme.value)
  document.title = "Wenbit: Blogs"

  // ** Constants
  const blogCategoryUrl = location.pathname.split("/")[2]

  // ** States
  const [blogs, setBlogs] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    perPage: 10
  })

  // ** Handlers
  const handlePaginationChange = (_, page) => {
    scrollTo({ top: 300 })
    setPaginationModel({
      page,
      perPage: 10
    })
  }

  const fetchBlogs = () => {
    setLoading(true)
    setShowError(false)
    _getBlogs(
      {
        page: paginationModel.page,
        perPage: paginationModel.perPage,
        category:
          blogCategoryUrl === "trending"
            ? 2
            : blogCategoryUrl === "wen-new-bits"
            ? 3
            : blogCategoryUrl === "events"
            ? 4
            : blogCategoryUrl === "inside-scoop"
            ? 5
            : blogCategoryUrl === "latest-buzz" && 6
      },
      (data) => {
        setLoading(false)
        setTotalRows(data.data.total)
        setBlogs(data.data.data)
      },
      (err) => {
        setLoading(false)
        setShowError(true)
      }
    )
  }

  useEffect(() => {
    if (["blogs", "/blogs", "/blogs/"].includes(location.pathname)) {
      navigate("/blogs/trending")
      return
    }
    if (location.pathname.split("/")[1] === "blogs") {
      fetchBlogs()
    }
  }, [paginationModel, location.pathname])

  return (
    <>
      {/* page header */}
      <div className={Styles.pageHeader}>
        <img
          src={theme === "dark" ? GridBackground : GridBackgroundLight}
          className={Styles.headerBackground}
        />
        <span>Blog & Articles</span>
        <h1>
          {/* Stay Ahead of the Curve <br />
          with our blog section */}
          {blogCategoryUrl === "trending"
            ? "Trending"
            : blogCategoryUrl === "wen-new-bits"
            ? "Wen’s new bits"
            : blogCategoryUrl === "events"
            ? "Events"
            : blogCategoryUrl === "inside-scoop"
            ? "Inside Scoop"
            : blogCategoryUrl === "latest-buzz" && "The Latest Buzz"}
        </h1>
      </div>
      <div className={Styles.pageContainer}>
        {showError ? (
          <div className={Styles.errorContainer}>
            <AlertIcon color="var(--danger-color)" width={100} height={100} />
            <span>Error while loading blogs!</span>
            <Button
              onClick={fetchBlogs}
              text="Retry"
              fitContent
              style={{ backgroundColor: "var(--danger-color)" }}
            />
          </div>
        ) : (
          <div className={Styles.gridContainer}>
            {loading
              ? new Array(5).fill(0).map(() => <BlogShimmer />)
              : blogs &&
                blogs.map((blog) => (
                  <BlogCard
                    id={blog.id}
                    created_at={blog.created_at}
                    title={blog.title}
                    author={blog.author && blog.author.name}
                  />
                ))}
          </div>
        )}

        {/* Empty State */}
        {!loading && !blogs.length && (
          <div className={Styles.errorContainer}>
            <SearchX color="var(--primary-color)" width={100} height={100} />
            <span>No Blogs Found!</span>
            <Button
              onClick={fetchBlogs}
              text="Retry"
              fitContent
              // style={{ backgroundColor: "var(--danger-color)" }}
            />
          </div>
        )}

        {/* PAGINATION */}
        <div className={Styles.paginationContainer}>
          <MUIPagination
            pageSize={paginationModel.perPage}
            totalCount={totalRows}
            page={paginationModel.page}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </>
  )
}

export default Blogs
