import { store } from "../../../../redux/store"
import {
  setDisable2FAModalData,
  setDisable2FAModalOpen
} from "./redux/disable2FAModalSlice"

export const showDisable2FAModal = () => {
  store.dispatch(
    setDisable2FAModalData({
      title: "Disable Two Factor Authenticator"
    })
  )
  store.dispatch(setDisable2FAModalOpen(true))
}

export const closeDisable2FAModal = () => {
  store.dispatch(setDisable2FAModalOpen(false))
}
