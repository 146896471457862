import React from "react"

// styles
import Styles from "./coinDetails.module.css"

// MUI
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import CircularProgress, {
  circularProgressClasses
} from "@mui/material/CircularProgress"
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress"

// images/icons
import placeholderPhoto from "../../assets/placeholder-1.png"
import placeholderAvatar from "../../assets/avatar.png"
import { ReactComponent as RefferalIcon } from "../../../../assets/icons/imported/refferal.svg"
import { ReactComponent as InstagramIcon } from "../../../../assets/icons/imported/instagram.svg"
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/imported/facebook.svg"
import { ReactComponent as TwitterIcon } from "../../../../assets/icons/imported/twitter.svg"
import { ReactComponent as LinkedInIcon } from "../../../../assets/icons/imported/linkedin.svg"
import { ReactComponent as YoutubeIcon } from "../../../../assets/icons/imported/youtube.svg"

// components
import Button from "../../../../components/Button/Button"

function CoinDetails() {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "gray"
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "var(--primary-color)"
    }
  }))

  return (
    <div className={Styles.pageContainer}>
      {/* Heading Container */}
      <div className={Styles.headingContainer}>
        {/* Image Container */}
        <div className={Styles.imageContainer}>
          <span className={Styles.status}>Live</span>
          <img src={placeholderPhoto} />
        </div>

        {/* coin details heading */}
        <div className={Styles.coinDetailsHeadingContainer}>
          <div className={Styles.coinTitleContainer}>
            <h1>WOO</h1>
            <Button
              text="Refer a Friend"
              icon={<RefferalIcon width={13} />}
              style={{
                width: "fit-content"
              }}
            />
          </div>

          <div className={Styles.coinDetailRowsContainer}>
            <div>
              <span>Tokens Offered</span>
              <span>50000.00 TAKI</span>
            </div>
            <div>
              <span>Sale Price</span>
              <span>1 USDT=0.000100 TAKI</span>
            </div>
            <div>
              <span>Participants</span>
              <span>1,000,673</span>
            </div>
            <div>
              <span>1,000,673</span>
              <span>672,873</span>
            </div>
          </div>

          {/* PROGRESS */}
          <div className={Styles.progressContainer}>
            <span>75% Progress</span>
            <BorderLinearProgress variant="determinate" value={75} />
          </div>

          <div className={Styles.headingTimingSpan}>
            Start time: 2023-03-08 09:00:00 (GMT+4)
          </div>
          <div className={Styles.headingTimingSpan}>
            End time: 2023-03-09 09:00:00 (GMT+4)
          </div>

          <div className={Styles.headingMainTimer}>25d : 12h : 4m : 37s</div>

          <Button
            text="Buy Now"
            style={{
              fontSize: 24,
              padding: 32,
              marginTop: 20
            }}
          />
        </div>
      </div>

      {/* TOKEN DESCRIPTION */}
      <div className={Styles.tokenDescriptionContainer}>
        <h2>WOO</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
          sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Phasellus quis ullamcorper nibh, at viverra mi.{" "}
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
          sagittis metus. Interdum et malesuada fames ac ante ipsum primis in
          faucibus. Mauris volutpat pharetra varius. Morbi rutrum eget mi
          commodo consectetur. Maecenas tempor imperdiet tincidunt. Sed faucibus
          tortor massa, vel condimentum ante pretium id.
        </p>
      </div>

      {/* PROJECT INTRODUCTION / FOUNDER CARD CONTAINER */}
      <div className={Styles.introductionFounderContainer}>
        {/* project introduction */}
        <div className={Styles.projectIntroductionContainer}>
          <h2>Project Introduction</h2>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
            sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Phasellus quis ullamcorper nibh, at viverra mi. <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
            sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Phasellus quis ullamcorper nibh, at viverra mi. <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
            sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Phasellus quis ullamcorper nibh, at viverra mi. <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
            sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Phasellus quis ullamcorper nibh, at viverra mi. <br />
            <br />
          </p>
        </div>

        {/* founder card */}
        <div className={Styles.founderCard}>
          <h2>Founder</h2>

          <div className={Styles.founderInfoContainer}>
            <img src={placeholderAvatar} />
            <span>Jamal Hussein</span>
          </div>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
            sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit.
            <br /> <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu
            sagittis metus.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. <br />
          </p>
        </div>
      </div>

      {/* SOCIALS CHANNEL */}
      <div className={Styles.socialsSection}>
        <h2>Social Channels</h2>

        <div className={Styles.socialsContainer}>
          <div>
            <FacebookIcon />
            <span>Lorem Ipsum</span>
          </div>

          <div>
            <InstagramIcon />
            <span>Lorem Ipsum</span>
          </div>

          <div>
            <TwitterIcon />
            <span>Lorem Ipsum</span>
          </div>
        </div>
      </div>

      {/* TOKEN SALE AND ECONOMICS */}
      <div className={Styles.tokenSaleSection}>
        <h2>TAKI Token Sale and Economics</h2>

        <div className={Styles.salesCard}>
          <div>
            <span>Hard Cap</span>
            <span>50000.00 WOO</span>
          </div>

          <div>
            <span>Soft Cap</span>
            <span>5000.00 WOO</span>
          </div>

          <div>
            <span>Minimum Purchase</span>
            <span>10.00 WOO</span>
          </div>

          <div>
            <span>Maximum Purchase </span>
            <span>100.00 WOO</span>
          </div>

          <div>
            <span>Base Price</span>
            <span>0.00010000 USDT</span>
          </div>

          <div>
            <span>Start Date</span>
            <span>28/2/2023 03:00 PM</span>
          </div>

          <div>
            <span>End Date</span>
            <span>31/3/2023 03:00 pm</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoinDetails
