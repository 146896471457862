// ** Redux Imports
import { store } from "../../redux/store"
import { useSelector } from "react-redux"

// ** Helpers  Imports
import { showSelectCoinModal } from "../../modules/modals/select-currency/helpers"
import { showWithdrawFormModal } from "../../modules/modals/deposit-withdraw/withdraw-form/helpers"
import { showKYCReminderModal } from "../../modules/modals/kyc/helpers"
import { showDangerModal } from "../../modules/modals/danger/helpers"

export const handleWithdraw = ({ selectedCoinId = null } = {}) => {
  const userData = store.getState().userData.value

  // Check if user if KYC verified
  if (
    !(
      userData?.labels.find((el) => el.key === "document")?.value === "verified"
    )
  ) {
    showKYCReminderModal()
    return
  }

  // Check if user if KYC verified and has at least 3 security levels on
  if (
    // KYC
    userData?.labels.find((el) => el.key === "document")?.value ===
      "verified" &&
    // 2FA
    (!userData.otp ||
      // SMS
      !userData.sms_auth)

    // Security level at least  3
    // level > 2
  ) {
    showDangerModal({
      title: "Security is required",
      subtitle:
        "You must enable 2FA and SMS Verification to proceed with withdrawal",
      showActionButton: true,
      actionButtonText: "Enable",
      href: "/account/security"
    })
    return
  }

  if (selectedCoinId) {
    showWithdrawFormModal({ coinId: selectedCoinId })
  } else {
    showSelectCoinModal({
      callback: (coin) => {
        showWithdrawFormModal({ coinId: coin.id })
      }
    })
  }
}
