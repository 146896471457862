import { store } from "../../../../redux/store"
import {
  setAddNewCardModalData,
  setAddNewCardModalOpen
} from "./redux/AddNewCardModalSlice"

export const showAddNewCardModal = ({
  callback = () => {},
  title = "Card Details",
  secondaryTitle = null
}) => {
  store.dispatch(
    setAddNewCardModalData({
      title,
      callback,
      secondaryTitle
    })
  )
  store.dispatch(setAddNewCardModalOpen(true))
}

export const hideAddNewCardModal = () => {
  store.dispatch(
    setAddNewCardModalData({
      title: "Card Details",
      callback: () => {},
      secondaryTitle: null
    })
  )
  store.dispatch(setAddNewCardModalOpen(false))
}
