import { API } from "../../../../../utility/API"

//************************************//
export const _getBeneficiaries = (data, callback, callbackErr) => {
  // persistStore().purge()
  API.get(`trading/account/beneficiaries`, {
    params: data
  })
    .then(function ({ data, headers }) {
      callback({ data, page: headers["page"], total: headers["total"] })
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _deleteBeneficiary = (id, callback, callbackErr) => {
  // persistStore().purge()
  API.delete(`trading/account/beneficiaries/${id}`)
    .then(function ({ data, headers }) {
      callback({ data })
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _activateBeneficiary = (id, data, callback, callbackErr) => {
  API.patch(`trading/account/beneficiaries/${id}/activate`, data)
    .then((response) => {
      if (response.data) {
        callback(response.data)
      }
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _resendBeneficiaryActivationPin = (id, callback, callbackErr) => {
  API.patch(`trading/account/beneficiaries/${id}/resend_pin`)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getCoinData = (coinId, callback, callbackErr) => {
  // persistStore().purge()
  API.get(`trading/public/currencies/${coinId}`)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _validateBeneficiary = (data, callback, callbackErr) => {
  // persistStore().purge()
  API.post(`trading/account/beneficiaries`, data)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
