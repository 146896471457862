import { createSlice } from '@reduxjs/toolkit'

const deleteBeneficiaryModalState = createSlice({
  name: 'deleteBeneficiaryModalState',
  initialState: {
    open: false,
    data: {
      callback: () => {}
    }
  },
  reducers: {
    setDeleteBeneficiaryModalOpen: (state, action) => {
      state.open = action.payload
    },
    setDeleteBeneficiaryModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setDeleteBeneficiaryModalOpen, setDeleteBeneficiaryModalData } = deleteBeneficiaryModalState.actions

export default deleteBeneficiaryModalState.reducer