// ** React Imports
import React, { useState, useEffect } from "react"

// ** Redux Imports
import { useDispatch } from "react-redux"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Component Imports
import TradingChart from "../../old-components/TradingChart"
import ChartTypeDropdown from "./components/ChartTypeDropdown"
import TimeframeDropdown from "./components/TimeframeDropdown"

// ** Icons Imports
import { LuCandlestickChart } from "react-icons/lu"
import { ReactComponent as IndicatorIcon } from "../../../../assets/icons/imported/indicator.svg"

// ** Utils Imports
import { changeResolution } from "../../redux/slices/klineSlice"
import { chartTypeToNumber, periodsMapNumber } from "../../utility/helpers"
import { triggerTechnicalIndicatorDialog } from "../../../../utility/tradingview/helpers"

// ** MUI Imports
import Tooltip from "@mui/material/Tooltip"
import { useMediaQuery } from "@mui/material"

function DesktopChart({ hideLeftToolbarByDefault = false }) {
  // ** Media Queries
  const isMobileView = useMediaQuery("(max-width:600px)")

  // ** Redux
  const dispatch = useDispatch()

  const CHANGE_KLINE_RESOLUTION = "CHANGE_KLINE_RESOLUTION"
  const [timeframe, setTimeframe] = useState({
    label: "4H",
    value: periodsMapNumber["4h"]
  })
  const [chartType, setChartType] = useState({
    label: "candle",
    value: chartTypeToNumber("candle"),
    icon: <LuCandlestickChart size={20} />
  })

  const handleClick = (el) => {
    console.log(el)
    setTimeframe(el)
    dispatch(changeResolution(timeframe.value))
    dispatch({ type: CHANGE_KLINE_RESOLUTION })
  }
  
  // useEffect(() => {
  //   dispatch(changeResolution(timeframe.value))
  //   dispatch({ type: CHANGE_KLINE_RESOLUTION })
  // }, [timeframe])

  // ** Constants
  const timeframes = [
    { label: "1m", value: periodsMapNumber["1"] },
    { label: "15m", value: periodsMapNumber["15m"] },
    { label: "1H", value: periodsMapNumber["1h"] },
    { label: "4H", value: periodsMapNumber["4h"] }
  ]

  return (
    <div className={styles.container}>
      {/* Chart Header */}
      <div className={styles.headerContainer}>
        {/* Timeframes */}
        {timeframes.map((el) => (
          <button
            className={`${styles.timeframeButton} ${
              timeframe.label === el.label && styles.selectedTimeframe
            }`}
            onClick={() => handleClick(el)}
          >
            {el.label}
          </button>
        ))}

        {/* Remaining Timeframes Dropdown */}
        <TimeframeDropdown timeframe={timeframe} setTimeframe={setTimeframe} />
 
        <div
          className={`${styles.divider} ${styles.technicalIndicatorDivider}`}
        />

        {/* Technical Indicator Button */}
        <Tooltip
          arrow
          enterDelay={0}
          placement="top"
          title="Technical Indicator"
        >
          <button
            className={styles.indicatorButton}
            onClick={triggerTechnicalIndicatorDialog}
          >
            <IndicatorIcon width={25} />
          </button>
        </Tooltip>

        <div className={styles.divider} />

        {/* Chart Types */}
        <ChartTypeDropdown chartType={chartType} setChartType={setChartType} />
      </div>

      {/* Chart */}
      <TradingChart
        timeframe={timeframe.value}
        chartType={chartType.label}
        containerStyle={{ height: isMobileView ? 370 : 415 }}
        hideDefaultStyleContainerStyle
        hideLeftToolbarByDefault={hideLeftToolbarByDefault}
      />
    </div>
  )
}

export default DesktopChart
