import { setBeneficiary } from "../../../../redux/slices/beneficiarySlice"
import { store } from "../../../../redux/store"
import { setViewBeneficiaryModalOpen } from "../redux/slices/viewBeneficiaryModalSlice"

export const showViewBeneficiaryModal = (beneficiary) => {
  store.dispatch(setViewBeneficiaryModalOpen(true))
  store.dispatch(
    setBeneficiary({
      id: beneficiary.id,
      name: beneficiary.name,
      description: beneficiary.description,
      address: beneficiary.data.address,
      state: beneficiary.state,
      currency: beneficiary.currency
    })
  )
}
