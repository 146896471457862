import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { showErrorSnackbar, showSuccessSnackbar } from "../snackbar/helpers"

function CoinifyRedirect() {
  // ** Hooks
  const navigate = useNavigate()

  useEffect(() => {
    // Intercept the payment status
    const urlParams = new URLSearchParams(window.location.search)
    const status = urlParams.get("status")

    if (status) {
      window.parent.postMessage(
        {
          type: "event",
          event: "payment.payment-page.returned",
          context: { status }
        },
        "*"
      )
    }

    if (status === "success") {
      navigate("/account/wallet/spot/all")
      showSuccessSnackbar({ alertMessage: "Payment successful" })
      navigate("/account/wallet/spot/all")
    } else if (status === "failed") {
      showErrorSnackbar({ alertMessage: "Payment failed" })
    }
  }, [])
  return <div></div>
}

export default CoinifyRedirect
