import { createSlice } from '@reduxjs/toolkit'

const withdrawFormModalSlice = createSlice({
  name: 'withdrawFormModalState',
  initialState: {
    open: false,
    data: {
        callback: () => {}
    }
  },
  reducers: {
    setWithdrawFormModalOpen: (state, action) => {
      state.open = action.payload
    },
    setwithdrawFormModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setWithdrawFormModalOpen, setwithdrawFormModalData } = withdrawFormModalSlice.actions

export default withdrawFormModalSlice.reducer