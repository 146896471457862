import React, { useState, useEffect } from "react"

// styles
import Styles from "./styles.module.css"

// icons
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/imported/back-arrow.svg"
import { ReactComponent as CoinifyIcon } from "../../../../assets/icons/imported/coinify.svg"

// components
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"
import WenbitModal from "../../../../components/Modal/Modal"
import Button from "../../../../components/Button/Button"
import Alert from "../../../../components/Alert/Alert"
import Tooltip from "../../../../components/Tooltip/Tooltip"
import { toast } from "react-toastify"
import { FormattedMessage, injectIntl } from "react-intl"

// helpers
import { formatNumber } from "../../../helpers/formatNumber"

// redux
import { setConfirmConvertModalData } from "../../convert/redux/confirmConvertModalSlice"
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarData,
  setSnackbarOpen
} from "../../../snackbar/redux/snackbarSlice"
import { _previewConversion, _convert } from "../../../convert/redux/actions"
import {
  setSuccessConvertModalOpen,
  setSuccessConvertModalData
} from "../../convert/redux/successConvertSlice"
import { hideCoinifyConfirmPaymentModal } from "./helpers"
import {
  _confirmCoinifyOrderRequest,
  _createCoinifyOrderRequest,
  _getCoinifyExchangeRate
} from "./redux/actions"
import { Box, Modal } from "@mui/material"
import { setCoinifyConfirmPaymentModalData } from "./redux/slices/coinifyConfirmPaymentSlice"

import ConfirmPaymentLoadingShimmer from "./components/ConfirmPaymentLoadingShimmer"
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../snackbar/helpers"
import { openCoinifyRedirectAlertModal } from "../coinify-redirect-alert/helpers"

function ConfirmCoinifyPaymentModal() {
  // ** Constants
  const timer = 120

  // ** States
  const modalOpen = useSelector(
    (state) => state.coinifyConfirmPaymentState.open
  )
  const modalData = useSelector(
    (state) => state.coinifyConfirmPaymentState.data
  )
  const [paymentModalData, setPaymentModalData] = useState({
    open: false,
    src: null
  })

  const [coinifyOrderDetails, setCoinifyOrderDetails] = useState(null)

  const [createOrderLoading, setCreateOrderLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)

  const theme = useSelector((state) => state.theme.value)
  const [timerStarted, setTimerStarted] = useState(true)
  const [countdown, setCountDown] = useState(timer)
  const [clicked, setClicked] = useState(false)

  const handleConfirm = () => {
    setConfirmLoading(true)
    setTimerStarted(false)
    _confirmCoinifyOrderRequest(
      {
        redirect_url:
          process.env.NODE_ENV === "production"
            ? "https://exchange-stage.wenbit.com/coinify-redirect"
            : `${window.location.origin}/coinify-redirect`
      },
      (data) => {
        setConfirmLoading(false)
        // -- IFRAME METHOD --
        // setPaymentModalData({
        //   open: true,
        //   src: data.transfer_in.details.redirect_url
        // })
        // -------------------
        // -- SAME WINDOW METHOD --
        // window.location.replace(data.transfer_in.details.redirect_url)
        // ------------------------
        // -- WINDOW POPUP METHOD --
        // const url = data.transfer_in.details.redirect_url
        // const windowFeatures =
        //   "location=no,height=600,width=800,scrollbars=yes,status=yes"
        // window.open(url, "_blank", windowFeatures)
        // -------------------------
        hideCoinifyConfirmPaymentModal()
        openCoinifyRedirectAlertModal({
          redirectUrl: data.transfer_in.details.redirect_url,
          timer: countdown
        })
      },
      (err) => {
        setConfirmLoading(false)
      }
    )
  }

  const handleOnMessage = (e) => {
    if (e.data.source !== "react-devtools-content-script") {
      console.log(e.data)
    }
    if (e.data?.context?.status === "success") {
      hideCoinifyConfirmPaymentModal()
      setPaymentModalData({ open: false, src: paymentModalData.src })
      showSuccessSnackbar({ alertMessage: "Payment successful" })
    }
    if (e.data?.context?.status === "failed") {
      hideCoinifyConfirmPaymentModal()
      setPaymentModalData({ open: false, src: paymentModalData.src })
      showSuccessSnackbar({ alertMessage: "Payment failed" })
    }
  }

  const handleRefresh = () => {
    setRefreshLoading(true)
    setClicked(true)
    setTimerStarted(true)
    setCountDown(timer)
    _getCoinifyExchangeRate(
      { base_unit: modalData.fiatCoin.id, quote_unit: modalData.cryptoCoin.id },
      (data) => {
        setRefreshLoading(false)
        setCoinifyConfirmPaymentModalData({
          ...modalData,
          exchange_rate: data.quote_amount
        })
      },
      (err) => setRefreshLoading(false)
    )
    // _previewConversion(
    //   {
    //     from: fromCurrency.id,
    //     to: toCurrency.id,
    //     volume: fromValue
    //   },
    //   (data) => {
    //     setCountDown(timer)
    //     setTimerStarted(true)
    //     dispatch(setConfirmConvertModalData(data))
    //     setClicked(false)
    //     // console.log(data)
    //   },
    //   (error) => {
    //     setCountDown(timer)
    //     // handleClose()
    //     dispatch(setSnackbarOpen(true))
    //     dispatch(
    //       setSnackbarData({
    //         alertMessage: error.errors[0].length ? (
    //           <FormattedMessage id={error.errors[0]} />
    //         ) : (
    //           "Unknown Error"
    //         ),
    //         severity: "error",
    //         //buttonMessage: 'See order',
    //         callback: () => {},
    //         //actionType: 'link', // link - close
    //         //linkTo: '/account/orders/open',
    //         position: "topRight"
    //       })
    //     )
    //   }
    // )
  }

  const handlecreateOrderRequest = () => {
    setCreateOrderLoading(true)
    _createCoinifyOrderRequest(
      {
        amount: modalData?.fromValue,
        buy_unit: modalData?.cryptoCoin?.id,
        sell_unit: modalData?.fiatCoin?.id
      },
      (data) => {
        setCreateOrderLoading(false)
        setCoinifyOrderDetails(data)
      },
      (err) => {
        hideCoinifyConfirmPaymentModal()
        setCreateOrderLoading(false)
        showErrorSnackbar({ alertMessage: err.errors[0] })
      }
    )
  }

  useEffect(() => {
    if (countdown > 0) {
      if (timerStarted) {
        const timerId = setTimeout(() => {
          setCountDown(countdown - 1)
        }, 1000)
        return () => clearTimeout(timerId)
      }
    } else if (countdown === 0) {
      setTimerStarted(false)
    }
  }, [timerStarted, countdown])

  useEffect(() => {
    // Get order fees on modal open
    if (modalOpen) {
      handlecreateOrderRequest()
    } else {
      setCoinifyOrderDetails(null)
    }
  }, [modalOpen])

  useEffect(() => {
    // when the user proceeds to pay
    if (paymentModalData.open) {
      // add a windown post message event listener
      window.addEventListener("message", handleOnMessage)

      return () => {
        window.removeEventListener("message", handleOnMessage)
      }
    }
  }, [paymentModalData])

  // ** Start the timer when the modal opens
  useEffect(() => {
    if (modalOpen) {
      setTimerStarted(true)
      setCountDown(timer)
    }
  }, [modalOpen])

  return (
    <WenbitModal
      open={modalOpen}
      onClose={(e) => {
        hideCoinifyConfirmPaymentModal()
        setTimerStarted(false)
        setCountDown(timer)
      }}
      title="Confirm"
      showCloseButton={true}
      render={
        createOrderLoading
          ? () => <ConfirmPaymentLoadingShimmer />
          : () => (
              <div>
                <div className={Styles.convertRow}>
                  <div>
                    <img src={modalData?.fiatCoin?.icon_url} />
                    <span>
                      {coinifyOrderDetails?.transfer_in.receive_amount}
                    </span>
                  </div>
                  <span>{modalData?.fiatCoin?.name}</span>
                </div>

                <BackArrowIcon
                  color="var(--primary-color)"
                  style={{
                    transform: "rotateZ(-90deg) translateY(7.5px)"
                  }}
                  height={12.5}
                  width={12.5}
                />

                <div className={Styles.convertRow}>
                  <div>
                    <img src={modalData?.cryptoCoin?.icon_url} />
                    <span>
                      {/* {parseFloat(modalData.total) === parseFloat(fromValue)
                  ? modalData.amount
                  : modalData.total} */}
                      {coinifyOrderDetails?.transfer_out.receive_amount}
                    </span>
                  </div>
                  <span>{modalData?.cryptoCoin?.name}</span>
                </div>

                {/* convert information */}
                {refreshLoading ? (
                  <ShimmerLine
                    height="197px"
                    width="100%"
                    style={{ margin: "25px 0" }}
                  />
                ) : (
                  <div
                    className={Styles.convertInfoContainer}
                    style={{
                      backgroundColor:
                        theme === "light" && "var(--secondary-color)"
                    }}
                  >
                    <div className={Styles.infoRowContainer}>
                      <span>
                        Rate
                        <div className={Styles.infoIconDiv}>
                          <Tooltip
                            text="Heads up! The rate is subject to market conditions and may differ from the spot rate. The price can always change based on the latest data."
                            color="var(--primary-color)"
                          />
                        </div>
                      </span>
                      <span>
                        1{" "}
                        {modalData?.fiatCoin?.id
                          ? modalData?.fiatCoin?.id.toUpperCase()
                          : ""}{" "}
                        ={" "}
                        {/* {parseFloat(modalData.exchangeRate) === parseFloat(fromValue)
                  ? modalData.amount / modalData.total + " "
                  : modalData.total / modalData.amount + " "} */}
                        {coinifyOrderDetails?.exchange_rate &&
                          (1 / coinifyOrderDetails?.exchange_rate).toFixed(
                            coinifyOrderDetails?.exchange_rate?.toString()
                              .length
                          )}{" "}
                        {modalData?.cryptoCoin?.id
                          ? modalData?.cryptoCoin?.id.toUpperCase()
                          : ""}
                      </span>
                    </div>

                    <div className={Styles.infoRowContainer}>
                      <span>Inverse Rate</span>
                      <span>
                        1{" "}
                        {modalData?.cryptoCoin?.id
                          ? modalData?.cryptoCoin?.id.toUpperCase()
                          : ""}{" "}
                        =
                        {/* {formatNumber(fromValue / modalData.total, 8)}{" "} */}
                        {coinifyOrderDetails?.exchange_rate}
                        {modalData?.fiatCoin?.id
                          ? modalData?.fiatCoin?.id.toUpperCase()
                          : ""}
                      </span>
                    </div>

                    <div className={Styles.infoRowContainer}>
                      <span>Payment Method</span>
                      <div className={Styles.coinifyIcon}>
                        <CoinifyIcon width={13.75} />
                        <strong>Coinify</strong>
                      </div>
                    </div>

                    <div className={Styles.infoRowContainer}>
                      <span>
                        Transaction Fees
                        {/* <div className={Styles.infoIconDiv}>
                  <Tooltip
                    text="The rate here is the final rate, with no extra transaction fees. No surprises or hidden costs here!"
                    color="var(--primary-color)"
                  />
                </div> */}
                      </span>
                      <span>
                        {coinifyOrderDetails?.transfer_in.total_fee}{" "}
                        {modalData.fiatCoin?.id.toUpperCase()}
                      </span>
                    </div>

                    <div className={Styles.infoRowContainer}>
                      <span>You will receive</span>
                      <span>
                        {/* {parseFloat(modalData.total) === parseFloat(fromValue)
                  ? modalData.amount
                  : modalData.total} */}
                        {coinifyOrderDetails?.transfer_out.receive_amount}{" "}
                        {modalData?.cryptoCoin?.id.toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}

                {/* QUOTE EXPIRED */}
                {countdown === 0 && (
                  <Alert
                    alertText="Quote expired. Please request a new quote."
                    showButton={true}
                    buttonText="Refresh"
                    icon={
                      <Tooltip
                        color="var(--primary-color)"
                        text="Market prices might have changed! Refresh to get an accurate quote."
                      />
                    }
                    buttonStyle={{
                      padding: "0px 9px"
                    }}
                    containerStyle={{ margin: "14px 0", borderRadius: 10 }}
                    buttonOnClick={handleRefresh}
                  />
                )}

                <Button
                  onClick={handleConfirm}
                  disabled={!timerStarted}
                  isLoading={confirmLoading}
                  text={timerStarted ? `Confirm ${countdown}s` : "Confirm"}
                />

                <Modal
                  open={paymentModalData.open}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Box
                    sx={{
                      width: "90%",
                      maxWidth: "1200px",
                      height: "75%",
                      maxHeight: "900px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      boxShadow: 24
                    }}
                  >
                    <iframe
                      src={paymentModalData.src}
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        outline: "none",
                        padding: 4,
                        backgroundColor: "var(--secondary-color)",
                        borderRadius: "10px"
                      }}
                    />
                  </Box>
                </Modal>
              </div>
            )
      }
    />
  )
}

export default injectIntl(ConfirmCoinifyPaymentModal)
