import { store } from "../../../redux/store"
import {
  setSelectAvatarModalData,
  setSelectAvatarModalOpen
} from "./redux/selectAvatarModalSlice"

export const showSelectAvatarModal = () => {
  store.dispatch(
    setSelectAvatarModalData({
      title: "Select Avatar"
    })
  )
  store.dispatch(setSelectAvatarModalOpen(true))
}

export const hideSelectAvatarModal = () => {
  store.dispatch(setSelectAvatarModalOpen(false))
}
