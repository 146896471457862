import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
  name: "userData",
  initialState: {
    value: {},
    wallets: [],
    activities: []
  },
  reducers: {
    setUser: (state, payload) => {
      // console.log(payload.payload)
      state.value = payload.payload
    },
    logoutUser: (state, payload) => {
      state.value = {}
      state.wallets = {}
      state.activities = {}
    },
    setWallets: (state, payload) => {
      state.wallets = payload.payload
    },
    setActivities: (state, payload) => {
      state.activities = payload.payload
    }
  }
})

export const { setUser, logoutUser, setWallets, setActivities } =
  userSlice.actions

export default userSlice.reducer
