// ** React Imports
import React from 'react'

// ** MUI Imports
import { Box, IconButton, Dialog as MUIDialog } from '@mui/material'
import { IoClose } from 'react-icons/io5'

function Dialog({ open, setOpen, fullScreen, children, ...rest }) {
  // ** Handlers
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <MUIDialog
      open={open}
      fullScreen={fullScreen}
      {...rest}
      sx={{ '& .MuiPaper-root': { bgcolor: 'var(--secondary-color)', p: '32px' } }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <IconButton onClick={handleClose} sx={{ h: '30px !important', w: '30px !important' }}>
          <IoClose
            style={{
              width: '23px !important',
              height: '23px !important',
              color: 'var(--gray-color)'
            }}
          />
        </IconButton>
      </Box>
      {children}
    </MUIDialog>
  )
}

export default Dialog
