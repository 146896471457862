import { createSlice } from "@reduxjs/toolkit"

const fundsSlice = createSlice({
  name: "funds",
  initialState: {
    data: {
      total_funds: null,
      total_balance_btc: null,
      total_deposits: null,
      total_withdrawals: null
    }
  },
  reducers: {
    setFunds: (state, action) => {
      state.data.total_funds = action.payload
    },
    setBtcBalance: (state, action) => {
      state.data.total_balance_btc = action.payload
    },
    setDeposits: (state, action) => {
      state.data.total_deposits = action.payload
    },
    setWithdrawals: (state, action) => {
      state.data.total_withdrawals = action.payload
    },
    resetFundsData: (state, payload) => {
      state.data = {
        total_funds: null,
        total_balance_btc: null,
        total_deposits: null,
        total_withdrawals: null
      }
    }
  }
})

export const {
  setFunds,
  setBtcBalance,
  setWithdrawals,
  setDeposits,
  resetFundsData
} = fundsSlice.actions

export default fundsSlice.reducer
