import React from "react"

// react router
import { Link } from "react-router-dom"

// icons
import { ReactComponent as RightArrowIcon } from "../../../../assets/icons/imported/right-arrow.svg"
import { ReactComponent as AddUserIcon } from "./assets/add-user-icon.svg"
import { ReactComponent as WalletIcon } from "./assets/wallet-icon.svg"
import { ReactComponent as GraphIcon } from "./assets/graph-icon.svg"
import { ReactComponent as CreditCardIcon } from "./assets/credit-card-icon.svg"

// styles
import Styles from "./gettingStarted.module.css"

// components
import Card from "./Card"
import SectionHeader from "../../../../components/SectionHeader/SectionHeader"

function GettingStarted() {
  return (
    <div className={Styles.container}>
      <SectionHeader
        sectionTitle="Create Profile"
        title="Easy way to get started"
        description="We see you've got an eye for potential, and we're ready to help you
        reach new heights in mere minutes. WenBit is your golden ticket to the
        journey of a lifetime - are you ready to take the leap? Register now and
        brace yourself for an unparalleled investment experience."
      />
      <div className={Styles.startTradingLinks}>
        <Link to="/markets">Start Trading</Link>
        <div className={Styles.arrowIconContainer}>
          <RightArrowIcon />
        </div>
      </div>
      <div className={Styles.gridContainer}>
        <Card
          title="Step 1: Sign Up"
          icon={<AddUserIcon />}
          description="Join the ranks of our elite 
trading community."
        />
        <Card
          title="Step 2: Verify"
          icon={<CreditCardIcon />}
          description="Keep our security team happy. 
We want to make sure you’re elite."
        />
        <Card
          title="Step 3: Fund"
          icon={<WalletIcon />}
          description="Beef up your wallet."
        />
        <Card
          title="Step 4: Trade"
          icon={<GraphIcon />}
          description="Take on the market like a boss."
        />
      </div>
    </div>
  )
}

export default GettingStarted
