// ** React Imports
import React from "react"
import { useNavigate } from "react-router-dom"

// ** Styles Imports
import Styles from "./kycReminderModal.module.css"

// ** Assets Imports
import kycReminderIcon from "../../../assets/icons/imported/kyc-reminder.svg"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Helpers Imports
import { closeKYCReminderModal } from "./helpers"

// ** Components Imports
import Button from "../../../components/Button/Button"
import WenbitModal from "../../../components/Modal/Modal"

function KYCReminderModal() {
  // ** Hooks
  const navigate = useNavigate()

  // ** States
  const modalOpen = useSelector((state) => state.kycReminderModalState.open)

  // ** Handlers
  const handleKYCRedirect = () => {
    closeKYCReminderModal()
    navigate("/kyc")
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        modalWidth={370}
        onClose={closeKYCReminderModal}
        lineHeaderColor="var(--warning-color)"
        render={() => (
          <div className={Styles.bodyContainer}>
            <img src={kycReminderIcon} width={150} />
            <h3>Verify your KYC</h3>
            <p>
              Please complete the KYC verification process before proceeding
              with deposit, withdraw or convert. It's mandatory for security and
              compliance purposes.
            </p>
            <Button onClick={handleKYCRedirect} text="Verify your KYC" />
          </div>
        )}
      />
    </>
  )
}

export default KYCReminderModal
