// ** Utils Imports
import {
  chartTypeToNumber,
  periodsMapNumber
} from "../../modules/exchange/utility/helpers"

export const triggerTechnicalIndicatorDialog = () => {
  if (window.tvWidget) {
    window.tvWidget.activeChart().executeActionById("insertIndicator")
  }
}

export const changeChartTimeframe = (timeframe) => {
  if (window.tvWidget) {
    console.log(timeframe)
    window.tvWidget.activeChart().setResolution(periodsMapNumber[timeframe])
  }
}

export const changeChartType = (type) => {
  if (window.tvWidget) {
    window.tvWidget.activeChart().setChartType(chartTypeToNumber(type))
  }
}

export const changeChartPair = (pair) => {
  if (window.tvWidget) {
    window.tvWidget.activeChart().setSymbol(pair, () => {})
  }
}

export const handleChartOnMessage = (e) => {
  const message = e.data

  if (typeof message !== "string") return

  // Trigger technical indicator
  if (message === "chart.indicator.trigger") {
    triggerTechnicalIndicatorDialog()
  }

  // Change timeframe
  if (message.includes("chart.timeframe.changeTo.")) {
    changeChartTimeframe(message.split(".")[3])
  }

  // Change chart type
  if (message.includes("chart.type.changeTo.")) {
    changeChartType(message.split(".")[3])
  }

  // Change chart pair
  if (message.includes("chart.pair.changeTo.")) {
    changeChartPair(message.split(".")[3])
  }
}
