// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Assets Imports
import stakingIllustrationDark from '../../assets/staking-dark.png'
import stakingIllustrationLight from '../../assets/staking-light.png'

// ** Redux Imports
import { useSelector } from 'react-redux'

function PageHeader() {
  // ** States
  const theme = useSelector(state => state.theme.value)

  return (
    <div className={styles.pageHeaderContainer}>
      {/* Left Side */}
      <div className={styles.pageHeaderLeft}>
        <h1>
          Maximize your returns: <br />
          Join our staking revolution
        </h1>
        <span className={styles.pageHeaderIndicatorText}>
          Earn passive income and secure the network with staking on WenBit
        </span>
      </div>

      {/* Right Side */}
      <div className={styles.pageHeaderRight}>
        <img
          alt='staking'
          src={theme === 'dark' ? stakingIllustrationDark : stakingIllustrationLight}
          className={styles.illustration}
        />
      </div>
    </div>
  )
}

export default PageHeader
