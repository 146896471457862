import { createSlice } from "@reduxjs/toolkit"

const selectPaymentMethodModalSlice = createSlice({
  name: "selectPaymentMethodModalState",
  initialState: {
    open: false,
    data: {
      callback: () => {},
      fiatCoin: null,
      cryptoCoin: null,
      fromValue: 0,
      toValue: 0,
      onChange: () => {},
      onConfirm: () => {}
    }
  },
  reducers: {
    setSelectPaymentMethodModalOpen: (state, action) => {
      state.open = action.payload
    },
    setSelectPaymentMethodModalData: (state, action) => {
      state.data = action.payload
    },
    clearSelectPaymentMethodModalData: (state, action) => {
      state.data = {
        callback: () => {},
        fiatCoin: null,
        cryptoCoin: null,
        fromValue: 0,
        toValue: 0,
        onChange: () => {},
        onConfirm: () => {}
      }
    }
  }
})

export const {
  setSelectPaymentMethodModalOpen,
  setSelectPaymentMethodModalData,
  clearSelectPaymentMethodModalData
} = selectPaymentMethodModalSlice.actions

export default selectPaymentMethodModalSlice.reducer
