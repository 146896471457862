import { setBackdropOpen } from "../../redux/slices/backdropSlice"
import { store } from "../../redux/store"

export const showBackdrop = () => {
  store.dispatch(setBackdropOpen(true))
}

export const hideBackdrop = () => {
  store.dispatch(setBackdropOpen(false))
}
