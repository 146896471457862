import { createSlice } from '@reduxjs/toolkit'

const warningSlice = createSlice({
  name: 'warningModalState',
  initialState: {
    open: false,
    data: {
        title: '',
        subtitle: '',
        callback: () => {}
    }
  },
  reducers: {
    setWarningModalOpen: (state, action) => {
      state.open = action.payload
    },
    setWarningModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setWarningModalOpen, setWarningModalData } = warningSlice.actions

export default warningSlice.reducer