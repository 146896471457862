import { createSlice } from '@reduxjs/toolkit'

const limitPriceSlice = createSlice({
  name: 'limitPrice',
  initialState: {
    value: ''
  },
  reducers: {
    setLimitPrice: (state, action) => {
      state.value = action.payload
    },
    clearLimitPrice: (state) => {
      state.value = ''
    },
  }
})

export const { setLimitPrice, clearLimitPrice } = limitPriceSlice.actions

export default limitPriceSlice.reducer