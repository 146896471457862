import React, { useState } from "react"

// styles
import Styles from "./SelectCoinInput.module.css"

// redux
import { useSelector } from "react-redux"
import { showSelectCoinModal } from "../../../modules/modals/select-currency/helpers"

// components
import CoinDisplay from "../../CoinDisplay/CoinDisplay"

// icons
import { ReactComponent as DropdownIcon } from "../../../assets/icons/imported/down-arrow.svg"

function SelectCoinInput({ coinId, onChange = () => {} }) {
  const currencies = useSelector((state) => state.currencies.value)

  const handleSelectCoin = () => {
    showSelectCoinModal({
      callback: (coin) => {
        onChange(coin)
      }
    })
  }

  return (
    <div className={Styles.selectedCoinContainer}>
      <span>Select coin</span>
      <div onClick={handleSelectCoin}>
        {coinId && (
          <CoinDisplay
            coinName={currencies.find((el) => el?.id === coinId)?.name}
            coinImgURL={currencies.find((el) => el?.id === coinId)?.icon_url}
          />
        )}
        <DropdownIcon color="var(--gray-color)" />
      </div>
    </div>
  )
}

export default SelectCoinInput
