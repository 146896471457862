import { API } from "../../../../../../utility/API"

//************************************//
export const _enableGoogleAuth = (data, callback, callbackErr) => {
  API.post(`authenticate/resource/otp/enable`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _withdrawRequest = (data, callback, callbackErr) => {
  API.post(`trading/account/withdraws_request`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _withdraw = (data, callback, callbackErr) => {
  API.post(`trading/account/withdraws`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // console.log('error', error)
      callbackErr(error.response.data)
    })
}
//************************************//
export const _generateCode = (callback, callbackErr) => {
  API.post(`authenticate/resource/otp/generate_qrcode`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
