import { store } from "../../../../redux/store"
import {
  setCoinifyConfirmPaymentModalOpen,
  setCoinifyConfirmPaymentModalData
} from "./redux/slices/coinifyConfirmPaymentSlice"

export const showCoinifyConfirmPaymentModal = ({
  fiatCoin = null,
  cryptoCoin = null,
  fromValue = 0,
  toValue = 0,
  exchangeRate = null
}) => {
  store.dispatch(
    setCoinifyConfirmPaymentModalData({
      fiatCoin,
      cryptoCoin,
      fromValue,
      toValue,
      exchangeRate
    })
  )
  store.dispatch(setCoinifyConfirmPaymentModalOpen(true))
}

export const hideCoinifyConfirmPaymentModal = () => {
  store.dispatch(setCoinifyConfirmPaymentModalOpen(false))
  store.dispatch(setCoinifyConfirmPaymentModalData({}))
}
