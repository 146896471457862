import { createSlice } from "@reduxjs/toolkit"

const AddNewCardModalSlice = createSlice({
  name: "addNewCardModalState",
  initialState: {
    open: false,
    data: {
      title: "Card Details",
      secondaryTitle: null,
      callback: () => {}
    }
  },
  reducers: {
    setAddNewCardModalOpen: (state, action) => {
      state.open = action.payload
    },
    setAddNewCardModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setAddNewCardModalOpen, setAddNewCardModalData } =
  AddNewCardModalSlice.actions

export default AddNewCardModalSlice.reducer
