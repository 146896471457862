import { createSlice } from '@reduxjs/toolkit'

const activateBeneficiaryModalState = createSlice({
  name: 'activateBeneficiaryModalState',
  initialState: {
    open: false,
    data: {
      callback: () => {}
    }
  },
  reducers: {
    setActivateBeneficiaryModalOpen: (state, action) => {
      state.open = action.payload
    },
    setActivateBeneficiaryModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setActivateBeneficiaryModalOpen, setActivateBeneficiaryModalData } = activateBeneficiaryModalState.actions

export default activateBeneficiaryModalState.reducer