// ** React Imports
import React, { useEffect, useState } from "react"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** Redux Imports
import { useSelector, useDispatch } from "react-redux"
import { _withdraw } from "../../security/Enable2FAModal/redux/actions"
import { _getActiveBeneficiaries } from "../../../profile/redux/actions"
import { setWithdrawFormModalOpen } from "../redux/withdrawFormModalSlice"

// ** Component Imports
import WenbitUserTab from "./components/WenbitUserTab"
import Button from "../../../../components/Button/Button"
import CryptoNetworkTab from "./components/CryptoNetworkTab"
import WenbitModal from "../../../../components/Modal/Modal"

function WithdrawFormModal() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const modalOpen = useSelector((state) => state.withdrawFormModalState.open)
  const [selectedTab, setSelectedTab] = useState("crypto") // crypto - user

  const handleClose = () => {
    dispatch(setWithdrawFormModalOpen(false))
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title="Move coins between wallets"
        render={() => (
          <>
            <div className={Styles.tabContainer}>
              <Button
                text="Crypto Network"
                type="tab"
                isTabSelected={selectedTab === "crypto"}
                onClick={() => setSelectedTab("crypto")}
              />
              <Button
                text="WenBit User"
                type="tab"
                isTabSelected={selectedTab === "user"}
                onClick={() => setSelectedTab("user")}
              />
            </div>
            {selectedTab === "user" && <WenbitUserTab />}
            {selectedTab === "crypto" && <CryptoNetworkTab />}
          </>
        )}
      />
    </>
  )
}

export default WithdrawFormModal
