import React from "react"

// react router
import { useNavigate, useLocation } from "react-router-dom"

// redux
import { useSelector } from "react-redux"

// assets
import Illustration from "../assets/images/login-signup-illustration.png"
import WenLogoDark from "../../../assets/icons/wen-logos/wen-header-dark.svg"
import WenLogoLight from "../../../assets/icons/wen-logos/wen-header-light.svg"

// styles
import Styles from "./userTabsNavigation.module.css"

// pages
import LoginTab from "../components/login/LoginTab"
import ResetPassword from "../components/ResetPassword"
import SignupTab from "../components/signup/SignupTab"
import CodeConfirmation from "../components/CodeConfirmation"
import EmailVerification from "../components/EmailVerification/EmailVerification"
import RegisterConfirm from "../components/RegisterConfirm/RegisterConfirm"
import PasswordRecoveryTab from "../components/passwordRecovery/PasswordRecoveryTab"
import EmailVerificationRequired from "../components/EmailVerificationRequired/EmailVerificationRequired"

function UserTabsNavigation() {
  const navigate = useNavigate()
  const location = useLocation()
  // console.log("EEE", location.pathname)
  const searchParams = new URLSearchParams(location.search)

  const theme = useSelector((state) => state.theme.value)

  return (
    <div className={Styles.pageContainer}>
      {/* illustration container */}
      <div className={Styles.illustrationContainer}>
        <img src={Illustration} />
      </div>

      {/* form container */}
      <div className={Styles.tabContainer} style={{ overflow: "hidden" }}>
        {/* logo */}
        <img
          src={theme === "dark" ? WenLogoDark : WenLogoLight}
          onClick={() => navigate("/trade")}
          style={{ cursor: "pointer" }}
        />

        {/* TABS DISPLAY */}
        {location.pathname === "/login" && <LoginTab />}
        {location.pathname === "/signup" && <SignupTab />}
        {location.pathname === "/recover-password" && <PasswordRecoveryTab />}
        {location.pathname === "/confirmation-code" && <CodeConfirmation />}
        {location.pathname === "/confirm-register" && <RegisterConfirm />}
        {location.pathname === "/accounts/password_reset" && <ResetPassword />}
        {location.pathname === "/email-verification-required" && (
          <EmailVerificationRequired />
        )}
        {location.pathname === "/accounts/confirmation" &&
        searchParams.get("confirmation_token") ? (
          <EmailVerification />
        ) : (
          () => navigate("not-found", { replace: true })
        )}
      </div>
    </div>
  )
}

export default UserTabsNavigation
