import React from "react"

// styles
import Styles from "./pageHeader.module.css"

function PageHeader({
  title = "",
  sectionTitle = "",
  description = "",
  size = "normal" // normal - small
}) {
  if (!title && !sectionTitle && !description) return null
  return (
    <section
      className={`${Styles.headerContainer} ${
        size === "small" ? Styles.smallContainer : ""
      }`}
    >
      {sectionTitle && <span>{sectionTitle}</span>}
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
    </section>
  )
}

export default PageHeader
