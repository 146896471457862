import { store } from '../../../../redux/store'
import { setStakingSubscribeModalData, setStakingSubscribeModalOpen } from './redux/subscribeStatkingModalSlice'

export const showStakingSubscribeModal = ({ title = 'Subscribe', coinId, selectedDuration = null }) => {
  store.dispatch(
    setStakingSubscribeModalData({
      title: 'Subscribe'
    })
  )
  store.dispatch(setStakingSubscribeModalOpen(true))
}

export const hideStakingSubscribeModal = () => {
  store.dispatch(setStakingSubscribeModalOpen(false))
}
