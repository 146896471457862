import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

// styles
import Styles from "./disableSMSAuthModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { _getUserInfo } from "../../../../redux/actions"
import { setLoggedIn } from "../../../../redux/slices/loggedIn"
import { setUser, logoutUser } from "../../../user/redux/slices/userDataSlice"
import { clearBalances } from "../../../../redux/slices/balancesSlice"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"

// utils
import { FormattedMessage, injectIntl } from "react-intl"
import { closeDisableSMSAuthModal } from "./helpers"
import { hideEmail } from "../../../helpers/HideEmail"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { _sendOtp, _verifyOtp } from "./redux/actions"

const inputStyle = {
  marginBottom: 22
}

// schema
const disableSMSAuthSchema = Yup.object().shape({
  smsCode: Yup.string()
    .required("SMS code is required")
    .matches(/^\d{5}$/, "SMS code must be 5 digits")
    .test("is-number", "SMS code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    }),
  emailCode: Yup.string()
    .required("Email code is required")
    .matches(/^\d{5}$/, "Email code must be 5 digits")
    .test("is-number", "Email code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    })
})

function DisableSMSAuthModal() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(disableSMSAuthSchema),
    mode: "onSubmit"
  })
  const dispatch = useDispatch()
  const modalOpen = useSelector((state) => state.disableSMSAuthModalState.open)
  const modalData = useSelector((state) => state.disableSMSAuthModalState.data)
  const userData = useSelector((state) => state.userData.value)

  const [loading, setLoading] = useState(false)
  const [smsCodeTimer, setSmsCodeTimer] = useState(null)
  const [emailCodeTimer, setEmailCodeTimer] = useState(null)

  const handleGetSMSCode = () => {
    _sendOtp(
      {
        verification_type: "phone",
        action_type: "disable"
      },
      () => {
        setSmsCodeTimer(180) // 3 minutes in seconds
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  const handleGetEmailCode = () => {
    _sendOtp(
      {
        verification_type: "email",
        action_type: "disable"
      },
      () => {
        setEmailCodeTimer(180) // 3 minutes in seconds
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  const onSubmit = (formData) => {
    setLoading(true)
    _verifyOtp(
      {
        email_otp: formData?.emailCode,
        phone_otp: formData?.smsCode
      },
      () => {
        _getUserInfo(
          (data) => {
            setLoading(false)
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "SMS Authentication Updated",
                severity: "success",
                callback: () => {},
                position: "topRight"
              })
            )
            closeDisableSMSAuthModal()
            dispatch(setLoggedIn(true))
            dispatch(setUser(data))
            setLoading(false)
            if (data.errors) {
              dispatch(logoutUser())
              dispatch(setLoggedIn(false))
              dispatch(clearBalances())
              setLoading(false)
            }
          },
          (error) => {
            closeDisableSMSAuthModal()
            setLoading(false)
            if (error?.errors) {
              dispatch(logoutUser())
              dispatch(clearBalances())
              dispatch(setLoggedIn(false))
            }
          }
        )
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  useEffect(() => {
    if (modalOpen) {
      reset()
      setSmsCodeTimer(null)
      setEmailCodeTimer(null)
    }
  }, [modalOpen])

  useEffect(() => {
    let interval = null
    if (smsCodeTimer !== null && smsCodeTimer > 0) {
      interval = setInterval(() => {
        setSmsCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (smsCodeTimer === 0) {
      setSmsCodeTimer(null)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [smsCodeTimer])

  useEffect(() => {
    let interval = null
    if (emailCodeTimer !== null && emailCodeTimer > 0) {
      interval = setInterval(() => {
        setEmailCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (emailCodeTimer === 0) {
      setEmailCodeTimer(null)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [emailCodeTimer])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        onClose={closeDisableSMSAuthModal}
        title={modalData?.title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            inputLabel="SMS Code"
            indicatorLabel={
              userData?.phones?.length &&
              `Enter the 5-digit code sent to the phone number ends with ** ${userData.phones[0]?.number?.slice(
                -2
              )}`
            }
            showBorder
            containerStyle={{ ...inputStyle }}
            icon={
              <Button
                variant="link"
                text={
                  smsCodeTimer !== null
                    ? `Resend in ${smsCodeTimer}s`
                    : "Get Code"
                }
                onClick={smsCodeTimer === null ? handleGetSMSCode : null}
                disabled={smsCodeTimer !== null}
              />
            }
            {...register("smsCode")}
            error={errors.smsCode}
            maxLength={5}
          />

          <Input
            inputLabel="Email Verification Code"
            indicatorLabel={
              userData?.email &&
              `Enter the 5-digit code sent to ${hideEmail(userData.email)}`
            }
            showBorder
            containerStyle={{ ...inputStyle }}
            icon={
              <Button
                variant="link"
                text={
                  emailCodeTimer !== null
                    ? `Resend in ${emailCodeTimer}s`
                    : "Get Code"
                }
                onClick={emailCodeTimer === null ? handleGetEmailCode : null}
                disabled={emailCodeTimer !== null}
              />
            }
            {...register("emailCode")}
            error={errors.emailCode}
            maxLength={5}
          />

          <Link to="/login">Security verification unavailable?</Link>

          <Button
            text="Submit"
            type="submit"
            style={{
              marginTop: 20
            }}
            isLoading={loading}
          />
        </form>
      </WenbitModal>
    </>
  )
}

export default injectIntl(DisableSMSAuthModal)
