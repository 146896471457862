import React from "react"

// styles
import Styles from "./mobileApplication.module.css"
import PageHeader from "../../components/PageHeader/PageHeader"

function MobileApplication() {
  document.title = "Wenbit: Resources"

  return (
    <div className={Styles.pageContainer}>
      <PageHeader
        sectionTitle="Resources"
        title="Mobile application"
        description="You can access WenBit on your phone! That's right, you don't have to always be tied to your desktop. With our mobile app, you can invest in more and monitor your bits from anywhere, at any time. Don't let your investments hold you back - take them with you wherever you go."
      />

      <div className={Styles.videoOuterContainer}>
        <div className={Styles.videoContainer}>
          <iframe
            width="100%"
            height="fit-content"
            src="https://www.youtube.com/embed/ScMzIvxBSi4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default MobileApplication
