import React from "react"

// redux
import { useSelector } from "react-redux"

// assets
import LogoDark from "../../../assets/icons/wen-logos/wen-header-dark.svg"
import LogoLight from "../../../assets/icons/wen-logos/wen-header-light.svg"

function WenbitLogo({ size = 120, style, ...rest }) {
  const theme = useSelector((state) => state.theme.value)

  return (
    <img
      src={theme === "dark" ? LogoDark : LogoLight}
      alt="Wenbit"
      style={{
        width: size,
        ...style
      }}
      {...rest}
    />
  )
}

export default WenbitLogo
