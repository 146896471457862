import { store } from "../../redux/store"

import { setSnackbarOpen, setSnackbarData } from "./redux/snackbarSlice"

export const showSuccessSnackbar = ({
  alertMessage = "",
  actionType = "close",
  buttonMessage = "",
  linkTo = "",
  position = "topRight"
}) => {
  store.dispatch(
    setSnackbarData({
      alertMessage,
      buttonMessage,
      actionType, // link - close
      linkTo, // if action type is link
      severity: "success", // error - warning - info - success
      position // topRight - bottomLeft
    })
  )
  store.dispatch(setSnackbarOpen(true))
}

export const showErrorSnackbar = ({
  alertMessage = "",
  actionType = "close",
  buttonMessage = "",
  linkTo = "",
  position = "topRight"
}) => {
  store.dispatch(
    setSnackbarData({
      alertMessage,
      buttonMessage,
      actionType, // link - close
      linkTo, // if action type is link
      severity: "error", // error - warning - info - success
      position // topRight - bottomLeft
    })
  )
  store.dispatch(setSnackbarOpen(true))
}

export const showWarningSnackbar = ({
  alertMessage = "",
  actionType = "close",
  buttonMessage = "",
  linkTo = "",
  position = "topRight"
}) => {
  store.dispatch(
    setSnackbarData({
      alertMessage,
      buttonMessage,
      actionType, // link - close
      linkTo, // if action type is link
      severity: "warning", // error - warning - info - success
      position // topRight - bottomLeft
    })
  )
  store.dispatch(setSnackbarOpen(true))
}

export const showInfoSnackbar = ({
  alertMessage = "",
  actionType = "close",
  buttonMessage = "",
  linkTo = "",
  position = "topRight"
}) => {
  store.dispatch(
    setSnackbarData({
      alertMessage,
      buttonMessage,
      actionType, // link - close
      linkTo, // if action type is link
      severity: "info", // error - warning - info - success
      position // topRight - bottomLeft
    })
  )
  store.dispatch(setSnackbarOpen(true))
}
