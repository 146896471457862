import React from "react"

// redux
import { useSelector } from "react-redux"

// packages
import Shimmer from "react-shimmer-effect"

function ShimmerCircle({ size = "25px", opacity, style }) {
  const theme = useSelector((state) => state.theme.value)

  const StyleSheet = {
    circle: {
      height: size,
      width: size,
      borderRadius: "50%"
    }
  }

  return (
    <div
      style={{
        opacity: opacity ? opacity : theme === "dark" ? 0.5 : 0.8,
        ...style
      }}
    >
      <Shimmer>
        <div style={StyleSheet.circle} />
        {/* <div style={StyleSheet.line} /> */}
      </Shimmer>
    </div>
  )
}

export default ShimmerCircle
