import { API } from "../../../../../utility/API"

//************************************//
export const _getCoinData = (coinId, callback, callbackErr) => {
  // persistStore().purge()
  API.get(`trading/public/currencies/${coinId}`)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getCurrencies = (callback, data) => {
  API.get(`/trading/public/currencies`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {})
}
//************************************//
export const _requestWithdraw = (params, callback, callbackErr = {}) => {
  API.post(`trading/account/withdraws_request`, params)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _createWithdraw = (params, callback, callbackErr = {}) => {
  API.post(`account/withdraws`, params)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getWithdrawFee = (params, callback, callbackErr) => {
  API.post(`trading/account/withdraws/transaction_fee`, params)
    .then((response) => {
      callback(response.data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
