import { Blogs_API } from "../../../../utility/API"

//************************************//
export const _getTerms = (callback, callbackErr) => {
  Blogs_API.get(`public/view_terms`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
