import React, { useState } from "react"

// styles
import Styles from "./verificationForm.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../snackbar/redux/snackbarSlice"
import {
  _sendCode,
  _verify,
  _reSendCode,
  _initializeKyc,
  _addInfo
} from "../redux/actions"
// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// components
import FormRow from "../../../components/FormRow/FormRow"
import Input from "../../../components/Input/Input"
import CheckboxInput from "../../../components/CheckboxInput/CheckboxInput"
import Button from "../../../components/Button/Button"
import RequiredIndicator from "../../../components/RequiredIndicator/RequiredIndicator"

// schema
const formSchema = Yup.object().shape({
  industry: Yup.string().required("Required"),
  accountPurpose: Yup.string().required("Required"),
  expectedMonthlyVolume: Yup.string().required("Required"),
  sourceOfFunds: Yup.string().required("Required"),
  acceptTerms: Yup.boolean()
    .oneOf([true], "You must agree to the terms and conditions")
    .required("You must agree to the terms and conditions"),
  politicalPerson: Yup.boolean(),
  privacy: Yup.boolean()
    .oneOf([true], "You must agree to the privacy and policy")
    .required("You must agree to the privacy and policy"),
  risks: Yup.boolean()
    .oneOf([true], "You must understand the risks of Cryptocurrency")
    .required("You must understand the risks of Cryptocurrency")
})

function VerificationForm({ setStep }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onSubmit"
  })

  // hooks
  const dispatch = useDispatch()

  // states
  const [sourceOfFunds, setSourceOfFunds] = useState(null)
  const theme = useSelector((state) => state.theme.value)

  const onSubmit = (formData) => {
    _addInfo(
      {
        industry: formData.industry,
        account_opening_purpose: formData.accountPurpose,
        expected_monthly_volume: formData.expectedMonthlyVolume,
        source_of_funds: formData.sourceOfFunds,
        politically_exposed_person: formData.politicalPerson
      },
      (data) => {
        setStep(3)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: "Information Added Successfully",
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  return (
    <div className={Styles.formContainer}>
      <h3>KYC Process</h3>

      <div className={Styles.stepNumberContainer}>
        <div
          style={{
            backgroundColor: theme === "light" ? "white" : "black",
            color: theme === "light" ? "black" : "white"
          }}
        >
          2
        </div>
        <div>
          <span style={{ fontSize: 16 }}>Verification Form</span>
        </div>
      </div>
      <div className={Styles.formSectionTitle}>
        <h5>Additional Information</h5>
        <RequiredIndicator />
      </div>

      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 35 }}>
        <FormRow>
          <Input
            inputLabel="Industry of Work"
            placeholder="Finance"
            variant="secondary"
            {...register("industry")}
            error={errors.industry}
            required
          />
          <Input
            inputLabel="Account Purpose"
            placeholder="Trading"
            variant="secondary"
            {...register("accountPurpose")}
            error={errors.accountPurpose}
            required
          />
        </FormRow>

        <FormRow>
          <Input
            inputLabel="Expected Monthly Deposit Increase"
            placeholder="0 - 5,000"
            variant="secondary"
            {...register("expectedMonthlyVolume")}
            error={errors.expectedMonthlyVolume}
            required
          />
          <Input
            type="select"
            inputLabel="Source of Funds"
            onChange={(e, value) => {
              setValue("sourceOfFunds", value)
              trigger("sourceOfFunds")
              setSourceOfFunds(value)
            }}
            selectValue={sourceOfFunds}
            error={errors.sourceOfFunds}
            placeholder="Select source of funds"
            variant="secondary"
            selectOptions={[
              { value: "employment", label: "Employment" },
              { value: "pension", label: "Pension/Social Benefits" },
              { value: "savings", label: "Savings" },
              { value: "investment", label: "Investments" },
              { value: "scholarship", label: "Scholarship" },
              { value: "inheritance", label: "Inheritance" },
              { value: "sale", label: "Sale/Rental of Real Estate" }
            ]}
            required
          />
        </FormRow>

        <FormRow>
          <CheckboxInput
            checkboxId="political-person"
            labelFor="political-person"
            label="Are you a politically exposed person?"
            register={{ ...register("politicalPerson") }}
            error={errors.politicalPerson}
          />
          <CheckboxInput
            checkboxId="privacy"
            labelFor="privacy"
            label="I have read and agree to the privacy and policy"
            register={{ ...register("privacy") }}
            error={errors.privacy}
            required
          />
        </FormRow>
        <FormRow>
          <CheckboxInput
            checkboxId="terms"
            labelFor="terms"
            label="I have read and agree to the terms and conditions"
            register={{ ...register("acceptTerms") }}
            error={errors.acceptTerms}
            required
          />
          <CheckboxInput
            checkboxId="risks"
            labelFor="risks"
            label="I have read & understood the risks related to Cryptocurrency"
            register={{ ...register("risks") }}
            error={errors.risks}
            required
          />
        </FormRow>

        <Button
          text="Proceed"
          style={{ margin: "50px auto 0 auto" }}
          type="submit"
          width="200px"
        />
      </form>
    </div>
  )
}

export default VerificationForm
