const getOrderStateColor = (state = "") => {
  if (state === "Pending" || state === "In Progress")
    return "var(--warning-color)"
  if (state === "Executed" || state === "Completed" || state === "Verified")
    return "var(--success-color)"
  if (state === "Rejected" || state === "Cancelled")
    return "var(--danger-color)"
  return "var(--text-color)"
}

export default getOrderStateColor
