import React from 'react'

// styles
import Styles from './goBackHeader.module.css'

// MUI
import { IconButton } from '@mui/material'

// icons
import { ReactComponent as BackArrow } from '../../assets/icons/imported/back-arrow.svg'

function GoBackHeader({
  containerStyle,
  backIcon = <BackArrow width={20} height={20} color='var(--text-color)'/>,
  title,
  onBackClick = () => {}
}) {
  return (
    <div
      className={`${Styles.headerContainer}`}
      style={{...containerStyle}}
    >
      <IconButton
        aria-label="go-back"
        style={{color: 'var(--primary-color)'}}
        onClick={onBackClick}
      >
        {backIcon}
      </IconButton>
      
      <span className={Styles.title}>{title}</span>
    </div>
  )
}

export default GoBackHeader