import React from "react"

// styles
import Styles from "./sectionHeader.module.css"

function SectionHeader({ sectionTitle, title, description }) {
  return (
    <div className={Styles.container}>
      {sectionTitle && (
        <span className={Styles.sectionTitle}>{sectionTitle}</span>
      )}
      {title && <h2 className={Styles.title}>{title}</h2>}
      {description && <p className={Styles.description}>{description}</p>}
    </div>
  )
}

export default SectionHeader
