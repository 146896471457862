/**
 * Returns the number rounded to the nearest interval.
 * Example:
 *
 *   roundToNearest(1000.5, 1) // 1000
 *   roundToNearest(1000.5, 0.5)  // 1000.5
 *
 * @param {number} value    The number to round
 * @param {number} interval The numeric interval to round to
 * @return {number}
 */
export const roundToNearest = (value, interval) => {
  return Math.floor(value / interval) * interval
}

/**
 * Applies updates to the raw order data.
 * 
 * @param {Array} rawOrders - The existing raw orders.
 * @param {Array} newOrders - The new orders to apply.
 * @returns {Array} - The updated raw orders.
 */
export const applyUpdatesToRawData = (rawOrders, newOrders) => {
  // Convert rawOrders to a map for efficient updates
  const orderMap = new Map(rawOrders.map(order => [order[0], order[1]]))

  // Apply updates from newOrders
  for (const [price, volume] of newOrders) {
    if (volume === 0) {
      // Remove the order if volume is zero
      orderMap.delete(price)
    } else {
      // Add or update the order
      orderMap.set(price, volume)
    }
  }

  // Convert the map back to an array of orders
  return Array.from(orderMap).map(([price, volume]) => [price, volume])
}

/**
 * Sorts and filters order data.
 *
 * @param {Array} orders - The orders to be processed.
 * @param {number} lastTrade - The price of the last trade for filtering purposes.
 * @param {string} type - The type of orders ('asks' or 'bids').
 * @returns {Array} - The sorted and filtered orders.
 */
export const sortAndFilterOrders = (orders, lastTrade, type) => {
  // Define sorting logic
  const sortFunction = type === 'asks'
    ? (a, b) => a[0] - b[0] // For asks, sort by price in ascending order
    : (a, b) => b[0] - a[0] // For bids, sort by price in descending order

  // Sort the orders
  const sortedOrders = orders.sort(sortFunction)

  // If lastTrade is defined, filter the orders
  if (lastTrade !== undefined) {
    const threshold = 0.1 // Define a threshold, e.g., 10%
    return sortedOrders.filter(([price]) => {
      return type === 'asks'
        ? price >= lastTrade // For asks, filter out prices too high
        : price <= lastTrade // For bids, filter out prices too low
    })    
  }

  return sortedOrders
}


/**
 * Groups price levels by their price
 * Example:
 *
 *  groupByPrice([ [1000, 100], [1000, 200], [993, 20] ]) // [ [ 1000, 300 ], [ 993, 20 ] ]
 *
 * @param {number[][]} levels
 * @return {number[][]}
 */
export const groupByPrice = (levels) => {
  const groupedLevels = {}

  levels.forEach(level => {
    const [price, volume] = level
    // console.log(level)
    if (!groupedLevels[price]) {
      groupedLevels[price] = 0
    }
    groupedLevels[price] += parseFloat(volume)
  })
  // console.log(groupedLevels)
  return Object.entries(groupedLevels).map(([price, totalVolume]) => [parseFloat(price), parseFloat(totalVolume)])
}
export const groupByPricme = (levels) => {
  const grouped = levels.reduce((acc, level) => {
    const price = parseFloat(level[0])
    const size = parseFloat(level[1])

    if (acc[price]) {
      acc[price] += size
    } else {
      acc[price] = size
    }

    return acc
  }, {})

  return Object.entries(grouped).map(([price, size]) => [parseFloat(price), size])
}


/**
 * Group price levels by given ticket size. Uses groupByPrice() and roundToNearest()
 * Example:
 *
 * groupByTicketSize([ [1000.5, 100], [1000, 200], [993, 20] ], 1) // [[1000, 300], [993, 20]]
 *
 * @param {number[][]} levels
 * @param {number} ticketSize
 * @return {number[][]}
 */
export const groupByTicketSize = (levels, ticketSize) => {
  // console.log(groupByPrice(levels.map(level => [roundToNearest(level[0], ticketSize), level[1]])))
  return groupByPrice(levels.map(level => [roundToNearest(level[0], ticketSize), level[1]]))
}

export const formatNumber = (arg) => {
  return new Intl.NumberFormat('en-US').format(arg)
}


// import { createSlice, current } from "@reduxjs/toolkit"
// import { groupByTicketSize, applyUpdatesToRawData, sortAndFilterOrders } from "../helpers" // Assuming the helper functions are in this path

// const MAX_ORDER_BOOK_ENTRIES = 10 // Maximum number of entries in the order book
// const ORDERBOOK_LEVELS = 10 // rows count
// const MAX_RAW_ORDERS = 500
// const deffaultTicketSize = 0.001
// export const sortAsks = (asks) => {
//   return asks.sort((a, b) => Number(a[0]) - Number(b[0]))
// }
// export const sortBids = (bids) => {
//   return bids.sort((a, b) => Number(b[0]) - Number(a[0]))
// }

// const sliceArray = (data, index) => {
//   if (!data || data.length === 0) {
//     return []
//   }

//   return data.slice(0, index)
// }

// export const handleIncrementalUpdate = (depthOld, newLevel, type) => {
//   if (newLevel.length !== 2) {
//     return depthOld
//   }
//   const index = depthOld.findIndex(([price]) => +price === +newLevel[0])
//   if (index === -1 && +newLevel[1]) {
//     const data = [...depthOld, newLevel]
//     if (type === "asks") {
//       return sortAsks(data)
//     }
//     return sortBids(data)
//   }
//   const result = [...depthOld]
//   if (Number(newLevel[1]) !== 0) {
//     result[index] = newLevel
//   } else {
//     result.splice(index, 1)
//   }
//   return result
// }

// export const handleIncrementalUpdateArray = (depthOld, newLevels, type) => {
//   const prices = {}
//   for (let i = 0; i < newLevels.length; i += 1) {
//     prices[newLevels[i][0]] = newLevels[i][1]
//   }
//   const rest = depthOld.filter(([price]) => !prices[price])
//   const newData = newLevels.filter(([_, amount]) => Number(amount) !== 0)
//   const result = [...rest, ...newData]
//   if (type === "asks") {
//     return sortAsks(result)
//   }
//   return sortBids(result)
// }

// const addTotalSums = (orders) => {
//   const totalSums = []

//   return orders.map((order, idx) => {
//     const size = parseFloat(order[1])
//     if (typeof order[2] !== "undefined") {
//       return order
//     } else {
//       const updatedLevel = [...order]
//       const totalSum = idx === 0 ? size : size + totalSums[idx - 1]
//       updatedLevel[2] = totalSum
//       totalSums.push(totalSum)
//       return updatedLevel
//     }
//   })
// }

// const addDepths = (orders, maxTotal) => {
//   return orders.map((order) => {
//     const calculatedTotal = parseFloat(order[1])
//     const depth = (calculatedTotal / maxTotal) * 100
//     const updatedOrder = [...order]
//     updatedOrder[3] = depth
//     return updatedOrder
//   })
// }

// const getMaxTotalSum = (orders) => {
//   const totalSums = orders.map((order) => order[1])
//   return Math.max.apply(Math, totalSums)
// }

// // const removePriceLevel = (price, levels) => levels.filter(level => level[0] !== price)
// const removePriceLevel = (deltaLevelPrice, updatedLevels) => {
//   return updatedLevels.filter((level) => level[0] !== deltaLevelPrice)
// }
// const updatePriceLevel = (updatedLevel, levels) => {
//   return levels.map((level) => {
//     if (level[0] === updatedLevel[0]) {
//       level = updatedLevel
//     }
//     return level
//   })
// }

// const levelExists = (deltaLevelPrice, currentLevels) =>
//   currentLevels.some((level) => level[0] === deltaLevelPrice)

// const addPriceLevel = (deltaLevel, levels) => {
//   return [...levels, deltaLevel]
// }

// const applyDeltas = (currentLevels, orders) => {
//   let updatedLevels = currentLevels

//   orders.forEach((deltaLevel) => {
//     const deltaLevelPrice = deltaLevel[0]
//     const deltaLevelSize = parseFloat(deltaLevel[1])

//     // If new size is zero - delete the price level
//     // if (deltaLevelSize === 0) {
//     //     console.log(`Removing level: ${deltaLevelPrice}`)

//     //   updatedLevels = removePriceLevel(deltaLevelPrice, updatedLevels)
//     // } else {
//     // If the price level exists and the size is not zero, update it
//     if (levelExists(deltaLevelPrice, currentLevels)) {
//       updatedLevels = updatePriceLevel(deltaLevel, updatedLevels)
//     } else {
//       // If the price level doesn't exist in the orderbook and there are less than 25 levels, add it
//       if (updatedLevels.length < ORDERBOOK_LEVELS) {
//         updatedLevels = addPriceLevel(deltaLevel, updatedLevels)
//       } else {
//         // console.log("<<<<<<<<<<<<<<<<<<WARNING!!!>>>>>>>>>>>>>>>>>>")
//       }
//       // }
//     }
//   })
//   // console.log(updatedLevels)
//   return updatedLevels
// }

// export const sortOrders = (orders, lastTrade) => {
//   // Group orders by price
//   const groupedOrders = orders.reduce((acc, order) => {
//     const price = parseFloat(order[0])
//     const size = parseFloat(order[1])
//     if (!acc[price]) {
//       acc[price] = 0
//     }
//     acc[price] += size
//     return acc
//   }, {})

//   // Convert the grouped orders back to an array
//   let sortedGroupedOrders = Object.entries(groupedOrders).map(
//     ([price, size]) => [parseFloat(price), size]
//   )

//   // Filter out too high or too low prices if lastTrade is defined
//   if (lastTrade !== undefined) {
//     const threshold = 0.1 // Define a threshold for "too far", e.g., 10%
//     sortedGroupedOrders = sortedGroupedOrders.filter(
//       ([price]) =>
//         price <= lastTrade * (1 + threshold) &&
//         price >= lastTrade * (1 - threshold)
//     )
//   }

//   // Sort the orders: asks in ascending, bids in descending order
//   // sortedGroupedOrders.sort((a, b) => type === 'asks' ? a[0] - b[0] : b[0] - a[0])

//   // Limit to 1000 orders
//   return sortedGroupedOrders.slice(0, MAX_RAW_ORDERS)
// }

// export const orderBookSlice = createSlice({
//   name: "orderBook",
//   initialState: {
//     value: {
//       asks: [],
//       bids: [],
//       sequence: null
//     },
//     asks: [],
//     rawAsks: [],
//     bids: [],
//     rawBids: [],
//     sequence: null,
//     load: true,
//     matching_engine: true,
//     groupingSize: 0.01, // Added grouping size state
//     maxTotalBids: 0,
//     maxTotalAsks: 0
//   },
//   reducers: {
//     changeOrderBook: (state, payload) => {
//       state.value = payload.payload
//     },
//     clearOrderBook: (state, payload) => {
//       state.rawBids = []
//       state.rawAsks = []
//       state.maxTotalBids = ""
//       state.maxTotalAsks = ""
//       state.bids = []
//       state.asks = []
//     },
//     addOrderBook: (state, action) => {
//       // Grouping logic added here
//       const groupedOrders = groupByTicketSize(
//         [action.payload, ...state.value],
//         state.groupingSize
//       )
//       state.value = groupedOrders.slice(0, MAX_ORDER_BOOK_ENTRIES)
//     },
//     addOrderBookBatch: (state, action) => {
//       // Grouping logic added here
//       const groupedOrders = groupByTicketSize(
//         [...action.payload, ...state.value],
//         state.groupingSize
//       )
//       state.value = groupedOrders.slice(0, MAX_ORDER_BOOK_ENTRIES)
//     },
//     setOrderBookLoad: (state, payload) => {
//       state.load = payload.payload
//     },

//     updateOrderBookSnaapshot: (state, action) => {
//       const { asks, bids, sequence = 1 } = action.payload
//       const groupedAsks = groupByTicketSize(asks, state.groupingSize)
//       const groupedBids = groupByTicketSize(bids, state.groupingSize)

//       return {
//         ...state,
//         asks: sliceArray(groupedAsks, MAX_ORDER_BOOK_ENTRIES),
//         bids: sliceArray(groupedBids, MAX_ORDER_BOOK_ENTRIES),
//         sequence,
//         loading: false
//       }
//     },
//     updateOrderBookSnapshot: (state, { payload }) => {
//       const rawBids = payload.bids
//       const rawAsks = payload.asks
//       const bids = groupByTicketSize(rawBids, current(state).groupingSize)
//       const asks = groupByTicketSize(rawAsks, current(state).groupingSize)

//       // state.market = payload['product_id']
//       state.rawBids = rawBids
//       state.rawAsks = rawAsks
//       state.maxTotalBids = getMaxTotalSum(bids)
//       state.maxTotalAsks = getMaxTotalSum(asks)
//       state.bids = addDepths(bids, current(state).maxTotalBids)
//       state.asks = addDepths(asks, current(state).maxTotalAsks)
//     },
//     addBids: (state, { payload }) => {
//       const { bids: newBids, lastTrade } = payload
//       const currentTicketSize = current(state).groupingSize
    
//       // Apply updates to raw data
//       const updatedRawBids = applyUpdatesToRawData(state.rawBids, newBids)
//       state.rawBids = updatedRawBids
    
//       // Regroup the updated raw data
//       const groupedBids = groupByTicketSize(state.rawBids, currentTicketSize)
    
//       // Filter and sort the grouped data
//       const sortedAndFilteredBids = sortAndFilterOrders(groupedBids, lastTrade)
    
//       // Calculate the maximum total for bids
//       state.maxTotalBids = getMaxTotalSum(sortedAndFilteredBids)
    
//       // Add depths to the bids
//       const newBidsWithDepth = addDepths(sortedAndFilteredBids.slice(0, 500), state.maxTotalBids)
    
//       // Limit bids to 500 and update the state
//       state.bids = newBidsWithDepth
//     },
//     addAsks: (state, { payload }) => {
//       const { asks: newAsks, lastTrade } = payload
//       const currentTicketSize = current(state).groupingSize
    
//       // Apply updates to raw data
//       const updatedRawAsks = applyUpdatesToRawData(state.rawAsks, newAsks)
//       state.rawAsks = updatedRawAsks
    
//       // Regroup the updated raw data
//       const groupedAsks = groupByTicketSize(state.rawAsks, currentTicketSize)
    
//       // Filter and sort the grouped data
//       const sortedAndFilteredAsks = sortAndFilterOrders(groupedAsks, lastTrade)
    
//       // Calculate the maximum total for asks
//       state.maxTotalAsks = getMaxTotalSum(sortedAndFilteredAsks)
    
//       // Add depths to the asks
//       const newAsksWithDepth = addDepths(sortedAndFilteredAsks.slice(0, 500), state.maxTotalAsks)
    
//       // Limit asks to 500 and update the state
//       state.asks = newAsksWithDepth
//     },
//     updateOrderBookIncrement: (state, action) => {
//       const payload = {
//         ...state,
//         sequence: action.payload.sequence
//       }

//       if (!state.matching_engine) {
//         const { asks, bids, sequence } = action.payload
//         const groupedAsks = groupByTicketSize(asks, state.groupingSize)
//         const groupedBids = groupByTicketSize(bids, state.groupingSize)

//         return {
//           ...state,
//           asks: sliceArray(groupedAsks, MAX_ORDER_BOOK_ENTRIES),
//           bids: sliceArray(groupedBids, MAX_ORDER_BOOK_ENTRIES),
//           sequence
//         }
//       }

//       if (action.payload.asks) {
//         const groupedAsks = groupByTicketSize(
//           action.payload.asks,
//           state.groupingSize
//         )
//         payload.asks = Array.isArray(groupedAsks[0])
//           ? handleIncrementalUpdateArray(state.asks, groupedAsks, "asks").slice(
//             0,
//             MAX_ORDER_BOOK_ENTRIES
//           )
//           : handleIncrementalUpdate(state.asks, groupedAsks, "asks").slice(
//             0,
//             MAX_ORDER_BOOK_ENTRIES
//           )
//       }

//       if (action.payload.bids) {
//         const groupedBids = groupByTicketSize(
//           action.payload.bids,
//           state.groupingSize
//         )
//         payload.bids = Array.isArray(groupedBids[0])
//           ? handleIncrementalUpdateArray(state.bids, groupedBids, "bids").slice(
//             0,
//             MAX_ORDER_BOOK_ENTRIES
//           )
//           : handleIncrementalUpdate(state.bids, groupedBids, "bids").slice(
//             0,
//             MAX_ORDER_BOOK_ENTRIES
//           )
//       }

//       return payload
//     },

//     setGroupingSize: (state, action) => {
//       const groupingSize = action.payload
//       state.groupingSize = groupingSize // New reducer for setting grouping size
//       const rawBids = state.rawBids
//       const rawAsks = state.rawAsks
//       const bids = groupByTicketSize(rawBids, groupingSize)
//       const asks = groupByTicketSize(rawAsks, groupingSize)

//       // state.market = payload['product_id']
//       state.maxTotalBids = getMaxTotalSum(bids)
//       state.maxTotalAsks = getMaxTotalSum(asks)
//       state.bids = addDepths(bids, current(state).maxTotalBids)
//       state.asks = addDepths(asks, current(state).maxTotalAsks)
//     }
//   }
// })

// // Action creators are generated for each case reducer function
// export const {
//   changeOrderBook,
//   clearOrderBook,
//   addOrderBook,
//   setOrderBookLoad,
//   addOrderBookBatch,
//   updateOrderBookIncrement,
//   updateOrderBookSnapshot,
//   setGroupingSize,
//   addAsks,
//   addBids
// } = orderBookSlice.actions

// export default orderBookSlice.reducer
