import { createSlice } from '@reduxjs/toolkit'

const MAX_TRADES = 15 // Maximum number of trades to keep

const tradesSlice = createSlice({
  name: 'trades',
  initialState: {
    value: [],
    last: ''
  },
  reducers: {
    changeTrade: (state, action) => {
      state.value = action.payload
      state.last = action.payload[0]
    },
    clearTrades: (state) => {
      state.value = []
    },
    lastTrade: (state, action) => {
      state.last = action.payload
    },
    resetLastTrade: (state) => {
      state.last = ''
    },
    addTrade: (state, action) => {
      // Insert the new trade at the beginning of the array
      state.value = [action.payload, ...state.value.slice(0, MAX_TRADES - 1)]
      state.last = action.payload
    },
  }
})

export const { changeTrade, clearTrades, addTrade, lastTrade, resetLastTrade } = tradesSlice.actions

export default tradesSlice.reducer
