// ** React Imports
import React, { useState } from "react"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Utils Imports
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate
} from "../../../../utility/format"
import { hideAddNewCardModal } from "./helpers"

// ** Icons Imports
import { GoPlus } from "react-icons/go"
import { ReactComponent as VisaIcon } from "../../../../assets/icons/imported/visa.svg"
import { ReactComponent as VisaIconLight } from "../../../../assets/icons/imported/visa-light.svg"
import { ReactComponent as MastercardIcon } from "../../../../assets/icons/imported/mastercard-footer.svg"
import { ReactComponent as MastercardIconLight } from "../../../../assets/icons/imported/mastercard-light.svg"
import { ReactComponent as CreditsCardsIcon } from "../../../../assets/icons/imported/credit-cards.svg"
import { ReactComponent as CreditsCardsIconLight } from "../../../../assets/icons/imported/credit-cards-light.svg"

// ** Third Party Imports
import Payment from "payment"
import Cards from "react-credit-cards"
import "react-credit-cards/es/styles-compiled.css"

// ** Form Validation Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** Component Imports
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"
import FormRow from "../../../../components/FormRow/FormRow"

// Schema Validation
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  number: yup.string().required("Card number is required"),
  expiry: yup.string().required("Expiry date is required"),
  cvc: yup.string().required("CVC is required")
})

function AddNewCardModal() {
  // ** Form Resolvers
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit"
  })

  // ** Credit Card States
  const [name, setName] = useState("")
  const [cvc, setCvc] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [focus, setFocus] = useState()
  const [expiry, setExpiry] = useState("")
  const [issuer, setIssuer] = useState("unknown") // VISA - MasterCard - etc...
  const handleBlur = () => setFocus(undefined)

  // ** Credit Card Handlers
  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value, Payment)
      setCardNumber(target.value)
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value)
      setExpiry(target.value)
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value, cardNumber, Payment)
      setCvc(target.value)
    }
  }

  // ** States
  const theme = useSelector((state) => state.theme.value)
  const modalOpen = useSelector((state) => state.addNewCardModalState.open)
  const modalData = useSelector((state) => state.addNewCardModalState.data)

  // ** Handlers
  const handleClose = () => {
    reset()
    setCvc("")
    setName("")
    setExpiry("")
    setCardNumber("")
    setFocus(undefined)
    hideAddNewCardModal()
  }

  const getIcon = () => {
    // VISA
    if (issuer === "visa") {
      if (theme === "dark") {
        return <VisaIcon />
      } else {
        return <VisaIconLight />
      }
    }

    // MasterCard
    if (issuer === "mastercard") {
      if (theme === "dark") {
        return <MastercardIcon />
      } else {
        return <MastercardIconLight />
      }
    }
  }

  const onSubmit = (data) => {
    // Handle form submission here
    window.alert("SUBMIT")
  }

  return (
    <WenbitModal
      open={modalOpen}
      showCloseButton={true}
      onClose={handleClose}
      modalWidth="500px"
      maxWidth="90%"
      title={modalData?.title}
      secondaryTitle={modalData?.secondaryTitle}
      disableBackdropClick
      render={() => (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Cards
            cvc={cvc}
            focused={focus}
            expiry={expiry}
            name={name}
            number={cardNumber}
            placeholders={{ name: "Your Name" }}
            callback={(e) => setIssuer(e.issuer)}
          />

          {/* Holder Name */}
          <FormRow style={{ marginTop: "25px" }}>
            <Input
              showBorder
              inputLabel="Name"
              name="name"
              value={name}
              autoComplete="off"
              label="Name on Card"
              placeholder="John Doe"
              {...register("name")}
              onChange={(e) => {
                setName(e.target.value)
                register("name").onChange(e)
              }}
              onBlur={(e) => {
                handleBlur(e)
                register("name").onBlur(e)
              }}
              onFocus={(e) => setFocus(e.target.name)}
              error={errors.name}
            />
          </FormRow>

          {/* Credit Card Number */}
          <FormRow>
            <Input
              showBorder
              inputLabel="Number"
              name="number"
              value={cardNumber}
              autoComplete="off"
              label="Card Number"
              {...register("number")}
              onBlur={(e) => {
                handleBlur(e)
                register("number").onBlur(e)
              }}
              onChange={(e) => {
                handleInputChange(e)
                register("number").onChange(e)
              }}
              placeholder="0000 0000 0000 0000"
              onFocus={(e) => setFocus(e.target.name)}
              error={errors.number}
              icon={getIcon()}
            />
          </FormRow>

          {/* Date / CVV */}
          <FormRow>
            <Input
              showBorder
              inputLabel="Valid"
              name="expiry"
              label="Expiry"
              value={expiry}
              placeholder="MM/YY"
              inputProps={{ maxLength: "5" }}
              maxLength={5}
              onFocus={(e) => setFocus(e.target.name)}
              {...register("expiry")}
              onBlur={(e) => {
                handleBlur(e)
                register("expiry").onBlur(e)
              }}
              onChange={(e) => {
                handleInputChange(e)
                register("expiry").onChange(e)
              }}
              error={errors.expiry}
            />
            <Input
              showBorder
              inputLabel="CVV"
              name="cvc"
              label="CVC"
              value={cvc}
              autoComplete="off"
              onFocus={(e) => setFocus(e.target.name)}
              placeholder={
                Payment.fns.cardType(cardNumber) === "amex" ? "1234" : "123"
              }
              {...register("cvc")}
              onBlur={(e) => {
                handleBlur(e)
                register("cvc").onBlur(e)
              }}
              onChange={(e) => {
                handleInputChange(e)
                register("cvc").onChange(e)
              }}
              error={errors.cvc}
            />
          </FormRow>

          <Button
            text="Add New Card"
            icon={<GoPlus size={20} />}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "var(--primary-color-light)",
              marginBottom: "25px"
            }}
          />

          <Button text="Confirm" size="large" type="submit" />
        </form>
      )}
    />
  )
}

export default AddNewCardModal
