// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function ConvertFeature({ icon = null, title = null }) {
  return (
    <div className={styles.container}>
      {icon}
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default ConvertFeature
