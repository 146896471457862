// ** React Imports
import React, { useCallback, useState, useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** Redux Imports
import { useSelector, useDispatch } from "react-redux"
import { _getActiveBeneficiaries } from "../../../../../profile/redux/actions"
import { _withdraw } from "../../../../security/Enable2FAModal/redux/actions"
import { _getWithdrawFee, _requestWithdraw } from "../../../redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../../../snackbar/redux/snackbarSlice"

// ** Form Validation Imports
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** Component Imports
import Input from "../../../../../../components/Input/Input"
import Button from "../../../../../../components/Button/Button"
import CoinDisplay from "../../../../../../components/CoinDisplay/CoinDisplay"
import ShimmerLine from "../../../../../../components/shimmer-components/ShimmerLine"
import SelectBeneficiaryInput from "../../../../../../components/Input/SelectBeneficiaryInput/SelectBeneficiaryInput"

// ** Third Party Imports
import { FormattedMessage } from "react-intl"

// ** Utils Imports
import { debounce } from "@mui/material"
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../../../snackbar/helpers"
import { showCreateBeneficiaryModal } from "../../../../beneficiaries/create/helpers"
import { showVerificationCodesInputModal } from "../../../../auth/VerificationCodesInput/helpers"
import { closeWithdrawFormModal } from "../../helpers"
import { setBalances } from "../../../../../../redux/slices/balancesSlice"
import { _getBalances } from "../../../../../exchange/redux/actions"
import { formatNumber } from "../../../../../helpers/formatNumber"

function CryptoNetworkTab() {
  // ** Form Schema
  const withdrawSchema = Yup.object().shape({
    amount: Yup.number("Amount must be a number")
      .typeError("Amount must be a number")
      .required("Amount is required"),
    beneficiary_id: Yup.string().required("A beneficiary is required")
  })

  // ** Hooks
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
    reset,
    setValue,
    setError
  } = useForm({
    resolver: yupResolver(withdrawSchema),
    mode: "onSubmit"
  })

  // ** States
  const theme = useSelector((state) => state.theme.value)
  const balances = useSelector((state) => state.balances.value)
  const currencies = useSelector((state) => state.currencies.value)
  const modalData = useSelector((state) => state.withdrawFormModalState.data)
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [transactionFee, setTransactionFee] = useState(0)
  const [transactionFeeLoading, setTransactionFeeLoading] = useState(false)
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({})
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")

  useEffect(() => {
    _getBalances(
      (res) => {
        console.log(res)
        dispatch(setBalances(res))
      },
      () => {}
    )
  }, [])
  // ** Handle max amount button click
  const handleMaxAmount = () => {
    getTransactionFee(balances)
    console.log(balances)
    console.log(
      balances.find((el) => el.currency === modalData?.selectedCoinID)?.balance
    )
    setAmount(
      balances.find((el) => el.currency === modalData?.selectedCoinID)
        ?.balance ?? 0
    )
    setValue(
      "amount",
      balances.find((el) => el.currency === modalData?.selectedCoinID)
        ?.balance ?? 0
    )
    trigger("amount")
  }

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  // ** Submit function
  const onSumbit = (formData) => {
    // console.log(formData)
    setLoading(true)
    _requestWithdraw(
      {
        currency: modalData?.selectedCoinID,
        amount: formData.amount,
        beneficiary_id: formData.beneficiary_id,
        captcha_response: recaptchaResponse
      },
      (data) => {
        setLoading(false)
        showVerificationCodesInputModal({
          title: "Withdraw Verification",
          sms: true,
          otp: true,
          email: true,
          callback: (formData) => {
            _withdraw(
              {
                phone_otp: formData?.smsCode,
                email_otp: formData?.emailCode,
                otp: formData?.otpCode,
                captcha_response: recaptchaResponse
              },
              (data) => {
                showSuccessSnackbar({
                  alertMessage: "Withdraw completed"
                })
                closeWithdrawFormModal()
              },
              (err) => {
                showErrorSnackbar({
                  alertMessage: "Withdraw was not successful"
                })
                closeWithdrawFormModal()
              }
            )
          }
        })
      },
      (err) => setLoading(false)
    )
  }

  // ** On amount change -> get new fee
  const getTransactionFee = useCallback(
    debounce((balances) => {
      // console.log(balances)
      // console.log(balances.find((el) => el.currency === modalData?.selectedCoinID)?.balance)
      // If balance > amount input --> return
      if (
        Number(
          balances.find((el) => el.currency === modalData?.selectedCoinID)
            ?.balance ?? 0
        ) < Number(getValues("amount"))
      ) {
        // Display error and return
        setError("amount", { message: "Insufficient balance" })
        setTransactionFee(0)
        return
      }
      setTransactionFeeLoading(true)
      _getWithdrawFee(
        {
          amount: getValues("amount"),
          currency: modalData?.selectedCoinID,
          beneficiary_id: getValues("beneficiary_id")
        },
        (data) => {
          setTransactionFeeLoading(false)
          setTransactionFee(data?.low?.networkFee)
        },
        (err) => {
          setTransactionFeeLoading(false)
        }
      )
    }, 2000),
    []
  )

  return (
    <form
      style={{
        paddingTop: 20
      }}
      onSubmit={handleSubmit(onSumbit)}
    >
      <span className={Styles.label}>From</span>
      <div className={`${Styles.infoContainer} ${Styles.fromCoinContainer}`}>
        <div className={Styles.fromCoinLeftContainer}>
          <CoinDisplay
            coinImgURL={
              currencies.find((el) => el?.id === modalData?.selectedCoinID)
                ?.icon_url
            }
            coinName={
              currencies.find((el) => el?.id === modalData?.selectedCoinID)
                ?.name
            }
            coinTag={currencies
              .find((el) => el?.id === modalData?.selectedCoinID)
              ?.id?.toUpperCase()}
          />
        </div>
        <span>
          Available{" "}
          {balances.find((el) => el.currency === modalData?.selectedCoinID)
            ?.balance > 0
            ? formatNumber(
                balances.find((el) => el.currency === modalData?.selectedCoinID)
                  ?.balance,
                6
              )
            : 0.0}
        </span>
      </div>

      <SelectBeneficiaryInput
        coinId={modalData?.selectedCoinID}
        onChange={(beneficiary) => {
          setSelectedBeneficiary(beneficiary)
          setValue("beneficiary_id", beneficiary?.id)
          trigger("beneficiary_id")
        }}
        beneficiaryName={selectedBeneficiary?.name}
        error={errors.beneficiary_id}
      />

      <Button
        text="Add beneficiary"
        variant="link"
        className={Styles.addBeneficiaryButton}
        onClick={() => {
          showCreateBeneficiaryModal({
            selectedCoin: modalData?.selectedCoinID
          })
          // dispatch(setCreateBeneficiaryModalOpen(true))
        }}
      />

      {/* AMOUNT INPUT */}
      <Input
        // type='number'
        inputLabel="Amount"
        inputIconContainerStyle={{
          border: "1px var(--border-color) solid",
          borderRadius: 5,
          height: 45,
          background: "var(--secondary-color)"
        }}
        style={{ fontSize: 13, background: "transparent" }}
        value={amount || ""}
        // onChange={handleAmountChange}
        placeholder="Enter amount"
        error={errors.amount}
        // {...register("amount")}
        onChange={(e) => {
          getTransactionFee(balances)
          setAmount(e?.target?.value)
          setValue("amount", e?.target?.value)
          trigger("amount")
        }}
        icon={
          <Button
            text="MAX"
            variant="link"
            onClick={handleMaxAmount}
            style={{
              padding: 0,
              width: "fit-content"
            }}
          />
        }
      />
      <div
        className={Styles.validAddress}
        style={{
          transform: "translateY(-16px)",
          color: "var(--gray-color)"
        }}
      >
        Transaction Fee{" "}
        {!amount ? (
          0
        ) : transactionFeeLoading ? (
          <ShimmerLine
            height={14}
            width={50}
            style={{ margin: "5px 1px 0 1px" }}
          />
        ) : (
          transactionFee
        )}{" "}
        {modalData?.selectedCoinID?.toUpperCase()}
      </div>

      <ReCAPTCHA
        theme={theme}
        key={theme}
        sitekey={process.env.REACT_APP_SITE_KEY}
        onChange={recaptchaOnChange}
        style={{ margin: "0 auto 20px auto", width: "fit-content" }}
      />

      {/* PROCEED BUTTON */}
      <Button
        text="Proceed withdrawal"
        type="submit"
        disabled={!recaptchaConfirmed}
        style={{ marginBottom: 20 }}
        isLoading={loading}
      />

      {/* DISCLAIMER */}
      <div className={Styles.disclaimer}>Disclaimer</div>
      <p className={Styles.disclaimerText}>
        Please note that only supported networks on our platform are shown and
        if you deposit through another network your assets may be lost forever.
        Send only [insert crypto name ex SHIB] to this deposit address. This
        address does not support the deposit of non-fungible tokens. Your funds
        will be automatically credited to this wallet once we have received
        them.
      </p>
    </form>
  )
}

export default CryptoNetworkTab
