// ** Utils Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Icons Imports
import { BiChevronDown } from "react-icons/bi"

// ** Utils Imports
import { periodsMapNumber } from "../../../../utility/helpers"

// ** Component Imports
import HoverPopper from "../../../../../../components/HoverPopper"

function TimeframeDropdown({ timeframe, setTimeframe }) {
  // ** Constants
  const timeframes = [
    { label: "5m", value: periodsMapNumber["5m"] },
    { label: "30m", value: periodsMapNumber["30m"] },
    { label: "2H", value: periodsMapNumber["2h"] },
    { label: "6H", value: periodsMapNumber["6h"] },
    { label: "12H", value: periodsMapNumber["12h"] },
    { label: "1D", value: periodsMapNumber["1d"] },
    { label: "3D", value: periodsMapNumber["3d"] },
    { label: "1W", value: periodsMapNumber["1w"] },
    { label: "1M", value: periodsMapNumber["4w"] }
  ]

  // ** Handlers
  const openTechnicalIndicators = () => {
    console.log("HERE")
    const event = new KeyboardEvent("keydown", {
      key: "/",
      code: "Slash",
      keyCode: 191,
      which: 191
    })

    // Dispatch the event to the TradingView widget's container or a suitable element
    document.getElementById("tv_chart_container").dispatchEvent(event)
  }

  return (
    <HoverPopper
      popperContent={
        <div className={styles.container}>
          {timeframes.map((el) => (
            <button
              className={`${styles.button} ${
                el.label === timeframe.label && styles.selectedTimeframe
              }`}
              onClick={() => setTimeframe(el)}
            >
              {el.label}
            </button>
          ))}
        </div>
      }
    >
      <button
        className={`${styles.timeframeButton} ${
          timeframes.find((el) => el.label === timeframe.label)?.label &&
          styles.selectedTimeframeDropdownButton
        }`}
      >
        {timeframes.find((el) => el.label === timeframe.label)?.label ?? "More"}
        <BiChevronDown />
      </button>

      {/* <button onClick={openTechnicalIndicators}>HERE</button> */}
    </HoverPopper>
  )
}

export default TimeframeDropdown
