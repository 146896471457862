// ** React Imports
import React, { useMemo, useEffect } from 'react'

// ** Utils Imports
import _ from 'lodash'
import { calcMaxVolume } from './helpers/calcMaxVolume'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatNumber } from '../../../helpers/formatNumber'
import { accumulateVolume } from './helpers/accumulateVolume'
import { showErrorSnackbar } from '../../../snackbar/helpers'

// ** Styles Imports
import Styles from './orderbookBody.module.css'
import rowStyles from './OrderbookRow.module.css'

// ** Icons Imports
import { ReactComponent as UpWhiteArrow } from '../../../../assets/icons/up-white-arrow.svg'

// ** Orderbook Imports
import OrderbookRow, { mapValues } from './OrderbookRow'

// ** Component Imports
import Decimal from '../../old-components/markets/Decimal'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'
import { CircularProgress, listItemTextClasses } from '@mui/material'

// ** Redux Imports
import { _getMarketDepth } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { resetLastTrade } from '../../redux/slices/tradesSlice'
import { setLimitPrice } from '../../redux/slices/limitPriceSlice'
import { addAsks, addBids, updateOrderBookSnapshot } from '../../redux/slices/orderBookSlice'

function OrderbookBody() {
  // ** Constants
  const DEPTH_LIMIT = 500
  const ROW_HEIGHT = '24px'
  // ** Hooks
  const dispatch = useDispatch()

  // ** Redux States
  const asks = useSelector(state => state.orderBook.asks)
  const bids = useSelector(state => state.orderBook.bids)
  const trades = useSelector(state => state.trades.value)
  const orderBooks = useSelector(state => state.orderBook)
  const lastTrade = useSelector(state => state.trades.last)
  const marketsList = useSelector(state => state.marketList.value)
  const selectedMarket = useSelector(state => state.selectedMarket.value)

  const orderBookLoad = orderBooks.load

  // ** States
  const memoizedOrderBooks = useMemo(() => orderBooks, [orderBooks])

  useEffect(() => {
    dispatch(resetLastTrade())
  }, [selectedMarket])

  function renderOrderBookRows(array, side, userOrders, currentMarket) {
    // setOrderBookLoad(false)
    // console.log(currentMarket)
    const priceFixed = currentMarket ? currentMarket.price_precision : 0
    const amountFixed = currentMarket ? currentMarket.amount_precision : 0
    if (side === 'asks') {
      // console.log(array)
    }
    const handleRowWidth = (data, index) => {
      const mapValues = (maxVolume, data) => {
        const resultData =
          data && maxVolume && data.length
            ? data.map(currentVolume => ({
                value: (currentVolume / maxVolume) * 100
              }))
            : []
        return resultData.sort((a, b) => a.value - b.value)
      }
      // console.log(asks)
      const resultData = mapValues(calcMaxVolume(bids, asks), accumulateVolume(data))
      return resultData && resultData.length ? resultData[index].value : 0
    }

    if (array.length === 0) {
      return <span className='order-book-no-data'>No data available</span>
    }

    return array.map((item, i) => {
      const [price, volume] = item
      const shouldHighlight = side === 'asks' ? i === array.length - 1 : i === 0

      const rowWidth = handleRowWidth(array, i)

      const rowBackgroundColor = side === 'bids' ? 'rgb(3, 166, 109, 0.2)' : 'rgb(220, 38, 38, 0.2)'
      const rowColor = side === 'bids' ? 'var(--positive-color)' : 'var(--negative-color)'
      const totalPrice = formatNumber((Number(price) * Number(volume)).toString().substring(0, 7)) || '0'
      return (
        <div className={rowStyles.rowContainer}>
          <div style={{ color: rowColor }}>
            <Decimal fixed={priceFixed} fromOrderBooks={true} shouldHighlight={shouldHighlight}>
              {price}
            </Decimal>
          </div>
          <div>
            <Decimal fixed={amountFixed} fromOrderBooks={true} shouldHighlight={shouldHighlight}>
              {formatNumber(volume)}
            </Decimal>
          </div>
          <span
            className={rowStyles.rowBackground}
            style={{ width: rowWidth, backgroundColor: rowBackgroundColor }}
          ></span>
          <div>
            {/* <Decimal fixed={priceFixed} fromOrderBooks={true} shouldHighlight={shouldHighlight}> */}
            {totalPrice}
            {/* </Decimal> */}
          </div>
        </div>
      )
    })
  }

  const handleRowClick = price => {
    // console.log(price)
    dispatch(setLimitPrice(price))
  }

  useEffect(() => {
    if (selectedMarket.id) {
      _getMarketDepth(
        selectedMarket.id,
        DEPTH_LIMIT,
        data => {
          dispatch(updateOrderBookSnapshot(data))
        },
        () => {
          showErrorSnackbar({
            alertMessage: 'Something went wrong with depth API'
          })
        }
      )
    }
  }, [selectedMarket.id])

  const maxVolume = calcMaxVolume(bids, asks)
  const resultAsksData = mapValues(maxVolume, accumulateVolume(asks))
  const resultBidsData = mapValues(maxVolume, accumulateVolume(bids))

  const sortAsks = asks => {
    return asks.sort((a, b) => Number(a[0]) - Number(b[0]))
  }
  const sortBids = bids => {
    return bids.sort((a, b) => Number(b[0]) - Number(a[0]))
  }

  const buildPriceLevels = (levels, orderType) => {
    let sortedLevelsByPrice
    if (orderType === 'bids') {
      sortedLevelsByPrice = [...levels].sort((currentLevel, nextLevel) => {
        let result = 0
        // if (orderType === "asks") {
        result = nextLevel[0] - currentLevel[0]
        // } else {
        //   result = currentLevel[0] - nextLevel[0]
        // }
        return result
      })
      // .slice(0, 10)
    } else {
      sortedLevelsByPrice = [...levels].sort((currentLevel, nextLevel) => {
        let result = 0
        // if (orderType === "asks") {
        result = nextLevel[0] - currentLevel[0]
        // } else {
        //   result = currentLevel[0] - nextLevel[0]
        // }
        return result
      })
      // .slice(-10)
    }

    const rowBackgroundColor = orderType === 'bids' ? 'rgb(3, 166, 109, 0.2)' : 'rgb(220, 38, 38, 0.2)'
    const rowColor = orderType === 'bids' ? 'var(--positive-color)' : 'var(--negative-color)'

    const formatPrice = arg => {
      return arg.toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits: 2
      })
    }
    return sortedLevelsByPrice.map((level, index) => {
      const depth = parseFloat(level[3])
      // console.log(level)
      // console.log(depth)
      const size = formatNumber(parseFloat(level[1]), 5)
      const price = formatPrice(level[0])
      const total = formatNumber((Number(level[0]) * Number(level[1])).toString().substring(0, 7)) || '0'
      return (
        <div key={`row-${index}`} onClick={e => handleRowClick(price)} className={rowStyles.rowContainer}>
          <div style={{ color: rowColor }}>{price}</div>
          <div>{size}</div>
          <span
            className={rowStyles.rowBackground}
            style={{
              width: `${depth}%`,
              backgroundColor: rowBackgroundColor
            }}
          ></span>
          <div>
            {/* <Decimal fixed={priceFixed} fromOrderBooks={true} shouldHighlight={shouldHighlight}> */}
            {total}
            {/* </Decimal> */}
          </div>
        </div>
      )
    })
  }

  return (
    <div className={Styles.card}>
      {asks && bids && (
        <div>
          {/* sorting row */}
          <div className={Styles.sortingContainer}>
            <span>Price ({marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()})</span>
            <span>Amount ({marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()})</span>
            <span>
              Total ({marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()}
              {/* {marketsList
                .find((el) => el.id === selectedMarket.id)
                ?.name.toUpperCase()} */}
              )
            </span>
          </div>
        </div>
      )}

      {asks.length ? (
        <>
          {/* asks */}
          <div className={Styles.bidsContainer}>
            {asks.length
              ? buildPriceLevels(asks, 'asks')
              : new Array(10).fill(0).map((_, i) => <ShimmerLine key={i} width='100%' height='24px' />)}
          </div>

          {/* last trade */}
          {lastTrade && lastTrade.price ? (
            <div
              className={Styles.lastTrade}
              style={{
                color: lastTrade.taker_type === 'buy' ? 'var(--negative-color)' : 'var(--positive-color)'
              }}
            >
              {/* {selectedMarket.quote_symbol} */}
              {formatNumber(lastTrade.price)}{' '}
              {marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()}
              <UpWhiteArrow
                width={17}
                style={{
                  transform:
                    lastTrade.taker_type === 'buy'
                      ? 'rotateY(180deg) rotateZ(90deg) rotateX(180deg)'
                      : 'rotateX(180deg) rotateZ(90deg)'
                }}
              />
              {/* <img
                src={UpWhiteArrow}
                style={{
                  transform:
                    lastTrade.taker_type === "buy"
                      ? "rotateY(180deg) rotateZ(90deg) rotateX(180deg)"
                      : "rotateX(180deg) rotateZ(90deg)"
                }}
              /> */}
            </div>
          ) : (
            <ShimmerLine style={{ margin: '10px' }} width='100%' height='45px' />
          )}

          {/* bids */}
          <div className={Styles.asksContainer}>
            {asks.length
              ? buildPriceLevels(bids, 'bids')
              : new Array(10).fill(0).map((_, i) => <ShimmerLine key={i} width='100%' height='24px' />)}
          </div>
        </>
      ) : (
        <div className={Styles.loadingContainer}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default injectIntl(OrderbookBody)
