import React from "react"

// styles
import Styles from "./formRow.module.css"

function FormRow({ style, children }) {
  return (
    <div className={Styles.row} style={{ ...style }}>
      {children}
    </div>
  )
}

export default FormRow
