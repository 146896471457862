import { API } from '../../../../utility/API'

//************************************//
export const _sendCode = (data, callback, callbackErr) => {
    API.post(`authenticate/resource/phones`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _reSendCode = (data, callback, callbackErr) => {
    API.post(`authenticate/resource/phones/send_code`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _initializeKyc = (data, callback, callbackErr) => {
    API.post(`authenticate/resource/kyc/initialize`, [])
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _verify = (data, callback, callbackErr) => {
    API.post(`authenticate/resource/phones/verify`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _addInfo = (data, callback, callbackErr) => {
    API.post(`authenticate/resource/registration_details`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _updateInfo = (data, callback, callbackErr) => {
    API.put(`authenticate/resource/resource/profiles`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}