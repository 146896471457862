import React, { useEffect, useState } from "react"

// react router
import { useNavigate, useLocation } from "react-router-dom"

// icons
import { ReactComponent as TVIcon } from "../../../assets/icons/imported/tv.svg"
import { ReactComponent as BellIcon } from "../../../assets/icons/imported/bell.svg"
import { ReactComponent as LockIcon } from "../../../assets/icons/imported/lock.svg"
import { ReactComponent as UserIcon } from "../../../assets/icons/imported/profile.svg"
import { ReactComponent as DocumentIcon } from "../../../assets/icons/imported/document.svg"
import { ReactComponent as BeneficiariesIcon } from "../../../assets/icons/imported/beneficiaries.svg"

//redux
import { useDispatch, useSelector } from "react-redux"

// styles
import Styles from "./profile.module.css"

// components
import ProfileAvatar from "../../../components/ProfileAvatar/ProfileAvatar"
import SideNavButton from "../../../components/SideNavButton/SideNavButton"

// tabs
import ProfileTab from "../components/ProfileTab/ProfileTab"
import SecurityTab from "../components/SecurityTab/SecurityTab"
import GoBackHeader from "../../../components/GoBackHeader/GoBackHeader"
import BeneficiariesTab from "../components/BeneficiariesTab/BeneficiariesTab"
import NotificationsTab from "../components/NotificationsTab/NotificationsTab"
import KYCVerificationTab from "../components/KYCVerificationTab/KYCVerificationTab"
import AccountActivitesTab from "../components/AccountActivitesTab/AccountActivitesTab"

function Profile() {
  const navigate = useNavigate()
  const location = useLocation()
  document.title = "Wenbit: Profile"

  const userData = useSelector((state) => state.userData.value)

  const profileRoutes = [
    "/account/profile",
    "/account/security",
    "/account/activities",
    "/account/kyc",
    "/account/beneficiaries"
  ]

  const [currentTabTitle, setCurrentTabTitle] = useState("")

  useEffect(() => {
    switch (location.pathname) {
      case "/account/profile":
        setCurrentTabTitle("Profile")
        break
      case "/account/security":
        setCurrentTabTitle("Security")
        break
      case "/account/activities":
        setCurrentTabTitle("Activities")
        break
      case "/account/kyc":
        setCurrentTabTitle("KYC")
        break
      case "/account/beneficiaries":
        setCurrentTabTitle("Beneficiaries")
        break
    }
  }, [location])

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 820)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 820)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (
      ["/account", "/account/"].includes(location.pathname) &&
      !isMobileView
    ) {
      navigate("/account/profile", { replace: true })
    }
  }, [isMobileView])
  // ------------------------------------
  // ------------------------------------

  return (
    <>
      {isMobileView && !profileRoutes.includes(location.pathname) && (
        <h1 className={Styles.mobilePageTitle}>My profile</h1>
      )}

      <div className={`${Styles.pageContainer}`}>
        {/* side nav */}
        <div
          className={`${Styles.sideNavContainer}`}
          style={{
            display:
              isMobileView &&
              profileRoutes.includes(location.pathname) &&
              "none",
            flexDirection:
              isMobileView &&
              !profileRoutes.includes(location.pathname) &&
              "column"
          }}
        >
          {/* side nav header */}
          <div className={Styles.sideNavHeader}>
            {/* <img src="/img/avatar.svg" /> */}
            <ProfileAvatar />
            <div>
              {userData.first_name ?? "Not "} {userData.last_name ?? " Set"}
            </div>
            <span>UID: {userData?.uid}</span>
          </div>

          {/* side nav buttons container */}
          <div className={Styles.sideNavButtonsContainer}>
            <SideNavButton
              text="My Profile"
              icon={<UserIcon width={12} />}
              isSelected={location.pathname.endsWith("/profile")}
              onClick={() => navigate("/account/profile")}
            />
            <SideNavButton
              text="Beneficiaries"
              icon={
                <BeneficiariesIcon
                  style={{ marginLeft: -7, marginRight: -11.5 }}
                  width={27}
                />
              }
              isSelected={location.pathname.endsWith("/beneficiaries")}
              onClick={() => navigate("/account/beneficiaries")}
            />
            <SideNavButton
              text="Security"
              icon={<LockIcon width={12} />}
              isSelected={location.pathname.endsWith("/security")}
              onClick={() => navigate("/account/security")}
            />
            {/* <SideNavButton
                    text='Notification Preferences'
                    icon={<BellIcon width={13} />}
                    isSelected={location.pathname.endsWith('/notification')}
                    onClick={() => navigate('/account/notification')}
                /> */}
            <SideNavButton
              text="Account Activities"
              icon={<TVIcon width={14} />}
              isSelected={location.pathname.endsWith("/activities")}
              onClick={() => navigate("/account/activities")}
            />
            <SideNavButton
              text="KYC Verification"
              icon={<DocumentIcon width={12} />}
              isSelected={location.pathname.endsWith("/kyc")}
              onClick={() => navigate("/account/kyc")}
            />
          </div>
        </div>

        {/* main container */}
        <div
          className={`${Styles.tabsContainer}`}
          style={{
            display:
              ((isMobileView && !profileRoutes.includes(location.pathname)) ||
                (isMobileView && location.pathname === "/account")) &&
              "none"
          }}
        >
          {isMobileView && (
            <GoBackHeader
              title={currentTabTitle}
              onBackClick={() => navigate("/account")}
            />
          )}

          {location.pathname.endsWith("/profile") && (
            <ProfileTab userData={userData} />
          )}
          {location.pathname.endsWith("/security") && <SecurityTab />}
          {location.pathname.endsWith("/beneficiaries") && <BeneficiariesTab />}
          {/* {
                location.pathname.endsWith('/notification') && (
                    <NotificationsTab />
                )
            } */}
          {location.pathname.endsWith("/activities") && <AccountActivitesTab />}
          {location.pathname.endsWith("/kyc") && <KYCVerificationTab />}
        </div>
      </div>
    </>
  )
}

export default Profile
