import { API } from "../../../../../../utility/API"

//************************************//
export const _getCoinifyExchangeRate = (
  params,
  callback,
  callbackErr = () => {}
) => {
  API.get(`trading/account/trade_gateway/exchange_rate`, { params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response)
    })
}
//************************************//
export const _createCoinifyOrderRequest = (
  params,
  callback,
  callbackErr = () => {}
) => {
  API.post(`trading/account/trade_gateway/buy_request`, { ...params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _confirmCoinifyOrderRequest = (
  params,
  callback,
  callbackErr = () => {}
) => {
  API.post(`trading/account/trade_gateway/buy_confirm`, { ...params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
