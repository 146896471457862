import React from "react"

// react router
import { useNavigate } from "react-router-dom"

// redux
import { useSelector } from "react-redux"

// styles
import Styles from "./kycStatus.module.css"

// icons
import { ReactComponent as LeftArrowIcon } from "../../../../assets/icons/imported/back-arrow.svg"
import { ReactComponent as CheckmarkIcon } from "../../../../assets/icons/imported/checkmark.svg"

// components
import Button from "../../../../components/Button/Button"

function KYCStatus({
  status // verify, verified, pending
}) {
  const navigate = useNavigate()

  const theme = useSelector((state) => state.theme.value)

  return (
    <div
      className={Styles.card}
      style={{
        backgroundColor: theme === "light" && "white"
      }}
    >
      {!status && (
        <>
          <div
            className={Styles.iconContainer}
            style={{
              backgroundColor: theme === "light" && "var(--secondary-color)"
            }}
          >
            {/* icon */}
            <LeftArrowIcon
              color={"var(--primary-color)"}
              style={{
                transform: "rotateZ(180deg)"
              }}
            />
          </div>

          {/* title */}
          <span
            className={Styles.statusTitle}
            style={{ color: "var(--primary-color)" }}
          >
            START YOUR KYC PROCESS
          </span>

          {/* veriy button */}
          <Button
            text="Verify your KYC"
            style={{ width: "fit-content" }}
            onClick={(e) => navigate("/kyc")}
          />
        </>
      )}

      {status === "verified" && (
        <>
          <div
            style={{
              backgroundColor: theme === "light" && "var(--secondary-color)"
            }}
            className={Styles.iconContainer}
          >
            {/* icon */}
            <CheckmarkIcon color={"var(--primary-color)"} />
          </div>

          {/* title */}
          <span
            className={Styles.statusTitle}
            style={{ color: "var(--primary-color)" }}
          >
            VERIFIED
          </span>

          {/* verified info container */}
          <div className={Styles.verifiedInfoContainer}>
            <span>
              <div
                style={{
                  backgroundColor: theme === "light" && "var(--secondary-color)"
                }}
                className={Styles.iconContainer}
              >
                {/* icon */}
                <CheckmarkIcon color={"var(--primary-color)"} width={9} />
              </div>
              Personal information
            </span>

            <span>
              <div
                style={{
                  backgroundColor: theme === "light" && "var(--secondary-color)"
                }}
                className={Styles.iconContainer}
              >
                {/* icon */}
                <CheckmarkIcon color={"var(--primary-color)"} width={9} />
              </div>
              Government-issued ID
            </span>

            <span>
              <div
                style={{
                  backgroundColor: theme === "light" && "var(--secondary-color)"
                }}
                className={Styles.iconContainer}
              >
                {/* icon */}
                <CheckmarkIcon color={"var(--primary-color)"} width={9} />
              </div>
              Facial recognition
            </span>
          </div>
        </>
      )}

      {status === "pending" && (
        <>
          <div
            className={Styles.iconContainer}
            style={{
              color: "orange",
              fontWeight: "bolder",
              fontSize: 30,
              backgroundColor: theme === "light" && "var(--secondary-color)"
            }}
          >
            {/* icon */}!
          </div>

          {/* title */}
          <span className={Styles.statusTitle} style={{ color: "orange" }}>
            VERIFICATION AWAITS
          </span>

          <span className={Styles.pendingMessage}>
            Just checking your details and we'll get back to you soon!
          </span>
        </>
      )}

      {status === "rejected" && (
        <>
          <div
            className={Styles.iconContainer}
            style={{
              color: "red",
              fontWeight: "bolder",
              fontSize: 30,
              backgroundColor: theme === "light" && "var(--secondary-color)"
            }}
          >
            {/* icon */}X
          </div>

          {/* title */}
          <span className={Styles.statusTitle} style={{ color: "red" }}>
            VERIFICATION REJECTED
          </span>

          <span className={Styles.pendingMessage}>
            Some of your documents are rejected. Please check again!
          </span>
          <Button
            text="Complete KYC"
            style={{ width: "fit-content" }}
            onClick={(e) => navigate("/kyc")}
          />
        </>
      )}
    </div>
  )
}

export default KYCStatus
