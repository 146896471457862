import React from "react"

// styles
import Styles from "./selectNetworkModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import { setSelectNetworkModalOpen } from "../redux/selectNetworkModalSlice"

// icon
import { ReactComponent as ArrowDownIcon } from "../../../../assets/icons/imported/up-arrow.svg"

// components
import WenbitModal from "../../../../components/Modal/Modal"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"

// MUI
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "../../../../components/Button/Button"
import { hideSelectNetworkModal } from "./helpers"
import { CircularProgress } from "@mui/material"
import { randomizeShimmerWidth } from "../../../../components/shimmer-components/helpers"

// styling
// --- MENU ITEM STYLING
const menuItemSX = {
  borderRadius: 1.25,
  margin: "9px 0"
}

function SelectNetworkModal() {
  const dispatch = useDispatch()

  let selectedNetworkId

  const modalOpen = useSelector((state) => state.selectNetworkModalState.open)
  const modalData = useSelector((state) => state.selectNetworkModalState.data)

  const handleProceed = () => {
    modalData?.callback({
      key: selectedNetworkId,
      name: modalData?.networks?.find((el) => el?.key === selectedNetworkId)
        ?.name
    })
  }

  const handleButtonClick = (network) => {
    modalData?.callback({
      key: network?.key,
      name: modalData?.networks?.find((el) => el?.key === network?.key)?.name
    })
    hideSelectNetworkModal()
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={hideSelectNetworkModal}
        title="Select network"
        disableBackdropClick={false}
        render={() => (
          <div className={Styles.select}>
            <p className={Styles.description}>
              Choose your lane! To finalise your deposit securely,
              <br />
              choose which network you’d like to use to complete the
              transaction.
            </p>

            <span className={Styles.boxLabel}>Select network</span>
            <div className={Styles.networksContainer}>
              {modalData?.networks ? (
                modalData?.networks?.map((network) => (
                  <button
                    onClick={() => handleButtonClick(network)}
                    key={network?.key}
                  >
                    {network?.name}
                  </button>
                ))
              ) : (
                <>
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                  <ShimmerLine height={30} width={randomizeShimmerWidth()} />
                </>
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default SelectNetworkModal

// ---OLD DESIGN---
// {
//   /* <MenuItem value={20} sx={menuItemSX}>
//                 <div className={Styles.menuItemContainer}>
//                   <p>BNB Smart Chain (BEP20)</p>
//                 </div>
//               </MenuItem> */
// }
// {
//   /* <MenuItem value={40} sx={menuItemSX}>
//                 <div className={Styles.menuItemContainer}>
//                   <p>BNB Smart Chain (BEP20)</p>
//                   <div>
//                     <p>Fee 0.29 USDT (= $0.289999)</p>
//                     <p>Arrival time = 5 mins</p>
//                   </div>
//                 </div>
//               </MenuItem> */
// }

//     <Select
//   defaultValue=""
//   displayEmpty
//   inputProps={{ "aria-label": "Without label" }}
//   MenuProps={{
//     MenuListProps: {
//       style: {
//         backgroundColor: "var(--secondary-color)",
//         padding: 10,
//         maxHeight: 195,
//         overflowY: "scroll"
//       }
//     }
//   }}
//   placeholder
//   sx={{
//     backgroundColor: "var(--secondary-color)",
//     "& .MuiSelect-icon": {
//       color: "var(--gray-color)"
//     },
//     width: "100%",
//     border: "1px var(--border-color) solid",
//     outline: "none",
//     "& .MuiOutlinedInput-input": {
//       padding: "7.5px 10px"
//     },
//     fontFamily: '"Outfit", "Overpass", sans-serif'
//   }}
//   // IconComponent={ArrowDownIcon}
//   // icon={<ArrowDownIcon />}
//   onChange={(e) => {
//     selectedNetworkId = e.target.value
//   }}
// >
//   {modalData?.networks && (
//     <MenuItem value="">
//       <em style={{ color: "var(--gray-color)" }}>Select Network</em>
//     </MenuItem>
//   )}

//   {modalData?.networks ? (
//     modalData?.networks?.map((network) => (
//       <MenuItem value={network?.key} sx={menuItemSX}>
//         <div className={Styles.menuItemContainer}>
//           <p>{network.name}</p>
//         </div>
//       </MenuItem>
//     ))
//   ) : (

//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center"
//       }}
//     >
//       <CircularProgress />
//     </div>
//   )}
// </Select>
