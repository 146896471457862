// ** Helpers Imports
import { showStakingSubscribeModal } from '../../modals/staking/staking-subscribe-modal/helpers'

/**
 * Function that handles the subsription action for a coin
 * @param {string} coinId The ID of the coin to subscribe to
 * @param {string} selectedDuration The selected duration to subscribe to the specified coin
 */
export const handleStakingSubscribe = ({ coinId, selectedDuration = null }) => {
  showStakingSubscribeModal({ coinId, selectedDuration })
}
