import React from "react"

// components
import CoinTag from "../CoinTag/CoinTag"

// styles
import Styles from "./marketCoinCard.module.css"

// images/icons
import UpArrow from "./up-arrow.svg"
import DownArrow from "./down-arrow.svg"
import AreaChart from "../AreaChart/AreaChart"

// redux
import { useSelector } from "react-redux"

function MarketCoinCard({
  name = "",
  tag = "",
  icon = "",
  price = 0,
  priceChangePercentage = "",
  style,
  kLineArray = []
}) {
  // console.log("TEST", name, tag, icon, price, priceChangePercentage, kLineArray)
  const theme = useSelector((state) => state.theme.value)

  const convertKLineDataToArray = (arr) => {
    return arr?.map((subArr) => subArr[2])
  }

  return (
    <div
      className={Styles.card}
      style={{
        backgroundColor: theme === "light" && "transparent",
        border: theme === "light" && "1.5px #F0F1F5 solid",
        ...style
      }}
    >
      <div>
        <div className={Styles.cardHeader}>
          <img src={icon} />
          {name}
          <CoinTag tag={tag} size={12} />
          <div className={Styles.priceChange}>
            {priceChangePercentage[0] === "+" ? (
              <span style={{ color: "var(--positive-color)" }}>
                {priceChangePercentage}
                <img src={UpArrow} style={{ width: "17px" }} />
              </span>
            ) : (
              <span style={{ color: "var(--negative-color)" }}>
                {priceChangePercentage}
                <img src={DownArrow} style={{ width: "17px" }} />
              </span>
            )}
          </div>
        </div>

        <div className={Styles.cardInfo}>
          <span>
            {price}{" "}
            <span style={{ color: "var(--gray-color)", fontSize: "14px" }}>
              USDT
            </span>
          </span>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          margin: "0 auto"
        }}
      >
        {kLineArray && (
          <AreaChart
            kLineData={convertKLineDataToArray(
              kLineArray[Object.keys(kLineArray)[0]]
            )}
            color={priceChangePercentage[0] === "-" ? "error" : "success"}
          />
        )}
      </div>
    </div>
  )
}

export default MarketCoinCard
