import React, { useState, useContext } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// react router
import { Link, useNavigate, useLocation } from "react-router-dom"

// utilities
import _, { isEmpty } from "lodash"

// translations
import { FormattedMessage, injectIntl } from "react-intl"

// redux
import { useSelector, useDispatch } from "react-redux"
import { _resetPassword } from "../../../../redux/actions"

// styles
import Styles from "./ResetPassword.module.css"

// components
import { Form } from "reactstrap"
import "react-toastify/dist/ReactToastify.css"
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import PasswordRequirements from "../signup/PasswordRequirements"

// photos
import illustrationPhoto from "../../assets/images/login-signup-illustration.png"

// form validations
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../snackbar/helpers"

// login schema
const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/,
      "Password doesn't meet requirements"
    )
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required")
})

const ResetPasswordTab = () => {
  // ** States
  const theme = useSelector((state) => state.theme.value)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit"
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("reset_token")
  const lang = searchParams.get("lang")

  document.title = "Wenbit: Password Reset"

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      setLoading(true)
      _resetPassword(
        {
          password: data.password,
          confirm_password: data.confirm_password,
          reset_password_token: token,
          captcha_response: recaptchaResponse
        },
        (data) => {
          setLoading(false)
          showSuccessSnackbar({ alertMessage: "Password successfully reset" })
          navigate("/login")
        },
        (error) => {
          setLoading(false)
          showErrorSnackbar({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            )
          })
        }
      )
    }
  }
  return (
    <div className={Styles.tabContainer}>
      {/* form */}
      <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>
        {/* title */}
        <span className={Styles.title}>
          {/* <FormattedMessage id={"page.header.signInToWenbit"} /> */}
          Reset Password
        </span>

        {/* mail input */}
        <Input
          type="password"
          {...register("password")}
          onChange={(e) => {
            register("password").onChange(e)
            setPassword(e.target.value)
          }}
          id="password"
          inputLabel="Password"
          error={errors.password}
        />

        {/* Password Requirements */}
        <PasswordRequirements
          password={password}
          containerStyle={{ marginBottom: 15, marginTop: -11 }}
        />

        {/* password input */}
        <Input
          type="password"
          {...register("confirm_password")}
          id="confirm_password"
          inputLabel="Confirm Password"
          error={errors.confirm_password}
        />

        {/* forgot password link */}
        <Link to={"/login"} className={Styles.forgotPassword}>
          Back to Login
        </Link>
        <ReCAPTCHA
          key={`reCaptcha-${theme}`}
          theme={theme}
          style={{ margin: "20px auto 0 auto" }}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={recaptchaOnChange}
        />

        {/* sign in button */}
        <Button
          text={"Reset Password"}
          type="submit"
          disabled={!recaptchaConfirmed || loading}
          loading={loading}
          style={{ marginTop: 30, height: "45px" }}
        />
      </Form>
    </div>
  )
}
export default injectIntl(ResetPasswordTab)
