import React, { useEffect, useRef, useState } from 'react'

// react router
import { useNavigate } from 'react-router-dom'

// styles imports
import Styles from './markets.module.css'

// icons imports
import { ReactComponent as LinkIcon } from '../../../assets/icons/imported/link.svg'
import { ReactComponent as BuyArrowIcon } from '../../../assets/icons/imported/buy-arrow.svg'
import { ReactComponent as SellArrowIcon } from '../../../assets/icons/imported/sell-arrow.svg'

// APIs imports
import { _addOrder, _getCoinKline, _getCurrencies, _getMarkets24h } from '../redux/actions'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedCoin } from '../redux/slices/selectedCoin'
import { setSnackbarOpen, setSnackbarData } from '../../snackbar/redux/snackbarSlice'

// component imports
import Slider from 'react-slick'
import Convert from '../components/Convert'
import BuyCrypto from '../components/BuyCrypto'
import QuickBuySell from '../components/QuickBuySell'
import Search from '../../../components/Search/Search'
import CoinTag from '../../../components/CoinTag/CoinTag'
import { FormattedMessage, injectIntl } from 'react-intl'
import TableButton from '../../../components/TableButton/TableButton'
import MarketGraphCard from '../../../components/MarketGraphCard/MarketGraphCard'

// utilities
import { debounce } from '../../../utility/debounce'

// MUI
import MarketFooter from '../components/MarketFooter'
import WenbitModal from '../../../components/Modal/Modal'
import MUIDatagrid from '../../../components/MUIDatagrid/MUIDatagrid'

const markets = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [modalStates, setModalStates] = useState({
    quickBuySellOpen: false,
    convertOpen: false
  })

  const [isTableCollapsed, setIsTableCollapsed] = useState(window.innerWidth < 940)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700)

  const handleResize = () => {
    setIsTableCollapsed(window.innerWidth < 900)
    setIsMobileView(window.innerWidth < 700)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const theme = useSelector(state => state.theme.value)
  const marketList = useSelector(state => state.marketList.value)
  const userData = useSelector(state => state.userData.value)
  const tickers = useSelector(state => state.marketTickers.value)

  const selectedCurrency = useSelector(state => state.currency.value)

  // pagination
  const [page, setPage] = useState(1)
  const pageLimit = 15
  const [marketRows, setMarketRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [orderBy, setOrderBy] = useState(null)
  const [ordering, setOrdering] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const searchInputRef = useRef(null)
  const [klineArray, setKlineArray] = useState([])
  const [filteredMarketList, setFilteredMarketList] = useState(marketList)

  const defaultTicker = {
    last: 0,
    vol: 0,
    price_change_percent: '+0.00%'
  }
  const fetchMarkets = () => {
    setTableLoading(true)
    _getMarkets24h(
      {
        quote_unit: 'usdt',
        order_by: orderBy,
        ordering,
        limit: pageLimit,
        page
      },
      ({ data, page, total }) => {
        setFilteredMarketList(data)
        setTotalRows(total)
        setTableLoading(false)
      },
      error => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
      }
    )
  }
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  useEffect(() => {
    const searchValue = searchInputRef.current.value
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
      fetchMarkets()
    } else {
      setTableLoading(true)
      _getMarkets24h(
        {
          // quote_unit: 'usdt',
          order_by: orderBy,
          ordering,
          page,
          limit: pageLimit,
          search: {
            quote_code: searchValue,
            base_name: searchValue
          }
        },
        ({ data, page, total }) => {
          setFilteredMarketList(data)
          setTotalRows(total)
          setTableLoading(false)
        },
        error => {
          setTableLoading(false)
          dispatch(setSnackbarOpen(true))
          dispatch(
            setSnackbarData({
              alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
              severity: 'error',
              //buttonMessage: 'See order',
              callback: () => {},
              //actionType: 'link', // link - close
              //linkTo: '/account/orders/open',
              position: 'topRight'
            })
          )
        }
      )
    }
  }, [page, orderBy, ordering])

  const handleMarketSearch = e => {
    setTableLoading(true)
    const searchValue = e.target.value
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
      setPage(1)
      fetchMarkets()
      return
    }
    _getMarkets24h(
      {
        quote_unit: 'usdt',
        order_by: orderBy,
        ordering,
        page,
        limit: pageLimit,
        search: {
          base_code: searchValue,
          base_name: searchValue
        }
      },
      ({ data, page, total }) => {
        setFilteredMarketList(data)
        setTotalRows(total)
        setTableLoading(false)
      },
      error => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? <FormattedMessage id={error.errors[0]} /> : 'Unknown Error',
            severity: 'error',
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: 'topRight'
          })
        )
      }
    )
  }

  const handleSortModelChange = sortData => {
    setOrdering(sortData[0].sort ? sortData[0].sort : null)
    setOrderBy(sortData[0].field ? sortData[0].field : null)
  }

  useEffect(() => {
    setPage(1)
    fetchMarkets()

    const marketIds = marketList
      .filter(market => market.quote_unit === selectedCurrency && market.last !== '0.0')
      .map(market => market.id)

    // fetch market kline
    _getCoinKline(
      {
        pairs: marketIds,
        limit: 15
      },
      data => {
        setKlineArray(data)
      },
      err => console.warn('KLINE ERROR')
    )
  }, [])

  useEffect(() => {
    // console.log(tickers['btcusdt']['last'])
    setFilteredMarketList(
      filteredMarketList?.map(market => {
        return {
          ...market,
          last: (tickers[market.id] || defaultTicker).last,
          price_change_percent: (tickers[market.id] || defaultTicker).price_change_percent,
          price_change_percent_num: Number.parseFloat((tickers[market.id] || defaultTicker).price_change_percent),
          vol: (tickers[market.id] || defaultTicker).volume
        }
      })
    )
  }, [tickers])

  const CarouselSettings = {
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const redirectToConvertHistory = id => {
    navigate(`/convert/${id.replace('/', '_')}`)
  }
  const redirectToTradeHistory = id => {
    navigate(`/trade/${id}`)
  }
  document.title = 'Wenbit: Markets'

  const resetTable = () => {
    searchInputRef.current.value = ''
    fetchMarkets()
  }

  const handleRowClick = row => {
    dispatch(
      setSelectedCoin({
        id: row.row.id,
        name: row.row.name
      })
    )
    if (
      searchInputRef.current.value === '' ||
      searchInputRef.current.value === null ||
      searchInputRef.current.value === undefined
    )
      return
    resetTable()
  }

  const marketsTableColumns = isTableCollapsed
    ? [
        {
          field: 'base_unit',
          headerName: 'Coin Name',
          minWidth: 200,
          renderCell: data => (
            <span className={Styles.coinNameColumn}>
              <img src={data.row.icon_url} />
              {data.row.coin_name}
              <CoinTag tag={data.row.name.split('/')[0].toUpperCase()} size={11} />
            </span>
          ),
          flex: 1
        },
        {
          field: 'last',
          headerName: 'Coin Price',
          // width: 135,
          editable: false,
          renderCell: data => (
            <span
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <span>{data.row.last}</span>
              <span
                style={{
                  color: data.row.price_change_percent[0] === '+' ? 'var(--positive-color)' : 'var(--negative-color)'
                }}
              >
                {data.row.price_change_percent}
                {data.row.price_change_percent[0] === '+' ? <BuyArrowIcon width={13} /> : <SellArrowIcon width={13} />}
              </span>
            </span>
          )
          // flex: 1
        }
      ]
    : [
        {
          field: 'base_unit',
          headerName: 'Coin Name',
          minWidth: 200,
          renderCell: data => (
            <span className={Styles.coinNameColumn}>
              <img src={data.row.icon_url} />
              {data.row.coin_name}
              <CoinTag tag={data.row.name.split('/')[0].toUpperCase()} size={11} />
            </span>
          ),
          flex: 1
        },
        {
          field: 'last',
          headerName: 'Coin Price (USDT)',
          minWidth: 175,
          // width: 135,
          editable: false,
          renderCell: data => <span>{data.row.last}</span>
          // flex: 1
        },
        {
          field: 'price_change_percent',
          headerName: '24%',
          // width: 90,
          editable: false,
          // flex: 1,
          renderCell: data => (
            <span
              style={{
                color: data.row.price_change_percent[0] === '+' ? 'var(--positive-color)' : 'var(--negative-color)'
              }}
            >
              {data.row.price_change_percent}
              {data.row.price_change_percent[0] === '+' ? <BuyArrowIcon width={13} /> : <SellArrowIcon width={13} />}
            </span>
          ),
          headerClassName: Styles.columnToHide,
          cellClassName: Styles.columnToHide
        },
        {
          field: 'high',
          headerName: '24h High',
          // width: 90,
          editable: false,
          // flex: 1,
          renderCell: data => <>{`${data.row.high}`}</>,
          headerClassName: Styles.columnToHide,
          cellClassName: Styles.columnToHide
        },
        {
          field: 'low',
          headerName: '24h Low',
          // width: 90,
          editable: false,
          // flex: 1,
          renderCell: data => <>{`${data.row.low}`}</>,
          headerClassName: Styles.columnToHide,
          cellClassName: Styles.columnToHide
        },
        {
          field: 'action',
          headerName: 'Action',
          sortable: false,
          width: 180,
          editable: false,
          // flex: 1,
          renderCell: data => (
            <span className={Styles.actionColumn}>
              <TableButton
                buttonText='Trade'
                buttonIcon={<LinkIcon width={11} />}
                onClick={() => redirectToTradeHistory(data.row.id)}
              />
              <TableButton
                buttonText='Convert'
                buttonIcon={<LinkIcon width={11} />}
                onClick={() => redirectToConvertHistory(data.row.name)}
              />
            </span>
          ),
          headerClassName: Styles.columnToHide,
          cellClassName: Styles.columnToHide
        }
      ]

  return (
    <div className={Styles.pageContainer}>
      {/* left section container */}
      <div className={Styles.leftSectionContainer}>
        {isMobileView && <BuyCrypto />}

        {/* market coins card */}
        <div
          className={Styles.card}
          style={{
            display: isMobileView && 'none'
          }}
        >
          {/* card header */}
          <div className={Styles.cardHeader}>
            {/* left section */}
            <div className={Styles.cardHeaderLeft}>
              <div className={Styles.cardTitle}>
                <FormattedMessage id='page.markets.trade.marketCoins' />
              </div>
              <div className={Styles.cardSubTitle}>Glorious, not only for your eyes.</div>
            </div>

            {/* right section */}
            <div className={Styles.cardHeaderRight}>
              {!isMobileView && (
                <Search
                  placeholder='Search Coin Name'
                  onChange={debounce(handleMarketSearch, 500)}
                  ref={searchInputRef}
                />
              )}
            </div>
          </div>

          {/* card graph marquees container */}
          {!isTableCollapsed && (
            <div className={Styles.graphMarqueesContainer}>
              <Slider {...CarouselSettings} arrows={false}>
                {marketList
                  .filter(market => market.quote_unit === selectedCurrency && market.last !== '0.0')
                  .map(market => {
                    const klineData = klineArray.find(el => Object.keys(el).includes(market.id))
                    return (
                      <MarketGraphCard
                        key={market.coin_name}
                        icon={market.icon_url}
                        pairId={market.id}
                        coinName={market.coin_name.toUpperCase()}
                        price={market.last}
                        priceChangePercentage={market.price_change_percent}
                        marketKLine={klineData}
                        containerStyle={{ margin: '0 6px' }}
                      />
                    )
                  })}
              </Slider>
            </div>
          )}
        </div>

        {/* markets list card */}
        <div className={Styles.card}>
          {isMobileView && (
            <Search
              placeholder='Search Coin Name'
              onChange={debounce(handleMarketSearch, 500)}
              ref={searchInputRef}
              containerStyle={{
                width: '100%',
                marginBottom: 25
              }}
              style={{ fontSize: 15 }}
            />
          )}

          {/* Datagrid */}
          <MUIDatagrid
            disableSorting
            page={page}
            pageLimit={pageLimit}
            rows={!tableLoading ? filteredMarketList.filter(market => market.last !== '0.0') : ''}
            totalRowsCount={totalRows}
            loading={tableLoading}
            onRowClick={handleRowClick}
            onPageChange={handlePageChange}
            onSortModelChange={handleSortModelChange}
            getRowId={row => row.id}
            columns={marketsTableColumns}
            tableHeight={isTableCollapsed ? (isMobileView ? 930 : 997) : 930}
          />
        </div>
      </div>

      {/* right section container */}
      {!isMobileView && (
        <div className={Styles.rightSectionContainer}>
          <BuyCrypto />
          <Convert />
          <QuickBuySell selectedCurrency={selectedCurrency} />
        </div>
      )}

      {isMobileView && userData.email && <MarketFooter setModalStates={setModalStates} />}

      <WenbitModal
        open={modalStates.quickBuySellOpen}
        modalWidth='90%'
        render={() => (
          <QuickBuySell
            containerStyle={{
              backgroundColor: 'transparent'
            }}
            hideTitle
          />
        )}
        onClose={() =>
          setModalStates({
            quickBuySellOpen: false,
            convertOpen: false
          })
        }
        title='Quick Order'
      />
      <WenbitModal
        open={modalStates.convertOpen}
        modalWidth='90%'
        render={() => (
          <Convert
            hideTitle
            containerStyle={{
              backgroundColor: 'transparent'
            }}
          />
        )}
        onClose={() =>
          setModalStates({
            quickBuySellOpen: false,
            convertOpen: false
          })
        }
        title='Convert'
      />
    </div>
  )
}
export default injectIntl(markets)
