import React, { useEffect, useState } from "react"

// ** Redux Imports
import { useSelector } from "react-redux"
import { _getCoinifyPaymentMethods } from "../../redux/actions"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** API Imports
import { _getCoinKline } from "../../../convert/redux/actions"

// ** Utils Imports
import { handleBuyCrypto } from "../../helpers/handleBuyCrypto"
import { estimateUnitValue } from "../../../helpers/estimateValueBase"
import { showSelectCoinModal } from "../../../modals/select-currency/helpers"

// ** Component Imports
import Button from "../../../../components/Button/Button"
import BuyCryptoFormShimmer from "../BuyCryptoFormShimmer"
import CurrencyInput from "../../../../components/CurrencyInput/CurrencyInput"

// ** Yup Form Validation Imports
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

function BuyCryptoForm() {
  // ** Constants
  const limitValues = { min: 65, max: 1000 }

  // ** Schema
  const buyCryptoSchema = Yup.object().shape({
    fromValue: Yup.number()
      .required("Amount required")
      .typeError("Amount required")
      .min(limitValues.min, `Amount must be higher than ${limitValues.min}`)
      .max(limitValues.max, `Amount must be higher than ${limitValues.max}`)
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(buyCryptoSchema),
    mode: "onSubmit"
  })

  // ** States
  const userData = useSelector((state) => state.userData?.value)

  const [fromValue, setFromValue] = useState(null)
  const [toValue, setToValue] = useState(null)

  const marketsList = useSelector((state) => state.marketList.value)
  const marketTickers = useSelector((state) => state.marketTickers.value)

  const [loading, setLoading] = useState(false)
  const currencies = useSelector((state) => state.currencies.value)
  const [fromCurrency, setFromCurrency] = useState(
    currencies.find((el) => el.type === "fiat")
  ) // Fiat
  const [toCurrency, setToCurrency] = useState(
    currencies.find((el) => el.id === "btc")
  ) // Crypto
  const [klineData, setKlineData] = useState([])

  // ** Handlers
  const getKlineData = () => {
    _getCoinKline(
      {
        pairs: [`${toCurrency.id}usdt`],
        limit: 15
      },
      (data) => {
        setKlineData(
          data.find((el) => Object.keys(el)[0] === `${toCurrency.id}usdt`)
        )
      }
    )
  }

  const handleFromValueChange = (event) => {
    setFromValue(event.target.value)
    // setToValue("")
  }

  const handleToValueChange = (event) => {
    setToValue(event.target.value)
    // setFromValue("")
  }

  const onSubmit = (formData) => {
    handleBuyCrypto({
      fiatCoin: fromCurrency,
      cryptoCoin: toCurrency,
      fromValue: formData.fromValue,
      toValue
    })
  }

  let convertedPrice = estimateUnitValue(
    fromValue ? toCurrency.id : fromCurrency.id,
    fromValue ? fromCurrency.id : toCurrency.id,
    parseFloat(fromValue || toValue),
    currencies,
    marketsList,
    marketTickers
  )
  useEffect(() => {
    convertedPrice = estimateUnitValue(
      fromValue ? toCurrency.id : fromCurrency.id,
      fromValue ? fromCurrency.id : toCurrency.id,
      parseFloat(fromValue || toValue),
      currencies,
      marketsList,
      marketTickers
    )
    console.log(
      estimateUnitValue(
        fromValue ? toCurrency.id : fromCurrency.id,
        fromValue ? fromCurrency.id : toCurrency.id,
        parseFloat(fromValue || toValue),
        currencies,
        marketsList,
        marketTickers
      )
    )
  }, [fromValue, toValue, fromCurrency, toCurrency])

  // Fetch new kline data on toCurrency change
  useEffect(() => {
    getKlineData()
  }, [toCurrency])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Fiat Currency Input */}
      <CurrencyInput
        title="Pay"
        type="number"
        error={errors.fromValue}
        showDropdownArrow
        containerStyle={{
          backgroundColor: "var(--secondary-color)"
        }}
        currencyImg={fromCurrency.icon_url}
        coinId={fromCurrency.id}
        coinName={fromCurrency.id.toUpperCase()}
        onDropdownClick={() =>
          showSelectCoinModal({
            type: "fiat",
            callback: (coin) => setFromCurrency(coin)
          })
        }
        placeholder={`${limitValues.min} - ${limitValues.max}`}
        onChange={handleFromValueChange}
        // value={fromValue || convertedPrice ? fromValue || convertedPrice : ""}
        // {...register("fromValue")}
        defaultValue={""}
        register={register("fromValue")}
      />
      {/* Rate */}
      <div className={Styles.priceRate}>
        {estimateUnitValue(
          toCurrency.id,
          fromCurrency.id,
          1,
          currencies,
          marketsList,
          marketTickers
        ) && (
          <>
            1 {toCurrency?.id?.toUpperCase()} ≈{" "}
            {(
              1 /
              estimateUnitValue(
                toCurrency.id,
                fromCurrency.id,
                1,
                currencies,
                marketsList,
                marketTickers
              )
            ).toFixed(fromCurrency.precision)}{" "}
            {fromCurrency?.id?.toUpperCase()}
          </>
        )}
      </div>

      {/* To Buy Currency */}
      <CurrencyInput
        title="Receive"
        readOnly
        showDropdownArrow
        containerStyle={{
          backgroundColor: "var(--secondary-color)"
        }}
        currencyImg={toCurrency.icon_url}
        coinId={toCurrency.id}
        coinName={toCurrency.id.toUpperCase()}
        onDropdownClick={() =>
          showSelectCoinModal({
            type: "coin",
            callback: (coin) => setToCurrency(coin)
          })
        }
        showChart
        klineData={klineData}
        placeholder="0.00"
        value={toValue || convertedPrice ? toValue || convertedPrice : ""}
        onChange={handleToValueChange}
      />

      {/* Buy Button */}
      <Button
        text={`Buy ${toCurrency.id.toUpperCase()}`}
        type={!userData.email ? "loggedOut" : "submit"}
        style={{
          marginTop: "25px"
        }}
        size="large"
      />
    </form>
  )
}

export default BuyCryptoForm
