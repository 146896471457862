import { createSlice } from "@reduxjs/toolkit"

const googleAuthModalSlice = createSlice({
  name: "Enable2FAModalState",
  initialState: {
    open: false,
    data: {
      barcode: "",
      url: "bt05hhjkndguj84hkilh3awsx"
    }
  },
  reducers: {
    setEnable2FAModalOpen: (state, action) => {
      state.open = action.payload
    },
    setEnable2FAModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setEnable2FAModalOpen, setEnable2FAModalData } =
  googleAuthModalSlice.actions

export default googleAuthModalSlice.reducer
