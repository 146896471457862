import { createSlice } from '@reduxjs/toolkit'

const statkingSubscribeSuccessModal = createSlice({
  name: 'statkingSubscribeSuccessModalState',
  initialState: {
    open: false,
    data: {
      title: '',
      callback: () => {}
    }
  },
  reducers: {
    setStatkingSubscribeSuccessModalOpen: (state, action) => {
      state.open = action.payload
    },
    setStatkingSubscribeSuccessModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setStatkingSubscribeSuccessModalOpen, setStatkingSubscribeSuccessModalData } =
  statkingSubscribeSuccessModal.actions

export default statkingSubscribeSuccessModal.reducer
