import React from "react"

// MUI
import MUISnackbar from "@mui/material/Snackbar"
import MUIAlert from "@mui/material/Alert"
import MUISlide from "@mui/material/Slide"
import IconButton from "@mui/material/IconButton"

// redux
import { Link } from "react-router-dom"

// styles
import "./snackbar.css"

// icons
import { ReactComponent as InfoIcon } from "../../assets/icons/imported/info.svg"
import { ReactComponent as CloseIcon } from "../../assets/icons/imported/close.svg"
import { ReactComponent as WarningIcon } from "../../assets/icons/imported/alert.svg"
import { ReactComponent as ErrorIcon } from "../../assets/icons/imported/declined-rounded.svg"
import { ReactComponent as SuccessIcon } from "../../assets/icons/imported/success-rounded.svg"

// redux
import { useDispatch, useSelector } from "react-redux"
import { setSnackbarOpen } from "./redux/snackbarSlice"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MUIAlert elevation={6} ref={ref} variant="outlined" {...props} />
})

function Snackbar() {
  const dispatch = useDispatch()

  const severity = useSelector((state) => state.snackbarState.data.severity)
  const snackbarData = useSelector((state) => state.snackbarState.data)
  const snackbarOpen = useSelector((state) => state.snackbarState.open)

  const accentColor =
    severity === "success"
      ? "#10B981"
      : severity === "warning"
      ? "#F7931A"
      : severity === "info"
      ? "var(--primary-color)"
      : severity === "error"
      ? "#EF4444"
      : ""
  const backgroundColor =
    severity === "success"
      ? "#D1FAE5"
      : severity === "warning"
      ? "#FFF1DF"
      : severity === "info"
      ? "#EAFCFC"
      : severity === "error"
      ? "#FEE2E2"
      : ""

  const handleClose = () => {
    dispatch(setSnackbarOpen(false))
  }

  const Transition = (props) => {
    return (
      <MUISlide
        {...props}
        direction={snackbarData.position === "topRight" ? "left" : "right"}
      />
    )
  }

  return (
    <MUISnackbar
      open={snackbarOpen}
      TransitionComponent={Transition}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={
        snackbarData.position === "topRight"
          ? { vertical: "top", horizontal: "right" }
          : { vertical: "bottom", horizontal: "left" }
      }
      style={snackbarData.position === "topRight" && { marginTop: 80 }}
    >
      <Alert
        severity="success"
        sx={{
          width: "100%",
          borderRadius: 2,
          borderWidth: 2,
          borderColor: accentColor,
          backgroundColor,
          fontFamily: '"Outfit", "Overpass" !important',
          display: "flex",
          alignItems: "center",
          color: "#000000b3"
        }}
        action={
          snackbarData?.actionType === "link" ? (
            <Link
              style={{
                marginTop: "-3.2px",
                fontSize: 12.5,
                color: accentColor
              }}
              to={snackbarData?.linkTo}
            >
              {snackbarData?.buttonMessage}
            </Link>
          ) : (
            snackbarData?.actionType === "close" && (
              <IconButton
                aria-label="close"
                sx={{ p: 1 }}
                onClick={handleClose}
              >
                <CloseIcon color={accentColor} width={10} height={10} />
              </IconButton>
            )
          )
        }
        icon={
          severity === "success" ? (
            <SuccessIcon color={accentColor} height={23} width={23} />
          ) : severity === "warning" ? (
            <WarningIcon color={accentColor} height={23} width={23} />
          ) : severity === "error" ? (
            <ErrorIcon color={accentColor} height={23} width={23} />
          ) : severity === "info" ? (
            <InfoIcon color={accentColor} height={23} width={23} />
          ) : (
            <></>
          )
        }
      >
        {snackbarData?.alertMessage}
      </Alert>
    </MUISnackbar>
  )
}

export default Snackbar
