// react
import React, { useEffect, useState } from 'react'

// react router
import { useNavigate, useSearchParams } from 'react-router-dom'

// redux
import { _getConversions } from '../../redux/actions'

// styles
import Styles from './conversionHistoryTab.module.css'

// components
import CoinTag from '../../../../components/CoinTag/CoinTag'
import CoinDisplay from '../../../../components/CoinDisplay/CoinDisplay'
import MUIDatagrid from '../../../../components/MUIDatagrid/MUIDatagrid'
import MobileRowDatagrid from '../../../../components/MUIDatagrid/MobileRowDatagrid'

// utils
import { isoStringToDate } from '../../../helpers/localeDate'
import Filters from '../../../../components/Filters'

function ConversionHistoryTab() {
  document.title = 'Wenbit: Conversion History'

  // ** URL Params
  const [filteringParams, setFilteringParams] = useSearchParams()

  // table states
  const pageLimit = 10
  const [page, setPage] = useState(1)

  const [totalRows, setTotalRows] = useState(0)
  const [conversions, setConversions] = useState([])
  const [tableLoading, setTableLoading] = useState(false)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 820)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 820)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // ** Handlers
  const fetchConversions = () => {
    setTableLoading(true)
    _getConversions(
      {
        page: filteringParams.get('page'),
        limit: pageLimit,
        base_unit: filteringParams.get('base_unit'),
        quote_unit: filteringParams.get('quote_unit'),
        time_from: filteringParams.get('time_from'),
        time_to: filteringParams.get('time_to')
      },
      res => {
        setTableLoading(false)
        setTotalRows(res.total)
        setConversions(res.data)
      },
      err => setTableLoading(false)
    )
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    setFilteringParams(prevParams => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set('page', value)
      return newParams
    })
  }

  useEffect(() => {
    // If no page is provided by URL
    if (!filteringParams.get('page')) {
      setFilteringParams(prevParams => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set('page', 1)
        return newParams
      })
    } else {
      // Else if page is provided by URL, set the page state
      setPage(Number(filteringParams.get('page')))
    }
  }, [])

  const conversionHistoryColumns = isMobileView
    ? [
        {
          minWidth: 180,
          flex: 1,
          field: '',
          renderCell: data => (
            <MobileRowDatagrid
              renderRowHeader={() => (
                <CoinDisplay
                  coinName={data.row.coin_name}
                  coinImgURL={data.row.icon_url}
                  coinTag={`${data.row.base_unit.toUpperCase()}/${data.row.quote_unit.toUpperCase()}`}
                />
              )}
              rows={[
                {
                  title: 'From',
                  value: (
                    <span>
                      {data.row.side === 'buy' ? data.row.total : data.row.amount}{' '}
                      {data.row.side === 'buy' ? data.row.quote_unit.toUpperCase() : data.row.base_unit.toUpperCase()}
                    </span>
                  )
                },
                {
                  title: 'To',
                  value: (
                    <span style={{ color: 'var(--text-color)' }} className={Styles.amountColumn}>
                      {data.row.side === 'buy' ? data.row.amount : data.row.total}{' '}
                      {data.row.side === 'buy' ? data.row.base_unit.toUpperCase() : data.row.quote_unit.toUpperCase()}
                    </span>
                  )
                },
                {
                  title: 'Conversion Rate',
                  value: (
                    <span>
                      1 {data.row.base_unit.toUpperCase()} = {data.row.price} {data.row.quote_unit.toUpperCase()}
                    </span>
                  )
                },
                {
                  title: 'Trade Date',
                  value: (
                    <span className={Styles.dateColumn} style={{ color: 'var(--text-color)' }}>
                      {isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}
                    </span>
                  )
                },
                {
                  title: 'Status',
                  value: (
                    <span style={{ color: 'var(--positive-color)' }}>
                      {data.row.state.charAt(0).toUpperCase() + data.row.state.slice(1)}
                    </span>
                  )
                }
              ]}
            />
          )
        }
      ]
    : [
        {
          minWidth: 180,
          field: 'markets',
          headerName: 'Pair',
          renderCell: data => (
            <span className={Styles.pairColumn}>
              <img src={data.row.icon_url} style={{ width: 27 }} />
              <span>{data.row.coin_name}</span>
              <CoinTag size={11} tag={`${data.row.base_unit.toUpperCase()}/${data.row.quote_unit.toUpperCase()}`} />
            </span>
          ),
          flex: 1
        },
        {
          minWidth: 120,
          field: 'price',
          headerName: 'Amount',
          renderCell: data => (
            <span>
              {data.row.side === 'buy' ? data.row.total : data.row.amount}{' '}
              {data.row.side === 'buy' ? data.row.quote_unit.toUpperCase() : data.row.base_unit.toUpperCase()}
            </span>
          ),
          flex: 1
        },
        {
          minWidth: 120,
          field: 'amount',
          headerName: 'Price',
          renderCell: data => (
            <span className={Styles.amountColumn}>
              {data.row.side === 'buy' ? data.row.amount : data.row.total}{' '}
              {data.row.side === 'buy' ? data.row.base_unit.toUpperCase() : data.row.quote_unit.toUpperCase()}
            </span>
          ),
          flex: 1
        },
        {
          minWidth: 185,
          headerName: 'Conversion Rate',
          renderCell: data => (
            <span>
              1 {data.row.base_unit.toUpperCase()} = {data.row.price} {data.row.quote_unit.toUpperCase()}
            </span>
          ),
          flex: 1
        },
        {
          minWidth: 170,
          field: 'done_at',
          headerName: 'Trade Date',
          renderCell: data => (
            <span className={Styles.dateColumn}>
              {isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}
            </span>
          ),
          flex: 1
        },
        {
          minWidth: 90,
          field: 'state',
          headerName: 'Status',
          renderCell: data => (
            <span className={Styles.actionColumn}>
              {data.row.state === 'done' ? (
                <span style={{ color: 'var(--positive-color)' }}>Done</span>
              ) : (
                <span style={{ color: 'var(--negative-color)' }}>Rejected</span>
              )}
            </span>
          ),
          flex: 1
        }
      ]

  return (
    // <div className={Styles.pageContainer}>
    <div className={Styles.tabContainer}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>Conversion History</h5>
          <span>Check out all your previous converted bits.</span>
        </div>
      </div>

      <Filters
        filteringParams={filteringParams}
        setFilteringParams={setFilteringParams}
        mobileBreakpoint='1008px'
        onChange={fetchConversions}
        filterBaseCoin
        filterQuoteCoin
        filterDateRange
      />

      <MUIDatagrid
        disableSorting
        page={page}
        rows={conversions}
        pageLimit={pageLimit}
        loading={tableLoading}
        hideHeader={isMobileView}
        totalRowsCount={totalRows}
        getRowId={row => row.id}
        onPageChange={handlePageChange}
        rowHeight={isMobileView && 220}
        columns={conversionHistoryColumns}
      />
    </div>
    // </div>
  )
}

export default ConversionHistoryTab
