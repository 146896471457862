import React from 'react'

// styles
import Styles from './DrawerNavigationMenuButton.module.css'

// icons
import { ReactComponent as ArrowIcon } from '../../assets/icons/imported/right-arrow.svg'

function DrawerNavigationMenuButton({
    text,
    icon,
    showArrow = false,
    isSelected = false,
    onClick = () => {},
    className = {},
    style
}) {
  return (
    <div
        className={`${Styles.container} ${isSelected && Styles.isSelected} ${className}`}
        onClick={onClick}
        style={{...style}}
    >
        {icon && icon}
        {text && <span>{text}</span>}
        {showArrow && <ArrowIcon className={Styles.arrowIcon}/>}
    </div>
  )
}

export default DrawerNavigationMenuButton