import { createSlice } from "@reduxjs/toolkit"

const coinifySlice = createSlice({
  name: "coinifyState",
  initialState: {
    data: null
  },
  reducers: {
    setCoinifyData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setCoinifyData } = coinifySlice.actions

export default coinifySlice.reducer
