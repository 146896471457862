import React, { useEffect, useState } from "react"

// styles
import Styles from "./terms-and-conditions.module.css"
import PageHeader from "../../components/PageHeader/PageHeader"
import { _getTerms } from "./redux/actions"

const terms = `
<div class="page" title="Page 4">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">1. The Wenbit Exchange Platform
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The Wenbit Exchange Program </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Platform (hereinafter referred to as the “Platform”, “we” or “us”) allows
its users (hereinafter referred to as “the Users” or “you”) to trade in </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets (as defined herein) and
provides other services related to Virtual asset</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">s (the “Services”). Fo</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">r convenience, you and we are hereby
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">collectively referred to as “both Parties” and individually as “each Party”.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The Platform is an ecosystem comprising the Platform’s websites, mobile applications, </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">clients, and other
applications that are developed to offer the Services, and includes independently operated platforms,
websites, and clients within the ecosystem. In case of any inconsistency between relevant terms of use of
the above platforms and this Agreement, the respective applicable terms of such platforms shall prevail.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“Wenbit Operators” refer to all parties that run the P</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">latform, including but not limited to legal persons,
unincorporated organisations and teams that provide the Services and are responsible for such Services.
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">For the avoidance of doubt, the “Platform”, “we” or “us” includes the Wenbit Operators. Under this
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Agreement, the Wenbit Operators may change as our business adjusts, in which case the changed
operators shall perform their obligations under this Agreement and provide the Services to you, and such
change does not affect your rights and interests under this Agreement. Additionally, the scope of Wenbit
Operators may be expanded due to the provision of new Services, in which case, if you continue to use
the Services, it is deemed that you have agreed to jointly execute this Agreement with the newly added
Wenbit Operators. In case of a dispute, you shall determine the entities by which this Agreement are
performed with you and the counterparties of the dispute, depending on the specific Services that you
use and the actions that affect your rights or interests.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You understand that this Website (as defined herein) is only intended to serve as a platform for you to
use the Services. You shall, at your sole discretion, carefully assess the authenticity, legality, and validity
of relevant Virtual assets and/or information, and solely bear the responsibilities and losses that may arise
therefrom.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Any opinions, information, discussion, analysis, prices, and other information we display on this Platform
shall not be construed as investment advice. We shall not be liable for any loss arising directly or indirectly
from reliance on the abovementioned information, including but not limited to, any loss of profits.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 5">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Unless you have an Account (as defined herein) with the Platform, you shall not use the Services intended
to be provided to Users with an Account.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">1.1. Risks
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">By using the Services through the Platform, you accept the risks associated with such Services. We
emphasise that:
</span></p>
					
					<ul style="list-style-type: disc">

          <li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
          <p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets available on the Platform may not be issued by us, financial institutions, or other legal
          entities.
          </span></p>
          </li>

						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The market for Virtual assets is new, uncertain and may not grow.
</span></p>
						</li>
						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets are highly speculative in nature and are not as established as other asset classes.
</span></p>
						</li>
						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets are volatile and are traded continuously with no limitations on price fluctuations.
</span></p>
						</li>
						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Trading in Virtual assets may lead to a partial or total loss in your capital.
</span></p>
						</li>
						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Actions by third parties such as market makers or governments may have a large impact on Virtual
assets.
</span></p>
						</li>
            <li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual asset markets are currently in the nascent phase of their development. As a result, clients may
              face disadvantages due to information imbalances while engaging in virtual asset transactions.
</span></p>
						</li>
            <li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets may not always be liquid or transferable. There may also not have any secondary
              market.</span></p>
						</li>
		
						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual asset trading is not suitable for the vast majority of people.
</span></p>
						</li>
						<li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Although we strive to comply with all applicable laws, rules, and regulations, we may not be regulated
in all jurisdictions that we operate in; and
</span></p>
						</li>
            <li style="font-size: 12.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">There may be other unpredictable or derivative risks aside from the abovementioned.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'"><br>
Please consider your own financial position before using the Services and if necessary, seek independent legal, financial, tax, accounting, or other advice.
</span></p>

					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the event of any conflict of interest on a transaction on the platform by an employee or senior officer
of WenGroup Ltd, the latter is obliged to full disclose of the conflict of interest to the Compliance Team
of WenGroup Ltd. The Compliance Team will review the matter and any decision shall be taken in line
with the prevailing laws and regulations.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">1.2. Restricted Jurisdictions
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">USERS FROM THE FOLLOWING JURISDICTIONS ARE PROHIBITED FROM USING ALL SERVICES: MAINLAND
CHINA, THE UNITED STATES OF AMERICA, CANADA, JAPAN, CUBA, IRAN, NORTH KOREA, SUDAN, SYRIA,
VENEZUELA, SINGAPORE, CRIMEA, AFGANISTAN, CONGO, HAITI, IRAQ, LEBANON, LIBYA, MALI,
MYANMAR, SOMALIA AND YEMEN.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 6">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">THE LIST OF RESTRICTED JURISDICTIONS MAY CHANGE AND DIFFER ACCORDING TO CHANGES IN OUR
POLICIES AND THE SERVICES WE OFFER TO A PARTICULAR JURISDICTION. IN THE EVENT OF SUCH
CHANGE, WE MAY NOT PROVIDE </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">N</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">OTICE TO YOU. YOU ARE REQUIRED TO REFER TO THE LATEST
INFORMATION ON THE PLATFORM OR THIS AGREEMENT TO BE INFORMED OF SUCH CHANGES.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">1.3. General Terms
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The user agreement, as updated from time to time (hereinafter referred to as “this Agreement” or “these
terms and conditions”) consists of this user agreement, Appendices, the Privacy Policy, the Fiat Rules, the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">KYC and AML Policies, the user agreements or terms and conditions of each Service and any other
guidelines, rules, statements, and instructions published on the Platform from time to time.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall read this Agreement carefully before using the Platform to access the Services and seek
independent legal advice if necessary. If you do not agree with or understand this Agreement, please log
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">out of your Account immediately and cease to use the Services. By clicking on “I Agree”, creating an
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Account, obtaining your Account identification number, and creating a password, logging into your
Account or using the Services, you shall be deemed to have understood, bound by and agreed in full of
the Agreement, including any modifications made to this Agreement from time to time.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">It is your responsibility to abide by your local laws in relation to the use of the Services as well as all
applicable laws, rules, and regulations. By using the Services, you confirm and acknowledge that all your
funds come from legitimate sources and do not originate from illegal activities. You agree that we shall
have the right to collect and provide the necessary information to the relevant authorities to verify the
legality of the source of funds and your use of funds.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All headings herein are exclusively for the convenience of wording and are not intended to expand or limit
the content or scope of the terms and conditions of this Agreement. All information on the Platform and
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">this Agreement are provided on an “as is” and “as available” basis, with no further promises made by us.
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Headings in this Agreement are for convenience only and shall not govern the meaning or interpretation
of any provision of this Agreement.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">1.4. Definitions
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Account</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means your account with the Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Agreement</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means the Wenbit </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Platform User Terms and Conditions. WenBit Exchange is a virtual asset
exchange platform offered by WenGroup Ltd, a private company limited by shares, incorporated under
the laws of Mauritius and having its principal business address at 6th Floor, ABSA House, Wall Street,
Ebene Cybercity, Mauritius. These terms and conditions constitute your agreement with WenGroup Ltd.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The “</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Appendices</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” set out the individual product service agreements made available by the Platform to
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Users.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 7">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">AML/CTF</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means anti</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">-money laundering/counter terrorism financing.<br/><br/>
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Business Days</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means a day other than a Saturday, Sunday or a day gazetted as a public holiday when the banks are open for the transaction of business.
</span></p>
					
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Buy Price</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 7.1.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Dealing with your Accounts</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 4.4.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Virtual assets</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means a digital representation of value that can be digitally transferred, </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">stored, and
traded which is supported by the Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Exchange Rate</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">described in clause 7.1.<br><br>
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Fiat Trading</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means peer to peer trading, depositing of fiat currencies into an Account, quick buy of Virtual assets through liquidity providers or through a third-party platform.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">forks</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 6.2</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">.<br><br>
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">ICC International Court of Arbitration</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 17.11.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Identification Information</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 10.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Intellectual Property</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 11.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Internal Resolution</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 17.11.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Login Details</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 4.3.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Miner fees</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">4.1.<br><br>
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Party</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means each party to this Agreement, which are the Users and the Platform. <br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Platform</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means the Wenbit Global Platform.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Pre-Closing Period</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 4.4.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Registration Data</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 4.1.<br><br>
“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Sanctioned Country</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 1</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">1.8.<br><br>
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Sanctioned Person</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 1</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">1.8.<br>
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 8">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Sell Price</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 7.1.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Services</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means the products and services offered to Users through the Platform, as described in the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Appendices. The Services are offered by WenGroup Ltd which is regulated by the Mauritius Financial
Services Commission as a Virtual Asset Marketplace (Class S) licence.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Trading Fee</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 8.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Transaction</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” has the meaning as described in clause 4.2.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Users</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">users of the Wenbit Global Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Website</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means the website or other gateways, such as the mobile application, where Users can access
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">the Platform to use the Services.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">2. Account<br>
2.1. Account Creation
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To be eligible to create an Account, you confirm that you are a natural person, a legal person, or any other
entity with the authority to enter into this agreement and use the Services in compliance with all
applicable laws, rules, and regulations. You shall not create and Account for the purposes of violating any
applicable laws, rules, and regulations and/or undermine the Services provided on the Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Should you not be eligible to create an Account, we reserve the right to refuse your registration for an
Account and if you have already created an Account, we shall have the right to suspend, terminate and/or
close your account without any advance notice to you and hold you accountable for any adverse
consequences arising therefrom. We reserve the right to reject any applications to create an Account at
our sole discretion, including, but not limited to Accounts relating to political and/or public figures or to
comply with AML/CTF laws and regulations.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To create an Account, you shall provide, where necessary:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;a valid email address.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;a mobile phone number.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;your real name.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;identification documentation; and
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) &nbsp;any other information as required by us to create and maintain your Account,
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">together, the </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“Registration Data”.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 9">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the event where your mobile phone number requires any registration with your real name, you shall
complete such registration process before providing the mobile phone number to us. You shall be
responsible for and bear any losses, direct or indirect, and any adverse consequences arising from the lack
of such registration.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are responsible for updating the Registration Data and ensure that it is accurate at all times. You shall
be responsible for the authenticity, integrity and accuracy of the Registration Data and bear any direct or
indirect loss and adverse consequences arising from any inaccuracies of your Registration Data.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">After we verify and approve the creation of your Account, you will receive an account identification
number and password which you can use to use the Services. You agree to receive emails and/or messages
sent by us relating to the management and operation of the Platform.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">2.2. Account Usage
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You have the right to use your Account to access the Platform to use the Services, including:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 11.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To browse the real-time quotes and transaction information of Virtual asset products on this
Website.
</span></p>
						</li>
						<li style="font-size: 11.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To submit virtual asset </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">transactions, whether to trade or otherwise (each, a “Transaction”)
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">instructions.
</span></p>
						</li>
						<li style="font-size: 11.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To complete Transactions.
</span></p>
						</li>
						<li style="font-size: 11.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">View the information provided to Users with your Account.
</span></p>
						</li>
						<li style="font-size: 11.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Participate in activities organised by us in accordance with the relevant rules of the activity;
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">and/or
</span></p>
						</li>
						<li style="font-size: 11.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Other Services that we may offer to you from time to time.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">2.3. Account Security
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are solely responsible for the login details associated with your account, including your username,
password, mobile number, Transaction passwords and verification codes received through your mobile
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">phone (“Login Details”). If your Login Details are compromised and/or your account has been accessed by
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">an unauthorised third party, you may inform us immediately to request a suspension of your Account.
After receiving such request, we shall act on your request within a reasonable period. However, we shall
not be liable for any consequences before the suspension of your Account.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Unless with our consent, you shall not assign your Account to any other person, whether through a
donation, loan, lease, transfer or otherwise.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We are under no obligation to issue any replacement Virtual assets in the event that any Virtual assets,
password or private key is lost, stolen, malfunctioning, destroyed or otherwise inaccessible.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 10">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">2.4. Suspension, Termination or Closure
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We reserve the right to suspend certain or all Services to your Account, freeze the Virtual assets in your
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Account, terminate your Account, close your Account and/or notify the relevant authorities (“Dealing </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">with
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">your Accounts”) if:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">we reasonably believe that we need to do so in order to protect our reputation.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Use of your Account is subject to any pending litigation, investigation, or government proceeding
and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Account activity.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are from a restricted jurisdiction as stated in clause 1.2.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are not eligible to create an Account in accordance with clause 4.1.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">When you create an Account on behalf of a legal entity and you are not an authorised person to
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">act on behalf of such legal entity.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We decide, in our sole discretion, that you are not suitable to use the Services.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are not the person as stated in the Registration Data associated with your Account.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You do not log into your Account for an uninterrupted period of 1 year.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You use your Account to engage in illegal or illegitimate activities, such as money laundering and
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">bribery.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You use your Account to engage in market manipulative activities, improper transactions, or other
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">illicit trading activities such as money laundering, smuggling and commercial bribery; and/or
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are in breach of this Agreement or any other applicable laws, rules, and regulations.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Notwithstanding the above, we reserve the right to close and terminate your account by providing you
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">with 7 Business Days’ notice. Thereafter, you shall have 7 Business </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Days to take any necessary actions,
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">including cancelling any orders or closing your positions (“Pre</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">-</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Closing Period”). After the end of the Pre</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">-
Closing Period, we shall have the right to cancel your orders, close your positions and return the Virtual
assets in your Account to you. Notwithstanding anything in this Agreement, if your Account has been
closed in accordance with this clause, all remaining account balance (which includes charges and liabilities
owed to us) will be immediately payable to us.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall not be liable for any adverse consequences and/or losses associated with our Dealing with your
Accounts.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We maintain full custody of the Virtual assets, funds and User information/data which may be turned over
to governmental authorities in the event of </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">an Account’s suspension or closure arising from fraud
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">investigations, investigations of violation of law or violation of this Agreement.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">3. Deposits, Custody, Withdrawals and Transfers
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The Services are available only in connection with the Virtual assets that we support, which may change
from time to time. Under no circumstances should you use your Account to store, send, request, or receive Virtual assets in any form that we do not support. We assume no responsibility or liability in connection
with any attempt to use your Account for Virtual assets that we do not support. The Services do not
include any deposit, withdrawal, or custody of any fiat currencies.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 11">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All Virtual assets held in your Account are held by us on a custodial basis. This means that:
</span></p>
					<ul style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">❖    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Title to the Virtual assets shall remain with you and not transfer to us. As the owner of the Virtual
assets is your Account, you shall bear all risk of loss of such Virtual assets. We do not represent or
treat assets in Accounts as belonging to us.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">❖    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">We may not grant a security interest in the Virtual assets held in your Account.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">❖    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Except as required by relevant authorities or otherwise stated in this Agreement, we will not sell,
transfer, loan, hypothecate or otherwise alienate the assets in your Account unless instructed by you or compelled by a court of competent jurisdiction to do so.
</span></p>
							
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">❖    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">You control the Virtual assets in your Account. At any time, subject to outages, downtime, and
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">other applicable policies, you may withdraw your Virtual assets by sending it to a different blockchain address controlled by you or a third party; and
</span></p>
							
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">❖    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">In order to hold the assets in your Account more securely, your Virtual assets are segregated from our own Virtual assets or funds by way of separately ledger accounting entries for User and our
              Accounts. Notwithstanding the foregoing, we shall not have any obligation to use different
              blockchain addresses to store Virtual assets owned by you and by other customers or by us.
</span></p>
							
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We reserve the right to set and adjust the limits on daily trading volume and withdrawals based on our
internal considerations, which include security and the state of the relevant Virtual asset networks.
</span></p>
							<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">4. Virtual asset Networks<br>
4.1. Virtual asset Transactions
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We will process Transactions in accordance with the instructions we receive from you. You should verify
all transaction information prior to submitting instructions to us. We do not guarantee the identity of any
User, receiver, requestee or other third party and will have no liability or responsibility for ensuring that
the information you provide is accurate and complete. Transactions cannot be reversed once they have
been broadcast to the relevant Virtual asset network.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We may charge network fees (“miner fees”) to process a Transaction on your behalf. We will calculate the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">miner fees at our discretion, although we will always notify you of the miner fees at (or before) the time
you authorise the Virtual asset Transaction. When you or a third party sends Virtual assets to an Account
from an external wallet not hosted on the Platform, the person initiating the transaction is solely
responsible for executing the transaction properly, which may include, among other things, payment of
miner fees in order for the transaction to be completed successfully. Non-payment of miner fees may
cause your Transaction to remain in a pending state outside of our control and we are not responsible for
</span></p>
							
						</li>
					</ul>
				</div>
			</div>
		</div><div class="page" title="Page 12">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">delays or loss incurred as a result of an error in the initiation of the transaction and have no obligation to
assist in the remediation of such Transactions.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Once submitted to a Virtual asset network, a Transaction will be unconfirmed for a period of time pending
sufficient confirmation of the Transaction by the Virtual asset network. A Transaction is not complete
while it is in a pending state. Virtual asset associated with Transactions that are in a pending state will be
designated accordingly and will not be included in your Account balance or be available to conduct
Transactions until confirmed by the network.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We may also refuse to process or cancel any pending Transaction as required by law, regulation or any
court or other authority to which we are subject in any jurisdiction, for instance, if there is suspicion of
money laundering, terrorist financing, fraud, or any other financial crime.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">4.2. Operation of Virtual asset Protocols
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We do not own or control the underlying software protocols which govern the operation of Virtual assets
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">supported on the Platform. Generally, the underlying protocols are ‘open source’, </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">and anyone can use,
copy, modify, and distribute them.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We assume no responsibility for the operation of the underlying protocols, and we are not able to
guarantee the functionality or security of network operations. You acknowledge and accept the risk that
underlying software protocols relating to any Virtual asset you store in your Account may change.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In particular, the underlying protocols are likely to be subject to sudden changes in operating rules
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">(“forks”). Any such material operating changes may materially affect the availability, value, functionality,
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">and/or the name of the Virtual asset you store in your Account. We do not control the timing and features
of these material operating changes. It is your responsibility to make yourself aware of upcoming
operating changes and you must carefully consider publicly available information and information that
may be provided by us in determining whether to continue to transact in the affected Virtual asset using
your Account. In the event of any such operational change, we reserve the right to take such steps as may
be necessary to protect the security and safety of assets held on the Platform, including temporarily
suspending operations for the involved virtual asset(s), and other necessary steps.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We will use our best efforts to provide you notice of our response to any material operating change.
However, such changes are outside of our control and may occur without notice to us. Our response to
any material operating change is subject to its sole discretion and includes deciding not to support any
new Virtual asset, fork, or other actions.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You acknowledge and accept the risks of operating changes to Virtual asset protocols and agree that we
are not responsible for such operating changes and not liable for any loss of value you may experience
because of such changes in operating rules. You acknowledge and accept that we have sole discretion to determine its response to any operating change and that we have no responsibility to assist you with
unsupported assets or protocols.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 13">
			<div class="layoutArea">
				<div class="column">
			
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5. Trading Rules
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall comply with the following rules when trading Virtual assets on the Platform:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall read, understand, and accept in full the information on the Platform before executing
your trade, including, but not limited to price, fees and whether the Virtual asset is to be bought
or sold.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">When you execute your trade and submit your Transaction, you authorise the Platform to act as
a broker for the relevant trade to match you with the relevant counterparty for your trade without
further notice to you.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You have the right to revoke or modify your Transaction at any time before the trade is executed;
and
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You can check the details and records of your trades on the Platform.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5.1. Exchange Rates
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Each purchase or sale of Virtual assets is also subject to the Exchange Rate for the given Transaction. The
"Exchange Rate" means the price of a given supported Virtual asset in fiat currency as quoted on the
Platform. The Exchange Rate is stated either as a "Buy Price" or as a "Sell Price", which is the price at which
you may buy or sell Virtual assets, respectively.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You acknowledge that the Buy Price Exchange Rate may not be the same as the Sell Price Exchange Rate
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">at any given time, and that there may be a margin or ‘spread’ to the quoted Exchange Rate. You agree to
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">accept the Exchange Rate when you authorise a Transaction. We do not guarantee the availability of any
Exchange Rate. We do not guarantee that you will be able to buy and/or sell your Virtual assets on the
open market at any particular price or time.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5.2. Fees
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree to be responsible for the payment of and pay all fees. Each purchase or sale of Virtual asset is
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">subject to a fee (“Trading Fee”). The applicable Trading Fee will be displayed to you on the Platform prior
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">to each trade and is stated in each receipt we issue to you. We may adjust our Trading Fees at any time.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5.3. Compliance
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 14">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Our KYC and AML/CTF policies are intended to comply with international laws and standards with regards
to KYC and AML/CTF, including the laws of your local jurisdiction. We shall comply with our KYC and
AML/CTF polices and use best efforts to adopt measures and technology to provide you the Services in a
safe and secure manner to prevent any losses due to money laundering.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You authorise us to make enquiries, whether directly or through third parties, that we consider necessary
to verify your identity or protect you and/or us against fraud or other financial crime, and to act we
reasonably deem necessary based on the results of such enquiries. When we carry out these enquiries,
you acknowledge and agree that your personal information may be disclosed to credit reference and fraud
prevention or financial crime agencies and that these agencies may respond to our enquiries in full. This
is an identity check only and should have no adverse effect on your credit rating. Additionally, we may
require you to wait some amount of time after completion of a Transaction before permitting you to use
further Services and/or before permitting you to engage in Transactions beyond certain volume limits.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If we are informed that any Virtual assets or funds received by your Account are stolen or otherwise are
not lawfully possessed by you, we may, but have no obligation to, place an administrative hold on the
affected funds and your Account. If we enact an administrative hold on some or all of your Virtual assets
or funds or Account, we may continue such hold until such time as the dispute has been resolved and
evidence of the resolution acceptable to us has been provided in a form acceptable to us. We will not
involve ourselves in any such dispute or the resolution of the dispute. You agree that we will have no
liability or responsibility for any such hold, or for your inability to withdraw Virtual assets or funds or
execute trades during the period of any such hold.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5.4. KYC and AML/CTF
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In relation to KYC and AML/CTF, we:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Shall promulgate and update our KYC and AML/CTF policies to meet the standards set by
applicable laws, rules, and regulations.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Shall promulgate and update the guidelines and rules relating to the operation of this Platform
and our staff shall facilitate the provision of the Services to you in accordance with such guidelines
and rules.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Shall design and execute procedures for transaction monitoring and control, which includes, but
is not limited to rigorous identity authentication procedures, and maintain a dedicated team to
address AML.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Adopt a risk-based approach to due diligence and supervision of our Users.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Have the right to review and inspect the transactions to, from and on our Platform.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Shall have the right to report Transactions that we deem in our sole discretion to be suspicious to the relevant authorities; and
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Shall maintain the Registration Data and Identification Information (as defined herein) for a minimum of 7 years.
</span></p>
						</li>
					</ul>
				</div>
			</div>
			<div class="layoutArea">
				
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 15">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Where you are an individual, we shall collect certain information from you, including, but not limited to,
your:
</span></p>
					<ul style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Name
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Address (and permanent address if it differs from your address). Your address (or permanent address) shall be verified in the manner prescribed by us.
</span></p>
							
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Valid email address
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Date of birth
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Nationality
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Photograph of you with your identification documents
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Official identification documents, including passports, identify cards (both front and back) and other identification documents issued by the relevant authorities; and
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Wingdings'">▪    </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Other information that we may request from you from time to time, the “Personal Identification Information”.
</span></p>
							
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Where you are a legal entity other than an individual, we shall collect certain information from you,
including, but not limited to, your:
</span></p>
							<ol style="list-style-type: none">
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;registration and incorporation documents.
</span></p>
								</li>
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;articles of association or memorandum.
</span></p>
								</li>
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;ownership structure and description of such ownership structure.
</span></p>
								</li>
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;board resolution designating your authorised person responsible for the Account.
</span></p>
								</li>
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) &nbsp;identification documents of the directors, major shareholders, and the authorised person(s).
</span></p>
								</li>
							</ol>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Generally, shareholder’s holding 10% or more of the relevant entity’s voting power is required to be
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">identified.
</span></p>
							<ol start="6" style="list-style-type: none">
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(vi) &nbsp;your address; and
</span></p>
								</li>
								<li>
									<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(vii) &nbsp;other information that we may request from you from time to time,
</span></p>
								</li>
							</ol>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">the </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“Corporate Identification Information” and together with the </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Personal Identification Information, the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">“Identification Information”.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Identification Information that we request from you may vary according to your jurisdiction and we also
may require you to notarise copies of documents you provide to us. We shall have the right to request
the notarised translation of your Identification Information to English if your Identification Information is
not provided in English.
</span></p>
							
						</li>
					</ul>
				</div>
			</div>
		</div><div class="page" title="Page 16">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5.5. Transaction Reporting
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall monitor the activity of your Account for suspicious transactions. If we determine a suspicious
transaction relating to your Account, we may suspend, halt, or terminate the transaction, reverse the
transaction and/or report the transaction to the relevant authorities, without notice to you. Any action
we take in response to a suspicious transaction shall not result in a breach of this Agreement.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">5.6. Suspended or Halt Trading
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall monitor the activity of your Account for stocks or security can no longer be traded on an
exchange. There are three types of limitation to trading which differs in terms of the period of time and
duration that the suspension or halt is effective as follows:
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">1. Suspended trading
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Suspended trading is when a certain stock or security can no longer be traded on an exchange for an
indefinite period. Suspended trading will prevent any further trades from being carried out.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">2. Trading halt
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">A trading halt refers to a temporary pause in the trading of a particular stock or security.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">3. Trading restrictions
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Trading restrictions refer to rules and regulations governing the types of trade that may be made on an
exchange. They are put in place to prevent any manipulation or fraudulent activities from taking place
within the market.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If any of the above events occur, when your Account is active preventing any further trades from being
carried out, we will inform you accordingly through our communication channels.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">6. Intellectual Property Rights
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All intellectual property relating to the Platform, including, but not limited to website name, goodwill,
trademarks, logos, databases, website design, text and graphics, software, photos, videos, music, sounds
and any combinations of the aforementioned files, and the intellectual property rights of software
compilation, associated source code and software (including small applications and scripts</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">) (“Intellectual
Property”) shall be owned by us. You shall not copy, modify, transmit or use any of the Intellectual
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Property for commercial purposes. Your use of the Services does not grant you any rights over the
Intellectual Property.
</span></p>
					
				</div>
			</div>
			
			
		</div><div class="page" title="Page 17">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Upon acceptance of this Agreement, it shall be deemed that you have transferred and assigned exclusively
and free of charge to us all copyright of any form of information that you publish on this Website,
including, but not limited to copyrights, distribution rights, lease rights, exhibition rights, performance
rights, projection rights, broadcasting rights, information network dissemination rights, shooting rights,
adaptation rights, translation rights, compilation rights and other transferable rights that copyright
owners are entitled to, and we shall have the right to sue for any infringement on such copyright and
obtain full compensation for such infringement. This Agreement shall apply to any content that is
published by you on this Website and is protected by copyright law, regardless of whether the content is
generated before or after the signing of this Agreement.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall not illegally use or dispose of our or any other person’s Intellectual Property when you use the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Services. You may not publish or authorise other websites or media to use the Intellectual Property in any
manner whatsoever.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">7. Rights and Obligations
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Notwithstanding anything in this Agreement, we reserve the right, in our sole discretion:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Where there are errors or updates, to amend information displayed on the Website.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To modify, suspend or terminate any or all the Services offered at any time without prior notice
to you. In the event of such termination, it will take effect on the date of the announcement of the termination on the Website.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Suspend or permanently terminate your access to your Account, the Platform, or your use of any or all the Services.
</span></p>
							
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If we suspect or determine that the information you provide to us is wrong, untrue, invalid and/or incomplete, notify you to correct or update the information you provide to us and if you do not
							correct and update the relevant information, suspend, or terminate the provision of Services to
							you.
</span></p>
						
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Delete any content or information on the Platform which does not comply with applicable laws,
rules, and regulations without prior notice to you.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In accordance with applicable laws, rules and regulations, request information from you. You shall
have an obligation to assist us with such request; and
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the interest of Users, under special circumstances which hinders the provision of Services on
the Platform, such as a system failure, network failure or in extreme market conditions, to amend
the Services provided. You shall be informed of such amendments through announcements on
the Website.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Unless otherwise stated in this Agreement, we shall:
</span></p>
						</li>
					</ul>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Use necessary technical means and management measures to ensure the normal operation of the
Platform and provide a necessary and reliable environment for your use of the Services.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Maintain the provision of Services to you.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Ensure the security of your Virtual assets by strengthening our technical capabilities and enhancing our security measures; and
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Provide you with advance notice regarding foreseeable security risks relating to your Account.
</span></p>
							
						</li>
					</ul>
				</div>
			</div>
		</div><div class="page" title="Page 18">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">8. Representations and Warranties
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">By agreeing to this Agreement, you represent, warrant and/or undertake that:
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
•
•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
•
•
•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
•
</span></p>
					<p><span style="font-size: 10.000000pt; font-family: 'SymbolMT'">•
•
</span></p>
				</div>
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">you have not been previously suspended or removed from using the Services.<br>
you are of statutory age, have the capacity and are authorised to enter into this Agreement and
use the Services as required by all applicable laws, rules, and regulations.<br>
your use of the Services and your Account activity is in compliance with all applicable laws, rules,
and regulations.<br>
all your Virtual assets are legally acquired and owned by you.<br>
you are solely liable for the actions taken using your Account.<br>
you are responsible for all profits, losses or liabilities arising from your transactions and use of
your Account.<br>
all the information you provide to us is true, accurate and complete.<br>
you shall make the relevant reports to authorities for taxation purposes.<br>
you shall refrain from engaging in any illegal activity through your use of the Services.<br>
you shall always refrain from engaging in or participating in any act or activity that damages the
interests of the Platform, whether or not in connection with the Services provided by this Website.
you shall not act in a way that is contrary to the intended use of the Platform, such as facilitating
the illegal transfer of information, spread false information about the Platform, actions that
infringe on our rights or the rights of other Users, invitations to pyramid schemes or falsifying and
relaying such false information to us or other Users.<br>
you shall not maliciously interfere with the normal operations of the Platform or oth</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">er Users’ use
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">of the Services, whether through technical means or otherwise.<br>
you shall not state or spread false defamatory information about us.<br>
in the event of a dispute between you and other User(s), you shall only resort to judicial or other
government-related means to request information from us.<br>
you are liable for all fees and taxes relating to any hardware, software and other third-party
services incurred by you in your use of the Services; and<br>
you shall comply with this Agreement and other guidelines and rules that we release in relation
to this Platform.
</span></p>

					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">9. Liability and Indemnification
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You understand and agree that we shall not be held liable, and you shall be solely accountable for any of
the following:
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">loss of income.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">loss of transaction profits.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">contractual losses.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">disruption of business.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">currency-related losses.
</span></p>
						</li>
					</ul>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 19">
			<div class="layoutArea">
				<div class="column">
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">loss or damage of information or data.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">loss of opportunities.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">loss from the price or value of Virtual assets.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">costs of purchasing products and services outside the scope of the Services.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">any failures in software, hardware, internet connection and any distortion and delays arising
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">therefrom.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">any indirect, special, or incidental loss or damages arising from any infringement (including
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">negligence) and/or breach of contract or any other cause, regardless of whether such loss or
damage may reasonably be foreseen by us and regardless of whether or not we are notified in
advance of the possibility of such loss or damages.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">any malicious programs such as viruses or trojans that you may have from your use of the Services.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">information, products, or business of third-party websites which link to this Website.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">loss arising from any information or technology on the Platform in relation to its validity, accuracy,
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">correctness, reliability, quality, stability, integrity, timeliness, applicability (including for a specific
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">purpose), freedom from error or omission and consistency.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">errors in our calculations of transactions on the Platform.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">damages:
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">to your goodwill or reputation.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">to you when we are reasonably justified to believe that your transactions and/or conduct on the
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Platform may involve any illegal activity or breach of this Agreement.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">arising from any expenses or losses arising from the purchase or acquisition of data, information,
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">or digital currency through the Services.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">arising from your misunderstanding of the Services; and/or
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">arising from any other loss relating to the Services which cannot be attributed to us.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">9.1. Limitation of Liability
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Notwithstanding anything in this Agreement, our liability to you shall not exceed your total cost incurred
from the period starting 3 months prior to the event giving rise to the liability and ending at the time of
the relevant event.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We do not waive any of the rights not mentioned in this Agreement and to the maximum extent permitted
by the applicable law, to limit, exempt or offset our liability for damages.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If you have a dispute with one or more Users of the Services (other than us), you agree that neither we
nor our affiliates or service providers, nor any of our respective officers, directors, agents, joint venturers,
employees and representatives, will be liable for any claims, demands and damages (actual and
consequential, direct or indirect) of any kind or nature arising out of or in any way connected with such
disputes.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">9.2. Indemnification
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 20">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree to indemnify us, our affiliates and service providers, and each of our, or their, respective
officers, directors, agents, employees and representatives, in respect of any costs (including attorneys'
fees and any fines, fees or penalties imposed by any regulatory authority) that have been reasonably
incurred in connection with any claims, demands or damages arising out of or related to your breach
and/or our enforcement of this Agreement or your violation of any law, rule or regulation, or the rights of
any third party.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">9.3. Our Guarantees
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The guarantees and undertakings specified in this Agreement shall be the only guarantee and statements
that we make in connection with the Services provided by us under this Agreement and through this
Website and shall supersede any and all the guarantees and undertakings arising in any other way and
manner, whether in writing or in words, express or implied. All these guarantees and undertakings
represent only our own guarantees and undertakings and do not guarantee any third party's compliance
with the guarantees and undertakings contained in this Agreement.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">9.4. Termination and Remedies for Breach
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall have the right at any time to terminate all Services offered by us to you in accordance with this
Agreement and this Agreement shall terminate on the relevant date of termination of all Services. You
shall have the right to terminate your use of the Services provided by us at any time and this Agreement
shall terminate on the relevant date of your termination of use of the Services. Upon termination of this
Agreement, you agree that you shall not have the right to require us to continue to provide the Services
to you or perform any other obligation, including, but not limited to, requesting us to keep or disclose to
you any information in your Account, or to disclose to you or any third party any information therein that
is not read or sent.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">9.5. Breach of Agreement
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the event you breach this Agreement or any applicable laws, rules, or regulations, you shall pay to us a
minimum of US$2 million in compensation and bear all expenses we incur in connection with such breach,
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">including any legal fees (“Compensation”). In the event the Compensation is unable to cover our losses,
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">you shall be liable for the additional amounts in excess of the Compensation.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">For the avoidance of doubt, the termination of this Agreement shall not prevent the non-breaching party
from demanding the breaching party to assume liabilities arising other than from this Agreement.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">10. Notices and Communications
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 21">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Users undertake to refer to the announcements, materials, and information on the Platform regularly and
promptly. We will not be held responsible or liable in any manner of compensation should you incur
personal losses arising from negligence of not referring to the announcements, materials, and
information. Any notice or other communication with the client will be in English language.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11. Miscellaneous
11.1. Assignment
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs,
executors, and administrators of the Parties hereto who benefit from the rights and obligations. Without
our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided,
however, we may, at any time, assign our rights and obligations under this Agreement to any third party
with 30 days' notice to you.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.2. Force Majeure
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Where we fail to provide the Services or delay in providing such Services due to circumstances not within
our control such as information network equipment maintenance, information network connectivity
failures, computer errors, communications or other systems, power failures, weather conditions,
unexpected accidents, industrial actions, labour disputes, revolts, uprisings, riots, lack of productivity or
production materials, fires, floods, storms, explosions, wars, failure on the part of banks or other partners,
collapse of the virtual asset market, actions by governments, judicial or administrative authorities, other
acts that are not within our control or beyond our inability to control, or due to causes on the part of third
parties, we shall not assume any responsibility for such failure to provide the Services to you or delay in
providing the Services, or for the resultant loss you may sustain as a result of such failure or delay.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.3. Relationship of Parties
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">This Agreement is only binding on the rights and obligations between you and us and does not involve
legal relations and legal disputes arising from and relating to Transactions between the users of this
Website, and between other parties and you.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your
agent, trustee, or other representative, unless it is provided otherwise in this Agreement.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.4. Entire Agreement
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 22">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If any provision of this Agreement is found unenforceable, invalid, or illegal by any court of competent
jurisdiction, validity of the remaining provisions of this Agreement shall not be affected.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.5. Amendment
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We reserve the right to determine, amend or modify any content of this Agreement at any time at our
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">sole discretion. The date and time displayed at the “Last updated on” section of this Agreement shall refer
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">to the timing of any changes to the provisions contained herein and the contents of this Agreement
(</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; color: rgb(0.000000%, 0.000000%, 100.000000%)">https://www.Wenbit.li/support/en-us/detail/360000298561</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">).The amended agreement will take effect
immediately upon announcement on the Platform. Although we have taken reasonable measures to
ensure the accuracy of the information on the Platform, we do not guarantee the degree of such accuracy
or bear any loss arising directly or indirectly from the information on the Platform or from any delay or
failure caused by failure to connect with the internet, transmit or receive any notice and information.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall be responsible for being updated with the amendments to this Agreement. If you do not agree
with the amendments, stop using the Services immediately. Any continued use of the Services shall be
deemed as you agreeing to the terms of this Agreement.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.6. Survival
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All provisions of this Agreement which by their nature extend beyond the expiration or termination of this
Agreement will continue to be binding and operate after the termination or expiration of this Agreement.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.7. Data Protection
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Your personal data will be properly protected and kept confidential, but we have the right to collect,
process, use or disclose your personal data in accordance with this Agreement or applicable laws.
Depending on the products or services concerned, your personal data may be disclosed to the following
third parties:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">your Transaction counterparties.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">our shareholders, partners, investors, directors, supervisors, senior managers, and employees.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">our joint ventures, alliance partners and business partners.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">our agents, contractors, suppliers, third-party service providers and professional advisers,
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">including the parties who have been contracted to provide us with administrative, financial,
research, operations, IT, and other services, in such areas as telecommunications, information
technology, payroll, information processing, training, market research and storage.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">third-party business partners who provide goods and services or sponsor contests or other
promotional activities, whether or not in cooperation with us.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">insurance companies or insurance investigators and credit providers.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">credit bureaus, or any debt collection agencies or dispute resolution centres in the event of
</span></p>
						</li>
					</ul>
				</div>
			</div>
			
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">dispute.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 23">
			<div class="layoutArea">
				<div class="column">
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">professional consultants such as auditors and lawyers.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">relevant government regulatory agencies or law enforcement agencies to comply with laws or
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">regulations implemented by government authorities.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">assignees of our rights and obligations.
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">banks, credit card companies and their respective service providers; and
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">persons with your consent as determined by you or the applicable contract.
</span></p>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Regarding the collection and use of your personal information and storage protection, the Privacy Policy
publicised on this Website shall prevail.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.8. Export Control
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Your use of the Services is subject to international export controls and economic sanctions requirements.
By sending, receiving, buying, selling, trading, or storing Virtual assets through your use of the Services,
you agree that you will comply with those requirements. You are not permitted to acquire Virtual assets
or use any of the Services if:
</span></p>
					<ul style="list-style-type: disc">
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">you are in, under the control of, or a national or resident of Cuba, Democratic Republic of Congo,
Democrat</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">ic People’s Republic of North Korea, Iran, Libya, Somalia, South Sudan, Sudan, Yemen or
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Syria or any other country subject to United States embargo, UN sanctions, the European Union,
the Monetary Authority of Singapore or HM Treasury's financial sanctions regimes (each a
"Sanctioned Country"), or if you are a person on the economic sanctions lists as published from
time to time by applicable authorities (including, but not limited to the Monetary Authority of
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Singapore’s Lists of Designated Individuals and </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Entities, U.S. Commerce Department's Denied
Persons List, Unverified List, Entity List, or the EU financial sanctions regime (a "Sanctioned
Person"); or
</span></p>
						</li>
						<li style="font-size: 10.000000pt; font-family: 'SymbolMT'">
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">you intend to supply any acquired or stored Virtual assets or Services to a Sanctioned Country (or
a national or resident of a Sanctioned Country) or Sanctioned Person.
</span></p>
						</li>
					</ul>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.9. No Waiver
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Our or your waiver of the right to hold the other Party liable for breaches of this Agreement or any other
liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to
hold the other Party for other breaches of contract. Any failure to exercise any right or remedy shall not
be construed in any way as a waiver of such right or remedy.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.10. Governing Law
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">This Agreement and the relationship between us shall be governed by and construed in accordance with
the laws of Mauritius.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 24">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.11. Dispute Resolution
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You acknowledge and agree that, before filing any dispute or claim, you will contact us
through support@Wenbitgroup.com where we will try to solve the relevant dispute internally as soon as
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">possible (“Internal Resolution”). The Parties agree to negotiate and solve any dispute in good faith (such
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">negotiation shall be confidential and protected under applicable laws, rules and regulations and will not
be used as evidence in any proceeding).
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You acknowledge and agree that, in case of any dispute, controversy, divergence or claim that can't be
solved by Internal Resolution, including any dispute in connection with the existence, validity,
interpretation, performance, breach or termination of this clause or any dispute concerning or arising out
of this clause (collectively referred to as the "Dispute"), the Parties shall submit the Dispute to the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">International Chamber of Commerce International Court of Arbitration (“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">ICC International Court of
Arbitration</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">”) according to the ICC Rules of Arbitration in effect. The arbitration shall take place in
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Mauritius and apply to applicable laws determined under the ICC Rules of Arbitration. Unless otherwise
agreed by the Parties hereto, only one arbitrator shall be appointed according to the ICC Rules of
Arbitration. The arbitrator shall have the exclusive right to make a ruling on his/her right of jurisdiction,
including but not limited to any objection to the existence, scope, or validity of the arbitration agreement
or the arbitrability of any claim or counterclaim. The arbitration shall be conducted in English. The
arbitrator shall, regardless of the method of arbitration, issue a reasonable written decision to explain the
arbitration award, its basic conclusion, and the conclusion (if any). The arbitration award shall be final and
binding upon the Parties and may be enforced by any court of competent jurisdiction.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.12. Unclaimed Property
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If we hold Virtual assets on your behalf, and we are unable to contact you and have no record of your use
of the Services for several years, applicable law may require us to report the Virtual assets as unclaimed
property to the authorities in certain jurisdictions. We will try to locate you at the address shown in our
records, but if we are unable to, we may be required to deliver any such Virtual assets to the authorities
in certain jurisdictions as unclaimed property. We reserve the right to deduct a dormancy fee or other
administrative charges from such unclaimed Virtual assets, as permitted by applicable law.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">11.13. Class Action Waiver
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree that any claims relating to this Agreement (whether based in contract, tort, statute, fraud,
misrepresentation, or any other legal theory, and whether the claims arise during or after the termination
of this Agreement) shall be brought against the other party in an arbitration on an individual basis only
and not as a plaintiff or class member in a purported class or representative action. You further agree to
waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or
private attorney general action, to the extent permissible by applicable law. Combining or consolidating
individual arbitrations into a single arbitration is not permitted without the consent of all Parties, including
us.
</span></p>
					
				</div>
			</div>
			
		</div><div class="page" title="Page 25">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12. Wenbit Spot Trading Services Agreement
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">Wenbit Spot Trading Platform
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The Wenbit Spot Trading Platform (hereinafter referred to as the “Platform”, “we” or “us”) allows its users
(hereinafter referred to as “Users” or “you”) to trade in </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets (as defined herein) through your
Spot Account (defined herein as </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">the “Platform Accounts”) (the “Services”). This Wenbit Spot Trading
Services Agreement (“Agreement”) shall apply to all the Services offered on the Platform. For
convenience, you and us are hereby collectively referred to as “both Parties” and individually as “each
Party”.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All terms and references used in this Agreement which are defined in the Wenbit Platform User
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Agreement (“User Agreement”) but are not defined in this Agreement shall have the same meaning and
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">construction in the terms of this Agreement.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">This Agreement is supplementary to the User Agreement and its terms shall be read together with the
User Agreement, guides, Platform rules, announcements or any other information on the Platform that
we may publish from time to time. In the event of any conflict between the terms of this Agreement and
the User Agreement, the User Agreement shall prevail.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.1. General Terms
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall read this Agreement carefully before using the Platform to access the Services and seek
independent legal advice if necessary. If you do not agree with or understand this Agreement, please log
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">out of your Platform Accounts immediately and cease to use the Services. By clicking on “Confirm”, "I
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Agree", creating your Platform Accounts, accessing your Platform Accounts or using the Services, you shall
be deemed to have understood, bound by and agreed in full of the Agreement, including any modifications
made to this Agreement from time to time.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All headings herein are exclusively for the convenience of wording and are not intended to expand or limit
the content or scope of the terms and conditions of this Agreement. All information on the Platform and
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">this Agreement are provided on an “as is” and “as available” basis, with no further promises made by us.
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Headings in this Agreement are for convenience only and shall not govern the meaning or interpretation
of any provision of this Agreement.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">“</span><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Users</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">” means the users of the Platform.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2. Platform Accounts<br>
12.2.1. Platform Account Creation
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 26">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To be eligible to create a Platform Account, you confirm that you are a natural person, a legal person, or
any other entity with the authority to enter into this agreement and use the Services in compliance with
all applicable laws, rules, and regulations. You shall not create a Platform Account for the purposes of
violating any applicable laws, rules, and regulations and/or undermine the Services provided on the
Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Should you not be eligible to create a Platform Account, we reserve the right to refuse your registration
for a Platform Account and if you have already created a Platform Account, we shall have the right to
suspend, terminate and/or close your Platform Account without any advance notice to you and hold you
accountable for any adverse consequences arising therefrom. We reserve the right to reject any
applications to create a Platform Account at our sole discretion, including, but not limited to Platform
Accounts relating to political and/or public figures or to comply with AML/CTF laws and regulations.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">To create a Platform Account, you shall provide the information necessary to fulfil the Platform’s KYC
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">procedure, which may include:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;a valid email address.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;a mobile phone number.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;your real name.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;identification documentation; and
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) &nbsp;any other information as required by us to create and maintain your Spot Account,
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">together, the “Registration Data”.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the event where your mobile phone number requires any registration with your real name, you shall
complete such registration process before providing the mobile phone number to us. You shall be
responsible for and bear any losses, direct or indirect, and any adverse consequences arising from the lack
of such registration.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You are responsible for updating the Registration Data and ensure that it is accurate at all times. You shall
be responsible for the authenticity, integrity and accuracy of the Registration Data and bear any direct or
indirect loss and adverse consequences arising from any inaccuracies of your Registration Data.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">After we verify and approve the creation of your Platform Account, you can transfer your Virtual assets to
your Platform Accounts and use the Services. You agree to receive emails and/or messages sent by us
relating to the management and operation of the Platform.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.2. Platform Account Usage
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
				</div>
			</div>
		</div><div class="page" title="Page 27">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You have the right to use your Platform Accounts to access the Platform to use the Services, including:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;to browse the real-time quotes and transaction information of Virtual assets on the Platform.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;to submit Virtual asset </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">transactions, whether to trade or otherwise (each, a “Transaction”);
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;to complete Transactions.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;view the information provided to Users with your Platform Account.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) &nbsp;participate in activities organised by us in accordance with the relevant rules of the activity; and/or
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(vi) &nbsp;other Services that we may offer to you from time to time.
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.3. Platform Account Security
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Unless with our consent, you shall not assign your Platform Accounts to any other person, whether
through a donation, loan, lease, transfer or otherwise.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We are under no obligation to issue any replacement Virtual assets in the event that any Virtual assets,
password or private key is lost, stolen, malfunctioning, destroyed or otherwise inaccessible.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.4. Suspension, Termination or Closure
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We reserve the right to suspend certain or all Services to your Platform Accounts, freeze the Virtual assets
in your Platform Accounts, terminate your Platform Accounts , close your Platform Accounts and/or notify
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">the relevant authorities (“Dealing with your Platform Accounts”) if:
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) we reasonably believe that we need to do so in order to protect our reputation.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) use of your Platform Account is subject to any pending litigation, investigation, or government
proceeding and/or we perceive a heightened risk of legal or regulatory non-compliance associated with
your Platform Account activity.
</span></p>
					<ol start="3" style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;you are not eligible to create a Platform Account in accordance with clause 4.1.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;when you create a Platform Account on behalf of a legal entity, and you are not an authorised
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">person to act on behalf of such legal entity.
</span></p>
					<ol start="5" style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) &nbsp;we decide, in our sole discretion, that you are not suitable to use the Services.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(vi) &nbsp;you are not the person as stated in the Registration Data associated with your Platform Account.
</span></p>
							
						</li>
					</ol>
				</div>
			</div>
		</div><div class="page" title="Page 28">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(vii) you do not log into your Platform Account for an uninterrupted period of 1 year.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(viii) you use your Platform Account to engage in illegal or illegitimate activities, such as money
laundering and bribery.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ix) you use your Platform Account to engage in market manipulative activities, improper transactions,
or other illicit trading activities such as money laundering, smuggling and commercial bribery; and/or
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(x) you are in breach of this Agreement or any other applicable laws, rules, and regulations.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Notwithstanding the above, we reserve the right to close and terminate your Platform Account by
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">providing you with 7 Business Days’ notice. Thereafter, you shall have 7 Business Days to take any
necessary actions, including cancelling any orders or making any Transactions (“Pre</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">-</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Closing Period”). After
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">the end of the Pre-Closing Period, we shall have the right to cancel your orders and return the Virtual
assets in your Platform Account to you. Notwithstanding anything in this Agreement, if your Platform
Account has been closed in accordance with this clause, all remaining account balance (which includes
charges and liabilities owed to us) will be immediately payable to us.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall not be liable for any adverse consequences and/or losses associated with our Dealing with your
Platform Accounts.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We maintain full custody of the Virtual assets, funds and User information/data which may be turned over
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">to governmental authorities in the event of a Platform Account’s suspension or closure arising from fraud
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">investigations, investigations of violation of law or violation of this Agreement
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.5. Deposits, Custody, Withdrawals and Transfers
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The Services are available only in connection with the Virtual assets that we support, which may change
from time to time. Under no circumstances should you use your Platform Account to store, send, request
or receive Virtual assets in any form that we do not support. We assume no responsibility or liability in
connection with any attempt to use your Platform Account for Virtual assets that we do not support. We
provide the Services to you and we are not a party to any Transactions of Virtual assets as a buyer or seller.
The Services do not include any deposit, withdrawal, or custody of any fiat currencies.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Unless otherwise stated on the Platform or in this Agreement, all Virtual assets held in your Platform
Account are held by us on a custodial basis. This means that:
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) title to the Virtual assets shall remain with you and not transfer to us. As the owner of the Virtual
assets is your Platform Account, you shall bear all risk of loss of such Virtual assets. We do not represent
or treat assets in Platform Accounts as belonging to us;
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 29">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) except as required by relevant authorities or otherwise stated in this Agreement, we will not sell,
transfer, loan, hypothecate or otherwise alienate the assets in your Platform Account unless instructed
by you or compelled by a court of competent jurisdiction to do so;
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) you control the Virtual assets in your Platform Account. At any time, subject to outages, downtime
and other applicable policies, you may withdraw your Virtual assets by sending it to a different Wenbit
account and/or different blockchain address controlled by you or a third party; and
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) your Virtual assets are segregated from our own Virtual assets or funds by way of separately ledger
accounting entries for you and our accounts. Notwithstanding the foregoing, we shall not have any
obligation to use different blockchain addresses to store Virtual assets owned by you and by other
customers or by us.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We reserve the right to set and adjust the limits on daily trading volume and withdrawals based on our
internal considerations, which include any investigations by the relevant authorities, the security and/or
the state of the relevant Virtual asset networks and/or any Loans (as defined herein) that are due and
payable.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.6. Spot Trading
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">After successfully registering for a Spot Account, you can transfer Virtual assets to your Spot Account to
place an order on the P</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">latform (“Order”). You may execute your Order through the order types made
available on the Platform (“Order Types”) and buy and/or sell your </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Virtual assets currently available on
the Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Each User is responsible for any Order made on the Platform, including to ensure that the Order is properly
submitted on the Platform through the relevant website, mobile application, or other means which we
may permit from time to time. Your Orders may be rejected by the Platform from time to time and it is
your responsibility to ensure that your Order has been executed. We shall not be liable for any loss
suffered as a result of any Order not being submitted by you or for your Order being rejected by the
Platform. Orders are submitted to the Platform at your own risk. We are entitled to rely on, and you are
bound by, all Orders submitted, and Transactions executed through your Spot Account.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall have the sole discretion to amend, suspend or terminate any or all of the Order Types and Virtual
assets available for trading on the Platform at any time and we shall not be liable for any losses incurred
by you in respect of such amendment, suspension or termination.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.7. Facilities
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Subject to the terms of this Agreement, we agree to provide to you loans of Virtual assets to support your
trading on the Platform subject to you meeting the Margin Ratio (as defined herein) in your Spot Account.
We reserve the right to vary or cancel partially or in full any amount of the Loan we provide to you.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 30">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Notwithstanding anything in this Agreement, we shall not be obligated to provide or continue to provide
any Loan to you. You agree to only use any Loan provided to you to use the Services on the Platform only.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree at all times to maintain in your Spot Account the minimum amount of Margin to satisfy the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">Platform’s margin ratio (“Margin Ratio”) that we prescribe from time to time. You are solely responsible
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">to monitor the Margin Ratio requirements of your Spot Account. In the event your Spot Account does not
contain sufficient Margin to meet the minimum Margin Ratio, you all be responsible to transfer additional
Margin to your Spot Account, whether or not a margin call has been sent to you to meet the minimum
Margin Ratio.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.8. Liquidation
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You acknowledge and agree that in the event the Margin Ratio in your Spot Account falls below the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">minimum Margin Ratio required by the Platform (“Liquidation Level”), you agree that we shall have the
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">sole and absolute discretion to liquidate any Margin in your Spot Account and/or cancel any open orders
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">in your Spot Account to bring your Margin Ratio above the Liquidation Level (“Forced Liquidation”). We
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">shall not be obligated to provide notice to you before commencing the Forced Liquidation on the Margin
in your Spot Account. In the event of such Forced Liquidation, you agree that:
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) you shall be liable for all fees relating to any Forced Liquidation.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) you shall be liable for any losses, whether realised or unrealised, resulting from the Forced
Liquidation; and
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) we shall not be liable for any losses, whether realised or unrealised, resulting from the Force
Liquidation.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree that if the Margin sold is insufficient to repay the Margin Loan and any interest accrued thereof,
you shall be liable for any remaining amounts owed to us. You shall be liable for a higher interest rate
depending on the period and amount of the Loan we provide to you.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.9. Leverage
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The Platform allows you to execute trades on leverage based on the amount that is offered by the
Platform at the time of your trade. After you select your leverage limit, all your positions shall use the
same leverage limit. By executing a leveraged trade, you agree and understand:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;the risks associated with trading Virtual assets using leverage.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;a leveraged position requires a higher level of risk management and risk tolerance and you have
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">accessed such risks in accordance with your personal circumstances.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 31">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) you are liable for all risks and losses associated with the use of leverage in trading Virtual assets on
the Platform; and
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) we are not responsible or liable for any losses you may incur by trading leveraged positions on the
Platform.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.10. Trading Bot
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The trading bot function (“Trading Bot”) allows you to set a price range whereby the Platform will assist
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">you to set buy and/or sell orders Virtual asset</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">s within the specified price range (“Grid”). The Grid shall
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">include parameters such as:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;the upper limit.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;the lower limit.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;number of grids.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;initial capital.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) &nbsp;take profit level; and
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(vi) &nbsp;stop loss level,
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">together, the “Parameters”.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You may either set the Parameters manually, copy the Parameters of other Users or through the
recommended inputs by the Platform.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">By using the Trading Bot, you agree that:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(a) &nbsp;you understand and agree to the relevant risk disclosure and disclaimer statements.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(b) &nbsp;you passed the Trading Bot knowledge test.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(c) &nbsp;we shall not be liable for any losses caused by your selection of recommended inputs by the
</span></p>
						</li>
					</ol>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Platform for your Grid.
</span></p>
					<ol start="4" style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(d) &nbsp;we shall not be liable for any losses through Transactions executed through the Trading Bot.
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(e) &nbsp;you shall be responsible for monitoring and managing the Trading Bot; and
</span></p>
							
						</li>
					</ol>
				</div>
			</div>
		</div><div class="page" title="Page 32">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(f) the expected yield displayed on the website is an estimation and we are not liable for any
difference between the expected yield and your actual profit or loss through your use of the Trading Bot.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.11. Calculations
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Notwithstanding anything in this Agreement and the trading rules of the Platform and unless otherwise
stated by us, you agree that all calculations performed by the Platform are final and conclusive.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.12. Fees and Premium
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall have the right to charge interest on any facilities provided to you at such rate as we determine
from time to time.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You sh</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">all promptly pay any fees, interest and commission owed to us and/or our service providers (“Fees”)
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">in such manner that we may stipulate from time to time with respect to your use of the Services. We shall
have the sole discretion to amend the Fees without prior notice to you and it is your sole responsibility to
ensure you are aware of the Fees associated with the Services.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree that all Fees owed may be settled by deducting the relevant amounts from your Platform
Accounts or other accounts you may have with us and you agree that any Fees owed is a debt immediately
due and payable to the relevant party.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the event you are unable to pay any of the Fees when they are due in the relevant Virtual asset owed,
we shall have the right to effect payment or repayment in any other Virtual asset that you have in your
Platform Accounts or other account with us at an exchange rate as determined by us at the time of
repayment.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Any applicable taxes, duties, disbursements, costs and/or other expenses incurred by us relating to your
use of the Services shall be borne by you.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.13. Market Misconduct
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You must not under any circumstances engage in any conduct which we determine to be market
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">misconduct (“Market Misconduct”), including, but not limited to:
</span></p>
					<ol style="list-style-type: none">
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(i) &nbsp;engage in any form of market manipulation, such as spoofing orders, false trading or price rigging;
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(ii) &nbsp;by taking any action which creates a false or misleading appearance of trades or prices;
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iii) &nbsp;taking any action to maintain an artificial price or manipulate the price;
</span></p>
						</li>
						<li>
							<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(iv) &nbsp;disclosing false or misleading information about the Platform; and/or
</span></p>
							
						</li>
					</ol>
				</div>
			</div>
		</div><div class="page" title="Page 33">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">(v) taking or omitting to take actions which would constitute fraud.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We shall have the discretion to use surveillance systems to monitor your activity on the Platform and shall
take any action which we deem necessary to address any actual or potential Market Misconduct.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.14. API Access
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Subject to your eligibility, you may be offered API access to use the Services on the Platform. We reserve
the right to limit, suspend or terminate any API access granted to you. Where using APIs to access the
Services, you agree that we shall not be liable for any losses incurred in your use of the relevant API.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.15. No Airdrop Rewards
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You agree that you shall be eligible for an</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">y airdrop reward (“Airdrop Reward”) and shall not have any legal
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">or equitable rights over such Airdrop Reward in relation to any Virtual assets which you borrowed
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">(“Ineligible Event”).
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Where you receive any Airdrop Rewards pursuant to any Ineligible Events, you shall be liable to return
any Airdrop Reward to us and we shall have the discretion to transfer the Airdrop Reward from your
Platform Account to our own proprietary wallets. Until we carry out such transfer, we may, at our sole
discretion, restrict you from withdrawing your Virtual assets from the Platform.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.16. Termination and Remedies for Breach
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">This Agreement shall be valid for a year from your consent to the terms and conditions and shall be
renewed automatically from year to year. We shall have the right at any time to terminate all Services
offered by us to you in accordance with this Agreement and this Agreement shall terminate on the
relevant date of termination of all Services. You shall have the right to terminate your use of the Services
provided by us at any time and this Agreement shall terminate on the relevant date of your termination
of use of the Services. Upon termination of this Agreement, you agree that you shall not have the right to
require us to continue to provide the Services to you or perform any other obligation, including, but not
limited to, requesting us to keep or disclose to you any information in your Platform Accounts, or to
disclose to you or any third party any information therein that is not read or sent.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.17. Breach of Agreement
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">In the event you breach this Agreement or any applicable laws, rules or regulations, you shall pay to us a
minimum of US$1 million in compensation and bear all expenses we incur in connection with such breach,
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">including any legal fees (“Compensation”)</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">. In the event the Compensation is unable to cover our losses,
you shall be liable for the additional amounts in excess of the Compensation.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">For the avoidance of doubt, the termination of this Agreement shall not prevent the non-breaching party
from demanding the breaching party to assume liabilities arising other than from this Agreement.
</span></p>
					
				</div>
			</div>
		</div><div class="page" title="Page 34">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.18. Notices and Communications
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Users undertake to refer to the announcements, materials, and information on the Platform regularly and
promptly. We will not be held responsible or liable in any manner of compensation should you incur
personal losses arising from negligence of not referring to the announcements, materials, and
information.
</span></p>
					<p><span style="font-size: 12.000000pt; font-family: 'Outfit'; font-weight: 700; color: var(--primary-color)">12.2.19. Miscellaneous
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(i) Assignment
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs,
executors, and administrators of the Parties hereto who benefit from the rights and obligations. Without
our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided,
however, we may, at any time, assign our rights and obligations under this Agreement to any third party
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">with 30 days’ notice to you.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(ii) Force Majeure
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">Where we fail to provide the Services or delay in providing such Services due to circumstances not
within our control such as information network equipment maintenance, information network
connectivity failures, computer errors, communications or other systems, power failures, weather
conditions, unexpected accidents, industrial actions, labour disputes, revolts, uprisings, riots, lack of
productivity or production materials, fires, floods, storms, explosions, wars, failure on the part of banks
or other partners, collapse of the virtual asset market, actions by governments, judicial or
administrative authorities, other acts that are not within our control or beyond our inability to control,
or due to causes on the part of third parties, we shall not assume any responsibility for such failure to
provide the Services to you or delay in providing the Services, or for the resultant loss you may sustain
as a result of such failure or delay.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(iii) Relationship of Parties
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">This Agreement is only binding on the rights and obligations between you and us and does not involve
legal relations and legal disputes arising from and relating to Transactions between the users of this
Website, and between other parties and you.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Nothing in this Agreement shall be deemed to have created, implied, or otherwise treated us as your
agent, trustee, or other representative, unless it is provided otherwise in this Agreement.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(iv) Entire Agreement
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">If any provision of this Agreement is found unenforceable, invalid, or illegal by any court of competent
jurisdiction, validity of the remaining provisions of this Agreement shall not be affected.
</span></p>
					
				</div>
			</div>
		</div><p>
		
	
	
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		</p><div class="page" title="Page 35">
			<div class="layoutArea">
				<div class="column">
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(v) Amendment
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">We reserve the right to determine, amend or modify any content of this Agreement at any time at our
sole discretion. The </span><span style="font-size: 11.000000pt; font-family: 'Outfit'">date and time displayed at the “Last updated on” section of this Agreement shall refer
</span><span style="font-size: 11.000000pt; font-family: 'Outfit'">to the timing of any changes to the provisions contained herein and the contents of this Agreement. The
amended agreement will take effect immediately upon announcement on the Platform. Although we have
taken reasonable measures to ensure the accuracy of the information on the Platform, we do not
guarantee the degree of such accuracy or bear any loss arising directly or indirectly from the information
on the Platform or from any delay or failure caused by failure to connect with the internet, transmit or
receive any notice and information.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">You shall be responsible for being updated with the amendments to this Agreement. If you do not agree
with the amendments, stop using the Services immediately. Any continued use of the Services shall be
deemed as you agreeing to the terms of this Agreement.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(vi) Survival
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">All provisions of this Agreement which by their nature extend beyond the expiration or termination of this
Agreement will continue to be binding and operate after the termination or expiration of this Agreement.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(vii) No Waiver
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">Our or your waiver of the right to hold the other Party liable for breaches of this Agreement or any other
liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to
hold the other Party for other breaches of contract. Any failure to exercise any right or remedy shall not
be construed in any way as a waiver of such right or remedy.
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'; font-weight: 700">(viii) Governing Law
</span></p>
					<p><span style="font-size: 11.000000pt; font-family: 'Outfit'">This Agreement and the relationship between us shall be governed by and construed in accordance with
the laws of Mauritius.
</span></p>
				</div>
			</div>
			<div class="layoutArea">
				<div class="column">
					
			</div>
		</div>
`

export default function TermsAndConditions() {
  const [terms, setTerms] = useState("")
  useEffect(() => {
    _getTerms(
      (data) => setTerms(data.data.TermsAndConditions),
      (err) => {
		//   console.log(err)
		}
    )
  }, [])

  return (
    <div className={Styles.pageContainwhater}>
      <PageHeader
        title={terms.title}
        // title="lorem ipsum jhfrm hev"
        // description="Lorem ipsum dolor sit amet. Est unde galisum et impedit praesentium aut enim consequatur non galisum ipsam At officia unde! Aut dicta consequatur et fuga
        // Lorem ipsum dolor sit amet. Est unde galisum et impedit praesentium aut enim consequatur non galisum ipsam At officia unde! Aut dicta consequatur et fuga"
      />

      <div
        style={{
          maxWidth: "1100px",
          margin: "0 auto",
          padding: 40
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: terms.body }} />
      </div>
    </div>
  )
}
