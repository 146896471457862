import { showErrorSnackbar } from '../../modules/snackbar/helpers'

/**
 *
 * @param {function} navigate The navigate hook function from react router V6
 */
const handleUnauthorized = (navigate, redirect_url) => {
  // Show toast error
  showErrorSnackbar({ alertMessage: 'Please Log In To Proceed' })

  // Navigate to login
  navigate(`/login?redirect_url=${encodeURIComponent(redirect_url)}`)
}

export default handleUnauthorized
