import React, { useState } from "react"

// react router
import { Link, useNavigate } from "react-router-dom"

// form validation
import { useForm } from "react-hook-form"

// redux
import { _login } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../redux/slices/userDataSlice"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"

// utilities
import _, { isEmpty } from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"

// components
import { Form } from "reactstrap"
import InputCode from "../../../../components/InputCode"
import Button from "../../../../components/Button/Button"

// styles
import Styles from "./codeConfirmation.module.css"

const CodeConfirmationTab = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const { errors, handleSubmit, control } = useForm()

  document.title = "Wenbit: Confirmation Code"
  const _loginSuccessCallback = (res) => {
    if (res?.email) {
      dispatch(setUser(res))
      navigate("/trade")
    }
  }

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      _login(
        {
          email
        },
        (data) => {
          _loginSuccessCallback(data)
        },
        (error) => {
          // console.log(error.errors)
          dispatch(setSnackbarOpen(true))
          dispatch(
            setSnackbarData({
              alertMessage: error.errors[0].length ? (
                <FormattedMessage id={error.errors[0]} />
              ) : (
                "Unknown Error"
              ),
              severity: "error",
              //buttonMessage: 'See order',
              callback: () => {},
              //actionType: 'link', // link - close
              //linkTo: '/account/orders/open',
              position: "topRight"
            })
          )
        }
      )
    }
  }

  return (
    <div className={Styles.tabContainer}>
      {/* form */}
      <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>
        {/* title */}
        <span className={Styles.title}>Enter your security code</span>

        {/* code input */}
        <InputCode
          length={4}
          label="Code Label"
          loading={loading}
          onComplete={(code) => {
            setLoading(true)
            setTimeout(() => setLoading(false), 10000)
          }}
        />
        {/* sign in button */}
        <Button text="Continue" type="submit" style={{ marginTop: 30 }} />

        <span className={Styles.linksContainer}>
          <Link to="/login">Log In</Link> or <Link to="/signup">Register</Link>
        </span>
      </Form>
    </div>
  )
}
export default injectIntl(CodeConfirmationTab)
