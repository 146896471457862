// ** React Imports
import React, { useEffect, useState } from "react"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** Redux Imports
import { useSelector, useDispatch } from "react-redux"
import { _resendEmailOTP, _resendPhoneOTP } from "./redux/actions"

// ** Component Imports
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"

// ** Form Validations Imports
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** Helpers
import { hideEmail } from "../../../helpers/HideEmail"
import { hideVerificationCodesInputModal } from "./helpers"

function VerificationCodesInput() {
  // ** Hooks
  const dispatch = useDispatch()

  // ** States
  const modalOpen = useSelector(
    (state) => state.VerificationCodesInputModalState.open
  )
  const modalData = useSelector(
    (state) => state.VerificationCodesInputModalState.data
  )
  const userData = useSelector((state) => state.userData.value)
  const [mobileCodeTimer, setMobileCodeTimer] = useState(0)
  const [emailCodeTimer, setEmailCodeTimer] = useState(null)

  // ** Form Validations
  // ** Schema Builder
  const buildSchema = (modalData) => {
    const validationObj = {}

    if (modalData.email) {
      validationObj.emailCode = Yup.string().required("Code is required")
      // .matches(/^\d{5}$/, "Code must be 5 digits")
      // .test("is-number", "Code must contain only digits", (value) => {
      //   return /^\d+$/.test(value);
      // });
    }

    if (modalData.sms) {
      validationObj.smsCode = Yup.string().required("Code is required")
      // .matches(/^\d{5}$/, "Code must be 5 digits")
      // .test("is-number", "Code must contain only digits", (value) => {
      //   return /^\d+$/.test(value);
      // });
    }

    if (modalData.otp) {
      validationObj.otpCode = Yup.string().required("Code is required")
      // .matches(/^\d{5}$/, "Code must be 5 digits")
      // .test("is-number", "Code must contain only digits", (value) => {
      //   return /^\d+$/.test(value);
      // });
    }

    return Yup.object().shape(validationObj)
  }
  // ** Schema
  const schema = buildSchema(modalData)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit"
  })

  // ** Handlers
  const onSubmit = (formData) => {
    modalData?.callback(formData)
  }

  const getEmailCode = () => {
    modalData.handleEmailResendCode().then(() => setEmailCodeTimer(60))
  }

  const getSMSCode = () => {
    modalData.handleSMSResendCode().then(() => setMobileCodeTimer(60))
  }

  // On close  -> reset modal states
  useEffect(() => {
    if (modalOpen) {
      reset()
      setMobileCodeTimer(modalData.initialSMSTimer)
      setEmailCodeTimer(modalData.initialEmailTimer)
    }
  }, [modalOpen])

  // ** SMS code timer
  useEffect(() => {
    let interval
    if (mobileCodeTimer > 0) {
      interval = setInterval(() => {
        setMobileCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (mobileCodeTimer === 0) {
      setMobileCodeTimer(null)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [mobileCodeTimer])

  // ** Email code timer
  useEffect(() => {
    let interval = null
    if (emailCodeTimer !== null && emailCodeTimer > 0) {
      interval = setInterval(() => {
        setEmailCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (emailCodeTimer === 0) {
      setEmailCodeTimer(null)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [emailCodeTimer])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={hideVerificationCodesInputModal}
        title={modalData.title}
        render={() => (
          <form onSubmit={handleSubmit(onSubmit)}>
            {modalData?.email && (
              <Input
                inputLabel="Email Verification Code"
                indicatorLabel={
                  userData?.email &&
                  `Enter the 6-digit code sent to ${hideEmail(userData.email)}`
                }
                showBorder
                icon={
                  <Button
                    variant="link"
                    text={
                      emailCodeTimer !== null
                        ? `Resend in ${emailCodeTimer}s`
                        : "Get Code"
                    }
                    onClick={emailCodeTimer === null ? getEmailCode : null}
                    disabled={emailCodeTimer !== null}
                  />
                }
                {...register("emailCode")}
                error={errors.emailCode}
                maxLength={6}
              />
            )}
            {modalData?.sms && (
              <Input
                inputLabel="Mobile verification code"
                indicatorLabel={
                  userData?.phones?.length &&
                  `Enter the 6-digit code sent to the phone number ends with ** ${userData.phones[0]?.number?.slice(
                    -2
                  )}`
                }
                showBorder
                icon={
                  <Button
                    variant="link"
                    text={
                      mobileCodeTimer > 0
                        ? `Resend in ${mobileCodeTimer}s`
                        : "Get Code"
                    }
                    onClick={mobileCodeTimer === null ? getSMSCode : null}
                    disabled={mobileCodeTimer > 0}
                  />
                }
                {...register("smsCode")}
                error={errors.smsCode}
                maxLength={6}
              />
            )}
            {modalData?.otp && (
              <Input
                inputLabel="Authenticator App Code"
                maxLength={6}
                showBorder
                {...register("otpCode")}
                error={errors.otpCode}
              />
            )}

            <Button type="submit" text="Submit" />
          </form>
        )}
      />
    </>
  )
}

export default VerificationCodesInput
