import { store } from "../../../../redux/store"
import { _getCoinData } from "../redux/actions"
import {
  setSelectNetworkModalData,
  setSelectNetworkModalOpen
} from "../redux/selectNetworkModalSlice"

export const showSelectNetworkModal = (coinId = "", callback = () => {}) => {
  store.dispatch(setSelectNetworkModalOpen(true))
  _getCoinData(
    coinId,
    (data) => {
      store.dispatch(
        setSelectNetworkModalData({
          coin_id: coinId,
          networks: data?.networks,
          callback
        })
      )
    },
    (err) => {}
  )
}

export const hideSelectNetworkModal = () => {
  store.dispatch(setSelectNetworkModalData({}))
  store.dispatch(setSelectNetworkModalOpen(false))
}
