// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Utils Imports
import { estimateUnitValue } from "../../modules/helpers/estimateValueBase"

function ExchangeRate({ fromCurrencyId, toCurrencyId, startText }) {
  // ** States
  const currencies = useSelector((state) => state.currencies.value)
  const marketsList = useSelector((state) => state.marketList.value)
  const marketTickers = useSelector((state) => state.marketTickers.value)

  return (
    <div className={styles.text}>
      {estimateUnitValue(
        toCurrencyId,
        fromCurrencyId,
        1,
        currencies,
        marketsList,
        marketTickers
      ) && (
        <>
          {startText}1 {fromCurrencyId?.toUpperCase()} ≈{" "}
          {estimateUnitValue(
            toCurrencyId,
            fromCurrencyId,
            1,
            currencies,
            marketsList,
            marketTickers
          ).slice(
            0,
            currencies.find((coin) => coin.id === fromCurrencyId).precision
          )}{" "}
          {toCurrencyId?.toUpperCase()}
        </>
      )}
    </div>
  )
}

export default ExchangeRate
