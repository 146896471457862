// ** React Imports
import React, { useEffect } from 'react'

// ** React Router Imports
import { useLocation, useNavigate } from 'react-router-dom'

// ** Utils Imports
import { API } from '../../utility/API'
import handleUnauthorized from '../../utility/user/handleUnauthorized'

const AxiosInterceptor = ({ children }) => {
  // ** Constants
  const apiEndpointsToIgnore = [
    'authenticate/resource/users/me' // Ignore redirection when the user is in login
    // 'trading/market/orders', // Ignore with the condition of the current page is the exchange page /trade
    // 'trading/account/balances', // Ignore balances API when in exchange page
    // 'trading/market/orders',
    // 'trading/public/markets'
  ]

  // ** Hooks
  const navigate = useNavigate()
  const location = useLocation()

  /**
   * NOTE: This global flag/booleab is used to track
   * wheter the user got redirected and stop redirection
   * of other 401 API calls
   */
  let isInterceptorCalled = false

  useEffect(() => {
    // Proceed with success calls
    const resInterceptor = response => {
      return response
    }

    // Check for error calls
    const errInterceptor = error => {
      const pathname = location.pathname
      const apiEndpoint = error.config.url

      // ** URL Params
      const urlParams = new URLSearchParams(location.search)
      const redirectUrl = urlParams.get('redirect_url')

      // Unauthorized case
      if (!isInterceptorCalled && error.response && error.response.status === 401) {
        // Set the flag to true to prevent further interceptor calls
        isInterceptorCalled = true
        if (
          apiEndpoint.split('?')[0].includes('authenticate/resource/users/me') ||
          !apiEndpointsToIgnore.includes(apiEndpoint.split('?')[0])
        ) {
          // Redirect to login if unauthorized
          if (!redirectUrl) {
            handleUnauthorized(navigate, location.pathname)
            isInterceptorCalled = false
          }
        }
      }
      //   else {
      //     toast.error('Something went wrong')
      //   }
      // Pass the error
      return Promise.reject(error)
    }

    const interceptor = API.interceptors.response.use(resInterceptor, errInterceptor)

    return () => {
      API.interceptors.response.eject(interceptor)
    }
  }, [])

  return <>{children}</>
}

export default AxiosInterceptor
