import React, { useState } from "react"

// redux
import { _generateCode } from "../../redux/actions"
import { useSelector, useDispatch } from "react-redux"
import { _changePassword } from "../../../user/redux/actions"
import { setBackdropOpen } from "../../../../redux/slices/backdropSlice"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"

// styles
import Styles from "./securityTab.module.css"

// icons
import SMSAuthIcon from "../../assets/security/sms-authentication.svg"
import EmailAuthIcon from "../../assets/security/email-authentication.svg"
import TFAIcon from "../../../../assets/icons/imported/two-factor-authenticator.svg"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import GoogleAuthModal from "../../../modals/security/Enable2FAModal/Enable2FAModal"
import CloseCircle from "../../../../components/icon-components/CloseCircle/CloseCircle"
import CheckmarkCircle from "../../../../components/icon-components/CheckmarkCircle/CheckmarkCircle"

// temp
import { showDisableSMSAuthModal } from "../../../modals/security/DisableSMSAuthModal/helpers"
import { showModifySMSAuthModal } from "../../../modals/security/ModifySMSAuthModal/helpers"
import { showEnableSMSAuthModal } from "../../../modals/security/EnableSMSAuthModal/helpers"
import { showEnable2FAModal } from "../../../modals/security/Enable2FAModal/helpers"
import { showDisable2FAModal } from "../../../modals/security/Disable2FAModal/helpers"
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar
} from "../../../snackbar/helpers"
import ResetPassword from "./ResetPassword"

function SecurityTab() {
  const dispatch = useDispatch()
  document.title = "Wenbit: Security"

  const modalOpen = useSelector((state) => state.Enable2FAModalState.open)
  const modalData = useSelector((state) => state.Enable2FAModalState.data)

  const userData = useSelector((state) => state.userData.value)
  const hasOtpLabel = userData?.otp
  const hasSMSAuth = userData?.sms_auth
  const isEmailVerified = userData?.labels?.some(
    (label) => label.key === "email" && label.value === "verified"
  )
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  // const handleGoogleAuth = () => {
  //   dispatch(setBackdropOpen(true))
  //   _generateCode(
  //     (data) => {
  //       dispatch(
  //         setEnable2FAModalData({
  //           barcode: data.data.barcode,
  //           url: data.data.url
  //         })
  //       )
  //       dispatch(setBackdropOpen(false))
  //       dispatch(setEnable2FAModalOpen(true))
  //     },
  //     (err) => {
  //       console.log(err)
  //     }
  //   )
  // }

  return (
    <div className={Styles.tabContainer}>
      <h5
        onClick={() => {
          showInfoSnackbar({
            alertMessage: "Hello",
            actionType: "close"
          })
        }}
      >
        Security
      </h5>

      <div className={Styles.authenticatorRow}>
        <div className={Styles.authenticatorRowLeft}>
          <img src={EmailAuthIcon} />
          <div>
            <span className={Styles.authTitle}>Email Authentication</span>
            <span className={Styles.authDescription}>
              We’ll send a digital bouncer to your inbox. Only VIPs allowed!
            </span>
            {isEmailVerified ? (
              <div className={Styles.activatedAuth}>
                <CheckmarkCircle size={13} />
                <span>ACTIVATED</span>
              </div>
            ) : (
              <div className={Styles.disabledAuth}>
                <CloseCircle size={11} />
                <span>NOT ACTIVATED</span>
              </div>
            )}
          </div>
        </div>
        {/* <Button
          variant="light"
          text="Enabled"
          disabled={true}
          style={{
            width: 100,
            transform: "scale(.85)"
          }}
        /> */}
      </div>

      <div className={Styles.authenticatorRow}>
        <div className={Styles.authenticatorRowLeft}>
          <img src={SMSAuthIcon} />
          <div>
            <span className={Styles.authTitle}>SMS Authentication</span>
            <span className={Styles.authDescription}>
              You’ll hear a ding on your phone. It's just us, making sure it's
              you.
            </span>
            {hasSMSAuth ? (
              <div className={Styles.activatedAuth}>
                <CheckmarkCircle size={13} />
                <span>ACTIVATED</span>
              </div>
            ) : (
              <div className={Styles.disabledAuth}>
                <CloseCircle size={11} />
                <span>NOT ACTIVATED</span>
              </div>
            )}
          </div>
        </div>
        {!hasSMSAuth ? (
          <Button
            variant="light"
            onClick={() => {
              showEnableSMSAuthModal()
            }}
            text="Enable"
            style={{
              width: 100,
              transform: "scale(.85)"
            }}
          />
        ) : (
          <div className={Styles.disabledAuthButtonsContainer}>
            <Button
              variant="link"
              text="Modify"
              onClick={() => {
                showModifySMSAuthModal()
              }}
            />
            <Button
              text="Disable"
              onClick={() => {
                showDisableSMSAuthModal()
              }}
              style={{
                backgroundColor: "#F87171",
                color: "white",
                transform: "scale(0.85)",
                width: 100
              }}
            />
          </div>
        )}
      </div>

      <div className={Styles.authenticatorRow}>
        <div className={Styles.authenticatorRowLeft}>
          <img src={TFAIcon} />
          <div>
            <span className={Styles.authTitle}>Two-Factor Authenticator</span>
            <span className={Styles.authDescription}>
              Keep your account on lockdown, and only you hold the key.
            </span>
            {hasOtpLabel ? (
              <div className={Styles.activatedAuth}>
                <CheckmarkCircle size={13} />
                <span>ACTIVATED</span>
              </div>
            ) : (
              <div className={Styles.disabledAuth}>
                <CloseCircle size={11} />
                <span>NOT ACTIVATED</span>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="light"
          text={hasOtpLabel ? "Disable" : "Enable"}
          // disabled={!hasOtpLabel}
          style={
            !hasOtpLabel
              ? {
                  width: 100,
                  transform: "scale(.85)"
                }
              : {
                  backgroundColor: "#F87171",
                  color: "white",
                  transform: "scale(0.85)",
                  width: 100
                }
          }
          onClick={hasOtpLabel ? showDisable2FAModal : showEnable2FAModal}
        />
      </div>

      {/* password section */}
      <ResetPassword />

      {modalOpen && <GoogleAuthModal />}
    </div>
  )
}

export default injectIntl(SecurityTab)
