import * as React from 'react'

// MUI
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

// prop types
import PropTypes from 'prop-types'

// icons
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/imported/down-arrow.svg'
import { useSelector } from 'react-redux'

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  background: 'none',
  // color: "red !important",
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ArrowDownIcon style={{ color: 'var(--text-color)' }} />} {...props} />
))(() => ({
  color: 'var(--text-color) !important',
  backgroundColor: 'transparent',
  padding: '13px 0',
  borderBottom: '1px var(--border-color) solid',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  color: theme === 'dark' ? '#AEB1BF' : '#797b88'
}))

Accordion.prototype = {
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      summary: PropTypes.string.isRequired,
      details: PropTypes.string.isRequired
    })
  ).isRequired,
  containerStyle: PropTypes.object
}

function Accordions({ accordions = [], containerStyle }) {
  const theme = useSelector(state => state.theme.value)
  const [expanded, setExpanded] = React.useState(null)
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  React.useEffect(() => {
    setExpanded(null)

    return () => {
      setExpanded(null)
    }
  }, [accordions])

  if (accordions.length > 0) {
    return (
      <div style={{ ...containerStyle }}>
        {accordions.map((accordion, i) => (
          <Accordion
            expanded={expanded === `panel${i}`}
            onChange={handleChange(`panel${i}`)}
            key={`accordion-${i}-main`}
          >
            <AccordionSummary aria-controls={`panel${i}-content`} id={`panel${i}-header`}>
              <div>{accordion.summary}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div>{accordion.details}</div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    )
  } else return null
}

export default Accordions
