import { useDispatch } from "react-redux"
import { store } from "../../../../redux/store"
import {
  setModifySAuthModalOpen,
  setModifySAuthModalData
} from "./redux/modifySMSAuthModalSlice"

export const showModifySMSAuthModal = () => {
  store.dispatch(
    setModifySAuthModalData({
      title: "Modify SMS Authenticator"
    })
  )
  store.dispatch(setModifySAuthModalOpen(true))
}

export const closeModifySMSAuthModal = () => {
  store.dispatch(setModifySAuthModalOpen(false))
}
