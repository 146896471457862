import { nationalitiesNames } from "./nationalities"
export const ar = {
  //Footer
  "page.footer.exchange": "تبادل",
  "page.footer.convert": "تحويل",
  "page.footer.launchpad": "منصة الإطلاق",
  "page.footer.wallet": "محفظة",
  "page.footer.support": "الدعم",
  "page.footer.requestForm": "نموذج طلب",
  "page.footer.contactSupport": "اتصل بالدعم",
  "page.footer.resources": "موارد",
  "resource.phone.new_number.please_modify_number_first":
    "لقد قمت بإدخال رقم جديد، قم بتعديله أولا.",
  "page.footer.downloads": "التحميلات",
  "page.footer.mobileApplication": "تطبيق الهاتف المحمول",
  "page.footer.buyCrypto": "شراء العملات المشفرة",
  "page.footer.referral": "الإحالة",
  "page.footer.listingTranding": "Listing Tranding",
  "page.footer.learn": "تعلم",
  "page.footer.whatsTrending": "ما هو الشائع",
  "page.footer.productNews": "أخبار المنتج",
  "page.footer.events": "الأحداث",
  "page.footer.university": "جامعة",
  "page.footer.research": "بحث",
  "page.footer.marketUpdate": "تحديث السوق",
  "page.footer.company": "شركة",
  "page.footer.aboutUs": "معلومات عنا",
  "page.footer.careers": "وظائف",
  "page.footer.news": "أخبار",
  "page.footer.security": "حماية",
  "page.footer.community": "مجتمع",
  "page.footer.announcements": "الإعلانات",
  "page.footer.allRightsReserved": "كل الحقوق محفوظة",
  "page.footer.privacy": "خصوصية",
  "page.footer.terms": "شروط",
  "page.footer.siteMap": "خريطة الموقع",
  "page.footer.iosDownload": "تنزيل من",
  "page.footer.androidDownload": "احصل عليه من",

  // Added :
  "page.header.navbar.blogs": "المدونات",
  "page.header.navbar.launchpad": "منصة الإطلاق",
  "page.header.navbar.exchange": "تبادل",
  "page.header.navbar.markets": "الأسواق",
  "page.header.navbar.convert": "تحويل",
  // 14 FEB
  "page.header.signInToWenbit": "قم بتسجيل الدخول إلى Wenbit",

  "page.markets.trade.marketCoins": "عملات السوق",

  "page.markets.trade.buy": "شراء",
  "page.markets.trade.sell": "بيع",
  "page.markets.trade.limit": "حد",
  "page.markets.trade.market": "سوق",
  "page.markets.trade.quantity": "كمية",
  "page.markets.trade.buyFor": "اشترِ مقابل",
  "page.markets.trade.sellFor": "بيع مقابل",
  "page.markets.trade.total": "المجموع",
  "page.markets.trade.fee": "الرسوم",
  "page.markets.trade.quickBuy": "شراء سريع",
  "page.markets.trade.quickSell": "بيع سريع",

  "page.markets.rows.name": "اسم العملة",
  "page.markets.rows.price": "سعر العملة",
  "page.markets.rows.24": "24%",
  "page.markets.rows.24High": "أعلى سعر لمدة 24 ساعة",
  "page.markets.rows.24Low": "أدنى سعر لمدة 24 ساعة",
  "page.markets.rows.chart": "جدول",

  "page.header.navbar.signIn": "تسجيل دخول",
  "page.header.navbar.signUp": "سجل الآن",
  "page.header.navbar.trade": "نجارة - تداول",
  "page.header.navbar.mining": " تعدين",
  "page.header.navbar.wallets": "محافظ",
  "page.header.navbar.openOrders": "طلبات",
  "page.header.navbar.history": " تاريخ - سجل",
  "page.header.navbar.profile": "ملف",
  "page.header.navbar.logout": "تسجيل خروج",
  "page.body.copy.success": "تم نسخ النص",
  "page.body.trade.header.markets": " أسواق ",
  "page.body.trade.header.markets.content.market": " سوق",
  "page.body.trade.header.markets.content.pair": " زوج - اقتران",
  "page.body.trade.header.markets.content.price": "سعر",
  "page.body.trade.header.markets.content.last_price": " آخر سعر",
  "page.body.trade.header.markets.content.change": " تغيير",
  "page.body.trade.header.markets.content.search": "بحث",
  "page.body.trade.header.markets.content.volume": "حجم - كميّة",
  "page.body.trade.header.newOrder": "نموذج طلب",
  "page.body.trade.header.newOrder.content.tabs.buy": " شراء",
  "page.body.trade.header.newOrder.content.tabs.sell": " بيع",
  "page.body.trade.header.newOrder.content.orderType": "طلب",
  "page.body.trade.header.newOrder.content.orderType.limit": "حد - سقف",
  "page.body.trade.header.newOrder.content.orderType.market": " سوق",
  "page.body.trade.header.newOrder.content.price": "سعر",
  "page.body.trade.header.newOrder.content.amount": " كمّية - مبلغ",
  "page.body.trade.header.newOrder.content.total": " مجموع",
  "page.body.trade.header.newOrder.content.available": " متاح",
  "page.body.trade.header.newOrder.content.estimatedFee": " رسوم",
  "page.body.trade.header.marketDepths": " عمق السوق",
  "page.body.trade.header.marketDepths.content.price": "  سعر",
  "page.body.trade.header.marketDepths.content.volume": "كمّية - مبلغ",
  "page.body.trade.header.marketDepths.content.cumulativeVolume": "  مجموع",
  "page.body.trade.header.marketDepths.content.cumulativeValue":
    "قيمة تقريبيّة",
  "page.body.trade.header.asks": "يطلب",
  "page.body.trade.header.bids": " عطاءات",
  "page.body.trade.orderbook": " سجل الطلبيات",
  "page.body.trade.orderbook.header.price": " سعر",
  "page.body.trade.orderbook.header.amount": " كمّية - مبلغ",
  "page.body.trade.orderbook.header.volume": " مجموع",
  "page.body.trade.header.recentTrades": " نجارة - تداول",
  "page.body.trade.header.recentTrades.content.time": "  وقت",
  "page.body.trade.header.recentTrades.content.price": "  سعر",
  "page.body.trade.header.recentTrades.content.amount": "كمّية - مبلغ",
  "page.body.trade.header.openOrders": "طلبات فعّالة",
  "page.body.trade.header.openOrders.content.date": "تاريخ",
  "page.body.trade.header.openOrders.content.state": "حالة",
  "page.body.trade.header.openOrders.content.price": "سعر",
  "page.body.trade.header.openOrders.content.amount": "كمّية - مبلغ",
  "page.body.trade.header.openOrders.content.total": " قيمة",
  "page.body.trade.header.openOrders.content.filled": " معبّأ - مملوء",
  "page.body.wallets.estimated_value": " قيمة تقريبيّة – قيمة مقدّرة",
  "page.body.wallets.locked": "مقفل - مغلق",
  "page.body.wallets.balance": "توازن – رصيد حساب",
  "page.body.wallets.tabs.deposit": "وديعة - ايداع",
  "page.body.wallets.tabs.deposit.ccy.message.submit":
    " قم بالإيداع باستخدام العنوان التالي او رمز الاستجابةالسريع. ستنعكس عملية الإيداع على حسابك بعد 6 عمليات تأكيد ",

  "page.body.wallets.tabs.deposit.ccy.message.address": " عنوان الإيداع ",
  "page.body.wallets.tabs.deposit.ccy.message.button": " نسخة",
  "page.body.wallets.tabs.deposit.ccy.message.success": " تم نسخ العنوان",
  "page.body.wallets.tabs.deposit.ccy.message.error":
    " يتم انشاء عنوان إيداع , يرجى إعادة المحاولة اذا استغرق المزيد من الوقت ",

  "page.body.wallets.tabs.deposit.fiat.message1":
    "الإيداع باستخدام حوالة بنكيّة ",
  "page.body.wallets.tabs.deposit.fiat.message2":
    " يرجى استخدام المعطيات التالية لإتمام حوالتك البنكيّة. عملية الإيداع ستنعكس على حسابك خلال يومين عمل ",

  "page.body.wallets.tabs.deposit.fiat.bankName": " اسم البنك",
  "page.body.wallets.tabs.deposit.fiat.accountNumber": "رقم الحساب",
  "page.body.wallets.tabs.deposit.fiat.accountName": "اسم الحساب",
  "page.body.wallets.tabs.deposit.fiat.branchNumber": "رقم الفرع",
  "page.body.wallets.tabs.deposit.fiat.branchAddress": "  عنوان الفرع",
  "page.body.wallets.tabs.deposit.fiat.swiftCode": " رمز (السويفت)",
  "page.body.wallets.tabs.deposit.fiat.phoneNumber": " رقم تلفون",
  "page.body.wallets.tabs.deposit.fiat.referenceCode": " رمزك المرجعي",
  "page.body.wallets.tabs.deposit.fiat.etrans_message1":
    " إيداع باستخدام حوالة عن طريق عنوان البريد الالكتروني",
  "page.body.wallets.tabs.deposit.fiat.etrans_message2":
    "  يرجى استخدام عنوان البريد الالكتروني التالي لإتمام حوالتك. عملية الإيداع ستنعكس على حسابك خلال يومين عمل",
  "page.body.wallets.tabs.deposit.fiat.etrans_email": " البريد الالكتروني",
  "page.body.wallets.table.pending": " جاري العمل عليه",
  "page.body.wallets.tabs.deposit.fiat.admin":
    "  لبدء عملية سحب ، يرجىالاتصال بخدمة العملاء",
  "page.body.wallets.tabs.deposit.fiat.email": "البريد الالكتروني",
  "page.body.wallets.tabs.withdraw": "سحب - تراجع",
  "page.body.wallets.tabs.withdraw.content.address": " عنوان سحب",
  "page.body.wallets.tabs.withdraw.content.amount": "كمية السحب ",
  "page.body.wallets.tabs.withdraw.content.balance": "الرصيد المتاح",
  "page.body.wallets.tabs.withdraw.content.code2fa":
    " رمز المصادقة ثنائي العوامل",
  "page.body.wallets.tabs.withdraw.content.fee": " رسوم",
  "page.body.wallets.tabs.withdraw.content.total": "  إجمالي مبلغ السحب",
  "page.body.wallets.tabs.withdraw.content.button": " سحب - تراجع",
  "page.body.wallets.tabs.withdraw.content.addressPlaceholder": "عنوان سحب",
  "page.body.wallets.tabs.withdraw.modal.confirmation": "تأكيد",
  "page.body.wallets.tabs.withdraw.modal.message1": "  سيصلك ",
  "page.body.wallets.tabs.withdraw.modal.button.cancel": " الغاء",
  "page.body.wallets.tabs.withdraw.modal.button.withdraw": "  سحب",
  "page.body.wallets.tabs.withdraw.content.enable2fa":
    "للقيام بالسحب عليك تفعيل رمز المصادقة ثنائي العوامل ",
  "page.body.wallets.tabs.withdraw.content.enable2faButton":
    " تفعيل رمز المصادقة ثنائي العوامل",
  "page.body.openOrders.tab.all": "طلبr",
  "page.body.openOrders.tab.open": "فتح",
  "page.body.openOrders.header.orderType": "  طلب",
  "page.body.openOrders.header.orderType.buy.market": "سوق الشراء ",
  "page.body.openOrders.header.orderType.buy.limit": "حد الشراء",
  "page.body.openOrders.header.orderType.sell.market": " سوق البيع",
  "page.body.openOrders.header.orderType.sell.limit": " حد البيع",
  "page.body.openOrders.header.pair": " زوج - اقتران",
  "page.body.openOrders.header.amount": " كمّية - مبلغ",
  "page.body.openOrders.header.price": "  سعر",
  "page.body.openOrders.header.executed": " نفّذت",
  "page.body.openOrders.header.remaining": " المتبقي",
  "page.body.openOrders.header.costRemaining": "  القيمة المتبقية",
  "page.body.openOrders.header.status": "حالة",
  "page.body.openOrders.content.status.done": "نفّذت",
  "page.body.openOrders.content.status.approve": "Pending",
  "page.body.openOrders.content.status.reject": "Rejected",
  "page.body.openOrders.content.status.wait": "فتح",
  "page.body.openOrders.content.status.cancel": "أغلق",
  "page.body.openOrders.header.button.cancelAll": "اغلاق الكل ",
  "page.body.history.deposit": " وديعة - ايداع",
  "page.body.history.deposit.header.txid": " رمز العملية التجارية ",
  "page.body.history.deposit.header.date": "تاريخ",
  "page.body.history.deposit.header.currency": "عمله",
  "page.body.history.deposit.header.amount": "كمّية - مبلغ",
  "page.body.history.deposit.header.status": "حالة",
  "page.body.history.deposit.content.status.accepted": "مقبولة",
  "page.body.history.deposit.content.status.collected": " تم التجميع – جمعت",
  "page.body.history.deposit.content.status.submitted": " قدّمت – تم التقديم",
  "page.body.history.deposit.content.status.canceled": " الغيت – تم الالغاء",
  "page.body.history.deposit.content.status.rejected": "تم الرفض - مرفوضة",
  "page.body.history.deposit.content.status.skipped": "تم التجاوز",
  "page.body.history.withdraw": "انسحاب",
  "page.body.history.withdraw.header.id": " تعريف",
  "page.body.history.withdraw.header.date": "تاريخ",
  "page.body.history.withdraw.header.currency": "عملة",
  "page.body.history.withdraw.header.address": " عنوان",
  "page.body.history.withdraw.header.amount": " كمّية - مبلغ",
  "page.body.history.withdraw.header.fee": " رسوم",
  "page.body.history.withdraw.header.status": "  حالة",
  "page.body.history.withdraw.content.status.prepared": "جاهز – تم اعداده",
  "page.body.history.withdraw.content.status.submitted": " قدّمت – تم التقديم",
  "page.body.history.withdraw.content.status.canceled": " الغيت – تم الالغاء",
  "page.body.history.withdraw.content.status.accepted": "  مقبولة",
  "page.body.history.withdraw.content.status.suspected": " مشتبه بها",
  "page.body.history.withdraw.content.status.rejected": "<تم الرفض - مرفوضة",
  "page.body.history.withdraw.content.status.processing": " تحت التنفيذ",
  "page.body.history.withdraw.content.status.succeed": " نجح",
  "page.body.history.withdraw.content.status.failed": " فشل",
  "page.body.history.withdraw.content.status.confirming": "تأكيد",
  "page.body.history.withdraw.content.status.errored": " خطأ",
  "page.body.history.trade": " نجارة - تداول",
  "page.body.history.trade.header.id": " تعريف",
  "page.body.history.trade.header.date": "تاريخ",
  "page.body.history.trade.header.side": "جانب",
  "page.body.history.trade.content.side.buy": " شراء",
  "page.body.history.trade.content.side.sell": " بيع",
  "page.body.history.trade.header.market": "  سوق",
  "page.body.history.trade.header.price": " سعر",
  "page.body.history.trade.header.funds": " موارد",
  "page.body.history.trade.header.amount": "كمّية - مبلغ",
  "page.body.history.trade.header.balance": "  توازن – رصيد حساب",
  "page.body.profile.kyc.content":
    "للمطابقة مع الانظمة, سيتوجّب على المشتركين المرور بعملية التحق من الهوية ",
  "page.body.profile.kyc.content.notice": "(يلزم التحقق من KYC للسحب)",
  "page.body.profile.kyc.button.success": " تم التحقق من ال KYC",
  "page.body.profile.kyc.button.pending": " يتم التحقق من ال KYC",
  "page.body.profile.kyc.button.fail": "  بدأ KYC",
  "page.body.profile.header.account": "  ملف",
  "page.body.profile.header.account.content.password": "  كلمة مرور",
  "page.body.profile.header.account.content.password.button.change": "تغيير",
  "page.body.profile.header.account.content.password.old":
    " كلمة المرور القديمة",
  "page.body.profile.header.account.content.password.new":
    "كلمة المرور الجديدة",
  "page.body.profile.header.account.content.password.button.save": "احفظ",
  "page.body.profile.header.account.content.password.button.cancel": " الغاء",
  "page.body.profile.header.account.content.password.conf": "تأكيد كلمة المرور",
  "page.body.profile.header.account.content.password.dont.match":
    " كلمة المرور غير متطابقة",
  "page.body.profile.header.account.content.password.change.success":
    "تم بنجاح",
  "page.body.profile.header.account.content.password.change":
    "  تغيير كلمة المرور",
  "page.body.profile.header.account.content.twoFactorAuthentication": "مفعّلة",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.enable":
    " مفعّلة",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.disable":
    " غير مفعّلة",
  "page.body.profile.header.account.content.twoFactorAuthentication.header":
    " مصادقة Google",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.1":
    " تنزيل ثم تحميل برنامج مصاقة google  من ",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.or":
    "أو",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.2":
    " امسح  رمز ال QR  او استخدم الرمز السري",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.3":
    " احتفظ بالرمز بالسري بمكان آمن. يمكنك استخدام الرمز السري للحصول على 2FAمن جهاز آخر",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.mfa":
    " رمز سري",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.4":
    "ادخل رمز ال 2FA من البرنامج",
  "page.body.profile.header.account.content.twoFactorAuthentication.subHeader":
    " رمز المصادقة ثنائي العوامل",
  "page.body.profile.header.account.content.twoFactorAuthentication.enable":
    "يمكن",
  "page.body.profile.header.account.content.twoFactorAuthentication.disable":
    "تعطيل 2FA",
  "page.body.profile.header.account.content.twoFactorAuthentication.modalBody":
    " يرجى التواصل مع المسؤول لالغاء التفعيل ",
  "page.body.profile.header.account.content.twoFactorAuthentication.modalHeader":
    " مفعّلة",
  "page.body.profile.header.account.profile": " التحقق من الحساب",
  "page.body.profile.header.account.profile.email.title":
    "تم التحقق من عنوان البريد",
  "page.body.profile.header.account.profile.email.message": "ايداعسحب مفعّلات",
  "page.body.profile.header.account.profile.phone.unverified.title":
    "التحقق من الهاتف",
  "page.body.profile.header.account.profile.phone.title": "تم التحقق من الهاتف",
  "page.body.profile.header.account.profile.phone.message": "ايداعتداول مفعّلة",
  "page.body.profile.header.account.profile.identity.unverified.title":
    "التحقق هويّة",
  "page.body.profile.header.account.profile.identity.title":
    " تم التحقق من لهوية",
  "page.body.profile.header.account.profile.identity.message": " السحب مفعّل",
  "page.body.profile.header.referralProgram": " رابط الاحالة",
  "page.body.profile.content.copyLink": " نسخ",
  "page.body.profile.apiKeys.header": " نسخواجهة برمجة تطبيق",
  "page.body.profile.apiKeys.header.create": "انشاء جديد",
  "page.body.profile.apiKeys.noOtp": "2FAرجاء قم بتفعيل ال",
  "page.body.profile.apiKeys.show": "اعرض",
  "page.body.profile.apiKeys.noKeys": " ليس لديك مفاتيح ال API",
  "page.body.profile.apiKeys.modal.btn.show": "اعرض",
  "page.body.profile.apiKeys.modal.btn.create": "  تأكيد ",
  "page.body.profile.apiKeys.modal.btn.copy": " نسخ",
  "page.body.profile.apiKeys.modal.btn.activate": " تفعيل",
  "page.body.profile.apiKeys.modal.btn.disabled": " الغاء التغعيل",
  "page.body.profile.apiKeys.modal.btn.delete": "حذف",
  "page.body.profile.apiKeys.modal.header": " 2FAالتحقق من ال",
  "page.body.profile.apiKeys.modal.created_header": " تم الانشاء ",
  "page.body.profile.apiKeys.modal.access_key": "مفتاح الوصول",
  "page.body.profile.apiKeys.modal.secret_key": " المفتاح السري",
  "page.body.profile.apiKeys.modal.secret_key_info":
    "سيتم عرض هذه المعلومات لمرة واحدة فقط ولايمكن استرجاعها في حالة فقدانهاز",
  "page.body.profile.apiKeys.modal.secret_key_store": "يرجى حفظها بصورة صحيحة",
  "page.body.profile.apiKeys.modal.note": " ملحوظة",
  "page.body.profile.apiKeys.modal.note_content": ` لتجنبفقدان المدخرات ( الأصول ) ، يرجى عدم إخبار الآخرين بالمفتاح السري والمفتاح الخاص. إذا نسيت المفتاح السري ، يرجى حذفه والتقدم بطلب للحصول على مفتاح سري جديد`,
  "page.body.profile.apiKeys.modal.title": " ادخل رمز ال 2FA  من التطبيق ",
  "page.body.profile.apiKeys.modal.label": "خانات رمز مصادقة Google ",
  "page.body.profile.apiKeys.modal.placeholder": "ادخل الرمز   ",
  "page.body.profile.apiKeys.table.header.kid": "طفل",
  "page.body.profile.apiKeys.table.header.algorithm": " خوارزمية",
  "page.body.profile.apiKeys.table.header.state": "حالة",
  "page.body.profile.apiKeys.table.header.created": " تم الانشاء ",
  "page.body.profile.apiKeys.table.header.updated": "تم التحديث",
  "success.api_keys.fetched": "تم جلب مفاتيح API بنجاح",
  "success.api_keys.created": " تم انشاء مفتاح ال API  بنجاح",
  "success.api_keys.copied.access": "تم نسخ مفتاح الوصول",
  "success.api_keys.copied.secret": "تم نسخ المفتاح السري",
  "success.api_keys.updated": "تم تحديث مفتاح ال API",
  "success.api_keys.deleted": " تم حذف مفتاح ال API",
  "page.body.profile.header.accountActivity": "نشاطات الحساب",
  "page.body.profile.header.accountActivity.content.date": " تاريخ",
  "page.body.profile.header.accountActivity.content.addressip": " عنوان ",
  "page.body.profile.header.accountActivity.content.action": " اجراء",
  "page.body.profile.header.accountActivity.content.result": " نتيجة",
  "page.body.profile.header.accountActivity.content.userAgent":
    " وكيل المستخدم",
  "page.body.profile.content.action.login": " تسجيل الدخول",
  "page.body.profile.content.action.logout": " تسجيل الخروج",
  "page.body.profile.content.action.request2fa": "طلب رمز ال QR  لـ 2FA",
  "page.body.profile.content.action.enable2fa": " تم تفعيل 2FA ",
  "page.body.profile.content.action.login.2fa": " تسجيل الدخول باستخدام 2FA",
  "page.body.profile.content.action.requestPasswordReset": "طلب كلمة المرور",
  "page.body.profile.content.action.passwordReset": " إعادة تعيين كلمة المرور ",
  "page.body.profile.content.result.succeed": " تم بنجاح",
  "page.body.profile.content.result.failed": " فشل",
  "page.body.kyc.phone.head": "التحقق من الهاتف ",
  "page.body.kyc.phone.enterPhone": " ادخل رقم الهاتف",
  "page.body.kyc.phone.phoneNumber": " رقم الهاتف",
  "page.body.kyc.phone.enterCode": " ادخل رمز التأكيد",
  "page.body.kyc.phone.code": " رمز الرسالة القصيرة",
  "page.body.kyc.phone.send": " ارسال الرمز",
  "page.body.kyc.phone.resend": "اعادة ارسال الرمز",
  "page.body.kyc.identity.head": "التحقق من الهوية",
  "page.body.kyc.document.head": "التحقق من المستندات",
  "page.body.kyc.identity.firstName": "الاسم الأول",
  "page.body.kyc.identity.lastName": "الاسم الأخير",
  "page.body.kyc.identity.dateOfBirth": "تاريخ الميلاد",
  "page.body.kyc.identity.residentialAddress": "عنوان السكن",
  "page.body.kyc.identity.city": " المدينة",
  "page.body.kyc.identity.postcode": " عنوان البريد",
  "page.body.kyc.identity.nationality": " المواطنة",
  "page.body.kyc.identity.CoR": "بلد الاقامة",
  "page.body.kyc.documents.expiryDate": " تاريخ الانتهاء",
  "page.body.kyc.documents.drag": " اسحب و افلت او قم بتصفح الملفات",
  "page.body.kyc.documents.maxFile": "حجم الملف الاقصى 10MB ",
  "page.body.kyc.documents.maxNum": " عدد الملفات الاقصى 5 ملفات",
  "page.body.kyc.documents.files": "أنواع الملفات المسوح بها :jpg, jpeg , png",
  "page.body.kyc.documents.upload": " قم بارفاق صورت الهوية ",
  "page.body.kyc.documents.select.passport": " جواز السفر",
  "page.body.kyc.documents.select.identityCard": " بطاقة الهوية",
  "page.body.kyc.documents.select.driverLicense": " رخصة القيادة",
  "page.body.kyc.documents.select.utilityBill": "فاتورة خدمات",
  "page.body.kyc.documents.number": ": رقم",
  "page.body.kyc.documentsType": "نوع الملف",
  "page.body.kyc.next": " التالي ",
  "page.body.kyc.submit": " تقديم",
  "page.body.kyc.head.phone": "التحقق من الهاتف",
  "page.body.kyc.head.identity": " التحقق هويّة",
  "page.body.kyc.head.document": " التحقق من المستندات",
  "page.body.kyc.error": "يرجى التحقق من KYC الخاص بك",
  "page.footer.legalDocuments": " الملفات القانونية",
  "page.footer.faq": " أسئلة متكررة",
  "page.header.signIn": " تسجيل دخول ",
  "page.header.signIn.email": " بريد الكتروني ",
  "page.header.signIn.password": "كلمة المرور",
  "page.header.signIn.password.message.error": "كلمة المرور غير صحيحة",
  "page.header.signIn.receiveConfirmation": "لم يصلك بريد التحقق الالكتروني",
  "page.header.signIn.forgotPassword": "نسيت كلمة مرورك",
  "page.header.signIn.resetPassword.title": " إعادة تعيين كلمة المرور",
  "page.header.signIn.resetPassword.oldPassword": "كلمة المرور القديمة",
  "page.header.signIn.resetPassword.newPassword": " كلمة مرور جديدة",
  "page.header.signIn.resetPassword.repeatPassword":
    " قم بإعادة ادخال كلمة المرور مرة اخرى",
  "page.header.signIn.resetPassword.button": " تغيير",
  "page.header.signIn.resetPassword.error": "المساحات فارغة او غير متطابقة",
  "page.header.signUp": "إنشاء حساب مجاني",
  "page.header.signUp.email": " بريد الكتروني ",
  "page.header.signUp.email.message.error": "بريد الكتروني خاطئ",
  "page.header.signUp.usernameLength.message.error":
    "يجب ألا يقل اسم المستخدم عن 4 أحرف ولا يزيد عن 20 حرفًا",
  "page.header.signUp.password": " كلمة مرور",
  "page.header.signUp.password.message.error":
    "كلمة المرور يجب ان تحتوي على 8 خانات على الاقل واحدة حرف كبير واخرى رقم",
  "page.header.signUp.confirmPassword": " تأكيد كلمة  المرور",
  "page.header.signUp.confirmPassword.message.error":
    "كلمات المرور غير متوافقات",
  "page.header.signUp.referalCode": " رمز الاحالة",
  "page.header.signUp.terms": " أنا قرأت و اوافق على ذلك",
  "page.header.signUp.modal.header": " تحقق من عنوان  بريدك الاكتروني",
  "page.header.signUp.modal.body":
    " لإكمال التسجيل ، تحقق من وجود بريد إلكتروني في صندوق الوارد الخاص بك مع مزيد من التعليمات. إذا لم تتمكن من العثور على البريدالإلكتروني ، يرجى التحقق من مجلد البريد العشوائي الخاص بك",
  "page.resendConfirmation": "اعد ارسال التأكيد ",
  "page.forgotPassword": "نسيت كلمة المرور",
  "page.forgotPassword.message": " ضع بريد الكتروني لاعادة تعيين كلمة المرور",
  "page.password2fa": "التحقق من ال 2FA",
  "page.password2fa.message": "ادخل رمز ال 2FA من البرنامج",
  "page.forgotPassword.email": " بريد الكتروني ",
  "page.forgotPassword.send": " ارسال",
  "page.noDataToShow": "ليس هناك معلومات لعرضها ",
  "page.modal.withdraw.success": "تم بنجاح",
  "page.modal.withdraw.success.message.content":
    " تم استلام طلب السحب الخاص بك ",
  "page.modal.withdraw.success.button": "موافق",
  // success messages
  "success.logout": "تم بنجاح تسجيل خروج تلقائي ",
  "success.documents.accepted": "تمت بنجاح عملية رفع الملفات",
  "success.documents.fetched": " تمت بنجاح عملية جلب الملفات",
  "success.withdraw.action": "تم استلام طلب السحب",
  "success.otp.enabled": "تم تمكين 2FA",
  "success.password.changed": "تم تغيير كلمة المرور",
  "success.payable_currency.updated": "تم تحديث العملة",
  "error.currency_already_assigned": "تم تحديد العملة مسبقا",
  "success.password.forgot": " تم ارسال بريد اعادة تعيين كلمة المرور ",
  "success.password.changed.successfuly": " تم تغيير كلمة المرور",
  "success.order.canceled": "الغي الطّلب",
  "success.order.canceled.all": " تم الغاء جميع الطلبات",
  "success.order.created": " تم انشاء طلب جديد",
  "success.phone.verification.send": " تم ارسال رمز تحقق الى هاتفك",
  "success.phone.confirmed": "تم التأكد من هاتفك",
  "success.message.sent": " تم ارسال الرسالة",
  "success.email.confirmed": "تم تأكيد عنوان بريدك الإلكتروني بنجاح",
  "resource.labels.private": " لا يمكن تحديث الملصق",
  "resource.user.no_activity": " لم يتم تسجيل أي نشاط أو الموضوع خاطئ",
  "resource.profile.not_exist": " المستخدم لا يملك ملف شخصي",
  "resource.profile.exist": "الملف الشخصي موجود بالفعل ",
  "resource.api_key.2fa_disabled": " يسمح فقط للحاسبات التي تم تفعيل 2FA  بها ",
  "resource.api_key.missing_otp":
    "تم تفعيل ال 2FA  على الحساب ولكن رمز ال OTP  مفقود",
  "resource.api_key.invalid_otp": " رمز ال OTP  خاطئ",
  "resource.phone.twillio": "شيء خاطئ في عميل Twilio ",
  "resource.phone.invalid_num": "رقم الهاتف غير صحيح",
  "resource.phone.exists": "رقم الهاتف موجود بالفعل",
  "resource.phone.please.provide.existing.phone.number":
    "يرجى تقديم رقم هاتف موجود",
  "resource.phone.number_exist": "رقم الهاتف موجود بالفعل",
  "resource.phone.verification_invalid": " الهاتف غير موجود او رقم التحقق غير ",
  "resource.documents.limit_reached": "تم الوصول للحد الاقصى من عدد ",
  "resource.documents.limit_will_be_reached":
    "سيصل حجم المستندات إلى الحدالأقصى بعد هذا التحميل",
  "resource.otp.already_enabled": "تم تفعيل ال 2FA  الخاص بهذا الحساب",
  "resource.otp.invalid": " رمز ال OTP  غير صحيح",
  "resource.password.doesnt_match": "كلمة المرور الجديدة غير متطابقة",
  "resource.password.prev_pass_not_correct": " كلمة المرور السابقة غير صحيحة",
  "resource.password.no_change_provided":
    "لا يمكن لكلمة المرور الجديدة ان تكون نفس القديمة",
  "resource.document.empty_doc_expire": " تاريخ الانتهاء غير صحيح",
  "password.requirements": "كلمة المرور لا تتوافق مع أقل الشروط",
  "password.password.password_strength":
    " كلمة المرور ضعيفة جد, يرجى زيادة قوتها بزيادة عدد الخانات او تعقيدها ",

  "email.taken": "البريد الالكتروني موجود بالفعل",
  "email.invalid": "بريد إلكتروني خاطئ",

  "identity.user.invalid_referral_format": "خطأ في صيغة ال UID",
  "identity.user.referral_doesnt_exist": "الاحالة غير موجودة",
  "identity.user.active_or_doesnt_exist":
    " المستخدم خير موجود او تم تفعيله منقبل ",
  "identity.password.user_doesnt_exist": "المستخدم غير موجود",
  "identity.user.passwords_doesnt_match": " كلمت المرور غير متطابقة",
  "identity.user.utilized_token": "مستخدمة مسبقا",
  "identity.session.invalid_login_params":
    "كلمة المرور او البريد الالكتروني غير صحيح",
  "identity.session.invalid": "الاتصال ( الجلسة ) غير صحيح ",
  "identity.captcha.required": " كلمة التحقق مطلوبة",
  "identity.captcha.mandatory_fields": " يجب تعبئة الحقول اللازمة",
  "identity.session.not_active": "لم يتم تفعيل حسابك",
  "identity.session.banned": "تم حجب حسابك",
  "identity.session.invalid_params": "كلمة المرور او البريد الالكتروني خاطئ ",
  "identity.session.missing_otp":
    "قام الحساب بتمكين المصادقة الثنائية (2FA) ولكن رمز OTP مفقود",
  "identity.session.invalid_otp": " رمز ال OTP  غير صحيح",
  "first_name.invalid": " الاسم الاول غير صحيح",
  "last_name.invalid": "الاسم الاخير غير صحيح",
  "city.invalid": " المدينة غير صحيحة",
  "postcode.invalid": " رمز البليد غير صيحيح",
  "address.invalid": "العنوان غير صحيح",
  "first_name.blank": " الاسم الاول غير موجود او فارغ",
  "last_name.blank": "الاسم الاخير غير موجود او فارغ",
  "dob.blank": "تاريخ الميلاد غير صحيح",
  "address.blank": "العنوان غير موجود او فارغ",
  "city.blank": "المدينة غير موجود او فارغ",
  "country.blank": " البلد غير موجود او فارغ",
  "postcode.blank": "Postcode.blank مفقود أو فارغ",
  "country.must have alpha2 or alpha3 format":
    "يجب أن يكون للبلد تنسيق alpha2 أو alpha3",
  "totp.error": "رمز ال OTP  غير صحيح",
  "record.not_found": " السجل غير موجود ",
  "jwt.decode_and_verify": "فشل في عملية فك تشفير والتحقق من ال JWT ",
  "authz.invalid_session": " فشل في عملية فك تشفير ملفات الارتباط",
  "authz.user_not_active": " المستخدم ليس نشطا",
  "authz.invalid_signature": "عنوان مفتاح ال API  غير صحيح",
  "authz.apikey_not_active": "حالة مفتاح ال API  غير نشطة",
  "authz.disabled_2fa": "مالك مفتاح ال API  قام بالغاء ال 2FA",
  "authz.invalid_api_key_headers": "عناوين ال API  مفودة او فارغة ",
  "authz.permission_denied": " المسار في القائمة السوداء",
  "authz.unexistent_apikey":
    " عنوان مفتاح ال X-Auth-API  غير صحيح \\ اخطاء في التحقق \\ وحدة الهوية ",
  "identity.user.missing_email": " البريد الاكتروني مفقود",
  "identity.user.empty_email": "البريد الالكتروني مفقود او فارغ",
  "identity.user.missing_password": " كلمة المرور مفقودة",
  "identity.user.empty_password": " كلمة المرور مفقودة او فارغة",
  "identity.user.missing_token": "الرمز مفقود",
  "identity.user.empty_token": " الرمزمفقود او فارغ",
  "identity.user.missing_reset_password_token":
    "اعادة تعيين رمز كلمة المرور مفقود",
  "identity.user.empty_reset_password_token":
    "< اعادة تعيين رمز كلمة المرور مفقود او فارغ",
  "identity.user.missing_confirm_password": " تأكيد كلمة المرور مفقود",
  "identity.user.empty_confirm_password":
    " تأككيد كلمة المرور مفقود او غير موجود",
  "identity.session.missing_emai": "البريد الاكتروني مفقود",
  "identity.session.missing_password": " كلمة المرور مفقودة",
  "identity.session.invalid_captcha_format":
    "خطأ في صيغة رمز التحقق || وحدة الموارد ",
  "resource.otp.missing_code": " رمز ال OTP  مفقود",
  "resource.otp.empty_code": "رمز ال OTP  مفقود او فارغ",
  "resource.labels.missing_key": " المفتاح مفقود",
  "resource.labels.empty_key": " المفتاح مفقود او فارغ",
  "resource.labels.missing_value": " القيمة مفقودة ",
  "resource.labels.empty_value": " القيمة مفقودة او فارغة",
  "resource.documents.missing_doc_expire": " تاريخ انتهاء الوثائق مفقود",
  "resource.documents.empty_doc_expire": " تاريخ انتهاء الوثاءق مفقود او فارغ",
  "resource.documents.missing_doc_type": " نوع الوثائق مفقود",
  "resource.documents.empty_doc_type": "نوع المستندات مفقود أو فارغ",
  "resource.documents.missing_doc_number": "عدد الوثائق مفقود",
  "resource.documents.empty_doc_number": "عدد الوثائق مفقود او فارغ",
  "resource.documents.missing_upload": " المرفق مفقود",
  "resource.user.missing_topic": " العنوان مفقود",
  "resource.user.empty_topic": "العنوان مفود او فارغ",
  "resource.user.missing_old_password": " كلمة المرور القديمة مفقودة",
  "resource.user.empty_old_password": " كلمة المرو القديمة مفقودة او فارغة",
  "resource.user.missing_new_password": " كلمة المرور الجديدة مفقودة",
  "resource.user.empty_new_password": " كلمة المرور الجديدة مفقودة او فارغة",
  "resource.user.missing_confirm_password": "تأكيد كلمة المرور مفقودة",
  "resource.user.empty_confirm_password": " تأكيد كلمة المرور مفقودة او فارغة",
  "resource.profile.missing_first_name": "الاسم الاول مفقود",
  "resource.profile.missing_last_name": " الاسم الاخير مفقود",
  "resource.profile.missing_dob": " تاريخ الميلاد مفقود",
  "resource.profile.missing_address": "العنوان مفقود",
  "resource.profile.missing_postcode": " عنوان البريد مفقود",
  "resource.profile.missing_city": "المدينة مفقودة",
  "resource.profile.missing_country": " البلد مفقودة",
  "resource.api_key.missing_algorithm": " الخوارزمية مفقودة",
  "resource.api_key.empty_algorithm": "الخوارزمية مفقودة او فارغة",
  "resource.api_key.empty_kid": "KID مفقود أو فارغ ",
  "resource.api_key.empty_scope": " المجال مفقود او فارغ",
  "resource.api_key.missing_totp": "كود TOTP مفقود",
  "resource.api_key.empty_totp": "رمز TOTP فارغ او مفقود",
  "resource.api_key.missing_kid": "KID مفقود",
  "resource.api_key.empty_state": " الحالة مفقودة او فارغة",
  "resource.phone.missing_phone_number": " الهاتف مفقود",
  "resource.phone.empty_phone_number": " الهاتف مفقود او فارغ",
  "resource.phone.missing_verification_code": " رم التأكيد مفقود",
  "resource.phone.empty_verification_code": "رمز التأكيد مفقود او فارغ ",
  "account.currency.doesnt_exist": " العملة غير موجودة",
  "account.deposit.invalid_state": "حالة الإيداع غير صالحة",
  "account.deposit.non_integer_limit":
    "تعذر تحليل القيمة التي ترسلها إلى نوع عدد صحيح",
  "account.deposit.invalid_limit": "حد غير صالح",
  "account.deposit.non_positive_page": " قيمة الصفحة يجب ان تكون ايجابية",
  "account.deposit.empty_txid": "Txid مفقود ، و txid فارغ",
  "account.deposit_address.invalid_address_format":
    "صيغة عنوان الايداع غير صحيحة",
  "account.deposit_address.doesnt_support_cash_address_format":
    " العملة لا تدعم تنسيق العنوان النقدي",
  "account.withdraw.non_integer_limit":
    " حد القيمة التي أدخلتها لايمكن معالجتها لقيمة صحيحة",
  "account.withdraw.invalid_limit": "سقف غير صحيح",
  "account.withdraw.non_positive_page": " قيمة الصفحة يجب ان تكون ايجابية",
  "account.withdraw.non_integer_otp": "  قيمة ال OTP لا يمكن معالجتها لقيمة",
  "account.withdraw.empty_otp": " ال OTP مفقود او فارغ",
  "account.withdraw.empty_rid": "التخلص من المفقودين ، والتخلص فارغ",
  "account.withdraw.non_decimal_amount":
    " لا يمكن معالجة قيمة المبلغ الذي ترسله إلى نوع عشري  ",
  "account.withdraw.non_positive_amount": " قيمة المبلغ يجب ان تكون ايجابية",
  "account.deposit.not_permitted": " يسمح بالايداع بعد التحقق الهاتفي",
  "account.withdraw.not_permitted": " يرجى اجتيازخطوات التحقق لسحب الأموال",
  "account.withdraw.insufficient_balance": "رصيد الحساب غير كافي",
  "account.withdraw.invalid_amount": "قيمة سحب غير صحيحة",
  "account.withdraw.create_error": "فشل في انشاء عملية سحب",
  "account.withdraw.invalid_otp": "otp غير صالح",
  "account.withdraw.disabled_api": " سحب ال API  غير فعّال",
  "market.market.doesnt_exist_or_not_enabled": "السوق غير موجود",
  "market.conversion.conversion_error": "انتهت صلاحية عرض أسعار التحويل",
  "market.order.invalid_state": " حالة ايداع غير صحيحة",
  "market.order.invalid_limit": " سقف غير صحيح",
  "market.order.non_integer_limit":
    "حد القيمة التي أدخلتها لا يمكن معالجتها لقيمة صحيحة ",
  "market.trade.empty_page": "الصفحة مفقودة او فارغة ",
  "market.order.invalid_order_by": " الطلب غير صحيح من ",
  "market.order.invalid_side": "جانب الطلب غير صحيح",
  "market.order.non_decimal_volume":
    " تعذر تحليل قيمة الكمية  التي أرسلتها إلى نوع عشري",
  "market.order.non_positive_volume": "يجب ان تكون قيمة الكمية موجبة",
  "market.order.invalid_type": "طلب غير صحيح",
  "market.order.non_decimal_price":
    " تعذر تحليل قيمة الكمية  التي أرسلتها إلى نوع عشري",
  "market.order.non_positive_price": "يجب ان تكون قيمة الكمية موجبة",
  "market.order.non_integer_id":
    " قيمة التعريف التي أدخلتها لايمكن معالجتها لقيمة صحيحة",
  "market.order.empty_id": " قيمة التعريف مفقودة او غير صحيح",
  "market.trade.non_integer_limit":
    "حد القيمة التي أدخلتها لا يمكن معالجتها لقيمة صحيحة",
  "market.trade.invalid_limit": " السقف غير صحيح",
  "market.trade.non_integer_timestamp":
    " قيمة الطابع الزمنيالتي أدخلتها لا يمكن معالجتها لقيمة صحيحة",
  "market.trade.empty_timestamp": " قيمة الطبع الزمني مفقودة او غير صحيح",
  "market.trade.invalid_order_by": "order_by غير صالح",
  "market.order.insufficient_market_liquidity": " عدم كفاية السيولة في السوق",
  "market.order.invalid_volume_or_price": " القيمة او السعر غير صحيح",
  "market.order.create_error": "فشل في انشاء خطأ",
  "market.order.cancel_error": " فشل في الغاء خطأ",
  "market.order.market_order_price": " طلب السوق ليس له سعر",
  "market.trade.not_permitted": " يرجى اجتياز خطوات التحقق لتفعيل التداول",
  "market.account.insufficient_balance": "رصيد الحساب غير كاف",
  "public.currency.doesnt_exist": "العملة غير موجودة",
  "public.currency.invalid_type": " نوع العملة غير صحيح",
  "public.market.doesnt_exist": " السوق غير موجود",
  "public.order_book.non_integer_ask_limit":
    " قيمة حد السؤال التي أدخلتها لا يمكن معالجتها لقيمة صحيحة",
  "public.order_book.invalid_ask_limit": "حد السؤال غير صالح",
  "public.order_book.non_integer_bid_limit":
    "تعذر تحليل قيمة حدود العطاء التي ترسلها إلى نوع عدد صحيح",
  "public.order_book.invalid_bid_limit": "حد ال bid غير صحيح",
  "public.trade.non_integer_limit":
    "تعذر تحليل القيمة المحددة التي ترسلها إلى نوع عدد صحيح",
  "public.trade.invalid_limit": "حد غير صالح",
  "public.trade.non_positive_page": "يجب أن تكون قيمة الصفحة موجبة",
  "public.trade.non_integer_timestamp":
    "تعذر تحليل قيمة الطابع الزمني التي ترسلها إلى نوع عدد صحيح",
  "public.trade.invalid_order_by": "طلب غير صالح من",
  "public.market_depth.non_integer_limit":
    "تعذر تحليل القيمة المحددة التي ترسلها إلى نوع عدد صحيح",
  "public.market_depth.invalid_limit": "حد غير صالح",
  "public.k_line.non_integer_period":
    "تعذر تحليل القيمة المحددة التي ترسلها إلى نوع عدد صحيح",
  "public.k_line.invalid_period": "فترة غير صحيحة",
  "public.k_line.non_integer_time_from":
    "تعذر تحليل القيمة المحددة التي ترسلها إلى نوع عدد صحيح",
  "public.k_line.empty_time_from": "الوقت من param  مفقود او فارغ",
  "public.k_line.non_integer_time_to":
    "تعذر تحليل القيمة المحددة التي ترسلها إلى نوع عدد صحيح",
  "public.k_line.empty_time_to": " الوقت الى param  مفقود او فارغ",
  "public.k_line.non_integer_limit":
    "تعذر تحليل القيمة المحددة التي ترسلها إلى نوع عدد صحيح",
  "public.k_line.invalid_limit": "حد غير صالح",
  "server.internal_error": "خطأ في الخادم الداخلي",
  "public.trade.tradingChart-label": "مخطط التداول",
  "public.trade.marketDepth-label": "عمق السوق",
  "public.trade.Chart-label-parent": " مخطط",

  //NEW TRANSLATIONS START HERE
  "page.header.navbar.header": "ن",
  "page.signIn.description": "مرحبا في {exchange_name} ",
  "page.signIn.2faRequired": " رجاءا ادخل رمز ال 2FA",
  "page.signIn.signup": " قم بالتسجيل الآن",
  "page.signIn.signupExists": "ليس لديك حساب؟ ",
  "page.forgotPassword.description":
    "إذا نسيت كلمة مرورك ، حسنًا ، فسنرسل إليك تعليمات عبر البريد الإلكتروني لإعادة تعيين كلمة مرورك.",
  "page.forgotPassword.back": " العودة لتسجيل الدخول",
  "page.forgotPassword.signUp": " قم بالتسجيل الان",
  "page.forgotPassword.signUpExists": "ليس لديك حساب؟",
  "page.body.forget_password": " نسيت كلمة المرور",
  "page.signUp.description": " مرحبا في {exchange_name}  ",
  "page.signUp.signin": " تسجيل الدخول من هنا",
  "page.signUp.signinExists": "هل لديك حساب؟",
  "page.profile": "ملف شخصي",
  "page.profile.profileDetailsTitle": "تفاصيل الملف الشخصي",
  "page.profile.referralTitle": " اكسب مع الاحالة",
  "page.profile.payableCurrencyPlaceholder":
    " حدد المبلغ المستحق الدفع الخاص بك حاليا",
  "page.profile.referralDescription":
    "قم بدعوة أصدقائك وعائلتك واحصل على مكافأة - 15٪ من قيمة المساهمة",
  "page.profile.2faTitle": "توثيق ذو عاملين",
  "page.profile.2faDescription":
    " المصادقة الثنائية هي طريقة لحماية حساب الويب الخاص بك. عندما يتم تنشيطه ، لا يلزمك إدخال كلمة المرور فحسب ، بل إدخال رمز خاص أيضًا. يمكنك الحصول على هذا الرمز من خلال التطبيق المحمول. حتى إذا عثر شخص ثالث على كلمة مرورك ، فلن يتمكن من الوصول باستخدام هذا الرمز.",
  "page.profile.currentStatus": "الحالة الحاليّة",
  "page.profile.kycTitle": " التحقق من الهوية - KYC",
  "page.emailAddress": "عنوان بريد الكتروني",
  "page.uid": "معرّف فريد",
  "page.show": "عرض",
  "page.hide": " اخفاء",
  "page.payableCurrency": " العملة المستحقة الدفع",
  "page.body.wallet.currencyTitle": "عمله",
  "page.body.wallet.currencyName": " اسم",
  "page.body.wallet.availableBalance": " متاح",
  "page.body.wallet.lockedBalance": " تم قفلهاغلاقه",
  "page.body.wallet.actionTitle": " اجراء",
  "page.body.wallet.action.deposit": "ايداع",
  "page.body.wallet.action.withdraw": " سحب ",
  "page.header.navbar.2fa": " المصادقة الثنائية",
  "page.header.navbar.forgetPassword": " المصادقة الثنائية",
  "page.header.navbar.emailVerification": "التحقق من البريد الالكتروني",
  "page.body.confirm.title": "ابدأ التحقق من هويتك",
  "page.body.confirm.subTitle": " تحقق من هويتك.",
  "success.identity.submitted": "تم تقديم الملف الشخصي",
  "page.body.profile.content.action.signup": "تسجيل",

  "page.body.profile.beneficiaries.header": " قائمة المستفيدين",
  "page.body.profile.beneficiaries.header.create": "انشاء جديد",
  "page.body.profile.beneficiaries.noBeneficiaries": " لا تملك مستفيدين",
  "page.body.profile.beneficiaries.modal.form.selectCurrency": "اخر العملة",
  "page.body.profile.beneficiaries.modal.form.name": "الاسم المرجعي للمستفيد",
  "page.body.profile.beneficiaries.modal.form.description": " وصف المستفيد",
  "page.body.profile.beneficiaries.modal.form.address": "عنوان المستفيد",
  "page.body.profile.beneficiaries.modal.form.accountNumber":
    " رقم الحساب لبنكي للمستفيد ",
  "page.body.profile.beneficiaries.modal.form.swift": " رمز السويفت للمستفيد",
  "page.body.profile.beneficiaries.modal.form.fullName":
    " اسم المستفيد الكامل ",
  "page.body.profile.beneficiaries.modal.form.submitFailed":
    " رجاءا تحقق من تعبئة جميع المتطلبات",
  "page.body.profile.beneficiaries.modal.form.alreadyActive":
    " المستفيد مفعّل فعلا, يمكنك حذفه",
  "page.body.profile.beneficiaries.modal.form.activationCode":
    " رمز تفعيل المستفيد",
  "page.body.profile.beneficiaries.modal.form.deleteConfirm":
    " هل فعلا تريد حذف هذا المستفيد ؟ ",
  "page.body.profile.beneficiaries.modal.btn.show": " عرض",
  "page.body.profile.beneficiaries.modal.btn.close": "اغلاق",
  "page.body.profile.beneficiaries.modal.btn.create": " تأكيد",
  "page.body.profile.beneficiaries.modal.btn.activate": " تفعيل",
  "page.body.profile.beneficiaries.modal.btn.disabled": " الغاء التفعيل",
  "page.body.profile.beneficiaries.modal.btn.delete": " حذف",
  "page.body.profile.beneficiaries.modal.header": " ادارة المستفيدين",
  "page.body.profile.beneficiaries.modal.created_header": " تم الانشاء",
  "page.body.profile.beneficiaries.table.header.currency": "عملة",
  "page.body.profile.beneficiaries.table.header.description": "وصف",
  "page.body.profile.beneficiaries.table.header.name": "عنوان التشفير",
  "page.body.profile.beneficiaries.table.header.address": "  عنوان التشفير",
  "page.body.profile.beneficiaries.table.header.swift": " رمز السويفت",
  "page.body.profile.beneficiaries.table.header.accountNumber": " رقم الحساب",
  "page.body.profile.beneficiaries.table.header.fullName": " الاسم الكامل",
  "page.body.profile.beneficiaries.table.header.state": "حالة",
  "success.beneficiaries.fetched": " تم ارجاع المستفيدين",
  "success.beneficiaries.created": "تم انشاء مستفيد",
  "success.beneficiaries.updated": " تم تحديث المستفيد",
  "success.beneficiaries.deleted": " تم حذف المستفيد",
  "account.beneficiary.non_integer_id": "هوية المستفيد غير موجودة",
  "account.beneficiary.invalid_pin": "Invalid Beneficiary Pin",
  "account.beneficiary.invalid_state_for_withdrawal": "Invalid Beneficiary",
  "page.body.wallets.tabs.withdraw.modal.message2": " في المستفيد",
  "page.body.wallets.tabs.withdraw.form.toggleLabel": " استخدم مستفيد",
  "page.body.wallets.tabs.withdraw.form.beneficiaryPlaceHolder":
    " اختار مستفيد",
  "page.body.wallets.tabs.withdraw.form.addNewBeneficiary": "اضف مستفيدين جدد",
  "currency.currency_doesnt_exist_or_not_payable":
    "العملة غير موجودة او غير قابلة للتداول",

  "resource.documents.already_expired":
    " الوثائق التي تحاول ارفاقها هي فعلا منتهية",
  "resource.documents.invalid_format":
    " الوثائق يجب ان تكون ثالحة و غير منتهية",
  "success.beneficiaries.pinResend":
    " تم اعادة ارسال الرمز للبريد الالكتروني المسجل",
  "account.beneficiary.cant_resend_within_1_minute":
    " تم ارسال الرمز, رجاءا انتظر دقيقة لاعادة ارسال الرمز",

  "page.header.navbar.otc": "؟؟؟؟",

  "page.trading.toolbar.progressLabel.lowest24": "أقل",
  "page.trading.toolbar.progressLabel.lastPrice": "آخر سعر",
  "page.trading.toolbar.progressLabel.highest24": " اعلى",
  "page.trading.toolbar.progressLabel.volume24": " حجم ال 24h",
  "page.trading.toolbar.progressLabel.change": " تغيير",

  "page.profile.section.profile.title": "حسابي",
  "page.profile.section.referral.title": "حالة",
  "page.profile.section.accountActivity.title": " فعاليّة",
  "page.profile.section.api.title": "API",
  "page.profile.section.beneficiary.title": " المستفيدون",

  "page.signUp.PEP.text": "PEP",
  "page.signUp.termsAndService.text": " الشروط و الخدمة",
  "page.signUp.PrivacyPolicy.text": "سياسة الخصوصية",
  "page.signUp.risks.text": "المخاطر المتعلقة بالعملات المشفرة",

  "page.header.signUp.username": " اسم المستخدم ",
  "page.sigin.welcomeText": " لنبدأ بتسجيل الدخو",
  "page.signUp.button": " قم بالتسجيل",
  "page.wallets.portfolio.text": "إجمالي قيمة المحفظة",
  "page.wallets.label.hideEmptyWallets": "إخفاء المحافظ الفارغة",

  "page.history.withdraw.details": "سحب التفاصيل",
  "page.history.deposit.details": "تفاصيل الإيداع",
  "page.markets.search.text": "عملات البحث",
  "page.productMenu.home": "الصفحة الرئيسية",
  "page.productMenu.p2p": "P2P",
  "page.auth.welcome.message": "لنبدأ بتسجيل الدخول!",
  "page.body.wallets.min_deposit_warning":
    "الحد الأدنى للإيداع حاليًا: {currency} {min_amount}",
  "page.body.wallets.mining_deposit_warning":
    "من فضلك لا تنجم مباشرة إلى عنوان الإيداع الخاص بك ، حيث يمكن أن يتغير دون سابق إنذار. لن يتم إيداع الودائع المفقودة.",
  "page.body.header.markets": "الأسواق",
  "page.body.header.charts": "الرسوم البيانية",
  "page.body.header.myOrders": "طلباتي",
  "page.body.header.trades": "الحرف",
  "page.body.header.orders": "سجل الطلبيات",
  "page.body.header.buySell": "يشتري /باع",

  "page.body.wallets.noWallets": "لم يتم العثور على محافظ",
  "page.body.dark.version": "نسخة مظلمة",

  "page.productMenu.market": "سوق",
  "page.productMenu.exchange": "تبادل",
  "page.productMenu.app": "تطبيق",
  "page.productMenu.cards": "البطاقات",
  "page.productMenu.mining": "التعدين",
  "page.productMenu.staking": "Staking",
  "page.productMenu.defi": "DeFi",
  "page.productMenu.convert": "Convert",

  "username.taken": "اسم المستخدم مُستخدم بالفعل",
  "username.too_long": "اسم المستخدم طويل جدًا",
  "username.too_short": "اسم المستخدم قصير جدًا",

  "account.update.sms_authentication_failed.resend_otp_again":
    "حدث خطأ ما، يرجى التحقق من الرموز",

  ...nationalitiesNames
}
