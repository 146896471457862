import { API } from '../../../../utility/API'

//************************************//
export const _getPublicStakingProducts = ({ page = 1, limit = 10, searchQuery = null }, callback, callbackErr) => {
  // persistStore().purge()
  API.get(`/trading/public/staking_products`, {
    params: { page, limit, search: { currency_id: searchQuery, product_name: searchQuery } }
  })
    .then(function ({ data, headers }) {
      callback({ data, page: headers['page'], total: headers['total'] })
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
