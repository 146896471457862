import { createSlice } from "@reduxjs/toolkit"

const selectBeneficiaryModalState = createSlice({
  name: "selectBeneficiaryModalState",
  initialState: {
    open: false,
    data: {
      callback: () => {}
    }
  },
  reducers: {
    setSelectBeneficiaryModalOpen: (state, action) => {
      state.open = action.payload
    },
    setSelectBeneficiaryModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setSelectBeneficiaryModalOpen, setSelectBeneficiaryModalData } =
  selectBeneficiaryModalState.actions

export default selectBeneficiaryModalState.reducer
