// ** React Imports
import React, { useState } from "react"

// ** MUI Imports
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"

// ** Icon Imports
import { ReactComponent as OptionsIcon } from "../../assets/icons/imported/options.svg"
import { useSelector } from "react-redux"

const RowOptions = ({
  options = [] // [{id, label, onClick, icon}]
}) => {
  const theme = useSelector((state) => state.theme.value)

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)
  const rowOptionsOpen = Boolean(anchorEl)

  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleRowOptionsClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <OptionsIcon height={15} width={15} color="var(--gray-color)" />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          "& .MuiPaper-root": {
            bgcolor: "var(--secondary-color)",
            borderRadius: "5px"
          }
        }}
        MenuListProps={{
          sx: {
            backgroundColor: "var(--secondary-color)",
            border: "1px (--secondary-color) solid",
            color: "#6C7080",
            zIndex: 1
          }
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "hidden",
            filter:
              theme === "dark"
                ? "drop-shadow(0px 2px 20px rgba(0,0,0,.4))"
                : "drop-shadow(0px 2px 10px rgba(0,0,0,.1))",
            borderRadius: 0,
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              backgroundColor: "var(--secondary-color)",
              transform: "translateY(-50%) rotate(45deg)",
              border: "1px #6C7080 solid",
              zIndex: 1
            }
          }
        }}
      >
        {options.map((option) => (
          <MenuItem
            onClick={option.onClick}
            style={{
              fontSize: 13,
              fontFamily: '"Overpass"',
              display: "flex",
              gap: "6px",
              alignItem: "center"
              // justifyContent: 'flex-start',
            }}
            sx={{
              color: "var(--text-color)",
              p: "0 16px ",
              m: 0,
              "&:hover": {
                // backgroundColor: theme === 'dark' ? 'black' : 'rgba(211, 211, 211, 0.1)',
                backgroundColor: theme === "light" ? "white" : "black",
                color: "var(--primary-color) !important"
              }
            }}
            {...option.menuItemProps}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default RowOptions
