import React, { useState, useEffect } from "react"

// styles
import Styles from "./viewBeneficiaryModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setViewBeneficiaryModalData,
  setViewBeneficiaryModalOpen
} from "../redux/slices/viewBeneficiaryModalSlice"

// components
import WenbitModal from "../../../../components/Modal/Modal"
import Button from "../../../../components/Button/Button"
import Input from "../../../../components/Input/Input"

// QR code
//temp imports
// import QRCode from 'react-qr-code'
import QRCode from "qrcode.react"
import CoinDisplay from "../../../../components/CoinDisplay/CoinDisplay"

function ViewBeneficiaryModal() {
  const dispatch = useDispatch()
  const modalOpen = useSelector((state) => state.viewBeneficiaryModalState.open)
  const modalData = useSelector((state) => state.viewBeneficiaryModalState.data)
  const theme = useSelector((state) => state.theme.value)
  const currencies = useSelector((state) => state.currencies.value)
  const beneficiary = useSelector((state) => state.beneficiary.value)
  const [addressIsCopied, setAddressIsCopied] = useState(false)

  const handleClose = () => {
    dispatch(
      setViewBeneficiaryModalData({
        callback: () => {}
      })
    )
    dispatch(setViewBeneficiaryModalOpen(false))
  }

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(beneficiary.address)
    setAddressIsCopied(true)
    setTimeout(() => {
      setAddressIsCopied(false)
    }, 5000)
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title="Beneficiary Details"
        render={() => (
          <div className={Styles.modalBodyContainer}>
            <p>Beneficiary wallet QR code</p>

            <div
              style={{
                height: "auto",
                margin: "20px auto",
                maxWidth: 140,
                width: "100%",
                padding: 16,
                borderRadius: 5,
                backgroundColor:
                  theme === "light" ? "var(--secondary-color)" : "white"
              }}
            >
              <QRCode
                bgColor={theme === "light" ? "var(--secondary-color)" : "white"}
                value={beneficiary.address}
                size={110}
                renderAs={"svg"}
              />
            </div>

            <div className={Styles.destinationContainer}>
              <span>Destination</span>
              <div>
                <span>
                  {
                    // (depositData.depositAddress === '' || depositData.depositAddress === null || depositData.depositAddress === undefined) ?
                    //   "LOADING" :
                    //   depositData.depositAddress
                  }
                  {beneficiary.address}
                </span>
                <Button
                  disabled={addressIsCopied}
                  text={addressIsCopied ? "Copied" : "Copy"}
                  style={{
                    transform: "scale(.8)",
                    borderRadius: 5,
                    width: "fit-content",
                    marginRight: -3,
                    fontSize: 14,
                    backgroundColor: addressIsCopied && "#9aa2c1",
                    marginLeft: "auto"
                  }}
                  onClick={handleCopyAddress}
                />
              </div>
            </div>

            {/* details container */}
            <div className={Styles.detailsContainer}>
              <CoinDisplay
                coinName={
                  <span style={{ fontWeight: 500 }}>
                    {
                      currencies.find((el) => el.id === beneficiary.currency)
                        ?.name
                    }
                  </span>
                }
                coinImgURL={
                  currencies.find((el) => el.id === beneficiary.currency)
                    ?.icon_url
                }
              />

              <div className={Styles.rowsContainer}>
                <div>
                  <span>Beneficiary reference name</span>
                  <span>{beneficiary.name}</span>
                </div>
                <div>
                  <span>Beneficiary description</span>
                  <span>{beneficiary.description}</span>
                </div>
                {/* <div>
                    <span>Beneficiary address</span>
                    <span>{beneficiary.address}</span>
                  </div> */}
                <div>
                  <span>Status</span>
                  <span>{beneficiary.state}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </>
  )
}

export default ViewBeneficiaryModal
