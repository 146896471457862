// Utility function to convert a time string to Unix epoch
export const convertToUnixEpoch = (timeStr) => {
  // Create a Date object from the time string
  const date = new Date(timeStr)

  // Convert the date to the Unix epoch time (milliseconds since the Unix epoch)
  // and then convert it to seconds
  const unixTimestamp = Math.floor(date.getTime() / 1000)

  return unixTimestamp
}
