import {
  setWarningModalData,
  setWarningModalOpen
} from "../../modules/modals/warning/redux/warningModalSlice"
import { store } from "../../redux/store"

export const handleWalletNotFound = () => {
  store.dispatch(setWarningModalOpen(true))
  store.dispatch(
    setWarningModalData({
      title: "Wallet Address Not Found",
      subtitle: "There was no wallet found for the selected coin",
      callback: () => store.dispatch(setWarningModalOpen(false))
    })
  )
}
