const getWalletHistoryStateColor = (state = "") => {
  if (state === "pending" || state === "In Progress")
    return "var(--warning-color)"
  if (state === "executed" || state === "Completed" || state === "verified")
    return "var(--success-color)"
  if (state === "rejected" || state === "cancelled" || state === "skipped")
    return "var(--danger-color)"
  return "var(--text-color)"
}

export default getWalletHistoryStateColor
