import React, { useEffect, useMemo } from "react"
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from "./recentTrades.module.css"

// components
import RecentTradesRow from "./RecentTradesRow"
import { CircularProgress } from "@mui/material"
import Tooltip from "../../../../components/Tooltip/Tooltip"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"

// icons
import { IoMdArrowDropup } from "react-icons/io"
import UpWhiteArrow from "../../../../assets/icons/up-white-arrow.svg"

// redux
import { useDispatch, useSelector } from "react-redux"

function RecentTrades() {
  const trades = useSelector((state) => state.trades.value)
  const marketsList = useSelector((state) => state.marketList.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  return (
    <div className={Styles.card}>
      <>
        {/* title */}
        <div className={Styles.flexContainer}>
          <span className={Styles.cardTitle}>
            <FormattedMessage id={"page.body.trade.header.recentTrades"} />
          </span>

          <Tooltip
            text="Recent Trades Hint Here Lorem Ipsum 123"
            iconWidth={10}
          />
        </div>

        {/* sorting row */}
        <div className={Styles.sortingContainer}>
          <span>
            Price (
            {marketsList
              .find((el) => el.id === selectedMarket.id)
              ?.quote_unit.toUpperCase()}
            )
          </span>
          <span>
            Amount (
            {marketsList
              .find((el) => el.id === selectedMarket.id)
              ?.base_unit.toUpperCase()}
            )
          </span>
          <span>
            <FormattedMessage
              id={"page.body.trade.header.recentTrades.content.time"}
            />
          </span>
        </div>
      </>

      {/* rows container */}
      <div className={Styles.rowsContainer}>
        {trades.length > 0 ? (
          trades
            .slice(0, 19) // Adjust the slice to get the last 19 trades
            // .reverse() // Reverse the array to display the trades in reverse order
            .map((trade, index) => (
              <RecentTradesRow
                key={index}
                isAsk={trade.taker_type === "sell"}
                trade={trade}
              />
            ))
        ) : (
          <div className={Styles.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}

export default injectIntl(RecentTrades)
