import { store } from "../../../redux/store"
import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen
} from "./redux/selectCurrencyModalSlice"

export const showSelectCoinModal = ({
  callback = () => {},
  title = "Select coin",
  type = "coin"
}) => {
  store.dispatch(
    setSelectCurrencyModalData({
      title,
      showTabs: false,
      type,
      callback
    })
  )
  store.dispatch(setSelectCurrencyModalOpen(true))
}
