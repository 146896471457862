// ** React Imports
import React from "react"

//  ** Styles Imports
import styles from "./styles.module.css"

// ** Component Imports
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"
import ShimmerCircle from "../../../../components/shimmer-components/ShimmerCircle"
import { ShimmerThumbnail } from "react-shimmer-effects"

function BlogShimmer() {
  return (
    <div>
      <div style={{ opacity: ".75" }}>
        <ShimmerThumbnail className={styles.thumbnail} />
      </div>
      <div className={styles.header}>
        <ShimmerCircle size="40px" />
        <div className={styles.headerTitle}>
          <div>
            <ShimmerLine width="150px" />
            <ShimmerLine width="100px" />
          </div>
          <div>
            <ShimmerLine width="60px" style={{ marginLeft: "10px" }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogShimmer
