import { store } from "../../redux/store"
import { setDrawerNavigationOpen } from "./redux/DrawerNavigationSlice"

export const openDrawerNavigation = () => {
  store.dispatch(setDrawerNavigationOpen(true))
}

export const closeDrawerNavigation = () => {
  store.dispatch(setDrawerNavigationOpen(false))
}

export const setOpenDrawerNavigation = (open) => {
  store.dispatch(setDrawerNavigationOpen(open))
}
