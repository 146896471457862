import { store } from "../../../../redux/store"
import {
  setCoinifyRedirectAlertModalOpen,
  setCoinifyRedirectAlertModalData
} from "./redux/CoinifyRedirectAlertModalSlice"

export const openCoinifyRedirectAlertModal = ({ redirectUrl = null }) => {
  store.dispatch(setCoinifyRedirectAlertModalOpen(true))
  store.dispatch(setCoinifyRedirectAlertModalData({ redirectUrl }))
}

export const closeCoinifyRedirectAlertModal = () => {
  store.dispatch(setCoinifyRedirectAlertModalOpen(false))
  store.dispatch(setCoinifyRedirectAlertModalData({}))
}
