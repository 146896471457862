import React, { useState, useEffect } from "react"

// react router
import { Link } from "react-router-dom"

// packages
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { FormattedMessage, injectIntl } from "react-intl"

// components
import Button from "../../../../components/Button/Button"
import RadioButton from "../../../../components/RadioButton/RadioButton"
import CurrencyInput from "../../../../components/CurrencyInput/CurrencyInput"

// styles
import Styles from "./marketFormModal.module.css"
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'

// icons
import { ReactComponent as FlagIcon } from '../../../../assets/icons/imported/finish.svg'
import { ReactComponent as MarketIcon } from '../../../../assets/icons/imported/market.svg'

// redux
import { _addOrder } from "../../redux/actions"
import { useSelector, useDispatch } from "react-redux"
import { _getBalances } from '../../../../redux/actions'
import { setBalances } from '../../../../redux/slices/balancesSlice'
import { addOrders, addOrder } from '../../redux/slices/openOrdersSlice'
import { setSnackbarData, setSnackbarOpen } from '../../../snackbar/redux/snackbarSlice'

// other
import { formatNumber } from '../../../helpers/formatNumber'
import { estimateUnitValue } from '../../../helpers/estimateValueBase'

const sliderMarks = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%'
}

function MarketFormModal({
  selectedTab,
  setSelectedTab
}) {
  const dispatch = useDispatch()

  const theme = useSelector((state) => state.theme.value)

  //user data
  const userData = useSelector((state) => state.userData?.value)
  const balances = useSelector((state) => state.balances.value)

  const marketsList = useSelector(state => state.marketList.value)
  const selectedMarket = useSelector(state => state.selectedMarket.value)
  const [buyQuantity, setBuyQuantity] = useState('')
  const [sellQuantity, setSellQuantity] = useState('')
  const [sellPrice, setSellPrice] = useState('')
  const [buyPrice, setBuyPrice] = useState('')
  const [toCurrency, setToCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
  const [fromCurrency, setFromCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
  const [selectedRadioButton, setSelectedRadioButton] = useState("limit")
  const marketTickers = useSelector((state) => state.marketTickers.value)
  const currencies = useSelector((state) => state.currencies.value)
  const [orderType, setOrderType] = useState('buy')
  const [buyLimitPrice, setBuyLimitPrice] = useState('')
  const [sellLimitPrice, setSellLimitPrice] = useState('')
  const [refreshBalance, setRefreshBalance] = useState(false)
  const [buySliderValue, setBuySliderValue] = useState(0)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [modalStates, setModalStates] = useState({
    quickBuySellOpen: false,
    convertOpen: false
  })

  const [isTableCollapsed, setIsTableCollapsed] = useState(window.innerWidth < 940)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700)

  const handleResize = () => {
    setIsTableCollapsed(window.innerWidth < 900)
    setIsMobileView(window.innerWidth < 700)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  let buyConvertedPrice = estimateUnitValue(buyQuantity ? toCurrency : fromCurrency, buyQuantity ? fromCurrency : toCurrency, parseFloat(buyQuantity || buyPrice), currencies, marketsList, marketTickers)
  useEffect(() => {
    setFromCurrency(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
    setToCurrency(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
    buyConvertedPrice = estimateUnitValue(buyQuantity ? toCurrency : fromCurrency, buyQuantity ? fromCurrency : toCurrency, parseFloat(buyQuantity || buyPrice), currencies, marketsList, marketTickers)
  }, [buyQuantity, selectedMarket, buyPrice, currencies, marketTickers])

  const resetInputValues = () => {
    setSellPrice('')
    setBuyPrice('')
    setBuyQuantity('')
    setSellQuantity('')
    setBuyLimitPrice('')
    setSellLimitPrice('')
    setBuySliderValue('')
  }

  useEffect(() => {
    _getBalances(
      (res) => {
        dispatch(setBalances(res))
      },
      () => { }
    )
  }, [refreshBalance, selectedMarket])

  useEffect(() => {
    resetInputValues()
  }, [selectedMarket])

  let sellConvertedPrice = estimateUnitValue(sellQuantity ? toCurrency : fromCurrency, sellQuantity ? fromCurrency : toCurrency, parseFloat(sellQuantity || sellPrice), currencies, marketsList, marketTickers)
  useEffect(() => {
    setFromCurrency(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
    setToCurrency(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
    sellConvertedPrice = estimateUnitValue(sellQuantity ? toCurrency : fromCurrency, sellQuantity ? fromCurrency : toCurrency, parseFloat(sellQuantity || sellPrice), currencies, marketsList, marketTickers)
  }, [sellQuantity, marketTickers, selectedMarket, sellPrice])

  const handleSubmit = () => {
    _addOrder(
      {
        market: selectedMarket.id,
        side: selectedTab,
        volume: orderType === 'buy' ? buyQuantity || buyConvertedPrice : sellQuantity || sellConvertedPrice,
        ...(selectedRadioButton === "limit" && { price: orderType === 'buy' ? buyLimitPrice : sellLimitPrice }),
        ord_type: selectedRadioButton
      },
      (data) => {
        dispatch(setSnackbarData({
          alertMessage: 'Order set',
          buttonMessage: 'See order',
          callback: () => { },
          actionType: 'link', // link - close
          linkTo: '/account/orders/open',
          severity: 'success',
          position: 'topRight'
        }))
        dispatch(setSnackbarOpen(true))
        // dispatch(setSnackbarData({
        //   alertMessage: 'Information Added Successfully',
        //   severity: 'success',
        //   callback: () => { },
        //   position: 'topRight'
        // }))
        //dispatch(addOrder(data))
        resetInputValues()
        setRefreshBalance(!refreshBalance)
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(setSnackbarData({
          alertMessage: error.errors[0].length ? (<FormattedMessage id={error.errors[0]} />) : "Unknown Error",
          severity: 'error',
          //buttonMessage: 'See order',
          callback: () => { },
          //actionType: 'link', // link - close
          //linkTo: '/account/orders/open', 
          position: 'topRight'
        }))
      }
    )
  }
  const handleBuyQuantityChange = (event) => {
    setBuyQuantity(event.target.value)
    setBuyPrice('')
    if (!event.target.value) {
      buyConvertedPrice = ''
      setBuyPrice('')
    }
  }

  const handleBuyPriceChange = (event) => {
    setBuyPrice(event.target.value)
    setBuyQuantity('')
  }

  const handleBuyLimitPriceChange = (event) => {
    setBuyLimitPrice(event.target.value)
  }

  const handleSellLimitPriceChange = (event) => {
    setSellLimitPrice(event.target.value)
  }
  const handleSellQuantityChange = (event) => {
    setSellQuantity(event.target.value)
    setSellPrice('')
  }

  const handleSellPriceChange = (event) => {
    setSellPrice(event.target.value)
    setSellQuantity('')
  }

  useEffect(() => {
    resetInputValues()
  }, [selectedRadioButton])

  const handleBuySliderChange = (value) => {
    setBuySliderValue(value)
    let currency
    if (selectedRadioButton === 'limit') {
      currency = balances.find((el) => el.currency === fromCurrency)
      if (buyLimitPrice) {
        setBuyQuantity((currency.balance * value) / 100)
      }
    } else {
      currency = balances.find((el) => el.currency === toCurrency)

      setBuyPrice((currency.balance * value) / 100)
    }
  }

  const coinNamePrice = useSelector(state => state.selectedMarket.value?.name?.split('/')[1])
  return (
    <div
      style={{
        backgroundColor: theme === 'light' ? 'white' : 'var(--secondary-color)'
      }}
      className={Styles.card}
    >

      {/* tabs container */}
      <div className={Styles.buySellTabsContainer}>
        <button
          className={selectedTab === 'buy' ? Styles.selectedTab : undefined}
          onClick={() => {
            if (selectedTab === 'buy') return
            setSelectedTab('buy')

          }}
        >
          <FormattedMessage id='page.markets.trade.buy' /> {fromCurrency?.toUpperCase()}
        </button>
        <button
          className={selectedTab === 'sell' ? Styles.selectedTab : undefined}
          onClick={() => {
            if (selectedTab === 'sell') return
            setSelectedTab('sell')
          }}
        >
          <FormattedMessage id='page.markets.trade.sell' /> {fromCurrency?.toUpperCase()}
        </button>
      </div>

      {/* radio buttons container */}
      <div className={Styles.radioButtonsContainer}>
        <RadioButton
          text={<FormattedMessage id="page.markets.trade.limit" />}
          icon={
            <FlagIcon />
          }
          isSelected={selectedRadioButton === "limit"}
          onClick={() => {
            if (selectedRadioButton === "limit") return
            setSelectedRadioButton("limit")
          }}
        />
        <RadioButton
          text={<FormattedMessage id="page.markets.trade.market" />}
          icon={
            <MarketIcon width={12} />
          }
          isSelected={selectedRadioButton === "market"}
          onClick={() => {
            if (selectedRadioButton === "market") return
            setSelectedRadioButton("market")
          }}
        />
      </div>

      {/* MAIN Buy/Sell container */}
      <div className={Styles.mainBuySellContainer}>
        {/* Buy Container */}
        <div className={Styles.buyContainer}>
          <span className={Styles.balanceSpan}>
            {
              userData.email && (
                balances ? (
                  (balances.find(el => el.currency === fromCurrency)?.balance) ? (
                    `Available: ${formatNumber(balances.find(el => el.currency === fromCurrency)?.balance) + ' ' + balances.find(el => el.currency === fromCurrency)?.currency.toUpperCase() ?? '0.00'} - ${formatNumber(balances.find(el => el.currency === toCurrency)?.balance) + ' ' + balances.find(el => el.currency === toCurrency)?.currency.toUpperCase() ?? '0.00'}`
                  ) : (
                    `Available: 0.00 ${fromCurrency?.toUpperCase()}`
                  )

                ) : (
                  <ShimmerLine
                    height='10px'
                    width='70px'
                  />
                )
              )
            }
          </span>
          <CurrencyInput
            title={`Price`}
            onChange={selectedRadioButton === 'limit' ? handleBuyLimitPriceChange : handleBuyPriceChange}
            type="number"
            onDropdownClick={() => {}}
            // disabled={selectedRadioButton === 'market'}
            placeholder={'0.00'}
            value={selectedRadioButton === 'limit' ? buyLimitPrice : (buyPrice || buyConvertedPrice ? buyPrice || buyConvertedPrice : '')}
            containerStyle={{
              marginTop: 15
            }}
            // currencyImg={useSelector(state => state.selectedMarket.value?.icon_url) ?? ''}
            currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.quote_url}
            coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[1])}
          />

          <CurrencyInput
            title={`Amount`}
            onChange={handleBuyQuantityChange}
            type="number"
            onDropdownClick={() => {}}
            placeholder="0.00"
            value={buyQuantity || buyConvertedPrice ? buyQuantity || buyConvertedPrice : ''}
            containerStyle={{
              marginTop: 15
            }}
            coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])}
            currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
          />
          {/* slider here */}
          <Slider
            min={0}
            max={100}
            marks={sliderMarks}
            value={buySliderValue}
            onChange={handleBuySliderChange}
            trackStyle={[{ backgroundColor: "var(--primary-color)" }]}
            railStyle={{ backgroundColor: "var(--gray-color)" }}
            handleStyle={{
              height: 12,
              width: 12,
              marginTop: -3.5,
              backgroundColor: "white",
              border: `2px solid var(--primary-color)`,
              borderRadius: "50%",
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            dotStyle={{
              backgroundColor: "var(--gray-color)",
              borderColor: "var(--gray-color)"
            }}
            activeDotStyle={{
              backgroundColor: "var(--primary-color)",
              borderColor: "var(--primary-color)"
            }}
            style={{ margin: "30px auto", width: "90%" }}
          />
          {
            selectedRadioButton === 'limit' && (
              <CurrencyInput
                title={`Total`}
                currencyIcon=''
                onChange={handleBuyPriceChange}
                type="number"
                onDropdownClick={() => {}}
                placeholder="0.00"
                value={selectedRadioButton ? buyQuantity * buyLimitPrice : buyPrice || buyConvertedPrice ? buyPrice || buyConvertedPrice : ''}
                containerStyle={{
                  marginTop: 15
                }}
                coinName={coinNamePrice}
              />
            )
          }

          {/* fees info row */}
          <div className={Styles.feesInfoRow}>
            {/* <span>Available:</span>
            <span>0 BTC = 0 USD</span> */}
          </div>
          {/* <div className={Styles.feesInfoRow}>
            <span>Volume:</span>
            <span>0 BTC = 0 USD</span>
          </div>
          <div className={Styles.feesInfoRow}>
            <span>Margin:</span>
            <span>0 BTC = 0 USD</span>
          </div> */}
          <div className={Styles.feesInfoRow}>
            {/* <span>{<FormattedMessage id='page.markets.trade.fee' />}:</span>
            <span>0 BTC = 0 USD</span> */}
          </div>

          {/* buy/sell button */}
          {
            userData.email ? (
              // <Button
                // text={`Buy ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`}
              //   style={{
              //     marginTop: 30,
              //     backgroundColor: '#10B981',
              //     color: 'white'
              //   }}
              //   onClick={e => {
              //     setOrderType('buy')
              //     handleSubmit()
              //   }}
              //   className={Styles.buyButton}
              // />

              // {/* buy/sell button */}
              <Button
                text={selectedTab === 'buy' ? (`Buy ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`) : (`Sell ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`)}
                style={{
                  marginTop: 30,
                  backgroundColor: selectedTab === 'sell' ? 'rgba(240,68,68,255)' : null,
                  color: selectedTab === 'sell' ? 'white' : null
                }}
                onClick={handleSubmit}
                // isLoading={buttonLoading}
              />
            ) : (
              <Button
                type='loggedOut'
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default injectIntl(MarketFormModal)