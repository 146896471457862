import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from "./deleteBeneficiaryModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setDeleteBeneficiaryModalData,
  setDeleteBeneficiaryModalOpen
} from "../redux/slices/deleteBeneficiaryModalSlice"
import { _deleteBeneficiary } from "../redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { removeBeneficiary } from "../../../../redux/slices/beneficiariesSlice"
// components
import WenbitModal from "../../../../components/Modal/Modal"
import Button from "../../../../components/Button/Button"
import Input from "../../../../components/Input/Input"

function DeleteBeneficiaryModal() {
  const dispatch = useDispatch()
  const modalOpen = useSelector(
    (state) => state.deleteBeneficiaryModalState.open
  )
  const modalData = useSelector(
    (state) => state.deleteBeneficiaryModalState.data
  )
  const beneficiaryID = useSelector((state) => state.beneficiary.value.id)

  const handleClose = () => {
    dispatch(
      setDeleteBeneficiaryModalData({
        callback: () => {}
      })
    )
    dispatch(setDeleteBeneficiaryModalOpen(false))
  }

  const handleDelete = () => {
    _deleteBeneficiary(
      beneficiaryID,
      () => {
        dispatch(removeBeneficiary(beneficiaryID))
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: "Beneficiary Deleted Successfully",
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
        handleClose()
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
        handleClose()
      }
    )
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title="Manage Beneficiaries"
        render={() => (
          <div>
            <p>Do you really want to delete this beneficiary?</p>
            <Button
              text="Delete"
              onClick={handleDelete}
              style={{
                backgroundColor: "#F87171"
              }}
            />
          </div>
        )}
      />
    </>
  )
}

export default DeleteBeneficiaryModal
