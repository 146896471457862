import React, { useRef, useState } from 'react'

// styles
import Styles from './checkboxInput.module.css'

// icons
import { ReactComponent as CheckboxIcon } from '../../assets/icons/imported/checkmark.svg'

function CheckboxInput({
  checkboxId,
  label,
  labelStyle,
  checkboxStyle,
  variation = 'regular', // regular - outlined
  containerStyle,
  error,
  onChange,
  register // for Yup form validation
}) {
  const inputRef = useRef(null)
  const [checked, setChecked] = useState(false)

  const handleCheckboxClick = event => {
    setChecked(event.target.checked)
  }

  return (
    <div className={Styles.container} style={{ ...containerStyle }} onClick={handleCheckboxClick}>
      <label htmlFor={checkboxId} className={Styles.label} style={{ ...labelStyle }}>
        <input
          id={checkboxId}
          name={checkboxId}
          type='checkbox'
          className={Styles.input}
          ref={inputRef}
          {...register}
          onChange={onChange !== undefined ? onChange : register && register.onChange}
        />
        <div
          className={`${Styles.checkbox} ${variation === 'outlined' && Styles.outlineCheckbox}`}
          style={{ ...checkboxStyle }}
        >
          {checked && <CheckboxIcon width={13} color='currentColor' />}
        </div>
        <div>{label}</div>
      </label>

      <span className={Styles.errorSpan}>{error?.message ? error.message : ' '}</span>
    </div>
  )
}

export default CheckboxInput
