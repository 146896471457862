import React, { useEffect, useState } from "react"

// react router
import { useNavigate } from "react-router-dom"
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from "./selectAvatarModal.module.css"

// redux
import { useDispatch, useSelector } from "react-redux"
import { _changeAvatar, _getAvatars } from "./redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../snackbar/redux/snackbarSlice"
import { _getUserInfo } from "../../../redux/actions"
import { setLoggedIn } from "../../../redux/slices/loggedIn"
import { clearBalances } from "../../../redux/slices/balancesSlice"
import { setUser, logoutUser } from "../../user/redux/slices/userDataSlice"

// components
import WenbitModal from "../../../components/Modal/Modal"
import { hideSelectAvatarModal } from "./helpers"
import Button from "../../../components/Button/Button"
import ShimmerCircle from "../../../components/shimmer-components/ShimmerCircle"

function SelectAvatarModal() {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData.value)
  const modalOpen = useSelector((state) => state.selectAvatarModalState.open)
  const modalData = useSelector((state) => state.selectAvatarModalState.data)

  const [selectedAvatarId, setSelectedAvatarId] = useState(
    userData?.user_avatar.id
  )
  const [avatars, setAvatars] = useState([])
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    hideSelectAvatarModal()
  }

  useEffect(() => {
    if (modalOpen) {
      _getAvatars(
        (data) => {
          setAvatars(data)
        },
        (err) => {
          // console.log(err)
        }
      )
    } else {
      setAvatars([])
    }
  }, [modalOpen])

  const handleSubmit = () => {
    setLoading(true)
    _changeAvatar(
      {
        avatar_id: selectedAvatarId
      },
      () => {
        setLoading(false)
        _getUserInfo(
          (data) => {
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "Avatar Set Successfully",
                severity: "success",
                callback: () => {},
                position: "topRight"
              })
            )
            dispatch(setUser(data))
            if (data.errors) {
              dispatch(logoutUser())
              dispatch(setLoggedIn(false))
              dispatch(clearBalances())
            }
          },
          (error) => {
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "Avatar Set Successfully",
                severity: "success",
                callback: () => {},
                position: "topRight"
              })
            )
            if (error?.errors) {
              dispatch(logoutUser())
              dispatch(clearBalances())
              dispatch(setLoggedIn(false))
            }
          }
        )
        handleClose()
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }
  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title={modalData?.title}
        render={() => (
          <>
            <span>Select an avatar to use as your profile picture</span>
            <div className={Styles.avatarsContainer}>
              {avatars.length
                ? avatars.map((avatar) => (
                    <img
                      src={avatar.image?.url}
                      className={`${Styles.avatarButton} ${
                        selectedAvatarId === avatar.id && Styles.selectedAvatar
                      }`}
                      onClick={() => {
                        // console.log(avatar.id)
                        setSelectedAvatarId(avatar.id)
                      }}
                      style={{
                        padding: ".7px"
                      }}
                    />
                  ))
                : new Array(2).fill(0).map((el) => <ShimmerCircle size={90} />)}
            </div>
            {avatars.length ? (
              <div className={Styles.buttonsContainer}>
                <Button variant="dark" text="Cancel" onClick={handleClose} />
                <Button
                  text="Save Change"
                  onClick={handleSubmit}
                  isLoading={loading}
                />
              </div>
            ) : null}
          </>
        )}
      />
    </>
  )
}

export default SelectAvatarModal
