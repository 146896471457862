import { createSlice } from "@reduxjs/toolkit"

const selectAvatarModalSlice = createSlice({
  name: "selectAvatarModalState",
  initialState: {
    open: false,
    data: {
      title: "",
      callback: () => {}
    }
  },
  reducers: {
    setSelectAvatarModalOpen: (state, action) => {
      state.open = action.payload
    },
    setSelectAvatarModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setSelectAvatarModalOpen, setSelectAvatarModalData } =
  selectAvatarModalSlice.actions

export default selectAvatarModalSlice.reducer
