// ** React Imports
import React from "react"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Styles Imports
import Styles from "./spotWalletFooter.module.css"

// ** Component Imports
import Button from "../../../../components/Button/Button"

// ** Handler Imports
import { handleDeposit } from "../../../../utility/deposit-withdraw-helpers/handleDeposit"
import { handleWithdraw } from "../../../../utility/deposit-withdraw-helpers/handleWithdraw"
import { showSelectCoinModal } from "../../../modals/select-currency/helpers"

function SpotWalletFooter({ setModalStates }) {
  // ** States
  const theme = useSelector((state) => state.theme.value)

  // ** Handlers
  const handleDepositPress = () => {
    showSelectCoinModal({
      title: "Select coin to deposit",
      showTabs: false,
      type: "coin",
      searchPlaceholder: "Search coin name",
      currenciesToHide: [],
      disableBackdropClick: true,
      callback: (coin) => {
        handleDeposit(coin.id)
      }
    })
  }

  return (
    <div className={Styles.footerContainer}>
      <Button text="Deposit" onClick={handleDepositPress} />
      <Button
        text="Withdraw"
        onClick={handleWithdraw}
        variant="dark"
        style={{
          backgroundColor:
            theme === "dark" ? "var(--secondary-color)" : "#E9EAF0",
          color: theme === "light" && "var(--text-color)"
        }}
      />
    </div>
  )
}

export default SpotWalletFooter
