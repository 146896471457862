import React from 'react'

// styles
import Styles from './search.module.css'

// redux
import { useSelector } from 'react-redux'

// icons
import { FiSearch } from 'react-icons/fi'

function Search({
  placeholder,
  containerStyle,
  onChange,
  inputStyle,
  ...rest
}, ref) {

  const theme = useSelector(state => state.theme.value)

  return (
    <div
      className={`${Styles.searchMarketPairContainer} ${theme === 'light' && Styles.searchMarketPairContainerLight}`}
      style={containerStyle}
    >
        <FiSearch className={Styles.searchMarketPairIcon} color='#CCCED9'/>
        <input
          placeholder={placeholder}
          onChange={onChange}
          style={{...inputStyle}}
          {...rest}
          ref={ref}
        />
    </div>
  )
}

export default React.forwardRef(Search)