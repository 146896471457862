import React, { useState, useEffect } from "react"

// react router
import { Link } from "react-router-dom"

// styles
import Styles from "./accountActivities.module.css"

// components
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"
import TableButton from "../../../../components/TableButton/TableButton"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"

//utils
import { capitalize } from "@mui/material"

// APIs
import { _getActivity } from "../../redux/actions"
import MobileRowDatagrid from "../../../../components/MUIDatagrid/MobileRowDatagrid"

function AccountActivitesTab() {
  const [data, setData] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(1)
  const [userAgent, setUserAgent] = useState(null)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  document.title = "Wenbit: Activities"

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 550)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 550)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const handleViewDetails = () => {
    setDetailsModalOpen(true)
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const fetchData = () => {
    setTableLoading(true)
    _getActivity(
      page,
      (res) => {
        setTableLoading(false)
        setTotalRows(res.total)
        setData(res.data)
      },
      (err) => setTableLoading(false)
    )
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const dateTime = (isoString) => {
    const dateObj = new Date(isoString)
    const date = dateObj.toISOString().slice(0, 10)
    const time = dateObj.toISOString().slice(11, 19)
    return { date, time }
  }

  const activitiesColumns = isMobileView
    ? [
        {
          flex: 1,
          field: "created_at",
          headerName: "Date",
          sortable: false,
          minWidth: 170,
          renderCell: (data) => (
            <MobileRowDatagrid
              rows={[
                {
                  title: "Date",
                  value: (
                    <>
                      <span>{dateTime(data.row.created_at).date}</span>
                      <span>{dateTime(data.row.created_at).time}</span>
                    </>
                  )
                },
                {
                  title: "Action",
                  value: <div>{capitalize(data.row.action)}</div>
                },
                {
                  title: "Result",
                  value: <span>{capitalize(data.row.result)}</span>
                },
                {
                  title: "IP Address",
                  value: <div>{data.row.user_ip}</div>
                },
                {
                  title: "User Agent",
                  value: (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        handleViewDetails()
                        setUserAgent(data.row.user_agent)
                      }}
                      text="View details"
                    />
                  )
                }
              ]}
            />
          )
        }
      ]
    : [
        {
          flex: 1,
          field: "created_at",
          headerName: "Date",
          sortable: false,
          minWidth: 200,
          renderCell: (data) => (
            <div className={Styles.dateTimeColumn}>
              <span>{dateTime(data.row.created_at).date}</span>
              <span>{dateTime(data.row.created_at).time}</span>
            </div>
          )
        },
        {
          flex: 1,
          field: "action",
          headerName: "Action",
          sortable: false,
          minWidth: 20,
          renderCell: (data) => (
            <div style={{ color: "var(--gray-color)" }}>
              {capitalize(data.row.action)}
            </div>
          )
        },
        {
          flex: 1,
          field: "result",
          headerName: "Result",
          sortable: false,
          minWidth: 70,
          renderCell: (data) => <span>{capitalize(data.row.result)}</span>
        },
        {
          flex: 1,
          field: "user_ip",
          headerName: "IP Address",
          sortable: false,
          minWidth: 70,
          renderCell: (data) => (
            <div style={{ color: "var(--gray-color)" }}>{data.row.user_ip}</div>
          )
        },
        {
          flex: 1,
          field: "user_agent",
          headerName: "User Agent",
          sortable: false,
          minWidth: 70,
          renderCell: (data) => (
            // <Link
            // onClick={(e) => {
            //   handleViewDetails()
            //   setUserAgent(data.row.user_agent)
            // }}
            // >
            //   View Details
            // </Link>
            <TableButton
              buttonText="View Details"
              onClick={(e) => {
                handleViewDetails()
                setUserAgent(data.row.user_agent)
              }}
            />
          )
        }
      ]

  return (
    <div className={Styles.tabContainer}>
      {/* VIEW DETAILS MODAL */}
      <WenbitModal
        title="Browser Details"
        showCloseButton={true}
        open={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        render={() => <div className={Styles.modalDiv}>{userAgent}</div>}
      />

      <h5>Account Activites</h5>

      {/* <div style={{width: '100%'}}> */}
      <MUIDatagrid
        page={page}
        pageLimit={7}
        rows={data}
        totalRowsCount={totalRows}
        loading={tableLoading}
        onPageChange={handlePageChange}
        getRowId={(row) => row.id}
        columns={activitiesColumns}
        hideHeader={isMobileView}
        rowHeight={isMobileView && 200}
      />
    </div>
  )
}

export default AccountActivitesTab
