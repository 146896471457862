// ** React Imports
import React from "react"

// ** Components Imports
import ShimmerLine from "../../../../../../components/shimmer-components/ShimmerLine"
import { Box } from "@mui/material"

function ConfirmPaymentLoadingShimmer() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <ShimmerLine width="140px" height="20px" />
      <ShimmerLine width="185px" height="20px" />
      <ShimmerLine width="95px" height="20px" />
      <ShimmerLine width="205px" height="20px" />
      <ShimmerLine width="100%" height="200px" style={{ marginTop: "30px" }} />
    </Box>
  )
}

export default ConfirmPaymentLoadingShimmer
