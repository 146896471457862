import { createSlice } from '@reduxjs/toolkit'

export const balancesSlice = createSlice({
  name: 'balances',
  initialState: {
    value: []
  },
  reducers: {
    setBalances: (state, payload) => {
      state.value = payload.payload
    },
    clearBalances: (state, payload) => {
      state.value = []
    }
  }
})

// Action creators are generated for each case reducer function
export const { setBalances, clearBalances } = balancesSlice.actions

export default balancesSlice.reducer