export function debounce(func, wait) {
  let timeoutId

  return function(...args) {
    clearTimeout(timeoutId)
    if (typeof func === 'function') {
      timeoutId = setTimeout(() => {
        func.apply(this, args)
      }, wait)
    }
  }
}