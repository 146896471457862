import React from 'react'

// styles
import Styles from './tableButton.module.css'
import './tableButton.css'

// redux
import { useSelector } from 'react-redux'

function TableButton({
    buttonIcon,
    buttonText,
    style,
    onClick,
    disabled,
    size = 11,
    ...rest
}) {

  return (
    <button
      className={`tableButton ${Styles.button}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        fontSize: size,
        ...style
      }}
      {...rest}
    >
        {buttonText}
        {buttonIcon}
    </button>
  )
}

export default TableButton