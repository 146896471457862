import { API } from "../../../../utility/API"
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"
// import { persistStore } from 'redux-persist'
//************************************//
export const _getCurrencies = (callback, callbackErr) => {
  API.get(`trading/public/currencies`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _previewConversion = (data, callback, callbackErr) => {
  API.post(`trading/market/conversions/preview`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _convert = (id, callback, callbackErr) => {
  API.post(`trading/market/conversions/${id}/confirm `)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getCoinKline = (data, callback, callbackErr) => {
  let url = `trading/public/markets/k-line?period=1440`
  if (data && data.pairs && data.pairs.length > 0) {
    url += data.pairs.map((pair) => `&markets[]=${pair}`).join("")
  }
  API.get(url, { params: data })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
