import { store } from "../../../../redux/store"
import { _getBeneficiaries } from "../redux/actions"
import {
  setSelectBeneficiaryModalData,
  setSelectBeneficiaryModalOpen
} from "../redux/slices/selectBeneficiaryModalSlice"

export const showSelectBeneficiaryModal = (
  coinId = "",
  callback = () => {}
) => {
  store.dispatch(
    setSelectBeneficiaryModalData({
      coinId,
      callback
    })
  )
  store.dispatch(setSelectBeneficiaryModalOpen(true))
}

export const hideSelectBeneficiaryModal = () => {
  store.dispatch(setSelectBeneficiaryModalOpen(false))
}
