// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./orderbook.module.css"

// ** Component Imports
import OrderbookBody from "./OrderbookBody"
import OrderbookHeader from "./OrderbookHeader"

function Orderbook() {
  return (
    <div className={styles.card}>
      <OrderbookHeader />
      <OrderbookBody />
    </div>
  )
}

export default Orderbook
