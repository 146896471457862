// ** React Imports
import React, { useEffect, useState } from "react"

// ** Redux I
import { useSelector } from "react-redux"
// ** Styles Imports
import styles from "./styles.module.css"

// ** Icons Imports
import { BiChevronDown, BiChevronUp } from "react-icons/bi"
import { ReactComponent as Bank } from "../../../../../assets/icons/imported/bank.svg"
import { ReactComponent as Coinify } from "../../../../../assets/icons/imported/coinify.svg"
import { ReactComponent as ApplePay } from "../../../../../assets/icons/imported/apple-pay.svg"
import { ReactComponent as CreditCard } from "../../../../../assets/icons/imported/credit-card.svg"
import { _getCoinifyPaymentMethods } from "../../redux/actions"
import { estimateUnitValue } from "../../../../helpers/estimateValueBase"

function PaymentMethods({ onChange = () => {} }) {
  // ** States
  const modalOpen = useSelector(
    (state) => state.selectPaymentMethodModalState.open
  )
  const modalData = useSelector(
    (state) => state.selectPaymentMethodModalState.data
  )
  const currencies = useSelector((state) => state.currencies.value)
  const marketsList = useSelector((state) => state.marketList.value)
  const marketTickers = useSelector((state) => state.marketTickers.value)

  const [open, setOpen] = useState(true)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [loading, setLoading] = useState(false)

  const estimateValue = estimateUnitValue(
    modalData?.fromValue ? modalData?.cryptoCoin?.id : modalData?.fiatCoin?.id,
    modalData?.fromValue ? modalData?.fiatCoin?.id : modalData?.cryptoCoin?.id,
    parseFloat(modalData?.fromValue || modalData?.toValue),
    currencies,
    marketsList,
    marketTickers
  )

  // ** LIST ITEMS
  const coinifyPaymentMethodsData = useSelector(
    (state) => state.coinifyState.data
  )

  // const [coinifyPaymentMethodsData, setCoinifyPaymentMethodsData] = useState({
  //   in_currencies: [],
  //   out_currencies: []
  // })
  const checkCoinifyDisabledState = (inCurrencies, outCurrencies) => {
    if (
      outCurrencies.includes(modalData?.cryptoCoin.id) &&
      inCurrencies.includes(modalData?.fiatCoin.id)
    ) {
      return false
    } else {
      return true
    }
  }
  // console.log(
  //   coinifyPaymentMethodsData.out_currencies.find(
  //     (el) => el.id === modalData.cryptoCoin
  //   ) === null &&
  //     coinifyPaymentMethodsData.in_currencies.find(
  //       (el) => el.id === modalData.fiatCoin
  //     ) === null
  // )
  const [items, setItems] = useState([
    {
      name: "Coinify",
      icon: <Coinify color="var(--gray-color)" width={20} />,
      text: "Buy crypto with a credit/debit card",
      disabled:
        coinifyPaymentMethodsData?.out_currencies.find(
          (el) => el.id === modalData?.cryptoCoin?.id
        ) === null &&
        coinifyPaymentMethodsData?.in_currencies.find(
          (el) => el.id === modalData?.fiatCoin?.id
        ) === null
    },
    {
      name: "Bank Wire Transfer",
      icon: <Bank color="var(--gray-color)" width={20} />,
      disabled: true
    },
    {
      name: "Apple Pay",
      icon: (
        <ApplePay
          color="var(--gray-color)"
          width={27}
          style={{ transform: "scale(1.3)", marginLeft: "4px" }}
        />
      ),
      disabled: true
    }
  ])

  // ** Constants
  const PADDING = 5
  const ITEM_HEIGHT = 55
  const EMPTY_STATE_HEIGHT = 65
  const OPEN_DROPDOWN_HEIGHT = items.length * (ITEM_HEIGHT + 2 * PADDING)

  // ** Handlers
  const handleToggleDropdown = () => {
    setOpen(!open)
  }

  const handleSelect = (item) => {
    if (item.disabled) return
    setSelectedMethod(item)
    handleToggleDropdown()
  }

  const fetchCoinifyPaymentMethods = () => {
    setLoading(true)
    _getCoinifyPaymentMethods(
      (data) => {
        // setCoinifyPaymentMethodsData({
        //   in_currencies: data.in_currencies,
        //   out_currencies: data.out_currencies
        // })
        setLoading(false)
      },
      (err) => setLoading(false)
    )
  }

  // Pass the selected method to the onChange prop function
  useEffect(() => {
    onChange(selectedMethod)
  }, [selectedMethod])

  useEffect(() => {
    // Get supported currencies on modal open
    if (modalOpen) {
      // fetchCoinifyPaymentMethods()
    }
  }, [modalOpen])

  return (
    <div>
      {/* Header */}
      <div className={styles.header} onClick={handleToggleDropdown}>
        <div>Pay with</div>

        {estimateValue && (
          <div>
            <span>You will get</span>
            {open ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
          </div>
        )}
      </div>

      {/* Dropdown */}
      <div
        className={`${styles.dropdownContainer}`}
        style={{
          padding: `${PADDING}px`,
          height: open
            ? `${OPEN_DROPDOWN_HEIGHT}px`
            : `${EMPTY_STATE_HEIGHT}px`,
          overflowY: open ? "overlay" : "hidden"
        }}
      >
        {selectedMethod ? (
          <button
            className={styles.item}
            style={{
              opacity: open ? 0 : 1,
              marginTop: open ? `-${ITEM_HEIGHT}px` : `0px`,
              marginBottom: !open && "10px",
              height: `${ITEM_HEIGHT}px`
            }}
            onClick={handleToggleDropdown}
          >
            {selectedMethod?.icon}
            <div className={styles.infoContainer}>
              <span className={styles.itemName}>{selectedMethod.name}</span>
              <span className={styles.itemDescription}>
                {selectedMethod.text}
              </span>
            </div>
            {/* <span className={styles.itemName}>{selectedMethod?.name}</span> */}

            {estimateValue && (
              <span className={styles.itemPrice}>
                ≈ {estimateValue} {modalData.cryptoCoin?.id?.toUpperCase()}
              </span>
            )}
          </button>
        ) : (
          <button
            className={styles.emptyStateButton}
            onClick={handleToggleDropdown}
            style={{
              opacity: open ? 0 : 1,
              marginTop: open && `-${ITEM_HEIGHT}px`,
              marginBottom: !open && "10px",
              height: `${ITEM_HEIGHT}px`
            }}
          >
            No payment method selected
          </button>
        )}
        {items.map((item, index) => (
          <>
            <button
              className={`${styles.item} ${
                selectedMethod?.name === item.name && styles.selectedItem
              } ${item.disabled && styles.disabled}`}
              style={{
                height: `${ITEM_HEIGHT}px`
              }}
              onClick={() => handleSelect(item)}
            >
              {item.icon}
              <div className={styles.infoContainer}>
                <span className={styles.itemName}>{item.name}</span>
                <span className={styles.itemDescription}>{item.text}</span>
              </div>

              {estimateValue && (
                <span className={styles.itemPrice}>
                  ≈ {estimateValue} {modalData.cryptoCoin?.id?.toUpperCase()}
                </span>
              )}
            </button>
            {index !== items.length - 1 && (
              <div
                style={{
                  backgroundColor: "var(--border-color)",
                  height: 1,
                  width: "95%",
                  margin: "5px auto"
                }}
              />
            )}
          </>
        ))}
      </div>
    </div>
  )
}

export default PaymentMethods
