import React from "react"

// styles
import Styles from "./verifyNumber.module.css"

// apis
import { _sendCode } from "../../redux/actions"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"

// utils
import { FormattedMessage } from "react-intl"

// redux
import { useDispatch } from "react-redux"
import {
  setSnackbarData,
  setSnackbarOpen
} from "../../../snackbar/redux/snackbarSlice"

function VerifyNumber({ phoneNumber, setPhoneNumber = () => {}, setStep }) {
  // hooks
  const dispatch = useDispatch()

  const sendVerification = () => {
    _sendCode(
      {
        phone_number: phoneNumber
      },
      (data) => {
        // console.log(data)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: <FormattedMessage id={data.message} />,
            severity: "success",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
        setStep(1)
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
        // console.log(error.errors)
        if (error.errors[0] === "resource.phone.exists") {
          setStep(1)
        }
      }
    )
  }

  return (
    <div className={Styles.verifyNumberContainer}>
      <h3>KYC Process</h3>

      {/* step number container */}
      <div className={Styles.stepNumberContainer}>
        <div>1</div>
        <div>
          <span>Verify your phone</span>
          <span>
            What’s your number? Let’s verify your phone number to keep bits
            legit.
          </span>
        </div>
      </div>

      <Input
        type="phoneNumber"
        inputLabel="Enter phone number"
        inputIconContainerStyle={{
          backgroundColor: "var(--background-color)"
        }}
        onChange={(value) => {
          setPhoneNumber(value)
        }}
      />

      <Button
        text="Send"
        onClick={sendVerification}
        style={{ marginTop: 20 }}
      />
    </div>
  )
}

export default VerifyNumber
