import * as moment from "moment-timezone"
import { getTimezone } from "./timezone"
export const localeDate = (
    date,
    timezone = getTimezone(),
    format = "HH:mm"
) => {
    const momentObj = moment.unix(date)
    //console.log(momentObj.tz('Asia/Beirut'))
    return momentObj.tz(timezone).format(format)
}

export const isoStringToDate = (isoString) => {
    const dateObj = new Date(isoString)
    const date = dateObj.toISOString().slice(0, 10)
    const time = dateObj.toISOString().slice(11, 19)
    return { date, time }
}