import { API } from "../../../../../../utility/API"

//************************************//
export const _getNameFromUID = (params, callback, callbackErr) => {
  API.get(`/authenticate/resource/users/secure_name`, { params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _requestInternalTransfer = (params, callback, callbackErr) => {
  API.post(`/trading/account/internal_transfers/request`, params)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _verifyInternalTransferOTP = (params, callback, callbackErr) => {
  API.post(`/trading/account/internal_transfers`, params)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _resendPhoneOTP = (params, callback, callbackErr) => {
  API.get(`/trading/account/internal_transfers/resend_phone_otp`, { params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _resendEmailOTP = (params, callback, callbackErr) => {
  API.get(`/trading/account/internal_transfers/resend_email_otp`, { params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
