import { createSlice } from "@reduxjs/toolkit"

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    value: "dark"
  },
  reducers: {
    toggleTheme: (state, payload) => {
      if (state.value === "dark") {
        state.value = "light"
      } else {
        state.value = "dark"
      }
    },
    setTheme: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggleTheme, setTheme } = themeSlice.actions

export default themeSlice.reducer
