import { setBackdropOpen } from "../../../../redux/slices/backdropSlice"
import { store } from "../../../../redux/store"
import { _generateCode } from "./redux/actions"
import {
  setEnable2FAModalData,
  setEnable2FAModalOpen
} from "./redux/slices/Enable2FAModalSlice"

export const showEnable2FAModal = () => {
  store.dispatch(setBackdropOpen(true))
  _generateCode(
    (data) => {
      store.dispatch(
        setEnable2FAModalData({
          barcode: data.data.barcode,
          url: data.data.url,
          title: "Enable Two Factor Authenticator"
        })
      )
      store.dispatch(setBackdropOpen(false))
      store.dispatch(setEnable2FAModalOpen(true))
    },
    (err) => store.dispatch(setBackdropOpen(false))
  )
}

export const closeEnable2FAModal = () => {
  store.dispatch(
    setEnable2FAModalData({
      barcode: "",
      url: ""
    })
  )
  store.dispatch(setEnable2FAModalOpen(false))
}
