import React, { useState, useEffect } from "react"

// styles
import Styles from "./selectCurrencyModal.module.css"

// icons
import { ReactComponent as InfoIcon } from "../../../assets/icons/imported/info.svg"

// components
import WenbitModal from "../../../components/Modal/Modal"
import Search from "../../../components/Search/Search"
import CoinTag from "../../../components/CoinTag/CoinTag"

// redux
import { useSelector } from "react-redux"

function SelectCurrencyModal({
  open,
  handleClose,
  currencies,
  setFromCurrency,
  setToCurrency,
  currencySide,
  setRefreshBalance,
  refreshBalance,
  onCurrencySelect = () => {} // feedback function to be called when a currency is selected
}) {
  // ** States
  const theme = useSelector((state) => state.theme.value)

  const [filteredCurrencies, setFilteredCurrencies] = useState(currencies)
  const handleSearchChange = (e) => {
    const tempArray = currencies.filter(
      (el) =>
        el?.name?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
        el?.id?.toLowerCase().includes(e.target.value?.toLowerCase())
    )
    setFilteredCurrencies(tempArray)
  }

  const handleRowClick = (currencyId) => {
    setRefreshBalance(refreshBalance + 1)
    if (currencySide === "fromCurrency") {
      setFromCurrency(currencyId)
      onCurrencySelect(currencyId)
    } else if (currencySide === "toCurrency") {
      setToCurrency(currencyId)
      onCurrencySelect(currencyId)
    }
    setFilteredCurrencies(currencies)
    handleClose()
  }

  // Reset search result
  useEffect(() => {
    if (!open) {
      setFilteredCurrencies(currencies)
    }
  }, [open])

  // -- Bug Fix --
  useEffect(() => {
    if (!filteredCurrencies.length) {
      setFilteredCurrencies(currencies)
    }
  }, [currencies])
  // ------------

  return (
    <WenbitModal
      open={open}
      onClose={handleClose}
      title="Select Currency"
      showCloseButton={true}
      render={() => (
        <div>
          <Search
            autoFocus
            placeholder="Search coin name"
            containerStyle={{ width: "100%" }}
            onChange={handleSearchChange}
          />

          <div className={Styles.rowsContainer}>
            {filteredCurrencies.length ? (
              filteredCurrencies?.map((currency) => (
                <div
                  className={`${Styles.row} ${
                    theme === "light" && Styles.lightRow
                  }`}
                  key={currency?.id}
                  onClick={() => {
                    handleRowClick(currency.id)
                  }}
                >
                  {currency.icon_url && <img src={currency?.icon_url} />}
                  <span>{currency?.name}</span>
                  <CoinTag tag={currency?.id?.toUpperCase()} />
                </div>
              ))
            ) : (
              <div className={Styles.notFound}>
                <InfoIcon width={12} />
                Coin not found
              </div>
            )}
          </div>
        </div>
      )}
    />
  )
}

export default SelectCurrencyModal
