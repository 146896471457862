import { API } from '../../../../../utility/API'

//************************************//
export const _changeAvatar = (data, callback, callbackErr) => {
    API.put(`authenticate/resource/users/avatar`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getAvatars = (callback, callbackErr) => {
    API.get(`authenticate/resource/users/available_avatars`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}