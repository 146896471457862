import React from "react"

// redux
import { useSelector } from "react-redux"

// styles
import Styles from "./marketFooter.module.css"

// components
import Button from "../../../components/Button/Button"

function MarketFooter({ setModalStates }) {
  const theme = useSelector((state) => state.theme.value)

  return (
    <div className={Styles.footerContainer}>
      <Button
        text="Quick Order"
        onClick={() =>
          setModalStates({
            quickBuySellOpen: true,
            convertOpen: false
          })
        }
      />
      <Button
        text="Convert"
        onClick={() =>
          setModalStates({
            quickBuySellOpen: false,
            convertOpen: true
          })
        }
        variant="dark"
        style={{
          backgroundColor:
            theme === "dark" ? "var(--secondary-color)" : "#E9EAF0",
          color: theme === "light" && "var(--text-color)"
        }}
      />
    </div>
  )
}

export default MarketFooter
