import React, { useState } from 'react'

// styles
import Styles from './ExchangeFooter.module.css'

//components
import MarketFormModal from './MarketFormModal'
import Button from '../../../../components/Button/Button'
import WenbitModal from '../../../../components/Modal/Modal'
import { useSelector } from 'react-redux'

function ExchangeFooter() {

    const userData = useSelector((state) => state.userData.value)

    const [selectedTab, setSelectedTab] = useState('buy')
    const [marketFormModalOpen, setMarketFormModalOpen] = useState(false)

    // if (!userData.email) return <></>

  return (
    <div className={Styles.footerContainer}>
        <Button
            text='Buy'
            style={{
                backgroundColor: 'var(--success-color)',
                color: 'white'
            }}
            onClick={() => {
                setSelectedTab('buy')
                setMarketFormModalOpen(true)
            }}
        />
        <Button
            text='Sell'
            style={{
                backgroundColor: 'var(--danger-color)',
                color: 'white'
            }}
            onClick={() => {
                setSelectedTab('sell')
                setMarketFormModalOpen(true)
            }}
        />

        {/* MARKET FORM MODAL */}
        <WenbitModal
            open={marketFormModalOpen}
            onClose={() => setMarketFormModalOpen(false)}
            render={() => (
                <MarketFormModal
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
            )}
        />
    </div>
  )
}

export default ExchangeFooter