import { createSlice } from "@reduxjs/toolkit"

const VerificationCodesInputModalState = createSlice({
  name: "VerificationCodesInputModalState",
  initialState: {
    open: false,
    data: {
      onOpen: () => {},
      onClose: () => {},
      title: "Verify OTP Code",
      email: false,
      sms: false,
      otp: false,
      showEmailResendCode: true,
      showSMSResendCode: true,
      handleEmailResendCode: () => {},
      handleSMSResendCode: () => {},
      callback: () => {},
      errCallback: () => {}
    }
  },
  reducers: {
    setVerificationCodesInputModalOpen: (state, action) => {
      state.open = action.payload
    },
    setVerificationCodesInputModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setVerificationCodesInputModalOpen,
  setVerificationCodesInputModalData
} = VerificationCodesInputModalState.actions

export default VerificationCodesInputModalState.reducer
