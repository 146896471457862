// import { Loader } from "@components/components"
import cr from "classnames"
import React, { useState, useEffect } from "react"
import { injectIntl } from "react-intl"
// import MaskInput from "react-maskinput"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { _sendCode, _verify, _reSendCode, _initializeKyc, _addInfo } from '../redux/actions'
import snsWebSdk from "@sumsub/websdk"
import { API } from '../../../utility/API'

const Document = ({ token, email }) => {
    const [newToken, setNewToken] = useState(token)

    const launchWebSdk = (
        apiUrl,
        flowName,
        accessToken,
        applicantEmail,
        applicantPhone,
        customI18nMessages
    ) => {
        const snsWebSdkInstance = snsWebSdk
            .init(accessToken, (newAccessTokenCallback) => {
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                API.post("authenticate/resource/kyc/initialize", []).then(function (data) {
                    newAccessTokenCallback(data["token"])
                })

            })
            .withConf({
                lang: "en",
                email: applicantEmail,
                phone: applicantPhone,
                i18n: customI18nMessages,
                onMessage: (type, payload) => {
                    // see below what kind of messages the WebSDK generates
                    // console.log("WebSDK onMessage", type, payload)
                },
                uiConf: {
                    customCss: "https://url.com/styles.css"
                    // URL to css file in case you need change it dynamically from the code
                    // the similar setting at Applicant flow will rewrite customCss
                    // you may also use to pass string with plain styles `customCssStr:`
                },
                onError: (error) => {
                    console.error("WebSDK onError", error)
                }
            })
            .build() // you are ready to go:
        // just launch the WebSDK by providing the container element for it
        // console.log("connected")

        snsWebSdkInstance.launch("#sumsub-websdk-container")
    }
    const url = "test-api.sumsub.com"

    useEffect(() => {
        _initializeKyc(
            (data) => {
                setNewToken(data.token)
                // console.log(data.token)
                const url = "test-api.sumsub.com"
                launchWebSdk(
                    `https://${url}`,
                    'basic-kyc',
                    data.token,
                    email,
                    'hello'
                )
            },
            (err) => {
                // console.log(err)
                launchWebSdk(
                    `https://${url}`,
                    'basic-kyc',
                    err.token,
                    email,
                    'hello'
                )
            }
        )
    }, [])

    // React.useEffect(() => {
    //     const url = "test-api.sumsub.com"
    //     launchWebSdk(
    //         `https://${url}`,
    //         'basic-kyc',
    //         newToken,
    //         "vladimir@cloudypro.com",
    //         'hello'
    //     )
    // }, [newToken])

    return (
        <>
            {/* <script src="https://static.sumsub.com/idensic/static/sns-websdk-builder.js"></script> */}
            <div id="sumsub-websdk-container"></div>
        </>
    )
}

export default Document