import React from "react"

const launchpadFAQs = [
  {
    summary: <span>How do I buy WenBit tokens?</span>,
    details: (
      <span>
        You can buy WenBit tokens on various cryptocurrency exchanges or
        directly from the WenBit website. Once you have WenBit in your digital
        wallet, you can use them to contribute to the crowdfunding campaign.
      </span>
    )
  },
  {
    summary: <span>Is WenBit safe to use for contributions?</span>,
    details: (
      <span>
        Absolutely! WenBit uses state-of-the-art encryption and blockchain
        technology to ensure all transactions are secure and transparent. Plus,
        no hidden fees or intermediaries are involved, so you can be sure that
        your contributions are going directly to the project.
      </span>
    )
  },
  {
    summary: (
      <span>
        What happens if the crowdfunding campaign doesn't reach its goal?
      </span>
    ),
    details: (
      <span>
        If the crowdfunding campaign doesn't reach its goal, all contributions
        made using WenBit tokens will be returned to the contributors. This
        ensures that your contributions are only used if the project is
        successful.
      </span>
    )
  },
  {
    summary: (
      <span>
        Can I use other cryptocurrencies to contribute to the crowdfunding
        campaign?
      </span>
    ),
    details: (
      <span>
        No, the crowdfunding campaign only accepts contributions in WenBit
        tokens. However, you can easily buy WenBit on various cryptocurrency
        exchanges or directly from the WenBit website.
      </span>
    )
  },
  {
    summary: (
      <span>
        What happens to the WenBit tokens after the crowdfunding campaign?
      </span>
    ),
    details: (
      <span>
        The WenBit tokens collected during the crowdfunding campaign will be
        used to fund the project. If any WenBit tokens remain, they will be held
        by the project team for future use.
      </span>
    )
  },
  {
    summary: (
      <span>
        Is there a limit to how much I can contribute using WenBit tokens?
      </span>
    ),
    details: (
      <span>
        No limit exists to how much you can contribute using WenBit tokens.
        However, ensuring you have enough WenBit in your digital wallet is vital
        to cover your contribution.
      </span>
    )
  }
]

export default launchpadFAQs
