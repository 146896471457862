// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Component Imports
import MegaMenuItem from "../../MegaMenuItem"

// ** Icons Imports
import { BiTimer, BiNews } from "react-icons/bi"
import { HiTrendingUp } from "react-icons/hi"
import { ReactComponent as WenLogo } from "../../../assets/icons/imported/wen-logo.svg"
import { ReactComponent as CalendarIcon } from "../../../assets/icons/imported/calendar.svg"

function BlogsMegaMenu() {
  return (
    <div>
      <MegaMenuItem
        name="What's Trending"
        description="See the lastest up-to-date trends"
        Icon={HiTrendingUp}
        to="/blogs/trending"
      />
      <MegaMenuItem
        name="Wen’s new bits"
        description="The Wenbit news"
        Icon={WenLogo}
        to="/blogs/wen-new-bits"
      />
      <MegaMenuItem
        name="Events"
        description="Ongoing crypto events"
        Icon={CalendarIcon}
        to="/blogs/events"
      />
      <MegaMenuItem
        name="Inside Scoop"
        description="Inside scoop news here"
        Icon={BiNews}
        to="/blogs/inside-scoop"
      />
      <MegaMenuItem
        name="The latest buzz"
        description="Recent fresh news"
        Icon={BiTimer}
        to="/blogs/latest-buzz"
      />
    </div>
  )
}

export default BlogsMegaMenu
