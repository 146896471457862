import React from "react"
import { useLocation } from "react-router-dom"

// redux
import { useSelector } from "react-redux"

// styles
import Styles from "./footer.module.css"
import { FormattedMessage, injectIntl } from "react-intl"

// components
import FooterLink from "./FooterLink"
import FooterSection from "./FooterSection"

// icons
import WenLogo from "../../assets/icons/wen-logos/wen-header-dark.svg"
import WenLogoLight from "../../assets/icons/wen-logos/wen-header-light.svg"
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube
} from "react-icons/fa"
import { ReactComponent as UpArrowIcon } from "../../assets/icons/imported/up-arrow.svg"
// --- payments icons
import { ReactComponent as VisaIcon } from "../../assets/icons/imported/visa.svg"
import { ReactComponent as VisaIconLight } from "../../assets/icons/imported/visa-light.svg"
import { ReactComponent as PaypalIcon } from "../../assets/icons/imported/paypal-footer.svg"
import { ReactComponent as MastercardIcon } from "../../assets/icons/imported/mastercard-footer.svg"
import { ReactComponent as MastercardIconLight } from "../../assets/icons/imported/mastercard-light.svg"
import { ReactComponent as BitcoinIcon } from "../../assets/icons/imported/bitcoin.svg"
import { ReactComponent as BitcoinIconLight } from "../../assets/icons/imported/bitcoin-light.svg"
import { ReactComponent as CreditsCardsIcon } from "../../assets/icons/imported/credit-cards.svg"
import { ReactComponent as CreditsCardsIconLight } from "../../assets/icons/imported/credit-cards-light.svg"

function Footer() {
  const theme = useSelector((state) => state.theme.value)

  const sections = [
    {
      //   title: <FormattedMessage id="page.footer.exchange" />,
      title: "The Platform",
      links: [
        { text: "Markets", url: "/markets" },
        { text: <FormattedMessage id="page.footer.exchange" />, url: "/trade" },
        {
          text: <FormattedMessage id="page.footer.convert" />,
          url: "/convert"
        },
        // {
        //   text: <FormattedMessage id="page.footer.launchpad" />,
        //   url: "/launchpad"
        // },
        {
          text: <FormattedMessage id="page.footer.wallet" />,
          url: "/account/wallet/spot/all"
        }
      ]
    },
    {
      title: <FormattedMessage id="page.footer.support" />,
      links: [
        // {
        //   text: <FormattedMessage id="page.footer.requestForm" />,
        //   url: "/request_form"
        // },
        // {
        //   text: <FormattedMessage id="page.footer.contactSupport" />,
        //   url: "/contact_support"
        // },
        // { text: <FormattedMessage id="page.footer.faq" />, url: "/FAQ" },
        // {
        //   text: <FormattedMessage id="page.footer.security" />,
        //   url: "/security"
        // }
        {
          text: "Your Bit Matters",
          url: "/your-bit-matters"
        },
        {
          text: "Contact Support",
          url: "/contact-support"
        },
        {
          text: "FAQs",
          url: "/faq"
        }
      ]
    },
    {
      title: <FormattedMessage id="page.footer.resources" />,
      links: [
        {
          text: <FormattedMessage id="page.footer.downloads" />,
          url: "/downloads"
        },
        {
          text: <FormattedMessage id="page.footer.mobileApplication" />,
          url: "/mobile-application"
        },
        // {
        //   text: <FormattedMessage id="page.footer.buyCrypto" />,
        //   url: "/buy_crypto"
        // },
        // {
        //   text: <FormattedMessage id="page.footer.referral" />,
        //   url: "/referral"
        // },
        // {
        //   text: <FormattedMessage id="page.footer.listingTranding" />,
        //   url: "/listing_tranding"
        // }
        {
          text: "Bring Your Crew",
          url: "/crew"
        },
        {
          text: "Fees",
          url: "/fees"
        }
      ]
    },
    {
      title: <FormattedMessage id="page.footer.learn" />,
      links: [
        {
          text: <FormattedMessage id="page.footer.whatsTrending" />,
          url: "/blogs/trending"
        },
        // {
        //   text: <FormattedMessage id="page.footer.productNews" />,
        //   url: "/product_news"
        // },
        {
          text: "Wen’s new bits",
          // url: "/news"
          url: "/blogs/wen-new-bits"
        },
        {
          text: <FormattedMessage id="page.footer.events" />,
          url: "/blogs/events"
        },
        // {
        //   text: <FormattedMessage id="page.footer.university" />,
        //   url: "/university"
        // },
        // {
        //   text: <FormattedMessage id="page.footer.research" />,
        //   url: "/research"
        // },
        // {
        //   text: <FormattedMessage id="page.footer.marketUpdate" />,
        //   url: "/market_update"
        // }
        {
          text: "Inside Scoop",
          url: "/blogs/inside-scoop"
        },
        {
          text: "The latest buzz",
          // url: "/market_update"
          url: "/blogs/latest-buzz"
        }
      ]
    },
    {
      title: <FormattedMessage id="page.footer.company" />,
      links: [
        {
          text: <FormattedMessage id="page.footer.aboutUs" />,
          url: "/about-us"
        },
        {
          text: <FormattedMessage id="page.footer.careers" />,
          url: "/careers"
        },
        {
          text: "Supported companies",
          url: "/support-companies"
        },
        // { text: <FormattedMessage id="page.footer.news" />, url: "/news" },
        {
          text: <FormattedMessage id="page.footer.security" />,
          url: "/company-security"
        }
        // {
        //   text: <FormattedMessage id="page.footer.community" />,
        //   url: "/community"
        // },
        // {
        //   text: <FormattedMessage id="page.footer.announcements" />,
        //   url: "/announcements"
        // }
      ]
    }
  ]

  return (
    // <div>

    <footer
      className={Styles.mainContainer}
      //   style={{
      //     paddingBottom: location.pathname === "/markets" && 60
      //   }}
    >
      {/* wenbit logo, moto, media links */}
      <div className={Styles.mediaSection}>
        {/* logo */}
        <img src={theme === "dark" ? WenLogo : WenLogoLight} />

        {/* moto */}
        <p>
          The only cryptocurrency exchange worth your time. We're talking
          next-level innovation, lightning-fast transactions, and top-tier
          security.
        </p>

        {/* media links container */}
        <div className={Styles.mediaLinksContainer}>
          {/* <FooterLink
                    //to='www.facebook.com'
                    key='www.facebook.com'
                    text={<FaFacebookF size={18}/>}
                    animationDirection='up'
                /> */}
          <FooterLink
            //to='www.instagram.com'
            key="www.instagram.com"
            text={<FaInstagram size={18} />}
            animationDirection="up"
          />
          {/* <FooterLink
                    //to='www.youtube.com'
                    key='www.youtube.com'
                    text={<FaYoutube size={18}/>}
                    animationDirection='up'
                /> */}
          <FooterLink
            //to='www.twitter.com'
            key="www.twitter.com"
            text={<FaTwitter size={18} />}
            animationDirection="up"
          />
          <FooterLink
            //to='www.linkedin.com'
            key="www.linkedin.com"
            text={<FaLinkedin size={18} />}
            animationDirection="up"
          />
        </div>
      </div>

      {sections.map((section, index) => (
        <FooterSection
          title={section.title}
          links={section.links}
          key={index}
        />
      ))}

      <div className={Styles.bottomContainer}>
        {/* scroll to top button */}
        <div
          className={Styles.srollTopButtonContainer}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            })
          }}
        >
          <UpArrowIcon width={12} />
        </div>

        <div className={Styles.mobilePaymentContainer}>
          <span>We accept following payment systems</span>

          <div className={Styles.footerPaymentsIonsContainer}>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? <VisaIcon /> : <VisaIconLight />}
            </div>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? <MastercardIcon /> : <MastercardIconLight />}
            </div>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? <BitcoinIcon /> : <BitcoinIconLight />}
            </div>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? (
                <CreditsCardsIcon
                  style={{ transform: "translateY(3px) translateX(2px)" }}
                />
              ) : (
                <CreditsCardsIconLight
                  style={{ transform: "translateY(3px) translateX(2px)" }}
                />
              )}
            </div>
          </div>
        </div>

        {/* left container */}
        <div className={Styles.bottomLeftContainer}>
          © 2023 Wenbit. <FormattedMessage id="page.footer.allRightsReserved" />
        </div>

        {/* mid container */}
        <div className={Styles.bottomMidContainer}>
          <FooterLink
            to="/terms-and-conditions"
            text="Terms & conditions"
            key={"terms-conditions"}
          />
          <FooterLink
            text="Policies & procedures"
            key={"policies-procedures"}
            to="/privacy-and-policy"
          />
          {/* <FooterLink
            text={<FormattedMessage id="page.footer.privacy" />}
            key={"privacy"}
          /> */}

          {/* <FooterLink
            text={<FormattedMessage id="page.footer.terms" />}
            key={"terms"}
          />

          <FooterLink
            text={<FormattedMessage id="page.footer.siteMap" />}
            key={"siteMap"}
          /> */}
        </div>

        {/* right container */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10
          }}
        >
          <span style={{ color: "var(--gray-color)" }}>
            We accept following payment systems
          </span>
          <div className={Styles.bottomRightContainer}>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? <VisaIcon /> : <VisaIconLight />}
            </div>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? <MastercardIcon /> : <MastercardIconLight />}
            </div>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? <BitcoinIcon /> : <BitcoinIconLight />}
            </div>
            <div className={Styles.paymentCard}>
              {theme === "dark" ? (
                <CreditsCardsIcon
                  style={{ transform: "translateY(3px) translateX(2px)" }}
                />
              ) : (
                <CreditsCardsIconLight
                  style={{ transform: "translateY(3px) translateX(2px)" }}
                />
              )}
            </div>
            {/* apple store */}
            {/* <div
                    className={Styles.appContainer}
                    onClick={() => {
                        window.open(URL, 'https://www.apple.com/app-store/')
                    }}
                >
                    <SiAppstore className={Styles.appleStoreIcon} size={23}/>
                    <div className={Styles.downloadContainer}>
                        <span><FormattedMessage id='page.footer.iosDownload' /></span>
                        <span><strong>App Store</strong></span>
                    </div>
                </div> */}

            {/* play store */}
            {/* <div
                    className={Styles.appContainer}
                    onClick={() => {
                        window.open(URL, 'https://play.google.com/')
                    }}
                >
                    <IoLogoGooglePlaystore size={23}/>
                    <div className={Styles.downloadContainer}>
                        <span><FormattedMessage id='page.footer.androidDownload' /></span>
                        <span><strong>Google Play</strong></span>
                    </div>
                </div> */}
          </div>
        </div>
        <div className={Styles.mediaLinksContainerMobile}>
          {/* <FooterLink
            //to='www.facebook.com'
            key="www.facebook.com"
            text={<FaFacebookF size={18} />}
            animationDirection="up"
          /> */}
          <FooterLink
            //to='www.instagram.com'
            key="www.instagram.com"
            text={<FaInstagram size={18} />}
            animationDirection="up"
          />
          {/* <FooterLink
            //to='www.youtube.com'
            key="www.youtube.com"
            text={<FaYoutube size={18} />}
            animationDirection="up"
          /> */}
          <FooterLink
            //to='www.twitter.com'
            key="www.twitter.com"
            text={<FaTwitter size={18} />}
            animationDirection="up"
          />
          <FooterLink
            //to='www.linkedin.com'
            key="www.linkedin.com"
            text={<FaLinkedin size={18} />}
            animationDirection="up"
          />
        </div>
      </div>
    </footer>
  )
}

export default injectIntl(Footer)
