// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function BlogCategoryCard({ name }) {
  return (
    <div className={styles.container}>
      <span className={styles.text}>{name}</span>
    </div>
  )
}

export default BlogCategoryCard
