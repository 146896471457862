import { createSlice } from '@reduxjs/toolkit'

const depositSlice = createSlice({
  name: 'depositData',
  initialState: {
    data: {
      selectedCoinId: '',
      selectedNetwork: '',
      depositAddress: ''
    }
  },
  reducers: {
    setSelectedCoinId: (state, action) => {
      state.data.selectedCoinId = action.payload
    },
    setSelectedNetwork: (state, action) => {
      state.data.selectedNetwork = action.payload
    },
    setDepositAddress: (state, action) => {
      state.data.depositAddress = action.payload
    }
  }
})

export const { setSelectedCoinId, setSelectedNetwork, setDepositAddress } = depositSlice.actions

export default depositSlice.reducer