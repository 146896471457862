import { createSlice } from "@reduxjs/toolkit"

const disableSMSAuthModalSlice = createSlice({
  name: "disableSMSAuthModalState",
  initialState: {
    open: false,
    data: {}
  },
  reducers: {
    setDisableSMSAuthModalOpen: (state, action) => {
      state.open = action.payload
    },
    setDisableSMSAuthModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setDisableSMSAuthModalOpen, setDisableSMSAuthModalData } =
  disableSMSAuthModalSlice.actions

export default disableSMSAuthModalSlice.reducer
