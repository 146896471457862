import React from "react"

// redux
import { useSelector } from "react-redux"

// icon
import { ReactComponent as MailIcon } from "../../../../assets/icons/imported/mail.svg"
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/imported/phone.svg"

// styles
import Styles from "./profileTab.module.css"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import { isoStringToDate } from "../../../helpers/localeDate"
import SelectLanguageCurrencyInput from "../../../../components/Input/SelectLanguageCurrencyInput/SelectLanguageCurrencyInput"

const inputStyle = {
  fontSize: 15
}

const inputContainerStyle = {
  margin: "20px 0",
  flex: 0.5
}

function ProfileTab({ userData }) {
  const theme = useSelector((state) => state.theme.value)

  return (
    <div className={Styles.tabContainer}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <h5>My Profile</h5>
      </div>

      {/* personal information */}
      <div className={Styles.formSection}>
        <p>Personal Information</p>
        <span>Fill out these deets, and let's get this party started.</span>

        {/* first/last name inputs container */}
        <div className={Styles.inputsContainer}>
          <Input
            inputLabel="First Name"
            style={inputStyle}
            containerStyle={inputContainerStyle}
            variant="secondary"
            value={userData.first_name ?? ""}
            disabled
          />
          <Input
            inputLabel="Last Name"
            style={inputStyle}
            containerStyle={inputContainerStyle}
            variant="secondary"
            value={userData.last_name ?? ""}
            disabled
          />
        </div>
      </div>

      {/* contact information */}
      <div className={Styles.formSection}>
        <p>Contact Information</p>
        <span>
          We need to keep in touch with you, so we're gonna need your contact
          information.
        </span>

        {/* email/number inputs container */}
        <div className={Styles.inputsContainer}>
          <Input
            inputLabel="Email"
            style={inputStyle}
            containerStyle={inputContainerStyle}
            variant="secondary"
            value={userData?.email}
            icon={<MailIcon width={18} />}
            disabled
          />
          <Input
            inputLabel="Phone Number"
            style={inputStyle}
            containerStyle={inputContainerStyle}
            variant="secondary"
            value={
              userData?.phones[0]?.number?.length && userData?.phones[0].number
            }
            icon={<PhoneIcon width={17} />}
            disabled
          />
        </div>

        {/* language/currency inputs container */}
        <div className={Styles.inputsContainer}>
          {/* language selector */}
          <SelectLanguageCurrencyInput
            containerStyle={inputContainerStyle}
            inputType="language"
          />
          {/* <Input
            type="select"
            inputLabel="Language"
            style={inputStyle}
            containerStyle={inputContainerStyle}
            selectOptions={[
              { label: "English", value: "en" }
              // {label: 'Arabic', value: 'ar'},
              // {label: 'French', value: 'fr'},
              // {label: 'Russian', value: 'ru'},
              // {label: 'Spanish', value: 'es'}
            ]}
            selectDefaultValue="en"
            variant="secondary"
          /> */}

          {/* currency selector */}
          <SelectLanguageCurrencyInput
            containerStyle={inputContainerStyle}
            inputType="currency"
          />
          {/* <Input
            type="select"
            inputLabel="Currency"
            style={inputStyle}
            containerStyle={inputContainerStyle}
            selectOptions={[
              { label: "US Dollar ($)", value: "usd" }
              // {label: 'British Pound (£)', value: 'gbp'},
              // {label: 'Euro (€)', value: 'eur'}
            ]}
            selectDefaultValue="usd"
            variant="secondary"
          /> */}
        </div>
      </div>

      <div className={Styles.footerContainer}>
        <span>
          This account was created on{" "}
          {isoStringToDate(userData.created_at).date}{" "}
          {isoStringToDate(userData.created_at).time}
        </span>

        {/* buttons container */}
        <div>
          <Button
            text="Cancel"
            variant="dark"
            style={{
              whiteSpace: "nowrap"
            }}
          />
          <Button
            text="Save changes"
            style={{
              width: "180%"
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileTab
