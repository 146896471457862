import React from "react"

function RequiredIndicator() {
  return (
    <span>
      <span style={{ color: "var(--primary-color)" }}>*</span>
      <span style={{ color: "var(--gray-color)", fontSize: 12 }}>Required</span>
    </span>
  )
}

export default RequiredIndicator
