import React from 'react'
import Button from '../Button/Button'

// styles
import Styles from './alert.module.css'

function Alert({
    alertText,
    showButton = false,
    buttonText = '',
    cardColor = '#AEF3F4',
    textColor = '#121318',
    containerStyle,
    textStyle,
    buttonStyle,
    icon,
    buttonOnClick = () => {},
    ...rest
}) {
  return (
    <div
        className={Styles.containerStyle}
        style={{
            color: textColor,
            backgroundColor: cardColor,
            ...containerStyle
        }}
        {...rest}
    >   
        <span className={Styles.iconTextContainer} style={{...textStyle}}>
            {icon}
            {alertText}
        </span>
        {
            showButton && (
                <Button
                    style={{
                        width: 'fit-content',
                        padding: '5px 29px',
                        height: 'fit-content',
                        borderRadius: 5,
                        ...buttonStyle
                    }}
                    text={buttonText}
                    onClick={buttonOnClick}
                />
            )
        }
    </div>
  )
}

export default Alert