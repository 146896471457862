import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

// routes
import {
  routesToHideFooter,
  routesToHideHeader
} from "../constants/routesConstants"

// components
import Footer from "./Footer/Footer"
import Header from "./Header/Header"

export default function Layout({ children }) {
  const location = useLocation()

  return (
    <>
      {!routesToHideHeader.includes(location.pathname) && <Header />}
      {
        <div
          style={{
            paddingTop: !routesToHideHeader.includes(location.pathname) && 95 //header height
          }}
        >
          {children}
        </div>
      }
      {!routesToHideFooter.includes(location.pathname) && <Footer />}
    </>
  )
}
