// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function MethodSelect({ icon, name, price }) {
  return (
    <div className={styles.container}>
      {icon} <span className={styles.name}>{name}</span>
      <span className={styles.price}>≈ {price}</span>
    </div>
  )
}

export default MethodSelect
