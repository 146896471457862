// ** React Imports
import React, { useState } from "react"

// ** Styles Imports
import styles from "./CoinifyRedirectAlertModal.module.css"

// ** Icons Imports
import { ReactComponent as BellIcon } from "../../../../assets/icons/imported/notification-bell.svg"
import { ReactComponent as BellLightIcon } from "../../../../assets/icons/imported/notification-bell-light.svg"

// ** Redux Imports
import { useSelector } from "react-redux"

// ** Helpers Imports
import { closeCoinifyRedirectAlertModal } from "./helpers"

// ** Components Imports
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"
import CheckboxInput from "../../../../components/CheckboxInput/CheckboxInput"

function CoinifyRedirectAlertModal() {
  // ** States
  const theme = useSelector((state) => state.theme.value)
  const modalOpen = useSelector((state) => state.coinifyRedirectAlertState.open)
  const modalData = useSelector((state) => state.coinifyRedirectAlertState.data)
  const [termsAgreed, setTermsAgreed] = useState(false)

  const timer = modalData.timer ?? 0

  // ** Handlers
  const handleConfirm = () => {
    if (modalData.redirectUrl) window.location.replace(modalData.redirectUrl)
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showLineHeader
        showCloseButton={false}
        modalWidth={370}
        onClose={closeCoinifyRedirectAlertModal}
        lineHeaderColor="var(--primary-color)"
        render={() => (
          <div>
            <div className={styles.iconContainer}>
              {theme === "dark" ? (
                <BellIcon width={100} />
              ) : (
                <BellLightIcon width={100} />
              )}
            </div>
            <h1 className={styles.title}>Redirecting To Coinify Platform</h1>

            <ol className={styles.termsContainer}>
              <li>
                1. You will leave WenBit and redirected to the Coinify Payment
                Page.
              </li>
              <li>
                2. Your information will be shared with Coinify to proceed with
                the transaction.
              </li>
              <li>
                3. WenBit is not responsible for any of our activities on the
                Coinify payment page and does not have access to any of your
                private information shared with Coinify.
              </li>
            </ol>

            <CheckboxInput
              label="I agree with the terms above"
              containerStyle={{ margin: "20px 0" }}
              onChange={(e) => setTermsAgreed(e.target.checked)}
            />

            <div className={styles.buttonsContainer}>
              <Button
                text="Cancel"
                variant="link"
                onClick={closeCoinifyRedirectAlertModal}
              />
              <Button
                text="Confirm"
                disabled={!termsAgreed}
                onClick={handleConfirm}
              />
            </div>
          </div>
        )}
      />
    </>
  )
}

export default CoinifyRedirectAlertModal
