import { createSlice } from "@reduxjs/toolkit"

const successPaymentModalSlice = createSlice({
  name: "successPaymentModalState",
  initialState: {
    open: false,
    data: {
      amount_converted: "",
      price: "",
      inverse_price: "",
      callback: () => {}
    }
  },
  reducers: {
    setSuccessConvertModalOpen: (state, action) => {
      state.open = action.payload
    },
    setSuccessConvertModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setSuccessConvertModalOpen, setSuccessConvertModalData } =
  successPaymentModalSlice.actions

export default successPaymentModalSlice.reducer
