import { API, Blogs_API } from "../../../../utility/API"

//************************************//
export const _getJobs = (callback, callbackErr) => {
  Blogs_API.get(`public/show_jobs`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _listJobs = (params, callback, callbackErr) => {
  Blogs_API.get(`public/list_jobs`, { params })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getJob = (id, callback, callbackErr) => {
  Blogs_API.get(`public/jobs/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _addApplication = (params, callback, callbackErr) => {
  Blogs_API.post(`public/add_application`, params)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _uploadFiles = (file, callback, callbackErr) => {
  // Create a new instance of FormData
  const formData = new FormData()

  // Append the file to the formData
  formData.append("file", file) // 'file' here is the field name, you might need to adjust it based on your API's expectations

  // Make the API call with the formData
  Blogs_API.post(`public/blog/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}

//************************************//
export const _getLocations = (callback, callbackErr) => {
  Blogs_API.get(`public/list_locations`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getDepartments = (callback, callbackErr) => {
  Blogs_API.get(`public/list_departments`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
