import React from "react"

// redux
import { useSelector } from "react-redux"

// packages
import Shimmer from "react-shimmer-effect"

function ShimmerLine({
  opacity,
  width = "96px",
  height = "12px",
  borderRadius = "5px",
  style
}) {
  const theme = useSelector((state) => state.theme.value)

  const StyleSheet = {
    line: {
      width,
      height,
      // alignSelf: "center",
      // marginLeft: "16px",
      borderRadius
    }
  }

  return (
    <div
      style={{
        opacity: opacity ? opacity : theme === "dark" ? 0.3 : 0.8,
        ...style
      }}
    >
      <Shimmer>
        <div style={StyleSheet.line} />
      </Shimmer>
    </div>
  )
}

export default ShimmerLine
