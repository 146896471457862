import React, { useEffect, useState } from "react"

// redux
import { useSelector } from "react-redux"

// styles
import Styles from "./currencyInput.module.css"

// icons
import { BiChevronDown } from "react-icons/bi"

// components
import MarketGraphCard from "../MarketGraphCard/MarketGraphCard"

// packages
import { FormattedMessage } from "react-intl"

function CurrencyInput({
  title,
  coinId, // Needed to get he k-line data
  secondaryTitle,
  coinName,
  currencyIcon,
  klineData,
  onChange = () => {},
  currencyImg,
  containerStyle,
  showDropdownArrow = false,
  onDropdownClick,
  showChart = false,
  error,
  register = {},
  ...rest
}) {
  // ** States
  const theme = useSelector((state) => state.theme.value)
  const markets = useSelector((state) => state.marketList.value)

  return (
    <>
      <div
        className={`${theme === "light" && Styles.buySellInputContainerLight} ${
          Styles.buySellInputContainer
        } ${error && Styles.errorContainer}`}
        style={{
          ...containerStyle
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* left section */}
          <div className={Styles.buySellLeftSection}>
            {/* input field title */}
            <div className={Styles.buySellInputTitle}>{title}</div>

            {/* input group container */}
            {/* the default value below returns a worning, since there should not be value and default value. Consider solving it */}
            <div className={Styles.buySellInputGroupContainer}>
              <span>{currencyIcon}</span>
              <input
                defaultValue={0}
                style={{ width: "100%" }}
                {...register}
                onChange={(e) => {
                  if (Object.keys(register).length !== 0) {
                    register && register.onChange(e)
                  }
                  onChange && onChange(e)
                }}
                {...rest}
              />
            </div>
          </div>

          {/* right section */}
          <div className={Styles.buySellRightSection}>
            {secondaryTitle && (
              <div className={Styles.secondaryTitle}>{secondaryTitle}</div>
            )}
            <div
              onClick={onDropdownClick}
              className={!onDropdownClick && Styles.notHoverable}
            >
              {currencyImg && <img src={currencyImg} />}
              {coinName && (
                <span
                  style={{ color: theme === "light" && "var(--gray-color)" }}
                >
                  {coinName}
                </span>
              )}
              {showDropdownArrow && <BiChevronDown color="#9295A6" />}
            </div>
          </div>
        </div>

        {showChart && (
          <MarketGraphCard
            icon={currencyImg}
            coinName={coinName}
            containerStyle={{
              width: "100%",
              marginBottom: "-10px",
              backgroundColor: "transparent"
            }}
            price={markets.find((el) => el.id === `${coinId}usd`)?.last}
            priceChangePercentage={
              markets.find((el) => el.id === `${coinId}usd`)
                ?.price_change_percent
            }
            marketKLine={klineData}
          />
        )}
      </div>
      {error?.message && (
        <span className={Styles.errorText}>{error.message}</span>
      )}
    </>
  )
}

export default CurrencyInput
