import { createSlice } from "@reduxjs/toolkit"

const dangerSlice = createSlice({
  name: "dangerModalState",
  initialState: {
    open: false,
    data: {
      title: "",
      subtitle: "",
      showActionButton: false,
      actionButtonText: "",
      href: null,
      callback: () => {}
    }
  },
  reducers: {
    setDangerModalOpen: (state, action) => {
      state.open = action.payload
    },
    setDangerModalData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setDangerModalOpen, setDangerModalData } = dangerSlice.actions

export default dangerSlice.reducer
