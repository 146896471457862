import React from "react"

import TradingChart from "../../modules/exchange/old-components/TradingChart"
import { useLocation } from "react-router-dom"
import { setTheme } from "../../redux/slices/themeSlice"
import { useDispatch } from "react-redux"

function MobileChart() {
  // ** Hooks
  const location = useLocation()
  const dispatch = useDispatch()

  // ** URL Params
  const queryParams = new URLSearchParams(location.search)

  // Extract the parameters
  const type = queryParams.get("type") || "candle"
  const theme = queryParams.get("theme") || "dark"
  const pairId = queryParams.get("pair_id") || "btcusdt"
  const timeframe = queryParams.get("timeframe") || "4h"

  return (
    <TradingChart
      containerStyle={{
        height: "100vh",
        width: "100vw",
        borderRadius: 0,
        padding: 0
      }}
      urlTheme={theme}
      timeframe={timeframe}
      chartType={type}
      selectedMarketId={pairId}
    />
  )
}

export default MobileChart
