import React from "react"

// styles
import Styles from "./SelectBeneficiaryInput.module.css"

// icons
import { ReactComponent as DropdownIcon } from "../../../assets/icons/imported/down-arrow.svg"
import {
  hideSelectBeneficiaryModal,
  showSelectBeneficiaryModal
} from "../../../modules/modals/beneficiaries/select/helpers"

function SelectBeneficiaryInput({
  coinId = "",
  beneficiaryName,
  onChange = () => {},
  error
}) {
  const handleSelectBeneficiary = () => {
    showSelectBeneficiaryModal(coinId, (beneficiary) => {
      onChange(beneficiary)
      hideSelectBeneficiaryModal()
    })
  }

  return (
    <div className={Styles.selectNetwordContainer}>
      <span>Select beneficiary</span>
      <div
        onClick={handleSelectBeneficiary}
        className={`${error?.message && Styles.errorInput}`}
      >
        {beneficiaryName ? (
          <span className={Styles.selectedNetwork}>{beneficiaryName}</span>
        ) : (
          <span style={{ color: "var(--gray-color)" }}>Select beneficiary</span>
        )}

        <DropdownIcon color="var(--gray-color)" />
      </div>
      {error?.message && (
        <span className={Styles.errorSpan}>
          {error?.message ? error.message : " "}
        </span>
      )}
    </div>
  )
}

export default SelectBeneficiaryInput
