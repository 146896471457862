// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Component Imports
import MegaMenuItem from "../../MegaMenuItem"

// ** Icons Imports
import { ShieldCheck } from "lucide-react"
import { RiCustomerServiceLine, RiQuestionLine } from "react-icons/ri"
import { ReactComponent as WenLogo } from "../../../assets/icons/imported/wen-logo.svg"
import { ReactComponent as BriefcaseIcon } from "../../../assets/icons/imported/portfolio.svg"

function MoreMegaMenu() {
  return (
    <div>
      <MegaMenuItem
        name="About Us"
        description="Learn more about WenBit"
        Icon={WenLogo}
        to="/about-us"
      />
      <MegaMenuItem
        name="Careers"
        description="Check our open positions"
        Icon={BriefcaseIcon}
        to="/careers"
      />
      <MegaMenuItem
        name="Security"
        description="Check out our security procedures"
        Icon={ShieldCheck}
        to="/company-security"
      />
      <MegaMenuItem
        name="Support"
        description="How can we help you?"
        Icon={RiCustomerServiceLine}
        to="/contact-support"
      />
      <MegaMenuItem
        name="FAQ"
        description="Frequently asked questions"
        Icon={RiQuestionLine}
        to="/faq"
      />
    </div>
  )
}

export default MoreMegaMenu
