import {API} from '../../../../../utility/API'

//************************************//
export const _getMarketsList = (callback, callbackErr) => {
    API.get(`trading/public/markets`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error)
        })
}
//************************************//
export const _getCurrencies = (callback, data) => {
    API.get(`/trading/public/currencies`, {
        params: data
    })
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}