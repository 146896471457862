import React from "react"

// styles
import Styles from "./card.module.css"
import { useSelector } from "react-redux"

function Card({ icon, title, description }) {
  const theme = useSelector((state) => state.theme.value)
  return (
    <div className={Styles.card}>
      {icon && <div className={Styles.iconContainer}>{icon}</div>}
      {title && <h6 className={Styles.title}>{title}</h6>}
      {description && <p className={Styles.description}>{description}</p>}
    </div>
  )
}

export default Card
