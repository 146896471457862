import React, { useEffect, useState } from 'react'

// MUI imports
import { Modal, Slide, Box, Dialog } from '@mui/material'

// styles
import Styles from './modal.module.css'

// icons
import { IoClose } from 'react-icons/io5'

// redux
import { useSelector } from 'react-redux'

function WenbitModal({
  open,
  onClose = () => {},
  render = () => {},
  modalWidth = 400,
  maxWidth,
  showCloseButton = true,
  showLineHeader = false,
  lineHeaderColor = 'var(--primary-color)',
  title,
  secondaryTitle,
  disableBackdropClick = false,
  showSlideAnimation = true,
  showOverflow = true,
  modalBoxSx, // used to override the Box styling
  children
}) {
  const theme = useSelector(state => state.theme.value)

  const SlideComponent = showSlideAnimation ? Slide : React.Fragment

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 420)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 420)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // const boxTop = showSlideAnimation ? 'calc(50% - 10vw)' : '50%'
  // const boxLeft = showSlideAnimation ? 'calc(50% - 35vw)' : '50%'

  return (
    <Modal
      open={open}
      onClose={onClose}
      slotProps={
        disableBackdropClick && {
          backdrop: {
            onClick: e => e.stopPropagation()
          }
        }
      }
      style={
        showSlideAnimation && {
          // height: '100vh',
          // margin: 'auto !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
      disableScrollLock={false}
    >
      <SlideComponent direction='up' in={open}>
        <Box
          sx={{
            position: 'relative',
            top: showSlideAnimation ? 'unset' : '50%',
            left: showSlideAnimation ? 'unset' : '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobileView ? '90%' : modalWidth,
            maxWidth,
            bgcolor: theme === 'light' ? 'white' : 'var(--secondary-color)',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            '&:focus': {
              border: 'none',
              outline: 'none'
            },
            margin: showSlideAnimation && '0 auto',
            maxHeight: '90vh',
            overflowY: showOverflow && 'auto',
            ...modalBoxSx
          }}
        >
          {
            <div className={Styles.modalHeader}>
              {showLineHeader && (
                <div className={Styles.modalLineHeader} style={{ backgroundColor: lineHeaderColor }}></div>
              )}
              <div>
                <h5>{title ? title : ' '}</h5>
                {secondaryTitle && <h6 className={Styles.secondaryTitle}>{secondaryTitle}</h6>}
              </div>
              {showCloseButton && <IoClose className={Styles.closeButton} onClick={onClose} />}
            </div>
          }

          <div style={{ marginTop: title && 20 }}>{children ?? render()}</div>
        </Box>
      </SlideComponent>
    </Modal>
  )
}

export default WenbitModal
