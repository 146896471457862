import React from "react"

// styles
import Styles from "./coinTag.module.css"

// redux
import { useSelector } from "react-redux"

function CoinTag({ tag, size = 11.5, style, className }) {
  const theme = useSelector((state) => state.theme.value)

  return (
    <div
      className={`${Styles.tag} coinTagContainer ${className}`}
      style={{
        backgroundColor: theme === "light" && "white",
        fontSize: size,
        ...style
      }}
    >
      {tag}
    </div>
  )
}

export default CoinTag
