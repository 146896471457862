import { nationalitiesNames } from "./nationalities"
export const ur = {
  //Footer
  "page.footer.exchange": "تبادلہ",
  "page.footer.convert": "تبدیل کریں",
  "page.footer.launchpad": "لانچ پیڈ",
  "page.footer.wallet": "پرس",
  "page.footer.support": "حمایت",
  "page.footer.requestForm": "عرضی",
  "page.footer.contactSupport": "سپورٹ سے رابطہ کریں۔",
  "page.footer.resources": "حوالہ جات",
  "resource.phone.new_number.please_modify_number_first":
    "آپ نے ایک نیا نمبر درج کیا ہے، پہلے اس میں ترمیم کریں۔",
  "page.footer.downloads": "ڈاؤن لوڈ",
  "page.footer.mobileApplication": "موبائل ایپلیکیشن",
  "page.footer.buyCrypto": "کریپٹو لیں",
  "page.footer.referral": "حوالہ",
  "page.footer.listingTranding": "لسٹنگ ٹریڈنگ",
  "page.footer.learn": "سیکھیں۔",
  "page.footer.whatsTrending": "کیا رجحان ہے۔",
  "page.footer.productNews": "مصنوعات کی خبریں۔",
  "page.footer.events": "تقریبات",
  "page.footer.university": "جامع درس گاہ",
  "page.footer.research": "تحقیق",
  "page.footer.marketUpdate": "مارکیٹ اپ ڈیٹ",
  "page.footer.company": "کمپنی",
  "page.footer.aboutUs": "ہمارے بارے میں",
  "page.footer.careers": "کیریئرز",
  "page.footer.news": "خبریں",
  "page.footer.security": "سیکورٹی",
  "page.footer.community": "برادری",
  "page.footer.announcements": "اعلانات",
  "page.footer.allRightsReserved": "جملہ حقوق محفوظ ہیں",
  "page.footer.privacy": "رازداری",
  "page.footer.terms": "شرائط",
  "page.footer.siteMap": "سائٹ کا نقشہ",
  "page.footer.iosDownload": "پر ڈاؤن لوڈ کریں۔",
  "page.footer.androidDownload": "اسے حاصل کریں۔",

  // Added :
  "page.header.navbar.blogs": "بلاگز",
  "page.header.navbar.launchpad": "لانچ پیڈ",
  "page.header.navbar.exchange": "تبادلہ",
  "page.header.navbar.markets": "منڈیاں",
  "page.header.navbar.convert": "تبدیل کریں",
  // 14 FEB
  "page.header.signInToWenbit": "Wenbit میں سائن ان کریں",

  "page.markets.trade.marketCoins": "مارکیٹ کے سکے",

  "page.markets.trade.buy": "خریدنے",
  "page.markets.trade.sell": "بیچنا",
  "page.markets.trade.limit": "حد",
  "page.markets.trade.market": "مارکیٹ",
  "page.markets.trade.quantity": "مقدار",
  "page.markets.trade.buyFor": "کے لئے خرید",
  "page.markets.trade.sellFor": "کے لیے فروخت کریں۔",
  "page.markets.trade.total": "کل",
  "page.markets.trade.fee": "فیس",
  "page.markets.trade.quickBuy": "فوری خریدیں۔",
  "page.markets.trade.quickSell": "فوری فروخت",

  "page.markets.rows.name": "سکے کا نام",
  "page.markets.rows.price": "سکے کی قیمت",
  "page.markets.rows.24": "24%",
  "page.markets.rows.24High": "24h سب سے زیادہ قیمت",
  "page.markets.rows.24Low": "24 گھنٹے سب سے کم قیمت",
  "page.markets.rows.chart": "چارٹ",

  "page.header.navbar.signIn": "سائن ان",
  "page.header.navbar.signUp": "اب رجسٹر ہوں",
  "page.header.navbar.trade": " تجارت",
  "page.header.navbar.mining": " کان کنی",
  "page.header.navbar.wallets": "بٹوے",
  "page.header.navbar.openOrders": "طلبات",
  "page.header.navbar.history": "تاریخ",
  "page.header.navbar.profile": " پروفائل",
  "page.header.navbar.logout": "لاگ آؤٹ",
  "page.body.copy.success": "متن کاپی",
  "page.body.trade.header.markets": " مارکیٹس",
  "page.body.trade.header.markets.content.market": "مارکیٹ",
  "page.body.trade.header.markets.content.pair": "جوڑا",
  "page.body.trade.header.markets.content.price": "سعر",
  "page.body.trade.header.markets.content.last_price": "آخری قیمت",
  "page.body.trade.header.markets.content.change": "تبدیلی",
  "page.body.trade.header.markets.content.search": "تلاش کریں",
  "page.body.trade.header.markets.content.volume": "حجم",
  "page.body.trade.header.newOrder": "آرڈر فارم",
  "page.body.trade.header.newOrder.content.tabs.buy": "خریدیں",
  "page.body.trade.header.newOrder.content.tabs.sell": "بیچنا",
  "page.body.trade.header.newOrder.content.orderType": "آرڈر",
  "page.body.trade.header.newOrder.content.orderType.limit": " حد",
  "page.body.trade.header.newOrder.content.orderType.market": "مارکیٹ",
  "page.body.trade.header.newOrder.content.price": "قیمت",
  "page.body.trade.header.newOrder.content.amount": "رقم",
  "page.body.trade.header.newOrder.content.total": "کل",
  "page.body.trade.header.newOrder.content.available": "دستیاب ہے",
  "page.body.trade.header.newOrder.content.estimatedFee": "فیس",
  "page.body.trade.header.marketDepths": "مارکیٹ کی گہرائی",
  "page.body.trade.header.marketDepths.content.price": " قیمت",
  "page.body.trade.header.marketDepths.content.volume": "رقم",
  "page.body.trade.header.marketDepths.content.cumulativeVolume": " کل",
  "page.body.trade.header.marketDepths.content.cumulativeValue": "تخمینہ قیمت",
  "page.body.trade.header.asks": "پوچھتا ہے",
  "page.body.trade.header.bids": " بولی",
  "page.body.trade.orderbook": "آرڈر بک",
  "page.body.trade.orderbook.header.price": " قیمت",
  "page.body.trade.orderbook.header.amount": "رقم",
  "page.body.trade.orderbook.header.volume": "کل",
  "page.body.trade.header.recentTrades": "تجارتی تاریخ",
  "page.body.trade.header.recentTrades.content.time": " وقت",
  "page.body.trade.header.recentTrades.content.price": " قیمت",
  "page.body.trade.header.recentTrades.content.amount": " رقم",
  "page.body.trade.header.openOrders": "اوپن آرڈرز",
  "page.body.trade.header.openOrders.content.date": "تاریخ",
  "page.body.trade.header.openOrders.content.state": "ریاست",
  "page.body.trade.header.openOrders.content.price": " قیمت",
  "page.body.trade.header.openOrders.content.amount": " رقم",
  "page.body.trade.header.openOrders.content.total": "کل",
  "page.body.trade.header.openOrders.content.filled": "بھرا ہوا",
  "page.body.wallets.estimated_value": "تخمینہ قیمت",
  "page.body.wallets.locked": "مقفل ہے",
  "page.body.wallets.balance": " توازن",
  "page.body.wallets.tabs.deposit": "جمع کروانا",
  "page.body.wallets.tabs.deposit.ccy.message.submit":
    " درج ذیل پتے یا کیو آر کوڈ کا استعمال کرکے جمع کروائیں۔ آپ کے جمع کرانے کی تصدیق کے بعد آپ کے اکاؤنٹ میں جھلکتی ہے۔",

  "page.body.wallets.tabs.deposit.ccy.message.address": " ڈپازٹ ایڈریس",
  "page.body.wallets.tabs.deposit.ccy.message.button": "کاپی",
  "page.body.wallets.tabs.deposit.ccy.message.success": "پتہ کاپی",
  "page.body.wallets.tabs.deposit.ccy.message.error":
    "ڈپازٹ ایڈریس بنانا ، براہ کرم کچھ دیر لگے تو دوبارہ کھولیں",

  "page.body.wallets.tabs.deposit.fiat.message1":
    "بینک ٹرانسفر کا استعمال کرتے ہوئے جمع کروائیں",
  "page.body.wallets.tabs.deposit.fiat.message2":
    "براہ کرم اپنے بینک ٹرانسفر کو شروع کرنے کے لئے درج ذیل اسناد استعمال کریں۔ آپ کی جمع رقم 2 کاروباری دنوں میں آپ کے اکاؤنٹ میں ظاہر ہوگی۔",

  "page.body.wallets.tabs.deposit.fiat.bankName": " بینک کا نام",
  "page.body.wallets.tabs.deposit.fiat.accountNumber": "اکاؤنٹ نمبر",
  "page.body.wallets.tabs.deposit.fiat.accountName": "ااکاؤنٹ کا نام",
  "page.body.wallets.tabs.deposit.fiat.branchNumber": "برانچ نمبر",
  "page.body.wallets.tabs.deposit.fiat.branchAddress": " شاخ کا پتہ",
  "page.body.wallets.tabs.deposit.fiat.swiftCode": " سوفٹ کوڈ",
  "page.body.wallets.tabs.deposit.fiat.phoneNumber": " فون نمبر",
  "page.body.wallets.tabs.deposit.fiat.referenceCode": "آپ کا حوالہ کوڈ",
  "page.body.wallets.tabs.deposit.fiat.etrans_message1":
    "ای میل کی منتقلی کا استعمال کرتے ہوئے جمع کریں",
  "page.body.wallets.tabs.deposit.fiat.etrans_message2":
    " براہ کرم اپنے ای میل کی منتقلی کا آغاز کرنے کے لئے درج ذیل ای میل کا استعمال کریں۔ آپ کے ڈپازٹ کو 2 کاروباری دنوں میں آپ کے اکاؤنٹ میں دکھایا جائے گا۔",
  "page.body.wallets.tabs.deposit.fiat.etrans_email": " اای میل",
  "page.body.wallets.table.pending": "زیر التواء",
  "page.body.wallets.tabs.deposit.fiat.admin":
    "  ایک فیٹ شروع کرنے کے لئے ، دستبرداری کریں ، براہ کرم کسٹمر سروس سے رابطہ کریں",
  "page.body.wallets.tabs.deposit.fiat.email": "ای میل",
  "page.body.wallets.tabs.withdraw": "واپس",
  "page.body.wallets.tabs.withdraw.content.address": "واپس لے لو",
  "page.body.wallets.tabs.withdraw.content.amount": "واپسی کا پتہ ",
  "Page.body.wallets.tabs.withdraw.content.balance":
    "دستیاب بیلنس واپسی کی رقم",
  "page.body.wallets.tabs.withdraw.content.code2fa": "2 ایف اے کوڈ",
  "page.body.wallets.tabs.withdraw.content.fee": "فیس",
  "page.body.wallets.tabs.withdraw.content.total": " واپسی کی کل رقم",
  "page.body.wallets.tabs.withdraw.content.button": "واپس لے لو",
  "page.body.wallets.tabs.withdraw.content.addressPlaceholder": " واپسی کا پتہ",
  "page.body.wallets.tabs.withdraw.modal.confirmation": "تصدیق",
  "page.body.wallets.tabs.withdraw.modal.message1": "   آپ وصول کریں گے",
  "page.body.wallets.tabs.withdraw.modal.button.cancel": " منسوخ کریں",
  "page.body.wallets.tabs.withdraw.modal.button.withdraw": "  واپس لے لو",
  "page.body.wallets.tabs.withdraw.content.enable2fa":
    "واپس لینے کے ل آپ کو2ایف اے کو قابل بنانا ہوگا",
  "page.body.wallets.tabs.withdraw.content.enable2faButton":
    "2 ایف اے کو فعال کریں",
  "page.body.openOrders.tab.all": "تاریخ کی تاریخ",
  "page.body.openOrders.tab.open": "اوپن آرڈرز",
  "page.body.openOrders.header.orderType": " آرڈر",
  "page.body.openOrders.header.orderType.buy.market": "مارکیٹ خریدیں ",
  "page.body.openOrders.header.orderType.buy.limit": "حد خریدیں",
  "page.body.openOrders.header.orderType.sell.market": "مارکیٹ فروخت کریں",
  "page.body.openOrders.header.orderType.sell.limit": " فروخت بیچیں",
  "page.body.openOrders.header.pair": " جوڑا",
  "page.body.openOrders.header.amount": "رقم",
  "page.body.openOrders.header.price": " قیمت",
  "page.body.openOrders.header.executed": "پھانسی دی گئی",
  "page.body.openOrders.header.remaining": " باقی",
  "page.body.openOrders.header.costRemaining": " باقی لاگت",
  "page.body.openOrders.header.status": " حیثیت",
  "page.body.openOrders.content.status.done": "پھانسی دی گئی",
  "page.body.openOrders.content.status.approve": "Pending",
  "page.body.openOrders.content.status.reject": "Rejected",
  "page.body.openOrders.content.status.wait": " کھلا",
  "page.body.openOrders.content.status.cancel": "رک گیا",
  "page.body.openOrders.header.button.cancelAll": "سب کو روکیں ",
  "page.body.history.deposit": " جمع شدہ تاریخ",
  "page.body.history.deposit.header.txid": "  TX-ID",
  "page.body.history.deposit.header.date": "تاریخ",
  "page.body.history.deposit.header.currency": "کرنسی,",
  "page.body.history.deposit.header.amount": "رقم",
  "page.body.history.deposit.header.status": "حیثیت",
  "page.body.history.deposit.content.status.accepted": "قبول کیا گیا",
  "page.body.history.deposit.content.status.collected": "جمع",
  "page.body.history.deposit.content.status.submitted": "پیش کیا",
  "page.body.history.deposit.content.status.canceled": "منسوخ",
  "page.body.history.deposit.content.status.rejected": "مسترد",
  "page.body.history.deposit.content.status.skipped": "اچٹ گیا",
  "page.body.history.withdraw": "واپس",
  "page.body.history.withdraw.header.id": " ID",
  "page.body.history.withdraw.header.date": "تاریخ",
  "page.body.history.withdraw.header.currency": "کرنسی",
  "page.body.history.withdraw.header.address": "پتہ",
  "page.body.history.withdraw.header.amount": "رقم",
  "page.body.history.withdraw.header.fee": "فیس",
  "page.body.history.withdraw.header.status": " حیثیت",
  "page.body.history.withdraw.content.status.prepared": " تیار ہے",
  "page.body.history.withdraw.content.status.submitted": " پیش کیا گیا",
  "page.body.history.withdraw.content.status.canceled": "منسوخ",
  "page.body.history.withdraw.content.status.accepted": "  قبول کیا گیا",
  "page.body.history.withdraw.content.status.suspected": " مشتبہ",
  "page.body.history.withdraw.content.status.rejected": "مسترد کردیا گیا",
  "page.body.history.withdraw.content.status.processing": "پروسیسنگ",
  "page.body.history.withdraw.content.status.succeed": "کامیاب",
  "page.body.history.withdraw.content.status.failed": "تصدیق کرنا",
  "page.body.history.withdraw.content.status.confirming": "خرابی",
  "page.body.history.withdraw.content.status.errored": "تجارتی تاریخ",
  "page.body.history.trade": "تجارتی تاریخ",
  "page.body.history.trade.header.id": " ID",
  "page.body.history.trade.header.date": "تاریخ",
  "page.body.history.trade.header.side": "سائیڈ",
  "page.body.history.trade.content.side.buy": "خریدیں",
  "page.body.history.trade.content.side.sell": "بیچنا",
  "page.body.history.trade.header.market": " مارکیٹ",
  "page.body.history.trade.header.price": " قیمت",
  "page.body.history.trade.header.funds": "فنڈز",
  "page.body.history.trade.header.amount": "رقم",
  "page.body.history.trade.header.balance": " توازن",
  "page.body.profile.kyc.content":
    "ضابطے کی تعمیل کرنے کے لئے ، شریک کو شناختی توثیق سے گزرنا ہوگا",
  "page.body.profile.kyc.content.notice":
    " KYC تصدیق واپس لینے کے لئے ضروری ہے",
  "page.body.profile.kyc.button.success": " KYC تصدیق شدہ",
  "page.body.profile.kyc.button.pending": "KYC زیر التواء",
  "page.body.profile.kyc.button.fail": " KYC اسٹارٹ",
  "page.body.profile.header.account": "کھاتہ",
  "page.body.profile.header.account.content.password": "اس ورڈ",
  "page.body.profile.header.account.content.password.button.change": "تبدیلی",
  "page.body.profile.header.account.content.password.old": "پرانا پاس ورڈ",
  "page.body.profile.header.account.content.password.new": "نیا پاس ورڈ",
  "page.body.profile.header.account.content.password.button.save": "محفوظ کریں",
  "page.body.profile.header.account.content.password.button.cancel":
    "منسوخ کریں",
  "page.body.profile.header.account.content.password.conf": "پاس ورڈ کی توثیق",
  "page.body.profile.header.account.content.password.dont.match":
    "پاس ورڈ مماثل نہیں ہیں",
  "page.body.profile.header.account.content.password.change.success":
    " کامیابی",
  "page.body.profile.header.account.content.password.change":
    " پاس ورڈ تبدیل کریں",
  "page.body.profile.header.account.content.twoFactorAuthentication":
    " 2 ایف اے",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.enable":
    "فعال",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.disable":
    "غیر فعال",
  "page.body.profile.header.account.content.twoFactorAuthentication.header":
    " گوگل کی توثیق",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.1":
    "سے گوگل مستند ایپلی کیشن ڈاؤن لوڈ اور انسٹال کریں",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.or":
    " یا",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.2":
    "کیو آر کوڈ اسکین کریں یا خفیہ کوڈ استعمال کریں" +
    "یہ راز کسی محفوظ جگہ پر محفوظ کریں۔ یہ کوڈ مختلف آلہ سے 2 ایف اے تک رسائی حاصل کرنے کے لئے استعمال کیا جاسکتا ہے\n",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.3":
    " اایپ سے 2fa کوڈ درج کریں",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.mfa":
    "2fa کوڈ",
  "page.body.profile.header.account.content.twoFactorAuthentication.message.4":
    "قابل بنائیں",
  "page.body.profile.header.account.content.twoFactorAuthentication.subHeader":
    "قابل بنائیں",
  "page.body.profile.header.account.content.twoFactorAuthentication.enable":
    "فعال",
  "page.body.profile.header.account.content.twoFactorAuthentication.disable":
    " 2fa کوڈ",
  "page.body.profile.header.account.content.twoFactorAuthentication.modalBody":
    "2Fa کو غیر فعال کریں" +
    "براہ کرم غیر فعال کرنے کے لئے ایڈمنسٹریٹر سے رابطہ کریں",
  "page.body.profile.header.account.content.twoFactorAuthentication.modalHeader":
    "دو فیکٹر تصدیق قابل ہے",
  "page.body.profile.header.account.profile": "اکاؤنٹ پروفائل",
  "page.body.profile.header.account.profile.email.title": "ای میل کی تصدیق",
  "page.body.profile.header.account.profile.email.message": "پیغام",
  "page.body.profile.header.account.profile.phone.unverified.title": "عنوان",
  "page.body.profile.header.account.profile.phone.title": "تم التحقق من الهاتف",
  "page.body.profile.header.account.profile.phone.message": "ايداعتداول مفعّلة",
  "page.body.profile.header.account.profile.identity.unverified.title":
    "التحقق هويّة",
  "page.body.profile.header.account.profile.identity.title":
    "شناخت کی تصدیق ہوگئی ہے",
  "page.body.profile.header.account.profile.identity.message": "واپسی سرگرم ہے",
  "page.body.profile.header.referralProgram": "رجوعیت کے لیے رابطہ",
  "page.body.profile.content.copyLink": "کاپیاں",
  "page.body.profile.apiKeys.header": "کاپی API",
  "page.body.profile.apiKeys.header.create": "نئی تعمیر",
  "page.body.profile.apiKeys.noOtp": "2FA براہ کرم 2 ایف اے کو چالو کریں",
  "page.body.profile.apiKeys.show": "دکھائیں",
  "page.body.profile.apiKeys.noKeys": "آپ کے پاس API کیز نہیں ہیں",
  "page.body.profile.apiKeys.modal.btn.show": "ادکھائیں",
  "page.body.profile.apiKeys.modal.btn.create": "  زور",
  "page.body.profile.apiKeys.modal.btn.copy": "کاپیاں",
  "page.body.profile.apiKeys.modal.btn.activate": "چالو کرنا",
  "page.body.profile.apiKeys.modal.btn.disabled": " اغیر فعال کریں",
  "page.body.profile.apiKeys.modal.btn.delete": "حذف کریں",
  "page.body.profile.apiKeys.modal.header": "2 ایف اے کی تصدیق کریں",
  "page.body.profile.apiKeys.modal.created_header": " بنائی گئی",
  "page.body.profile.apiKeys.modal.access_key": "رسائی کلید",
  "page.body.profile.apiKeys.modal.secret_key": "خفیہ چابی",
  "page.body.profile.apiKeys.modal.secret_key_info":
    "اس معلومات کو صرف ایک بار دکھایا جائے گا اور گمشدہ آلہ کی صورت میں بازیافت نہیں کی جاسکتی ہے",
  "page.body.profile.apiKeys.modal.secret_key_store":
    "براہ کرم اسے صحیح طریقے سے محفوظ کریں",
  "page.body.profile.apiKeys.modal.note": " ملحوظة",
  "page.body.profile.apiKeys.modal.note_content":
    " اپنی بچت (اثاثوں) کو کھونے سے بچنے کے ل please ، براہ کرم دوسروں کو اپنی خفیہ کلید اور نجی کلید نہ بتائیں۔ اگر آپ خفیہ کلید کو بھول گئے ہیں تو ، براہ کرم اسے حذف کریں اور نئی خفیہ کلید کے لئے درخواست دیں۔",
  "page.body.profile.apiKeys.modal.title": " ایپ سے 2 ایف ای کوڈ درج کریں",
  "page.body.profile.apiKeys.modal.label": "گوگل توثیقی کوڈ اندراجات ",
  "page.body.profile.apiKeys.modal.placeholder": "کوڈ درج کریں   ",
  "page.body.profile.apiKeys.table.header.kid": "بچہ",
  "page.body.profile.apiKeys.table.header.algorithm": "الگورتھم",
  "page.body.profile.apiKeys.table.header.state": "حالت",
  "page.body.profile.apiKeys.table.header.created": " بنائی گئی",
  "page.body.profile.apiKeys.table.header.updated": "تازہ کاری",
  "success.api_keys.fetched": "API چابیاں کامیابی کے ساتھ حاصل کی گئیں",
  "success.api_keys.created": "API کلید کامیابی کے ساتھ تشکیل دی گئی تھی",
  "success.api_keys.copied.access": "رسائی کلید کاپی کردی گئی ہے",
  "success.api_keys.copied.secret": "خفیہ چابی کاپی کی گئی ہے",
  "success.api_keys.updated": "API کلید کو اپ ڈیٹ کر دیا گیا ہے",
  "success.api_keys.deleted": "API کی کو ہٹا دیا گیا ہے",
  "page.body.profile.header.accountActivity": "اکاؤنٹ کی سرگرمیاں",
  "page.body.profile.header.accountActivity.content.date": "تاریخ",
  "page.body.profile.header.accountActivity.content.addressip": " عنوان",
  "page.body.profile.header.accountActivity.content.action": "طریقہ کار",
  "page.body.profile.header.accountActivity.content.result": "نتیجہ",
  "page.body.profile.header.accountActivity.content.userAgent": "صارف ایجنٹ",
  "page.body.profile.content.action.login": "تسجيل الدخول",
  "page.body.profile.content.action.logout": "باہر جائیں",
  "page.body.profile.content.action.request2fa":
    "2 ایف اے کیلئے QR کوڈ کی درخواست کریں",
  "page.body.profile.content.action.enable2fa": " 2 ایف اے چالو ہے ",
  "page.body.profile.content.action.login.2fa": "2 ایف اے کے ساتھ لاگ ان کریں",
  "page.body.profile.content.action.requestPasswordReset": "پاس ورڈ کی درخواست",
  "page.body.profile.content.action.passwordReset": " پاس ورڈ دوبارہ ترتیب دیں",
  "page.body.profile.content.result.succeed": "کامیابی کے ساتھ کیا",
  "page.body.profile.content.result.failed": "ناکامی",
  "page.body.kyc.phone.head": "فون چیک کریں ",
  "page.body.kyc.phone.enterPhone": "ایک فون نمبر درج کریں",
  "page.body.kyc.phone.phoneNumber": "ٹیلی فون نمبر",
  "page.body.kyc.phone.enterCode": "تصدیقی کوڈ درج کریں",
  "page.body.kyc.phone.code": "ایس ایم ایس کی علامت",
  "page.body.kyc.phone.send": " پیغام کی علامت",
  "page.body.kyc.phone.resend": "کوڈ پھر سے بھیجو",
  "page.body.kyc.identity.head": "شناخت کی تصدیق",
  "page.body.kyc.document.head": "دستاویزات کی تصدیق کریں",
  "page.body.kyc.identity.firstName": "پہلا نام",
  "page.body.kyc.identity.lastName": "آخری نام",
  "page.body.kyc.identity.dateOfBirth": "پیدائش کی تاریخ",
  "page.body.kyc.identity.residentialAddress": "ہوم ایڈریس",
  "page.body.kyc.identity.city": "شہر",
  "page.body.kyc.identity.postcode": "پوسٹ کوڈ",
  "page.body.kyc.identity.nationality": "شہریت",
  "page.body.kyc.identity.CoR": "قیام کا ملک",
  "page.body.kyc.documents.expiryDate": "خاتمے کی تاریخ",
  "page.body.kyc.documents.drag": "فائلوں کو ڈریگ اور ڈراپ کریں یا براؤز کریں",
  "page.body.kyc.documents.maxFile": "فائل کا زیادہ سے زیادہ سائز 10MB ہے ",
  "page.body.kyc.documents.maxNum":
    "فائلوں کی زیادہ سے زیادہ تعداد 5 فائلیں ہیں",
  "page.body.kyc.documents.files": "اجازت فائل کی اقسام: jpg، jpeg، png",
  "page.body.kyc.documents.upload": " اپنی تصویر کی ID اپ لوڈ کریں",
  "page.body.kyc.documents.select.passport": "پاسپورٹ",
  "page.body.kyc.documents.select.identityCard": "شناختی کارڈ",
  "page.body.kyc.documents.select.driverLicense": "ڈرائیور لائسنس",
  "page.body.kyc.documents.select.utilityBill": "یوٹیلیٹی بل",
  "page.body.kyc.documents.number": "نمبر",
  "page.body.kyc.documentsType": "دستاویز کی قسم",
  "page.body.kyc.next": " اگلا",
  "page.body.kyc.submit": "جمع کروائیں",
  "page.body.kyc.head.phone": "فون کی تصدیق",
  "page.body.kyc.head.identity": "شناخت کی تصدیق",
  "page.body.kyc.head.document": "دستاویز کی تصدیق",
  "page.body.kyc.error": "براہ کرم اپنے KYC کی تصدیق کریں۔",
  "page.footer.legalDocuments": "قانونی دستاویزات",
  "page.footer.faq": "عمومی سوالات",
  "page.header.signIn": " لاگ ان",
  "page.header.signIn.email": "ای میل ",
  "page.header.signIn.password": "پاس ورڈ",
  "page.header.signIn.password.message.error": "پاس ورڈ غلط ہے",
  "page.header.signIn.receiveConfirmation":
    "آپ کی توثیقی ای میل موصول نہیں ہوئی",
  "page.header.signIn.forgotPassword": "اپنا پاس ورڈ بھول گئے",
  "page.header.signIn.resetPassword.title": "پاس ورڈ دوبارہ ترتیب یں",
  "page.header.signIn.resetPassword.oldPassword": "پرانا پاسورڈ",
  "page.header.signIn.resetPassword.newPassword": "نیا پاس ورڈ",
  "page.header.signIn.resetPassword.repeatPassword": "پاس ورڈ دہرائیں",
  "page.header.signIn.resetPassword.button": "تبدیلی",
  "page.header.signIn.resetPassword.error": "فیلڈز خالی ہیں یا مماثل نہیں ہیں",
  "page.header.signUp": "آئیے رجسٹر کے ساتھ ریاست کریں",
  "page.header.signUp.email": " ای میل",
  "page.header.signUp.email.message.error": "ای میل غلط ہے",
  "page.header.signUp.usernameLength.message.error":
    "صارف نام کم از کم 4 اور زیادہ سے زیادہ 20 حروف کا ہونا ضروری ہے",
  "page.header.signUp.password": "پاس ورڈ",
  "page.header.signUp.password.message.error":
    "پاس ورڈ میں کم از کم 8 حروف ، کم از کم ایک بڑے حرف اور ایک ہندسہ ہونا چاہئے",
  "page.header.signUp.confirmPassword": "پاس ورڈ کی توثیق کریں",
  "page.header.signUp.confirmPassword.message.error": " پاس ورڈ مماثل نہیں ہیں",
  "page.header.signUp.referalCode": " ریفرل کوڈ",
  "page.header.signUp.terms": "میں نے پڑھا ہے اور اس سے اتفاق کرتا ہوں",
  "page.header.signUp.modal.header": "اپنے ای میل ایڈریس کی تصدیق کریں",
  "page.header.signUp.modal.body":
    "اندراج مکمل کرنے کے لئے ، مزید ہدایات کے ساتھ اپنے ان باکس میں ای میل کی جانچ کریں۔ اگر آپ کو ای میل نہیں مل پاتا ہے تو ، براہ کرم اپنے اسپام فولڈر کو چیک کریں",
  "page.resendConfirmation": " تصدیق دوبارہ بھیجیں ",
  "page.forgotPassword": "اپنا پاس ورڈ بھول گئے",
  "page.forgotPassword.message":
    "پاس ورڈ دوبارہ ترتیب دینے کے لئے ای میل درج کریں",
  "page.password2fa": "2 ایف اے کی توثیق",
  "page.password2fa.message": "ایپ سے 2fa کوڈ درج کریں",
  "page.forgotPassword.email": " ای میل",
  "page.forgotPassword.send": " بھیجیں",
  "page.noDataToShow": "ظاہر کرنے کے لئے کوئی ڈیٹا نہیں ہے ",
  "page.modal.withdraw.success": "کامیابی",
  "page.modal.withdraw.success.message.content":
    " آپ کی واپسی کی درخواست جیسا کہ موصول ہوا ہے",
  "page.modal.withdraw.success.button": "ٹھیک ہے",
  // success messages
  "success.logout": "آٹو لاگ آؤٹ کامیابی ",
  "success.documents.accepted": "دستاویزات اپ لوڈ کرنا کامیاب رہا",
  "success.documents.fetched": "دستاویزات کامیاب لائے",
  "success.withdraw.action": "واپسی کی درخواست موصول ہوئی",
  "success.otp.enabled": "2FA قابل تھا",
  "success.password.changed": "پاس ورڈ تبدیل کر دیا گیا تھا",
  "success.payable_currency.updated": "کرنسی کی تازہ کاری ہوئی",
  "error.currency_already_assigned": "کرنسی پہلے ہی تفویض کی گئی ہے",
  "success.password.forgot":
    " پاس ورڈ دوبارہ ترتیب دینے والا لنک آپ کے ای میل پر بھیج دیا گیا ہے",
  "success.password.changed.successfuly": " پاس ورڈ تبدیل کر دیا گیا تھا",
  "success.order.canceled": "آرڈر منسوخ کردیا گیا تھا",
  "success.order.canceled.all": "تمام احکامات منسوخ کردیئے گئے",
  "success.order.created": "آرڈر بنایا گیا تھا",
  "success.phone.verification.send":
    " آپ کے فون کی تصدیق ہوگئی توثیقی کوڈ آپ کے فون پر بھیجا گیا تھا",
  "success.phone.confirmed": " پیغام بھیجا گیا تھا",
  "success.message.sent": " تم ارسال الرسالة",
  "success.email.confirmed":
    "آپ کے ای میل ایڈریس کی کامیابی کی تصدیق // غلطی کے پیغامات // بارونگ سے ہوگئی ہے",
  "resource.labels.private":
    "کوئی سرگرمی ریکارڈ نہیں ہوئی یا غلط عنوان لیبل کو اپ ڈیٹ نہیں کرسکتے ہیں",
  "resource.user.no_activity": "کوئی سرگرمی ریکارڈ نہیں کی گئی یا عنوان غلط ہے",
  "resource.profile.not_exist": " صارف کے پاس کوئی پروفائل نہیں ہے",
  "resource.profile.exist": "پروفائل پہلے سے موجود ہے ",
  "resource.api_key.2fa_disabled": " صرف 2FA فعال اکاؤنٹس کی اجازت ہے",
  "resource.api_key.missing_otp":
    "اکاؤنٹ نے 2 ایف اے کو فعال کیا ہے لیکن او ٹی پی کوڈ غائب ہے",
  "resource.api_key.invalid_otp": "OTP کوڈ غلط ہے",
  "resource.phone.twillio": " ٹویلیو کلائنٹ کے ساتھ کچھ غلط ہے ",
  "resource.phone.invalid_num": "فون نمبر غلط ہے",
  "resource.phone.exists": "فون نمبر پہلے ہی موجود ہے",
  "resource.phone.number_exist": "فون نہیں ملا ، یا توثیقی کوڈ غلط ہے",
  "resource.phone.verification_invalid":
    "دستاویزات کی زیادہ سے زیادہ تعداد پہلے ہی پہنچ چکی ہے",
  "resource.phone.please.provide.existing.phone.number":
    "براہ کرم ایک موجودہ فون نمبر فراہم کریں۔",
  "resource.documents.limit_reached":
    "اس اپ لوڈ کے ذریعہ دستاویزات کی رقم حد تک پہنچ جائے گی",
  "resource.documents.limit_will_be_reached":
    "اس اکاؤنٹ کے لئے 2 ایف اے پہلے ہی فعال کردی گئی ہے",
  "resource.otp.already_enabled": "تم تفعيل ال 2FA  الخاص بهذا الحساب",
  "resource.otp.invalid": " OTP کوڈ غلط ہے",
  "resource.password.doesnt_match":
    " سابقہ ​​پاس ورڈ درست نہیں ہے نئے پاس ورڈ مماثل نہیں ہیں",
  "resource.password.prev_pass_not_correct":
    "نیا پاس ورڈ ایک جیسا نہیں ہوسکتا ، جتنا پرانا ہے",
  "resource.password.no_change_provided": "میعاد ختم ہونے کی تاریخ غلط ہے",
  "resource.document.empty_doc_expire":
    "پاس ورڈ کم سے کم ضروریات کو پورا نہیں کرتا ہے",
  "password.requirements":
    "پاس ورڈ بہت کمزور ہے ، براہ کرم پاس ورڈ کی لمبائی یا پیچیدگی میں اضافہ کریں ، ای میل لیا ، ای میل پہلے سے استعمال میں ہے۔",
  "password.password.password_strength":
    "پاس ورڈ بہت کمزور ہے ، براہ کرم حروف کی تعداد یا پیچیدگی میں اضافہ کرکے اس کی طاقت میں اضافہ کریں ",

  "email.taken": "ای میل پہلے سے موجود ہے",
  "email.invalid": "غلط ای میل",

  "identity.user.invalid_referral_format": " غلط ریفرل UID فارمیٹ",
  "identity.user.referral_doesnt_exist": "ریفرل موجود نہیں ہے",
  "identity.user.active_or_doesnt_exist":
    " صارف موجود نہیں ہے یا پہلے ہی چالو ہوچکا ہے",
  "identity.password.user_doesnt_exist": "صارف موجود نہیں ہے",
  "identity.user.passwords_doesnt_match": " پاس ورڈ مماثل نہیں ہیں",
  "identity.user.utilized_token": "جے ڈبلیو ٹی پہلے ہی استعمال ہوچکا ہے",
  "identity.session.invalid_login_params": "غلط ای میل یا پاس ورڈ",
  "identity.session.invalid": "غلط سیشن ",
  "identity.captcha.required": "کیپچا جواب ضروری ہے",
  "identity.captcha.mandatory_fields": "لازمی کھیتوں کو ضرور پُر کرنا چاہئے",
  "identity.session.not_active": "آپ کا اکاؤنٹ فعال نہیں ہے",
  "identity.session.banned": "آپ کے اکاؤنٹ پر پابندی ہے",
  "identity.session.invalid_params": "غلط ای میل یا پاس ورڈ ",
  "identity.session.missing_otp":
    "اکاؤنٹ نے 2fA کو فعال کیا ہے لیکن او ٹی پی کوڈ غائب ہے",
  "identity.session.invalid_otp": "OTP کوڈ غلط ہے",
  "first_name.invalid": " پہلا نام غلط ہے",
  "last_name.invalid": "آخری نام غلط ہے",
  "city.invalid": " شہر غلط ہے",
  "postcode.invalid": "پوسٹ کوڈ غلط ہے",
  "address.invalid": "پتہ غلط ہے",
  "first_name.blank": "پہلا نام غائب یا خالی ہے",
  "last_name.blank": "آخری نام غائب یا خالی ہے",
  "dob.blank": "تاریخ پیدائش غلط ہے",
  "address.blank": "پتہ غائب یا خالی ہے",
  "city.blank": "اشہر لاپتہ یا خالی ہے",
  "country.blank": " ملک غائب ہے یا خالی ہے",
  "postcode.blank": "Postcode.blank ملک غائب ہے یا خالی ہے",
  "country.must have alpha2 or alpha3 format":
    "پوسٹ کوڈ. خالی ، لاپتہ یا خالی ہے ، ملک۔ الفا 2 یا الفافی شکل ہونی چاہئے",
  "totp.error": "OTP کوڈ غلط ہے",
  "record.not_found": " ریکارڈ نہیں ملا ہے",
  "jwt.decode_and_verify": "جے ڈبلیو ٹی کو ڈی کوڈ اور تصدیق کرنے میں ناکام",
  "authz.invalid_session": "کوکیز کو ڈی کوڈ کرنے میں ناکام",
  "authz.user_not_active": "صارف فعال نہیں ہے",
  "authz.invalid_signature": "API کلیدی ہیڈر ‘دستخط’ غلط ہے",
  "authz.apikey_not_active": "حالة مفتاح ال API  غير نشطة",
  "authz.disabled_2fa": "API کلید غیر فعال ہے",
  "authz.invalid_api_key_headers":
    "API کلیدی مالک نے 2 ایف اے کو غیر فعال کر دیا ہے",
  "authz.permission_denied": "خالی یا لاپتہ API کلیدی ہیڈر",
  "authz.unexistent_apikey":
    "X-Auth-API کلیدی ہیڈر غلط ہے ، // توثیق کی غلطیاں // شناختی ماڈیول",
  "identity.user.missing_email": "ای میل غائب ہے",
  "identity.user.empty_email": "ای میل غائب یا خالی ہے",
  "identity.user.missing_password": "پاس ورڈ غائب ہے",
  "identity.user.empty_password": "پاس ورڈ غائب یا خالی ہے",
  "identity.user.missing_token": "ٹوکن غائب ہے",
  "identity.user.empty_token": " ٹوکن غائب یا خالی ہے",
  "identity.user.missing_reset_password_token": "ری سیٹ پاس ورڈ ٹوکن غائب ہے",
  "identity.user.empty_reset_password_token":
    " ری سیٹ پاس ورڈ ٹوکن غائب یا خالی ہے",
  "identity.user.missing_confirm_password": "تصدیق شدہ پاس ورڈ غائب ہے",
  "identity.user.empty_confirm_password": "تصدیق شدہ پاس ورڈ غائب یا خالی ہے",
  "identity.session.missing_emai": "ای میل غائب ہے",
  "identity.session.missing_password": " پاس ورڈ غائب ہے",
  "identity.session.invalid_captcha_format":
    ",غلط کیپچا فارمیٹ // وسائل ماڈیول ",
  "resource.otp.missing_code": " OTP کوڈ غائب ہے",
  "resource.otp.empty_code": "OTP کوڈ غائب یا خالی ہے ",
  "resource.labels.missing_key": "چابی غائب ہے",
  "resource.labels.empty_key": "کلید گم یا خالی ہے",
  "resource.labels.missing_value": " قدر غائب ہے",
  "resource.labels.empty_value": " ائب ہے یا خالی ہے قدر غ",
  "resource.documents.missing_doc_expire":
    "دستاویزات کی میعاد ختم ہونے کی تاریخ غائب ہے",
  "resource.documents.empty_doc_expire":
    "دستاویزات کی میعاد ختم ہونے کی تاریخ گم یا خالی ہے",
  "resource.documents.missing_doc_type": " ہے دستاویزات کی قسم غائب",

  "resource.documents.empty_doc_type": "دستاویزات کی قسم گم یا خالی ہے",
  "resource.documents.missing_doc_number": "دستاویزات کا نمبر غائب ہے",
  "resource.documents.empty_doc_number": "دستاویزات کا نمبر غائب یا خالی ہے",
  "resource.documents.missing_upload": "ملحق غائب ہے",
  "resource.user.missing_topic": " عنوان غائب ہے",
  "resource.user.empty_topic": "عنوان غائب یا خالی ہے",
  "resource.user.missing_old_password": "پرانا پاس ورڈ غائب ہے",
  "resource.user.empty_old_password": "پرانا پاس ورڈ غائب یا خالی ہے",
  "resource.user.missing_new_password": "نیا پاس ورڈ غائب ہے",
  "resource.user.empty_new_password": "نیا پاس ورڈ غائب یا خالی ہے",
  "resource.user.missing_confirm_password": "تصدیق شدہ پاس ورڈ غائب ہے",
  "resource.user.empty_confirm_password": "تصدیق شدہ پاس ورڈ غائب یا خالی ہے",
  "resource.profile.missing_first_name": "پہلا نام غائب ہے",
  "resource.profile.missing_last_name": "آخری نام غائب ہے",
  "resource.profile.missing_dob": " تاریخ پیدائش غائب ہے",
  "resource.profile.missing_address": " پتہ غائب ہے",
  "resource.profile.missing_postcode": "پوسٹ کوڈ غائب ہے",
  "resource.profile.missing_city": "شہر لاپتہ ہے",
  "resource.profile.missing_country": " ملک لاپتہ ہے",
  "resource.api_key.missing_algorithm": "الگورتھم غائب ہے",
  "resource.api_key.empty_algorithm": "الگورتھم غائب یا خالی ہے",
  "resource.api_key.empty_kid": "KID غائب یا خالی ہے ",
  "resource.api_key.empty_scope": "دائرہ کار غائب یا خالی ہے",
  "resource.api_key.missing_totp": "TOTP کوڈ غائب یا خالی ہے",
  "resource.api_key.empty_totp": "TOTP خالی ہے یا غائب ہے",
  "resource.api_key.missing_kid": "KID غائب ہے",
  "resource.api_key.empty_state": "ریاست لاپتہ ہے یا خالی ہے",
  "resource.phone.missing_phone_number": "فون نمبر غائب ہے",
  "resource.phone.empty_phone_number": "فون نمبر غائب یا خالی ہے",
  "resource.phone.missing_verification_code": "توثیقی کوڈ غائب ہے",
  "resource.phone.empty_verification_code":
    "توثیقی کوڈ غائب ہے یا خالی ہے // پیٹیو ",
  "account.currency.doesnt_exist": "کرنسی موجود نہیں ہے",
  "account.deposit.invalid_state": "جمع غیرقانونی",
  "account.deposit.non_integer_limit":
    "آپ نے جو قدر بھیجی ہے اس کی پوری تعداد میں قسم نہیں کی جاسکتی ہے",
  "account.deposit.invalid_limit": "غلط حد",
  "account.deposit.non_positive_page": "صفحہ کی قدر مثبت ہونی چاہئے",
  "account.deposit.empty_txid": "TXID غائب ہے ، TXID خالی ہے",
  "account.deposit_address.invalid_address_format": "غلط ڈپازٹ ایڈریس فارمیٹ",
  "account.deposit_address.doesnt_support_cash_address_format":
    " کرنسی کیش ایڈریس فارمیٹ کی حمایت نہیں کرتی ہے",
  "account.withdraw.non_integer_limit": "آپ کی بھیجنے والی محدود قدر کا انٹیجر",
  "account.withdraw.invalid_limit":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "account.withdraw.non_positive_page": "صفحہ کی قدر مثبت ہونی چاہئے",
  "account.withdraw.non_integer_otp":
    "  OTPویلیو کو انٹیجر قسم میں پارس نہیں کیا جاسکتا",
  "account.withdraw.empty_otp": "  غائب ہے ، OTP خالی ہے",
  "account.withdraw.empty_rid": "التخلص من المفقودين ، والتخلص فارغ",
  "account.withdraw.non_decimal_amount":
    " آپ جو رقم بھیجتے ہیں اس کو اعشاریہ میں تبدیل نہیں کیا جاسکتا",
  "account.withdraw.non_positive_amount": "رقم کی قیمت مثبت ہونی چاہئے",
  "account.deposit.not_permitted": "فون کی توثیق کے بعد ڈپازٹ کی اجازت ہے",
  "account.withdraw.not_permitted":
    "براہ کرم رقوم کی واپسی کے لئے تصدیق کے اسی مراحل سے گزریں",
  "account.withdraw.insufficient_balance": "اکاؤنٹ میں بیلنس ناکافی ہے",
  "account.withdraw.invalid_amount": "واپسی کی غلط رقم",
  "account.withdraw.create_error": "واپسی بنانے میں ناکام",
  "account.withdraw.invalid_otp": "غلط او پی ٹی",
  "account.withdraw.disabled_api": "واپسی کا APL غیر فعال ہے",

  "market.conversion.conversion_error": "تبادلوں کی قیمت ختم ہو گئی",

  "market.market.doesnt_exist_or_not_enabled": " مارکیٹ موجود نہیں ہے",
  "market.order.invalid_state": "غلط جمع ریاست",
  "market.order.invalid_limit": "غلط حد",
  "market.order.non_integer_limit":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے ",
  "market.trade.empty_page": "صفحہ غائب یا خالی ہے ",
  "market.order.invalid_order_by": " غلط آرڈر_ کے ذریعے",
  "market.order.invalid_side": "غلط آرڈر کی طرف",
  "market.order.non_decimal_volume":
    "حجم قیمت جو آپ نے بھیجی ہے اس کو اعشاریہ میں تبدیل نہیں کیا جاسکا",
  "market.order.non_positive_volume": " حجم کی قیمت مثبت ہونی چاہئے",
  "market.order.invalid_type": "غلط حکم",
  "market.order.non_decimal_price":
    "آپ کی بھیجنے والی حجم کی قیمت کو اعشاریہ میں تبدیل نہیں کیا جاسکا",
  "market.order.non_positive_price": "حجم کی قیمت مثبت ہونی چاہئے",
  "market.order.non_integer_id":
    "آپ کے بھیجنے والے ID ویلیو کا صحیح تعداد میں تجزیہ نہیں کیا جاسکتا ہے",
  "market.order.empty_id": "ID گم یا خالی ہے",
  "market.trade.non_integer_limit":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "market.trade.invalid_limit": "غلط حد",
  "market.trade.non_integer_timestamp":
    "آپ نے جو ٹائم اسٹیمپ ویلیو ارسال کیا ہے اس کا انٹیجر قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "market.trade.empty_timestamp": "ٹائم اسٹیمپ غائب یا خالی ہے",
  "market.trade.invalid_order_by": " غلط آرڈر_ کے ذریعے",
  "market.order.insufficient_market_liquidity": "ناکافی مارکیٹ میں لیکویڈیٹی",
  "market.order.invalid_volume_or_price": "غلط حجم یا قیمت",
  "market.order.create_error": "غلطی پیدا کرنے میں ناکام",
  "market.order.cancel_error": "غلطی منسوخ کرنے میں ناکام",
  "market.order.market_order_price": "مارکیٹ آرڈر کی قیمت نہیں ہے",
  "market.trade.not_permitted":
    "براہ کرم ، تجارت کو قابل بنانے کے ل verification اسی طرح کے تصدیقی مراحل سے گزریں",
  "market.account.insufficient_balance": "اکاؤنٹ میں بیلنس ناکافی ہے",
  "public.currency.doesnt_exist": "کرنسی موجود نہیں ہے",
  "public.currency.invalid_type": " غلط کرنسی کی قسم",
  "public.market.doesnt_exist": "مارکیٹ موجود نہیں ہے",
  "public.order_book.non_integer_ask_limit":
    "پوچھیں حد کی قیمت جو آپ نے بھیجی ہے انفرادی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.order_book.invalid_ask_limit": " بولی کی غلط حد",
  "public.order_book.non_integer_bid_limit":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.order_book.invalid_bid_limit": "بولی کی حد غلط ہے",
  "public.trade.non_integer_limit": "صفحہ کی قدر مثبت ہونی چاہئے",
  "public.trade.invalid_limit": "صفحہ کی قدر مثبت ہونی چاہئے",
  "public.trade.non_positive_page": " غلط آرڈر بذریعہ",
  "public.trade.non_integer_timestamp":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.trade.invalid_order_by": " غلط حد",
  "public.market_depth.non_integer_limit":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.market_depth.invalid_limit": "غلط مدت",
  "public.k_line.non_integer_period":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.k_line.invalid_period": " پیرم سے آنے والا وقت غائب یا خالی ہے",
  "public.k_line.non_integer_time_from":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.k_line.empty_time_from": "پیرم کا وقت غائب یا خالی ہے",
  "public.k_line.non_integer_time_to":
    "آپ کی بھیجنے والی محدود قدر کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکتا ہے",
  "public.k_line.empty_time_to": " پرم گم یا خالی وقت ہے",
  "public.k_line.non_integer_limit":
    "آپ جو مخصوص قیمت بھیج رہے ہیں اس کا انٹیجر کی قسم میں تجزیہ نہیں کیا جاسکا",
  "public.k_line.invalid_limit": "تجارتی چارٹ",
  "server.internal_error": "مارکیٹ کی گہرائی",
  "public.trade.tradingChart-label": "تجارتی چارٹ",
  "public.trade.marketDepth-label": "مارکیٹ کی گہرائی",
  "public.trade.Chart-label-parent": "چارٹ",

  //NEW TRANSLATIONS START HERE
  "page.header.navbar.header": "این",
  "page.signIn.description":
    "ایکسچینج_امینام میں خوش آمدید // تبادلہ نام تبدیل نہ کریں",
  "page.signIn.2faRequired": "براہ کرم 2 ایف اے کوڈ درج کریں",
  "page.signIn.signup": "ابھی رجسٹر ہوں",
  "page.signIn.signupExists": " آپ کے پاس کوئی اکاؤنٹ نہیں ہے ",
  "page.forgotPassword.description":
    "إاگر آپ اپنا پاس ورڈ بھول گئے ہیں ، ٹھیک ہے ، تو ہم آپ کو اپنا پاس ورڈ دوبارہ ترتیب دینے کی ہدایت ای میل کریں گے",
  "page.forgotPassword.back": " الاگ ان واپس",
  "page.forgotPassword.signUp": "ابھی رجسٹر ہوں",
  "page.forgotPassword.signUpExists": "آپ کا اکاؤنٹ نہیں ہے",
  "page.body.forget_password": "پاس ورڈ بھول گئے",
  "page.signUp.description":
    "(Exchange_name) میں خوش آمدید ، تبادلہ نام تبدیل نہ کریں",
  "page.signUp.signin": "یہاں لاگ ان کریں",
  "page.signUp.signinExists": " پہلے ہی ایک اکاؤنٹ ہے",
  "page.profile": "پروفائل",
  "page.profile.profileDetailsTitle": " پروفائل کی تفصیلات",
  "page.profile.referralTitle": " ریفرل کے ساتھ کمائیں",
  "page.profile.payableCurrencyPlaceholder":
    "فی الحال اپنے قابل ادائیگی کو منتخب کریں",
  "page.profile.referralDescription":
    "اپنے دوستوں اور اہل خانہ کو مدعو کریں اور بونس وصول کریں۔ شراکت کی قیمت کا 15٪",
  "page.profile.2faTitle": "دو فیکٹر توثیق",
  "page.profile.2faDescription":
    "دو فیکٹر تصدیق آپ کے ویب اکاؤنٹ کے تحفظ کے لئے ایک طریقہ ہے۔ جب یہ متحرک ہوجاتا ہے تو آپ کو نہ صرف اپنا پاس ورڈ ، بلکہ ایک خاص کوڈ بھی داخل کرنے کی ضرورت ہوتی ہے۔ آپ یہ کوڈ موبائل ایپ کے ذریعہ وصول کرسکتے ہیں۔ یہاں تک کہ اگر تیسرا شخص آپ کا پاس ورڈ ڈھونڈ لے گا ، تو پھر اس کوڈ کے ساتھ رسائی حاصل نہیں کرسکتی ہے۔",
  "page.profile.currentStatus": " موجودہ صورتحال",
  "page.profile.kycTitle": "شناخت کی تصدیق - کے وائی سی",
  "page.emailAddress": "ای میل پتہ",
  "page.uid": "UID",
  "page.show": " دکھائیں",
  "page.hide": "چھپائیں",
  "page.payableCurrency": " قابل ادائیگی کرنسی",
  "page.body.wallet.currencyTitle": " کرنسی",
  "page.body.wallet.currencyName": " نام",
  "page.body.wallet.availableBalance": " دستیاب ہے",
  "page.body.wallet.lockedBalance": "مقفل ہے",
  "page.body.wallet.actionTitle": "ایکشن",
  "page.body.wallet.action.deposit": " جمع کروانا",
  "page.body.wallet.action.withdraw": "  واپس لے لو",
  "page.header.navbar.2fa": "دو عنصر کی توثیق",
  "page.header.navbar.forgetPassword": "دو فیکٹر توثیق",
  "page.header.navbar.emailVerification": "ای میل کی تصدیق",
  "page.body.confirm.title": "اپنی شناختی توثیق شروع کریں",
  "page.body.confirm.subTitle": "اپنی شناخت کی تصدیق کریں.",
  "success.identity.submitted": " پروفائل پیش کیا گیا",
  "page.body.profile.content.action.signup": "سائن اپ",

  "page.body.profile.beneficiaries.header": "میرے فائدہ اٹھانے والے",
  "page.body.profile.beneficiaries.header.create": " نیا بنائیں",
  "page.body.profile.beneficiaries.noBeneficiaries": "آپ کو کوئی فائدہ نہیں ہے",
  "page.body.profile.beneficiaries.modal.form.selectCurrency":
    " کرنسی منتخب کریں",
  "page.body.profile.beneficiaries.modal.form.name": "​​فائدہ مند حوالہ نام",
  "page.body.profile.beneficiaries.modal.form.description": "فائدہ مند تفصیل",
  "page.body.profile.beneficiaries.modal.form.address": "فائدہ مند ایڈریس",
  "page.body.profile.beneficiaries.modal.form.accountNumber":
    " فائدہ مند بینک اکاؤنٹ نمبر",
  "page.body.profile.beneficiaries.modal.form.swift":
    "فائدہ مند بینک سوئفٹ کوڈ",
  "page.body.profile.beneficiaries.modal.form.fullName":
    " فائدہ اٹھانے والا پورا نام ",
  "page.body.profile.beneficiaries.modal.form.submitFailed":
    "براہ کرم چیک کریں کہ آیا تمام مطلوبہ ضرورت پُر ہے؟",
  "page.body.profile.beneficiaries.modal.form.alreadyActive":
    "فائدہ اٹھانے والا پہلے سے ہی فعال ہے آپ اسے حذف کرسکتے ہیں",
  "page.body.profile.beneficiaries.modal.form.activationCode":
    "فائدہ مند ایکٹیویشن کوڈ",
  "page.body.profile.beneficiaries.modal.form.deleteConfirm":
    "کیا آپ واقعتا اس فائدہ اٹھانے والے کو حذف کرنا چاہتے ہیں؟",
  "page.body.profile.beneficiaries.modal.btn.show": "دکھائیں",
  "page.body.profile.beneficiaries.modal.btn.close": "بند",
  "page.body.profile.beneficiaries.modal.btn.create": " تصدیق کریں",
  "page.body.profile.beneficiaries.modal.btn.activate": "چالو کریں",
  "page.body.profile.beneficiaries.modal.btn.disabled": " غیر فعال",
  "page.body.profile.beneficiaries.modal.btn.delete": "حذف کریں",
  "page.body.profile.beneficiaries.modal.header":
    " فائدہ اٹھانے والوں کا انتظام کریں",
  "page.body.profile.beneficiaries.modal.created_header": " تشکیل دیا گیا",
  "page.body.profile.beneficiaries.table.header.currency": " کرنسی",
  "page.body.profile.beneficiaries.table.header.description": "تفصیل",
  "page.body.profile.beneficiaries.table.header.name": "نام",
  "page.body.profile.beneficiaries.table.header.address": "  کریپٹو ایڈریس",
  "page.body.profile.beneficiaries.table.header.swift": " سوئفٹ",
  "page.body.profile.beneficiaries.table.header.accountNumber": "اکاؤنٹ نمبر",
  "page.body.profile.beneficiaries.table.header.fullName": " پورا نام",
  "page.body.profile.beneficiaries.table.header.state": "ریاست",
  "success.beneficiaries.fetched":
    "کامیابی سے فائدہ اٹھانے والوں کو بازیافت کیا",
  "success.beneficiaries.created": "فائدہ اٹھانے والا بنایا گیا تھا",
  "success.beneficiaries.updated": "فائدہ اٹھانے والے کو اپ ڈیٹ کیا گیا تھا",
  "success.beneficiaries.deleted": "فائدہ اٹھانے والا حذف کردیا گیا تھا",
  "account.beneficiary.non_integer_id": "فائدہ اٹھانے والا ID نہیں ملا",
  "account.beneficiary.invalid_pin": "Invalid Beneficiary Pin",
  "account.beneficiary.invalid_state_for_withdrawal": "Invalid Beneficiary",
  "page.body.wallets.tabs.withdraw.modal.message2": " فائدہ اٹھانے والے پر",
  "page.body.wallets.tabs.withdraw.form.toggleLabel":
    "فائدہ اٹھانے والا استعمال کریں",
  "page.body.wallets.tabs.withdraw.form.beneficiaryPlaceHolder":
    " مستفید منتخب کریں",
  "page.body.wallets.tabs.withdraw.form.addNewBeneficiary":
    "نیا مستفید شامل کریں",
  "currency.currency_doesnt_exist_or_not_payable":
    "کرنسی موجود نہیں ہے یا قابل ادائیگی نہیں ہے",

  "resource.documents.already_expired":
    " آپ جس دستاویزات کو اپ لوڈ کرنے کی کوشش کر رہے ہیں ان کی میعاد پہلے ہی ختم ہوگئی ہے",
  "resource.documents.invalid_format":
    " دستاویزات لازمی ہونی چاہ and اور میعاد ختم نہ ہو",
  "success.beneficiaries.pinResend": " آپ کے رجسٹرڈ ای میل پر پن سے ناراضگی",
  "account.beneficiary.cant_resend_within_1_minute":
    " پن پہلے ہی بھیج دیا گیا ہے۔ براہ کرم پن کو دوبارہ بھیجنے کے لئے 1 منٹ انتظار کریں",

  "page.header.navbar.otc": "او ٹی سی",

  "page.trading.toolbar.progressLabel.lowest24": "کم ترین 24h",
  "page.trading.toolbar.progressLabel.lastPrice": "آخری قیمت",
  "page.trading.toolbar.progressLabel.highest24": " اعلی 24h",
  "page.trading.toolbar.progressLabel.volume24": " 24h جلد",
  "page.trading.toolbar.progressLabel.change": " تبدیلی",

  "page.profile.section.profile.title": " میرا پروفائل",
  "page.profile.section.referral.title": "ریفرل",
  "page.profile.section.accountActivity.title": " سرگرمی",
  "page.profile.section.api.title": "API",
  "page.profile.section.beneficiary.title": "  فائدہ اٹھانے والے",

  "page.signUp.PEP.text": "پی ای پی",
  "page.signUp.termsAndService.text": "شرائط اور خدمت",
  "page.signUp.PrivacyPolicy.text": "رازداری کی پالیسی",
  "page.signUp.risks.text": "کریپٹو کرنسی سے متعلق خطرات",

  "page.header.signUp.username": " صارف نام ",
  "page.sigin.welcomeText": "آئیے لاگ ان کے ساتھ آغاز کریں",
  "page.signUp.button": "سائن اپ",
  "page.wallets.portfolio.text": "پورٹ فولیو کی کل قیمت",
  "page.wallets.label.hideEmptyWallets": "خالی بٹوے چھپائیں",

  "page.history.withdraw.details": "تفصیلات واپس لیں",
  "page.history.deposit.details": "جمع کروانے کی تفصیلات",
  "page.markets.search.text": "سکے تلاش کریں",
  "page.productMenu.home": "گھر",
  "page.productMenu.p2p": "پی 2 پی",
  "page.auth.welcome.message": "آئیے لاگ ان کے ساتھ آغاز کریں!",
  "page.body.wallets.min_deposit_warning":
    "کم سے کم ڈپازٹ فی الحال ہے: {min_amount} {کرنسی}",
  "page.body.wallets.mining_deposit_warning":
    "براہ کرم براہ راست اپنے ڈپازٹ ایڈریس پر نہ جائیں ، کیونکہ یہ غیر اعلانیہ تبدیل ہوسکتا ہے۔ کھوئے ہوئے ذخائر جمع نہیں ہوں گے۔",
  "page.body.header.markets": "مارکیٹس",
  "page.body.header.charts": "چارٹس",
  "page.body.header.myOrders": "میرے احکام",
  "page.body.header.trades": "تجارت",
  "page.body.header.orders": "آرڈر بک",
  "page.body.header.buySell": "خرید و فروخت",

  "page.body.wallets.noWallets": "کوئی بٹوے نہیں ملے",
  "page.body.dark.version": "سیاہ ورژن",

  "page.productMenu.market": "مارکیٹ",
  "page.productMenu.exchange": "تبادلہ",
  "page.productMenu.app": "ایپ",
  "page.productMenu.cards": "کارڈز",
  "page.productMenu.mining": "کان کنی",
  "page.productMenu.staking": "سٹیکنگ",
  "page.productMenu.defi": "ڈی فائی",
  "page.productMenu.convert": "Convert",

  "username.taken": "صارف نام استعمال میں ہے",
  "username.too_long": "صارف نام بہت لمبا ہے۔",
  "username.too_short": "صارف نام بہت چھوٹا ہے۔",

  "account.update.sms_authentication_failed.resend_otp_again":
    "کچھ غلط ہو گیا ہے، براہ کرم کوڈز چیک کریں",

  ...nationalitiesNames
}
