const getBuyHistoryStateColor = (state = "") => {
  if (
    state === "reviewing" ||
    state === "processing" ||
    state === "awaiting_transfer_in"
  )
    return "var(--warning-color)"
  if (state === "completed") return "var(--success-color)"
  if (state === "rejected" || state === "expired" || state === "cancelled")
    return "var(--danger-color)"
  return "var(--text-color)"
}

export default getBuyHistoryStateColor
