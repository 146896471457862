import { API } from "../../../../utility/API"
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"
// import { persistStore } from 'redux-persist'
//************************************//
export const _getCurrencies = (callback) => {
  API.get(`/trading/public/currencies`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {})
}
//************************************//
export const _addOrder = (data, callback, callbackErr) => {
  API.post(`trading/market/orders`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getMarkets24h = (data, callback, callbackErr) => {
  API.get(`trading/public/markets/markets_24h`, {
    params: data
  })
    .then(function ({ data, headers }) {
      callback({ data, page: headers["page"], total: headers["total"] })
    })
    .catch(function ({ data }) {})
}
//************************************//
export const _getCoinKline = (data, callback, callbackErr) => {
  const currentTime = Math.floor(Date.now() / 1000)
  // let url = `trading/public/markets/k-line?period=30&time_from=${currentTime - (24 * 60 * 60)}&time_to=${currentTime}`
  let url = `trading/public/markets/k-line?period=1440`
  if (data && data.pairs && data.pairs.length > 0) {
    url += data.pairs.map((pair) => `&markets[]=${pair}`).join("")
  }
  API.get(url, { params: data })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
