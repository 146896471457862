// ** React Imports
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

// ** Styles Imports
import Styles from "./styles.module.css"

// ** Assets Imports
import GridBackground from "../../assets/background.png"
import GridBackgroundLight from "../../assets/background-light.png"

// ** Icons Imports
import { CgProfile } from "react-icons/cg"
import { AiOutlineClockCircle } from "react-icons/ai"

// ** API Imports
import { _getBlog } from "../../redux/actions"

// ** Utils Imports
import { showErrorSnackbar } from "../../../snackbar/helpers"
import { useSelector } from "react-redux"
import { formatDate } from "../../../../utility/formatDate"
import { randomizeShimmerWidth } from "../../../../components/shimmer-components/helpers"

// ** Component Imports
import BlogCategoryCard from "../../../../components/BlogCategoryCard"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"

function ViewBlog() {
  // ** Hooks
  const { id } = useParams()

  // ** States
  const [blogData, setBlogData] = useState(null)
  const theme = useSelector((state) => state.theme.value)

  useEffect(() => {
    _getBlog(
      id,
      (data) => setBlogData(data.data.Blog),
      (err) => showErrorSnackbar({ alertMessage: "Error  while fetching blog" })
    )
  }, [])

  return (
    <div>
      {/* Header */}
      <div className={Styles.pageHeader}>
        <img
          src={theme === "dark" ? GridBackground : GridBackgroundLight}
          className={Styles.headerBackground}
        />
        {/* <span>Blog & Articles</span> */}
        <span>
          {blogData?.categories.map(
            (el, index) =>
              `${el.category.name}${
                index !== blogData?.categories.length - 1 ? " & " : ""
              }`
          )}
        </span>
        <h1>{blogData?.title}</h1>
      </div>

      {/* BLOG */}
      {blogData ? (
        <div className={Styles.container}>
          <div className={Styles.blogImg}></div>

          <div className={Styles.blogInfoContainer}>
            <span>
              <CgProfile color="var(--primary-color)" size={25} />
              {blogData.author?.name}
            </span>
            <span>
              <AiOutlineClockCircle color="var(--primary-color)" size={25} />
              {formatDate(blogData.created_at)}
            </span>
            <div className={Styles.categoriesContainer}>
              {blogData?.categories.map((el) => (
                <BlogCategoryCard
                  name={el.category.name}
                  key={el.category.id}
                />
              ))}
            </div>
          </div>

          <div className={Styles.contentContainer}>
            {/* <h1 className={Styles.blogTitle}>{blogData.title}</h1> */}
            {/* BLOG CONTENT */}
            <div
              dangerouslySetInnerHTML={{ __html: blogData.content }}
              style={{ margin: "100px 0" }}
            />
          </div>
        </div>
      ) : (
        <div className={Styles.shimmerContainer}>
          {new Array(11).fill(0).map(() => (
            <ShimmerLine
              style={{ margin: "10px auto" }}
              width={randomizeShimmerWidth(120, 420)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ViewBlog
