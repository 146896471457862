// ** React Imports
import React from 'react'

// ** Styles Imports
import styles from './styles.module.css'

// ** Redux Imports
import { useSelector } from 'react-redux'

// ** Icon Imports
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/imported/checkbox-circle-checked.svg'

// ** Component Imports
import Button from '../../../../components/Button/Button'
import WenbitModal from '../../../../components/Modal/Modal'

// ** Helpers Imports
import { hideStakingSubscribeSuccessModal } from './helpers'

function StakingSubscribeSuccessModal() {
  // ** States
  const modalOpen = useSelector(state => state.statkingSubscribeSuccessModalState.open)
  const modalData = useSelector(state => state.statkingSubscribeSuccessModalState.data)

  // ** Handlers
  const handleClose = () => {
    hideStakingSubscribeSuccessModal()
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        showCloseButton={true}
        onClose={handleClose}
        title={modalData?.title}
        render={() => (
          <div className={styles.container}>
            <div className={styles.successIconContainer}>
              <SuccessIcon color='var(--primary-color)' style={{ transform: 'scale(1.9)' }} />
            </div>
            <h1>Subscription Successful</h1>
            <span className={styles.amount}>1 BTC</span>
            <p className={styles.description}>
              Congratulations, Real-Time APR rewards will start accumulating in your Wallet every minute!
            </p>

            <div className={styles.buttonsContainer}>
              <Button text='Back' variant='dark' />
              <Button text='View History' />
            </div>
          </div>
        )}
      />
    </>
  )
}

export default StakingSubscribeSuccessModal
