import React from "react"

// ** Styles Imports
import Styles from "./jobApplySection.module.css"

// ** Icons Imports
import { ReactComponent as TempIcon } from "../../../assets/icons/imported/add-user.svg"

// ** Utils Imports
import { formatDate } from "../../../utility/formatDate"
import { formatDateTime } from "../../../utility/formatDateTime"
import { randomizeShimmerWidth } from "../../shimmer-components/helpers"

// ** Component Imports
import ShimmerLine from "../../shimmer-components/ShimmerLine"
import JobApplyCard from "../JobApplyCard/JobApplyCard"

function JobApplySection({ title, jobs = [], loading = false }) {
  return (
    <section>
      {!loading && title && (
        <div className={Styles.header}>
          <div className={Styles.iconContainer}>
            <TempIcon width="100%" />
          </div>
          <h2>{title}</h2>
        </div>
      )}

      <div className={Styles.jobsContainer}>
        {!loading ? (
          jobs.map((job) => {
            return (
              <JobApplyCard
                id={job.id}
                title={job.title}
                description={job.summary}
                datePosted={formatDateTime(job.created_at)}
              />
            )
          })
        ) : (
          <>
            {new Array(5).fill(0).map((el) => (
              <div style={{ marginBottom: 50 }}>
                <ShimmerLine width="200px" height="50px" />
                <ShimmerLine
                  width={randomizeShimmerWidth(233, 333)}
                  height="20px"
                />
                <ShimmerLine
                  width={randomizeShimmerWidth(233, 333)}
                  height="20px"
                />
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  )
}

export default JobApplySection
