// ** React Imports
import React, { useEffect, useState } from 'react'

// ** API Imports
import { _getPublicStakingProducts } from './redux/actions'

// ** Component Imports
import FAQ from './components/FAQ'
import PageHeader from './components/pageHeader'
import Button from '../../components/Button/Button'
import StakingTable from './components/StakingTable'
import StakingTableFilters from './components/StakingTableFilters'

function Staking() {
  // ** Tab Title
  document.title = 'Wenbit: Staking'

  // ** Pagination/Filtering Params
  const [paginationParams, setPaginationParams] = useState({ page: 1, limit: 10 })
  const [filteringParams, setFilteringParams] = useState({ duration: null, searchQuery: null })

  // ** States
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [canFetchMore, setCanFetchMore] = useState(true)

  // ** Handlers
  const fetchStakingProducts = (page, limit, searchQuery) => {
    setLoading(true)
    _getPublicStakingProducts(
      { page, limit, searchQuery },
      res => {
        // Add the selected duration property to be 0 by default
        const dataArray = res.data.map(item => ({
          ...item,
          selectedDuration: item.product_summary_list[0]?.duration
        }))

        setRows(dataArray)
        setLoading(false)

        // If no more data can be fetched
        if (res.data.length < paginationParams.limit) {
          setCanFetchMore(false)
        }
      },
      error => setLoading(false)
    )
  }

  const handleViewMore = () => {
    setPaginationParams(prevParams => {
      return {
        ...prevParams,
        page: prevParams.page + 1
      }
    })
  }

  const handleSearchCoin = q => {
    setFilteringParams(prevParams => ({ ...prevParams, searchQuery: q || null }))
  }

  useEffect(() => {
    fetchStakingProducts(paginationParams.page, paginationParams.limit, filteringParams.searchQuery)
  }, [paginationParams, filteringParams])

  return (
    <div className='pageContainer'>
      <PageHeader />

      <StakingTableFilters filteringParams={filteringParams} setFilteringParams={setFilteringParams} />

      <StakingTable rows={rows} setRows={setRows} loading={loading} />
      {canFetchMore && (
        <Button text='View more' variant='link' style={{ margin: '20px auto' }} onClick={handleViewMore} />
      )}

      <FAQ />
    </div>
  )
}

export default Staking
